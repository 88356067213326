import { forwardRef, useMemo } from 'react';
import { Link, LinkProps, To, useLocation } from 'react-router-dom';
import * as S from 'fp-ts/string';

const PreserveSearchLink = forwardRef<HTMLAnchorElement, LinkProps>(({ to: receivedTo, ...props }, ref) => {
  const location = useLocation();

  const to = useMemo<To>(
    () => (S.isString(receivedTo) ? { ...location, pathname: receivedTo } : { ...location, ...receivedTo }),
    [location, receivedTo],
  );

  return <Link ref={ref} to={to} {...props} />;
});

export default PreserveSearchLink;
