import { FC, ReactElement } from 'react';
import { Editorial } from '@shared/modules/editorial/model';
import Block = Editorial.Block;
import Template = Editorial.Block.Template;
import TitleRenderer from './render/TitleRenderer';
import TextRenderer from './render/TextRenderer';
import ImageRenderer from '@shared/modules/editorial/components/render/ImageRenderer';
import { logSentryMessage } from '@shared/modules/sentry/utils';
import GalleryRenderer from '@shared/modules/editorial/components/render/GalleryRenderer';
import YoutubeRenderer from '@shared/modules/editorial/components/render/YoutubeRenderer';
import LinkRenderer from '@shared/modules/editorial/components/render/LinkRenderer';
import PdfRenderer from '@shared/modules/editorial/components/render/PdfRenderer';
import AddressRenderer from '@shared/modules/editorial/components/render/AddressRenderer';
import ContactRenderer from '@shared/modules/editorial/components/render/ContactRenderer';

const logUnimplemented = (block: Editorial.Block) => {
  const message = `Renderer for block '${block.template}' is not implemented`;
  console.warn(message);
  logSentryMessage(message, 'warning');
  return null;
};

const renderer: { [T in Editorial.Block.Template]: (block: Editorial.Block<T>) => ReactElement | null } = {
  [Template.Title]: block => <TitleRenderer block={block} />,
  [Template.Text]: block => <TextRenderer block={block} />,
  [Template.Image]: block => <ImageRenderer block={block} />,
  [Template.Gallery]: block => <GalleryRenderer block={block} />,
  [Template.Youtube]: block => <YoutubeRenderer block={block} />,
  [Template.Link]: block => <LinkRenderer block={block} />,
  [Template.Pdf]: block => <PdfRenderer block={block} />,
  [Template.Address]: block => <AddressRenderer block={block} />,
  [Template.Contact]: block => <ContactRenderer block={block} />,
};

interface EditorialRendererProps {
  block: Editorial.Block;
}

const BlockRenderer: FC<EditorialRendererProps> = ({ block }) => {
  const component = renderer[block.template];

  if (component) {
    return component(block as any);
  }

  return logUnimplemented(block);
};

export default BlockRenderer;
