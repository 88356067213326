import { z } from 'zod';
import { AlertTrigger } from '@modules/alert-triggers/model';
import { Sensor } from '@modules/sensors/model';
import { Threshold } from '@modules/iot/model';
import { NonEmptyString } from '@shared/schemas';
import { Measure } from '@modules/measures/model';

export namespace AlertTriggersSchema {
  export const toggleStateParams = z.object({
    id: AlertTrigger.Id,
    state: z.nativeEnum(AlertTrigger.State),
  });

  const notificationParams = z.object({
    byPush: z.boolean(),
    byEmail: z.boolean(),
  });

  export const createParams = z.object({
    name: NonEmptyString,
    sensors: z.array(Sensor.Id).min(1),
    type: z.nativeEnum(Measure.Type),
    thresholdLevel: z.nativeEnum(Threshold.Level),
    notification: notificationParams,
  });

  export const updateParams = createParams.omit({ sensors: true });
}
