import { z } from 'zod';
import { Utils } from '@shared/utils/model';
import { SensorsSchema } from '@modules/sensors/schema';
import { ThresholdSchema } from '@modules/iot/schema';

export namespace SinafisSchema {
  import registerPendingSchema = SensorsSchema.registerPendingSchema;

  export const transmitterSchema = z.object({
    height: Utils.Length,
    humidityThreshold: ThresholdSchema.humidityScaleSchema,
    temperatureThreshold: ThresholdSchema.temperatureScaleSchema,
  });

  export const groundSchema = z.object({
    depth: Utils.Length,
    humidityThreshold: ThresholdSchema.humidityScaleSchema,
    temperatureThreshold: ThresholdSchema.temperatureScaleSchema,
  });

  export const leafSchema = z.object({
    height: Utils.Length,
    humidityThreshold: ThresholdSchema.humidityScaleSchema,
  });

  const configSchema = z.object({
    transmitter: transmitterSchema,
    ground1: groundSchema.nullish(),
    ground2: groundSchema.nullish(),
    leaf: leafSchema.nullish(),
  });

  export const registerSinafisSchema = registerPendingSchema.extend({
    config: configSchema,
  });
}
