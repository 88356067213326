import React, { FC } from 'react';
import { Previsions } from '@shared/modules/previsions/model';
import MainWeather from '@shared/modules/previsions/components/weather/today/MainWeather';

import DayWeather from '@shared/modules/previsions/components/weather/day/DayWeather';
import { Box, Group, ScrollArea, Stack } from '@mantine/core';
import Weather = Previsions.Weather;
import { LocalDate } from '@shared/modules/dates';
import { Geo } from '@shared/modules/geo/model';

interface WeatherPrevisionsProps {
  location: Geo.City | null;
  main: Weather.ForecastValues | null;
  days: Array<Weather.DayForecast>;
  date?: LocalDate;
}

const WeatherPrevisions: FC<WeatherPrevisionsProps> = ({ location, main, days, date }) => {
  return (
    <Stack mt={20} spacing={20}>
      <Box px={20}>
        <MainWeather values={main} location={location} date={date} />
      </Box>

      <ScrollArea>
        <Group spacing={10} px={20} noWrap>
          {days.map(day => (
            <DayWeather key={day.date} day={day} />
          ))}
        </Group>
      </ScrollArea>
    </Stack>
  );
};

export default WeatherPrevisions;
