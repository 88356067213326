import React, { FC, useMemo, useRef } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { YearPickerInput } from '@mantine/dates';
import { endOfYear, getYear, setYear, startOfYear } from 'date-fns';
import { ActionIcon } from '@mantine/core';
import { IconChevronDown, IconX } from '@tabler/icons-react';

interface AlertReportListTopFiltersProps {
  filters: AlertReport.Filter.History;
  onFilter: (filters: AlertReport.Filter.History) => void;
}

const AlertReportListTopFilters: FC<AlertReportListTopFiltersProps> = ({ filters, onFilter }) => {
  const ref = useRef<HTMLButtonElement | null>(null);

  const maxDate = useMemo(() => endOfYear(new Date()), []);

  const handleYearChange = (date: Date | null) => {
    onFilter({
      ...filters,
      year: date ? getYear(date) : null,
    });
  };

  const handleRightSectionClick = () => {
    if (filters.year) {
      handleYearChange(null);
    } else {
      ref.current?.click();
    }
  };

  return (
    <YearPickerInput
      ref={ref}
      value={filters.year ? startOfYear(setYear(new Date(), filters.year)) : null}
      placeholder="Choisir une année"
      maxDate={maxDate}
      rightSection={
        <ActionIcon c="primary.5" h={24} w={24} variant="transparent" onClick={handleRightSectionClick}>
          {filters.year ? <IconX size={24} /> : <IconChevronDown size={24} />}
        </ActionIcon>
      }
      clearable
      onChange={handleYearChange}
    />
  );
};

export default AlertReportListTopFilters;
