import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import { Sinafis } from '@modules/sensors/sinafis/model';
import { Controller } from 'react-hook-form';
import { NumberInput } from '@mantine/core';
import ExtendedMeasureProbeMapping = Sinafis.Config.ExtendedMeasureProbeMapping;
import FirstStepForm from '@modules/sensors/pending/components/steps/FirstStepForm';
import { Zone } from '@modules/iot/zones/model';

const configIdentifierDistanceLabel: Record<Sinafis.Config.Identifier, string> = {
  transmitter: 'Hauteur Transmetteur (cm)',
  ground1: 'Profondeur Capteur Sol 1 (cm)',
  ground2: 'Profondeur Capteur Sol 2 (cm)',
  leaf: 'Hauteur Capteur Foliaire (cm)',
};

interface SinafisFirstStepFormProps {
  zones: Array<Zone>;
  measureMappings: ExtendedMeasureProbeMapping;
}

const SinafisFirstStepForm: FC<SinafisFirstStepFormProps> = ({ zones, measureMappings }) => {
  const {
    control,
    formState: { errors },
  } = useEnhancedFormContext<Sinafis.RegisterParams>();

  const measuresDistances = pipe(
    R.keys(measureMappings),
    A.sort(Sinafis.Config.configOrd),
    A.map(key => {
      switch (key) {
        case 'ground1':
        case 'ground2':
          return (
            <Controller
              control={control}
              name={`config.${key}.depth`}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  label={configIdentifierDistanceLabel[key]}
                  placeholder={configIdentifierDistanceLabel[key]}
                  error={!!errors.config?.[key]?.depth}
                  required
                />
              )}
            />
          );
        case 'transmitter':
        case 'leaf':
          return (
            <Controller
              control={control}
              name={`config.${key}.height`}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  label={configIdentifierDistanceLabel[key]}
                  placeholder={configIdentifierDistanceLabel[key]}
                  error={!!errors.config?.[key]?.height}
                  required
                />
              )}
            />
          );
        default:
          return null;
      }
    }),
  );

  return <FirstStepForm zones={zones}>{measuresDistances}</FirstStepForm>;
};

export default SinafisFirstStepForm;
