import { Utils } from '@shared/utils/model';
import { Threshold } from '@modules/iot/model';
import { NumberUtils } from '@shared/utils/number';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import { ProgressProps } from '@mantine/core/lib/Progress/Progress';
import { MantineColor } from '@mantine/core';
import { Icon, IconBattery4, IconCircuitResistor, IconDroplet, IconTemperature, IconWifi2 } from '@tabler/icons-react';
import * as O from 'fp-ts/Option';
import * as N from 'fp-ts/number';
import { Measure } from '@modules/measures/model';

export namespace LastMeasureUtils {
  export type NumericMeasure = Exclude<Measure.Type, Measure.Type.Signal>;

  export const MaxMeasure: { [Key in NumericMeasure]: Measure.Value<Key> } = {
    [Measure.Type.Battery]: Utils.Percent.parse(1),
    [Measure.Type.Humidity]: Utils.Percent.parse(1),
    [Measure.Type.Temperature]: Utils.Temperature.parse(50),
    [Measure.Type.Nutrition]: 100,
  };

  export const MinMeasure: { [Key in NumericMeasure]: Measure.Value<Key> } = {
    [Measure.Type.Battery]: Utils.Percent.parse(0),
    [Measure.Type.Humidity]: Utils.Percent.parse(0),
    [Measure.Type.Temperature]: Utils.Temperature.parse(-20),
    [Measure.Type.Nutrition]: 0,
  };

  export const StepMeasure: { [Key in NumericMeasure]: Measure.Value<Key> } = {
    [Measure.Type.Battery]: Utils.Percent.parse(0.01),
    [Measure.Type.Humidity]: Utils.Percent.parse(0.01),
    [Measure.Type.Temperature]: Utils.Temperature.parse(1),
    [Measure.Type.Nutrition]: 1,
  };

  export interface MeasureValue<Type extends NumericMeasure> {
    measure: Measure.RealTimeMeasure.Impl<Type>;
    scale: Threshold.Scale<Measure.Value<Type>, Threshold.Level>;
  }

  export function formatter<Type extends Measure.Type>(value: Measure.Value<Type>, type: Type & Measure.Type): string {
    switch (type) {
      case Measure.Type.Battery:
        return NumberUtils.formatPercent(value as Measure.Value<typeof type>);
      case Measure.Type.Humidity:
        return NumberUtils.formatPercent(value as Measure.Value<typeof type>);
      case Measure.Type.Temperature:
        return NumberUtils.formatTemperature(value as Measure.Value<typeof type>);
      case Measure.Type.Nutrition:
        return N.Show.show(value as Measure.Value<typeof type>);
      case Measure.Type.Signal:
        return Measure.signalStrengthLabel[value as Measure.Value<typeof type>];
      default:
        return '';
    }
  }

  export function mapMeasureScaleToSections<Type extends NumericMeasure, Level extends string>(
    scale: Threshold.Scale<Measure.Value<Type>, Level>,
    type: Type,
  ) {
    const levels = pipe(scale.levels, A.append({ until: MaxMeasure[type], level: scale.last }));

    return pipe(
      levels,
      NEA.reduce<(typeof levels)[number], NonNullable<ProgressProps['sections']>>([], (acc, val) => [
        ...acc,
        {
          value: val.until - acc.reduce((sum, { value }) => sum + value, MinMeasure[type].valueOf()),
          color: levelColor[val.level],
        },
      ]),
    );
  }

  export function getCurrentScale<Type extends NumericMeasure>(
    scale: Threshold.Scale<Measure.Value<Type>, Threshold.Level>,
    value: Measure.Value<Type>,
    type: Type,
  ) {
    return scale.levels.reduceRight((acc, curr) => (value > curr.until ? acc : curr), {
      until: MaxMeasure[type],
      level: scale.last,
    });
  }

  export const levelColor: Record<Threshold.Level, MantineColor> = {
    [Threshold.Level.None]: 'green.7',
    [Threshold.Level.Alert]: 'yellow.5',
    [Threshold.Level.Critical]: 'primary',
  };

  export const measureIcon: Record<Measure.Type, Icon> = {
    [Measure.Type.Battery]: IconBattery4,
    [Measure.Type.Humidity]: IconDroplet,
    [Measure.Type.Signal]: IconWifi2,
    [Measure.Type.Nutrition]: IconCircuitResistor,
    [Measure.Type.Temperature]: IconTemperature,
  };

  export function filterMeasures<Type extends NumericMeasure>(
    measures: Array<Measure.RealTimeMeasure.Impl<Measure.Type>>,
    scale: Threshold.Scale<Measure.Value<Type>, Threshold.Level> | null | undefined,
    type: Type,
  ): O.Option<MeasureValue<Type>> {
    return pipe(
      measures,
      A.findFirst((measure): measure is Measure.RealTimeMeasure.Impl<Type> => measure.type === type),
      O.filterMap(measure =>
        pipe(
          O.fromNullable(scale),
          O.map(scale => ({ measure, scale })),
        ),
      ),
    );
  }
}
