import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { Filter } from '@shared/modules/filter/model';
import { Box, Button, Card, Divider, Drawer, Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { HttpTask } from '@core/http';
import { useLocation, useNavigate } from 'react-router-dom';
import { stringifyQueries } from '@shared/utils/queries';
import { useDisclosure } from '@mantine/hooks';
import CreateFilterModal from '@shared/modules/filter/components/CreateFilterModal';
import * as A from 'fp-ts/Array';
import * as TE from 'fp-ts/TaskEither';
import { IconChevronDown } from '@tabler/icons-react';
import SavedFiltersDrawer from '@shared/modules/filter/components/SavedFiltersDrawer';
import { renderNullable } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';

export interface SavedFilter {
  id: string;
  name: string;
}

export interface FilterChildrenProps<F> {
  filter: F;
  onChange: (filter: F) => void;
}

export type FilterChildren<F> = (props: FilterChildrenProps<F>) => ReactNode;

export interface FiltersDrawerProps<F extends Filter, SF extends SavedFilter> {
  filters: F;
  savedFilters: Array<SF>;
  canSaveFilter: boolean;
  activeSavedFilter: (filters: F) => SF | null;
  onSavedFilterChange: (savedFilter: SF | null, filters: F) => HttpTask<F>;
  onCreateSavedFilter: (name: string, filters: F) => HttpTask<F>;
  onDeleteSavedFilter: (savedFilter: SF) => HttpTask;
  onResetFilter: (filters: F) => F;
  children: FilterChildren<F>;
}

function FiltersDrawer<F extends Filter, SF extends SavedFilter>({
  filters,
  savedFilters,
  canSaveFilter,
  activeSavedFilter,
  onSavedFilterChange,
  onCreateSavedFilter,
  onDeleteSavedFilter,
  onResetFilter,
  children,
}: FiltersDrawerProps<F, SF>) {
  const location = useLocation();
  const navigate = useNavigate();

  const opened = location.hash === '#filters';

  const filtersRef = useRef(filters);
  filtersRef.current = filters;

  const [pendingFilters, setPendingFilters] = useState(filters);

  const [createFilterModalOpened, createFilterModalTriggers] = useDisclosure();
  const [savedFiltersDrawerOpened, savedFiltersDrawerTriggers] = useDisclosure();

  useEffect(() => {
    setPendingFilters(filtersRef.current);
  }, [opened]);

  const handleClose = () =>
    navigate({ hash: undefined, search: location.search }, { replace: true, preventScrollReset: true });

  const handleResetFilters = () => setPendingFilters(onResetFilter);

  const handleApplyFilters = () =>
    navigate(
      { hash: undefined, search: stringifyQueries(pendingFilters) },
      { replace: true, preventScrollReset: true },
    );

  const handleRemoveFilter = () =>
    pipe(
      onSavedFilterChange(null, pendingFilters),
      TE.chainIOK(filters => () => setPendingFilters(filters)),
    )();

  const selectedFilter = activeSavedFilter(pendingFilters);

  return (
    <Drawer
      size="100vw"
      opened={location.hash === '#filters'}
      onClose={handleClose}
      transitionProps={{ transition: 'slide-up', timingFunction: 'ease' }}
      styles={theme => ({
        header: { background: theme.colors.tertiary[8], zIndex: 300 },
        // z-index to prevent dropdown options behind
        close: {
          border: 'none',
          width: '26px ',
          height: '26px ',
          background: 'transparent !important',
          svg: {
            width: '100%',
            height: '100%',
            color: 'white',
          },
        },
        body: {
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          background: theme.colors.tertiary[2],
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          border: 'none !important',
        },
      })}
      withCloseButton
      title={
        <Text color="white" size={17} weight={600}>
          Filtrer par
        </Text>
      }
      lockScroll={false}
      closeOnClickOutside
    >
      <Stack spacing={0} p={20}>
        <Group position="right">
          <Button color="tertiary.3" c="tertiary.8" fw={500} radius={8} px={12} py={10} onClick={handleResetFilters}>
            Effacer
          </Button>
        </Group>

        <Box>{children({ filter: pendingFilters, onChange: setPendingFilters })}</Box>

        {canSaveFilter ? (
          <>
            <Group mt={20}>
              <UnstyledButton onClick={createFilterModalTriggers.open}>
                <Text c="primary.5" td="underline" size={12} weight={500} lh={1.33}>
                  Enregistrer le filtre
                </Text>
              </UnstyledButton>
            </Group>

            {A.isNonEmpty(savedFilters) ? (
              <>
                <Divider my={20} color="#e1ded9" />

                <Group position="apart">
                  <Text size={16} color="tertiary.8">
                    Filtres enregistrés
                  </Text>
                  <UnstyledButton c="primary.5" fw={600} onClick={savedFiltersDrawerTriggers.open}>
                    <Group spacing={5}>
                      Choisir
                      <IconChevronDown size={15} />
                    </Group>
                  </UnstyledButton>
                </Group>

                {renderNullable(selectedFilter, ({ name }) => (
                  <Card p={10} radius={10} withBorder={false} mt={8}>
                    <Group position="apart">
                      <Text size={12} color="tertiary.8">
                        {name}
                      </Text>
                      <UnstyledButton
                        pl={20}
                        pr={10}
                        onClick={handleRemoveFilter}
                        sx={theme => ({ borderLeft: `1px solid ${theme.colors.tertiary[3]}` })}
                      >
                        <Text td="underline" size={12} weight={500} lh={1.33}>
                          Retirer
                        </Text>
                      </UnstyledButton>
                    </Group>
                  </Card>
                ))}
              </>
            ) : null}
          </>
        ) : null}
      </Stack>

      <CreateFilterModal
        opened={createFilterModalOpened}
        filter={pendingFilters}
        onClose={createFilterModalTriggers.close}
        onCreate={onCreateSavedFilter}
        onFiltersChange={setPendingFilters}
      />

      <SavedFiltersDrawer
        opened={savedFiltersDrawerOpened}
        onClose={savedFiltersDrawerTriggers.close}
        savedFilters={savedFilters}
        filters={pendingFilters}
        selectedFilter={selectedFilter}
        onChange={setPendingFilters}
        onDelete={onDeleteSavedFilter}
        onSelect={onSavedFilterChange}
      />

      <Button
        mt="auto"
        pos="sticky"
        bottom={0}
        h={61}
        radius={0}
        color="primary.5"
        tt="uppercase"
        onClick={handleApplyFilters}
      >
        Appliquer
      </Button>
    </Drawer>
  );
}

export default FiltersDrawer;
