import React, { FC, PropsWithChildren } from 'react';
import { Drawer } from '@mantine/core';
import { usePreserveNavigate } from '@core/router';
import { useLocation } from 'react-router-dom';
import { useWindowInnerHeight } from '@shared/hooks/window';

interface DetailDrawerProps {
  opened: boolean;
}

const DetailDrawer: FC<PropsWithChildren<DetailDrawerProps>> = ({ opened, children }) => {
  const location = useLocation();
  const navigate = usePreserveNavigate();
  const handleClose = () => navigate('..', { relative: 'path' });

  const isExpand = location.hash === '#expand';

  const vh = useWindowInnerHeight();

  return (
    <Drawer
      opened={opened}
      size="100vw"
      onClose={handleClose}
      transitionProps={{ transition: 'slide-up', timingFunction: 'ease' }}
      styles={theme => ({
        root: {
          zIndex: 10000,
        },
        body: {
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: isExpand ? 'visible' : 'hidden',
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          border: 'none !important',
          borderRadius: '8px 8px 0 0 !important',
          backgroundColor: theme.colors.tertiary[2],
          overflowY: 'unset !important' as any,
        },
        inner: {
          position: 'sticky',
          transitionProperty: 'height',
          transitionDuration: '400ms',
          transitionTimingFunction: 'ease-in-out',
          height: isExpand ? vh - 64 : 115,
          bottom: 0,
          top: isExpand ? 0 : 'unset',
          zIndex: 150,
          background: '#ceead6',
        },
        overlay: {
          zIndex: 100,
        },
      })}
      withCloseButton={false}
      lockScroll={false}
      closeOnClickOutside
      withinPortal={false}
      withOverlay={false}
    >
      {children}
    </Drawer>
  );
};

export default DetailDrawer;
