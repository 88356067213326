import { SensorFilter } from '@modules/sensors/map/filters/model';
import { httpService, HttpTask } from '@core/http';

export namespace SensorFilterService {
  const URI = '/iot/sensor-filters';

  export function create(params: SensorFilter.CreateParams): HttpTask<SensorFilter> {
    return httpService.post(URI, params);
  }

  export function listing(): HttpTask<Array<SensorFilter>> {
    return httpService.get(URI);
  }

  export function deleteFilter(id: SensorFilter.Id): HttpTask {
    return httpService.delete(`${URI}/${id}`);
  }
}
