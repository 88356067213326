import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { z } from 'zod';
import { Alert, Box, Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import { emailSchema, NonEmptyString } from '@shared/schemas';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { OAuthService } from '@core/oauth/service';
import { constFalse } from 'fp-ts/function';
import * as EI from 'fp-ts/Either';
import { renderOptional } from '@shared/utils/render';
import { OAuthUtils } from '@core/oauth/utils';
import ErrorPage from '@shared/components/error-page/ErrorPage';

const Params = z.object({
  email: emailSchema,
  password: NonEmptyString,
});
type Params = z.infer<typeof Params>;

const actions = {
  authenticate: defineAction({
    type: 'authenticate',
    payload: Params,
    handler: ({ payload }) => OAuthService.passwordStrategyRequest(payload.email, payload.password),
    flashOptions: {
      error: constFalse,
    },
    redirect: ({ result }) => {
      if (EI.isRight(result)) {
        return '/';
      }

      return null;
    },
  }),
};

const LoginDev: FC = () => {
  const [loading, authenticate, error] = useAction(actions.authenticate);

  const onSubmit = (params: Params) => authenticate(params)();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Params>({
    resolver: zodResolver(Params),
  });

  if (!import.meta.env.DEV) {
    return <ErrorPage />;
  }

  return (
    <Box p={20}>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {OAuthUtils.getOAuthErrorMessage(error)}
          </Text>
        </Alert>
      ))}

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput type="email" label="E-mail" placeholder="E-mail" {...register('email')} error={!!errors.email} />

        <PasswordInput
          mt={20}
          label="Mot de passe"
          placeholder="Mot de passe"
          {...register('password')}
          error={!!errors.password}
        />

        <Group position="center" mt={30}>
          <Button type="submit" loading={loading}>
            Se connecter
          </Button>
        </Group>
      </form>
    </Box>
  );
};

const loginDevRoute = defineRoute({
  element: <LoginDev />,
  actions,
});

export default loginDevRoute;
