import React, { PropsWithChildren, useState } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { Button, Group, Stepper, Text, UnstyledButton } from '@mantine/core';
import { DeepPartial, FieldPath, UseFormReturn } from 'react-hook-form';
import { flow, pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import * as TO from 'fp-ts/TaskOption';
import * as IOO from 'fp-ts/IOOption';
import * as A from 'fp-ts/Array';
import { zodResolver } from '@hookform/resolvers/zod';
import { Sensor } from '@modules/sensors/model';
import { HttpTask } from '@core/http';
import PendingHeader from '@modules/sensors/pending/components/PendingHeader';
import z from 'zod';
import { PendingSensor } from '@modules/sensors/pending/model';
import * as TE from 'fp-ts/TaskEither';
import { useNavigate } from 'react-router-dom';

type RegisterParams<Type extends Sensor.Type> = z.infer<(typeof PendingSensor.typeRegisterSchema)[Type]>;

interface DetailPageProps<Type extends Sensor.Type> {
  sensor: PendingSensor<Type>;
  defaultValues: DeepPartial<RegisterParams<Type>>;
  onRegister: (params: RegisterParams<Type>) => HttpTask<PendingSensor.RegisterSensorOut>;
  loading: boolean;
  checks: Array<Array<FieldPath<RegisterParams<Type>>>>;
}

function DetailPage<Type extends Sensor.Type>({
  onRegister,
  loading,
  sensor,
  checks,
  defaultValues,
  children,
}: PropsWithChildren<DetailPageProps<Type>>) {
  const [active, setActive] = useState(0);
  const navigate = useNavigate();

  const { form, formRef, handleFormSubmit } = useEnhancedForm<RegisterParams<Type>>({
    resolver: zodResolver(PendingSensor.typeRegisterSchema[sensor.type]),
    defaultValues,
  });
  const handleChangeStep = (step: number) => () => setActive(step);

  function handleFormCheck<Val extends typeof form extends UseFormReturn<infer Values> ? FieldPath<Values> : never>(
    names: Array<Val>,
  ) {
    return pipe(
      () => form.trigger(names),
      T.map(O.guard),
      TO.chainIOK(() => handleChangeStep(active + 1)),
    );
  }

  const handleNextStep = pipe(
    IOO.fromOption(A.lookup(active)(checks)),
    IOO.fold(() => handleChangeStep(active + 1), handleFormCheck),
  );

  const pageProps: PageProps = {
    seoTitle: 'Configuration',
    back: {
      title: 'Configuration',
      to: '/sensors/pending',
    },
    bottomBar: (
      <Group mt="auto" position="apart" pos="sticky" w="100%">
        {active > 0 && (
          <UnstyledButton onClick={handleChangeStep(active - 1)} pl={15}>
            <Text color="tertiary.8" lh={1.33} weight={500} size={12} td="underline">
              Précédent
            </Text>
          </UnstyledButton>
        )}

        {active < checks.length - 1 ? (
          <Button color="primary.5" tt="uppercase" onClick={handleNextStep} ml="auto">
            Suivant
          </Button>
        ) : (
          <Button color="primary.5" tt="uppercase" onClick={handleFormSubmit} loading={loading}>
            Enregistrer
          </Button>
        )}
      </Group>
    ),
  };

  const onSubmit = flow(
    onRegister,
    TE.chainFirstIOK(
      ({ id }) =>
        () =>
          navigate(`/sensors/pending/success/${id}`, { replace: true }),
    ),
  );

  return (
    <Page {...pageProps}>
      <PendingHeader sensor={sensor} />
      <EnhancedForm ref={formRef} form={form} onSubmit={onSubmit} preventLeave>
        <Stepper active={active} onStepClick={setActive} pt={20} mt={20} style={{ borderTop: `1px solid #e1ded9` }}>
          {children}
        </Stepper>
      </EnhancedForm>
    </Page>
  );
}

export default DetailPage;
