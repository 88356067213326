import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, MouseEvent, PropsWithChildren, ReactNode } from 'react';
import { Anchor, Button, Group, Modal, Text } from '@mantine/core';

export interface DeleteModalButton {
  text?: string;
  icon?: ReactNode;
}

interface DeleteModalProps {
  title?: string;
  loading?: boolean;
  button?: DeleteModalButton;
  onDelete: () => void;
  handleClose: () => void;
  opened: boolean;
}

const DeleteModal: FC<PropsWithChildren<DeleteModalProps>> = ({
  title = 'Êtes-vous sûr ?',
  loading,
  button,
  onDelete,
  children = 'La suppression est définitive.',
  opened,
  handleClose,
}) => {
  const stopPropagation = (e: MouseEvent) => e.stopPropagation();

  const handleSubmit = (e: MouseEvent) => {
    stopPropagation(e);
    handleClose();
    onDelete();
  };

  const optButton = O.fromNullable(button);

  const buttonText = pipe(
    optButton,
    O.chainNullableK(b => b.text),
    O.getOrElse(() => 'Supprimer'),
  );

  const buttonIcon = pipe(
    optButton,
    O.chainNullableK(b => b.icon),
    O.toUndefined,
  );

  return (
    <Modal
      opened={opened}
      centered
      onClose={handleClose}
      onClick={stopPropagation}
      title={
        <Text size="lg" weight="600">
          {title}
        </Text>
      }
      withCloseButton
      size={500}
    >
      <Text size="sm">{children}</Text>

      <Group mt={20} position="right">
        <Anchor
          component="button"
          color="tertiary.8"
          fz={12}
          sx={{ textDecoration: 'underline' }}
          onClick={handleClose}
          disabled={loading}
        >
          Annuler
        </Anchor>

        <Button leftIcon={buttonIcon} loading={loading} tt="uppercase" onClick={handleSubmit}>
          {buttonText}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteModal;
