import React, { FC } from 'react';
import { Button, Drawer, Stack, Text } from '@mantine/core';
import { Maps } from '@shared/modules/maps/model';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import SensorPin from '@modules/sensors/map/components/SensorPin';
import { Sensor } from '@modules/sensors/model';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import { Utils } from '@shared/utils/model';

interface GeolocationDrawerProps {
  opened: boolean;
  onClose: () => void;
  type: Sensor.Type;
  location: Utils.GPSCoordinates;
}

const GeolocationDrawer: FC<GeolocationDrawerProps> = ({ opened, onClose, type, location }) => {
  const position: google.maps.LatLngLiteral = { lng: location.longitude, lat: location.latitude };
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      transitionProps={{ transition: 'slide-up', timingFunction: 'ease' }}
      title={
        <Text color="white" weight={600} size={17}>
          Voir ma sonde
        </Text>
      }
      styles={theme => ({
        header: { background: theme.colors.tertiary[8], padding: '17px 11px 14px 20px' },
        body: {
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        },
        close: {
          border: 'none',
          width: '26px ',
          height: '26px ',
          background: 'transparent !important',
          svg: {
            width: '100%',
            height: '100%',
            color: 'white',
          },
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          border: 'none !important',
        },
      })}
      lockScroll={false}
      closeOnClickOutside
    >
      <GoogleMaps options={{ ...Maps.defaultOptions, center: position }} style={{ flexGrow: 1 }}>
        {map => (
          <>
            <GoogleMapsOverlay position={position} map={map}>
              <SensorPin type={type} />
            </GoogleMapsOverlay>
            <Stack px={28} spacing={20} pos="absolute" bottom={30} w="100%" align="center">
              <Button color="primary.5" tt="uppercase" onClick={onClose}>
                Quitter la carte
              </Button>
            </Stack>
          </>
        )}
      </GoogleMaps>
    </Drawer>
  );
};

export default GeolocationDrawer;
