import React, { FC } from 'react';
import { Button, Card, Stack, Text, ThemeIcon } from '@mantine/core';
import config from '@root/config';
import { useNavigate } from 'react-router-dom';
import { IconChartBar } from '@tabler/icons-react';
import { AlertReport } from '@modules/diseases/alert-reports/model';

interface AlertReportAnalysisPlaceholderProps {
  alertReport: AlertReport.Detail;
}

const AlertReportAnalysisPlaceholder: FC<AlertReportAnalysisPlaceholderProps> = ({ alertReport }) => {
  const navigate = useNavigate();

  const handleGoBack = () => navigate(`/diseases/alert-reports/${alertReport.id}`);

  return (
    <Stack p={30} sx={{ flex: '1 1 auto' }} justify="center">
      <Card py={35} px={10} bg="white" withBorder={false} radius={8}>
        <Stack align="center" ta="center">
          <ThemeIcon size={146} radius={100} bg="#f5f4f2" c="#C3BBB2">
            <IconChartBar size={94} strokeWidth={1} />
          </ThemeIcon>

          <Text fz={20} fw={600} c="primary.5">
            Consultation d'analyse
          </Text>

          <Text c="tertiary.8">
            Cet espace est réservé aux membres ayant souscrit à l’abonnement PRO. Il vous permet de manager votre
            équipe, de piloter votre parc de sonde et de gérer les risques épidémiologiques.
          </Text>

          <Button component="a" href={config.VITE_CONTACT_LINK} tt="uppercase">
            Nous contacter
          </Button>
          <Button tt="uppercase" variant="outline" onClick={handleGoBack}>
            Retour
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
};

export default AlertReportAnalysisPlaceholder;
