import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { AspectRatio, Box, Image, SimpleGrid, Text } from '@mantine/core';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { useDisclosure } from '@mantine/hooks';
import GalleryModal from '@shared/modules/images/components/GalleryModal';

interface AlertReportDetailImagesProps {
  alertReport: AlertReport.Detail;
}

const AlertReportDetailImages: FC<AlertReportDetailImagesProps> = ({ alertReport }) => {
  const [imagesGalleryOpened, imagesGalleryTriggers] = useDisclosure();

  const images = pipe(
    alertReport.images ?? [],
    A.map(image => image.variants),
  );

  const mainImage = pipe(
    images,
    A.head,
    O.chainNullableK(image => image.original),
    O.toNullable,
  );

  const handleOpenGallery = () => {
    if (A.isNonEmpty(images)) {
      imagesGalleryTriggers.open();
    }
  };

  return (
    <SimpleGrid cols={2} spacing={2}>
      <Box pos="relative">
        <AspectRatio ratio={4 / 3}>
          <Image src={alertReport.type.images.bound_512x512} withPlaceholder />
        </AspectRatio>

        <Box bg="rgba(0, 0, 0, 0.4)" pos="absolute" bottom={0} left={0} w="100%" p={3}>
          <Text c="#fff" fz={14} ta="center">
            Photo référence
          </Text>
        </Box>
      </Box>

      <Box pos="relative" onClick={handleOpenGallery}>
        <AspectRatio ratio={4 / 3}>
          <Image src={mainImage} withPlaceholder />
        </AspectRatio>

        <Box bg="rgba(0, 0, 0, 0.4)" pos="absolute" bottom={0} left={0} w="100%" p={3}>
          <Text c="#fff" fz={14} ta="center">
            Photos {images.length > 0 ? `(${images.length})` : ''}
          </Text>
        </Box>
      </Box>

      <GalleryModal opened={imagesGalleryOpened ? 0 : null} onClose={imagesGalleryTriggers.close} images={images} />
    </SimpleGrid>
  );
};

export default AlertReportDetailImages;
