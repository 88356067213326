import React, { FC } from 'react';
import ThresholdScaleCard from '@shared/modules/threshold/ThresholdScaleCard';
import ThresholdScaleController from '@shared/modules/threshold/ThresholdScaleController';
import { Sensor } from '@modules/sensors/model';
import SecondStepForm from '@modules/sensors/pending/components/steps/SecondStepForm';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Measure } from '@modules/measures/model';
import { Hortee } from '@modules/sensors/hortee/model';

const HorteeSecondStepForm: FC = () => {
  const { control } = useEnhancedFormContext<Hortee.RegisterParams>();

  return (
    <SecondStepForm>
      <ThresholdScaleCard title="Capteur Sol" icon={Sensor.typeIcon[Sensor.Type.Sensoterra]}>
        <ThresholdScaleController name="config.humidityThreshold" control={control} type={Measure.Type.Humidity} />
        <ThresholdScaleController
          name="config.temperatureThreshold"
          control={control}
          type={Measure.Type.Temperature}
        />
      </ThresholdScaleCard>
    </SecondStepForm>
  );
};

export default HorteeSecondStepForm;
