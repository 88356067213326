import React, { createElement, FC } from 'react';
import { ThemeIcon, Tooltip } from '@mantine/core';
import { Measure } from '@modules/measures/model';
import signalStrengthIcon = Measure.signalStrengthIcon;
import signalStrengthLabel = Measure.signalStrengthLabel;

interface SignalThemeIconProps {
  signal: Measure.Value<Measure.Type.Signal>;
}

const SignalThemeIcon: FC<SignalThemeIconProps> = ({ signal }) => {
  return (
    <Tooltip label={signalStrengthLabel[signal]}>
      <ThemeIcon size={31} variant="transparent" c="tertiary.8">
        {createElement(signalStrengthIcon[signal], { strokeWidth: 1, size: 31 })}
      </ThemeIcon>
    </Tooltip>
  );
};

export default SignalThemeIcon;
