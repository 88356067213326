import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { AspectRatio, Box, Button, Card, Chip, Group, SegmentedControl, Text } from '@mantine/core';
import { Maps } from '@shared/modules/maps/model';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import AlertReportPin from '@modules/diseases/alert-reports/map/components/AlertReportPin';
import { renderNullable } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import { IconChartBar } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

interface AlertReportDetailContentProps {
  alertReport: AlertReport.Detail;
}

const AlertReportDetailContent: FC<AlertReportDetailContentProps> = ({ alertReport }) => {
  const position = { lat: alertReport.coordinates.latitude, lng: alertReport.coordinates.longitude };

  return (
    <Box pos="relative" p={20} pt={10} pb={30}>
      <Text size={17} c="tertiary.8" fw={600}>
        Localisation
      </Text>

      <AspectRatio mt={10} ratio={20 / 9}>
        <GoogleMaps options={{ ...Maps.defaultOptions, zoomControl: false, center: position, zoom: 15 }}>
          {map => (
            <GoogleMapsOverlay position={position} map={map}>
              <AlertReportPin category={alertReport.category} subCategory={alertReport.subCategory} size={43} />
            </GoogleMapsOverlay>
          )}
        </GoogleMaps>
      </AspectRatio>

      {renderNullable(alertReport.grassType, grassType => (
        <>
          <Text mt={15} size={17} c="tertiary.8" fw={600}>
            Type de pelouse
          </Text>

          <Card bg="white" withBorder={false} radius={5} mt={10} p={15}>
            <Chip.Group multiple={false} value={alertReport.grassType ?? ''}>
              <Group spacing={8}>
                {Object.values(AlertReport.GrassType).map(grassType => (
                  <Chip key={grassType} value={grassType} radius={5}>
                    {AlertReport.grassTypeLabels[grassType]}
                  </Chip>
                ))}
              </Group>
            </Chip.Group>
          </Card>
        </>
      ))}

      {renderNullable(alertReport.gravity, gravity => (
        <>
          <Text mt={15} size={17} c="tertiary.8" fw={600}>
            Gravité
          </Text>

          <Card bg="white" withBorder={false} radius={5} mt={10} p={15}>
            <SegmentedControl
              w="100%"
              color="primary"
              data={NEA.range(1, alertReport.subCategory.maxGravity).map(severity => `${severity}`)}
              value={`${gravity}`}
            />
          </Card>
        </>
      ))}

      {renderNullable(alertReport.comment, comment => (
        <>
          <Text mt={15} size={17} c="tertiary.8" fw={600}>
            Commentaire
          </Text>

          <Card bg="white" withBorder={false} radius={5} mt={10} p={15}>
            <Text fz={14} color="tertiary.7">
              {comment}
            </Text>
          </Card>
        </>
      ))}

      {renderNullable(alertReport.internalComment, internalComment => (
        <>
          <Text mt={15} size={17} c="tertiary.8" fw={600}>
            Commentaire privé
          </Text>

          <Card bg="white" withBorder={false} radius={5} mt={10} p={15}>
            <Text fz={14} color="tertiary.7">
              {internalComment}
            </Text>
          </Card>
        </>
      ))}

      <Group pt={30} position="center" pos="sticky" left={0} bottom={90} mt="auto" sx={{ zIndex: 100 }}>
        <Button component={Link} to="analysis" relative="path" tt="uppercase" leftIcon={<IconChartBar size={16} />}>
          Consulter l'analyse
        </Button>
      </Group>
    </Box>
  );
};

export default AlertReportDetailContent;
