import React, { FC } from 'react';
import { ActionIcon, Card, Container, Group, Stack, Text } from '@mantine/core';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { defineRoute } from '@core/router';
import { IconChevronRight } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import Page, { PageProps } from '@layout/page/Page';
import { EmergencyService } from '@modules/emergency-numbers/service';
import { Emergency } from '@modules/emergency-numbers/model';
import { filterMapEmergencyNumbers } from '@modules/emergency-numbers/utils';
import Type = Emergency.Type;
import EmergencyGreenNumberCard from '@modules/emergency-numbers/components/EmergencyGreenNumberCard';
import EmergencyNumberSimpleCard from '@modules/emergency-numbers/components/EmergencyNumberSimpleCard';
import EmergencyPoisonControlDrawer from '@modules/emergency-numbers/components/EmergencyPoisonControlDrawer';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(EmergencyService.getEmergencyNumbers()),
});

const EmergencyNumbersListPage: FC = () => {
  const numbers = useLoader<typeof loader>();
  const [opened, handlers] = useDisclosure(false);

  const {
    [Type.GreenNumber]: greenNumbers,
    [Type.PoisonControlCenter]: poisonControlCenters,
    [Type.Main]: mains,
  } = filterMapEmergencyNumbers(numbers);

  const title = "Liste des numéros d'urgence";

  const pageProps: PageProps = {
    title,
    back: {
      to: '/',
      title: "Numéros d'urgence",
    },
  };

  return (
    <Page {...pageProps} withNav>
      <Stack spacing={10}>
        {mains.map(emergency => (
          <EmergencyNumberSimpleCard key={emergency.id} emergency={emergency} />
        ))}
        {renderOptional(NEA.fromArray(poisonControlCenters), () => (
          <Card bg="#f3f6fb" pr={20} pl={25} py={13} radius={71} withBorder={false}>
            <Group position="apart" noWrap>
              <Stack spacing={5}>
                <Text size={15} weight={600} lh={1}>
                  Centres antipoison
                </Text>
                <Text size="sm" lh={1}>
                  Liste des numéros antipoison
                </Text>
              </Stack>
              <ActionIcon
                onClick={handlers.open}
                variant="filled"
                radius="xl"
                size={41}
                bg="white"
                color="gray.1"
                c="black"
              >
                <IconChevronRight size={22} />
              </ActionIcon>
            </Group>
          </Card>
        ))}
        {greenNumbers.map(emergency => (
          <Card key={emergency.id} bg="background.3" pr={20} pl={25} py={13} radius={10} withBorder={false}>
            <Container maw={294} p={0}>
              <Text size={15} weight={600}>
                {emergency.label}
              </Text>
              <EmergencyGreenNumberCard emergency={emergency} />
              <Text ta="right" size="xs">
                Appel gratuit
              </Text>
            </Container>
          </Card>
        ))}
      </Stack>
      <EmergencyPoisonControlDrawer
        opened={opened}
        onClose={handlers.close}
        poisonControlCenters={poisonControlCenters}
      />
    </Page>
  );
};

const emergencyNumbersListRoute = defineRoute({
  element: <EmergencyNumbersListPage />,
  loader,
});

export default emergencyNumbersListRoute;
