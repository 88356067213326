import React, { FC } from 'react';
import { AspectRatio, Button, Group, Stack, Text } from '@mantine/core';
import config from '@root/config';

import placeholder from '@assets/images/sensors-placeholder.png';

const SensorsDashboardPlaceholder: FC = () => {
  return (
    <Stack spacing={15} ta="center" maw={335} mx="auto">
      <Text fz={20} fw={600} c="primary.5">
        Pilotez à distance vos sondes connectées
      </Text>

      <Text fz={15} fw={500} c="tertiary.8">
        Cet espace est réservé aux membres ayant souscrit à l’abonnement PRO. Il vous permet de manager votre équipe, de
        piloter votre parc de sonde et de gérer les risques épidémiologiques.
      </Text>

      <Group mt={10} position="center">
        <Button component="a" href={config.VITE_CONTACT_LINK} variant="outline" tt="uppercase">
          Nous contacter
        </Button>
      </Group>

      <AspectRatio ratio={742 / 1054}>
        <img src={placeholder} alt="placeholder" />
      </AspectRatio>
    </Stack>
  );
};

export default SensorsDashboardPlaceholder;
