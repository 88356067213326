import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import React from 'react';
import alertsList from '@modules/alert-triggers/ListPage';
import createAlert from '@modules/alert-triggers/CreatePage';
import updateAlert from '@modules/alert-triggers/DetailPage';
import * as P from 'fp-ts/Predicate';
import { ProfilePredicate } from '@modules/profile/predicate';

const alertsRoutes = (
  <Route path="alerts" {...createRoute({ restriction: P.not(ProfilePredicate.isAccessLimited) })}>
    <Route index {...createRoute(alertsList)} />
    <Route path="new" {...createRoute(createAlert)} />
    <Route path=":id" {...createRoute(updateAlert)} />
  </Route>
);

export default alertsRoutes;
