import React, { createElement, FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import Page from '@layout/page/Page';
import { Button, Card, Group, Stack, Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import { Sensor } from '@modules/sensors/model';
import { z } from 'zod';
import { Link } from 'react-router-dom';
import { SensorsService } from '@modules/sensors/service';

const params = z.object({ id: Sensor.Id });

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(SensorsService.getActiveSensor(params.id)),
});

const SuccessPage: FC = () => {
  const sensor = useLoader<typeof loader>();

  return (
    <Page>
      <Group pos="fixed" top={0} left={0} w="100%" bg="tertiary.8" h={63} px={20}>
        <Text size={17} weight={600} color="white">
          Configuration
        </Text>
      </Group>
      <Card withBorder={false} m="auto" radius={8} pt={35} pb={26}>
        <Stack align="center" ta="center" spacing={15}>
          <ThemeIcon size={146} radius="50%" c="#c3bbb3" color="tertiary.2">
            {createElement(Sensor.typeIcon[sensor.type], { width: '100%', height: '100%' })}
          </ThemeIcon>
          <Text size={16} color="tertiary.8" lh="normal">
            Votre sonde {sensor.name} a été configurée avec succès
          </Text>
          <Button color="primary.5" tt="uppercase" component={Link} to={`/sensors/map/${sensor.id}`} mt={5}>
            Consulter le détail
          </Button>
          <UnstyledButton component={Link} to="/sensors/pending">
            <Text color="tertiary.8" size={12} lh={1.33} weight={500} td="underline">
              Configurer d'autres sondes
            </Text>
          </UnstyledButton>
        </Stack>
      </Card>
    </Page>
  );
};

const pendingSuccessDetail = defineRoute({
  element: <SuccessPage />,
  loader,
});

export default pendingSuccessDetail;
