import { MantineColor } from '@mantine/core';
import { pipe, tuple } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import { mix } from 'polished';
import { Measure } from '@modules/measures/model';
import { Sensor } from '@modules/sensors/model';
import { Previsions } from '@shared/modules/previsions/model';
import { NumberUtils } from '@shared/utils/number';

export namespace Charts {
  export namespace Line {
    export type SensorDataValues = {
      [Type in Measure.History.Type as `${Sensor.Probe<Sensor.Type>}.${Type}`]?: Measure.Value<Type>;
    };

    export interface SensorData extends SensorDataValues {
      date: Date;
    }

    export const sensorDataLabel: Record<keyof SensorDataValues, string> = pipe(
      Object.values(Measure.History.Type),
      A.chain(type =>
        Object.values(Sensor.Probe.Identifier).map(id =>
          tuple(`${id}.${type}`, `${Sensor.Probe.identifierLabel[id]} - ${Measure.typeTitle[type]}`),
        ),
      ),
      R.fromEntries,
    );

    export const sensorDataColor: Record<keyof SensorDataValues, MantineColor> = pipe(
      Object.values(Measure.History.Type),
      A.chain(type =>
        Object.values(Sensor.Probe.Identifier).map(id =>
          tuple(`${id}.${type}`, mix(0.5, Sensor.Probe.identifierColor[id], Measure.History.typeColor[type])),
        ),
      ),
      R.fromEntries,
    );

    export namespace Disease {
      import formatPercent = NumberUtils.formatPercent;
      type Values = {
        [Type in Previsions.Disease.Model]?: number;
      };

      export interface Data extends Values {
        date: Date;
      }

      export const modelColor: Record<Previsions.Disease.Model, MantineColor> = {
        [Previsions.Disease.Model.ColdFusarium]: 'red.6',
        [Previsions.Disease.Model.DollarSpot]: 'violet.6',
        [Previsions.Disease.Model.LWD]: 'teal.6',
      };

      export const modelFormatter: Record<Previsions.Disease.Model, (value: number) => string> = {
        [Previsions.Disease.Model.ColdFusarium]: formatPercent,
        [Previsions.Disease.Model.DollarSpot]: formatPercent,
        [Previsions.Disease.Model.LWD]: String,
      };
    }
  }
}
