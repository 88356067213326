import React, { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import * as Styled from './Layout.styles';
import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';
import { Text, Title } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

const Layout: FC = () => {
  return (
    <>
      <Styled.LayoutLock p="md" align="center">
        <IconAlertTriangle size={50} />
        <Title>On dirait que vous êtes sur un ordinateur.</Title>
        <Text>
          Pour une meilleure expérience utilisateur, nous vous invitons à vous connecter depuis un appareil mobile.
        </Text>
      </Styled.LayoutLock>

      <Styled.LayoutContainer>
        <Suspense fallback={<DebouncedLineLoader />}>
          <Outlet />
        </Suspense>
      </Styled.LayoutContainer>
    </>
  );
};

export default Layout;
