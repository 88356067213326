import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import { Maps } from '@shared/modules/maps/model';
import SelectReportLocation from '@modules/diseases/alert-reports/flow/components/location/SelectReportLocation';
import { Box, Stack } from '@mantine/core';
import ReportLocationInput from '@modules/diseases/alert-reports/flow/components/location/ReportLocationInput';
import { useReportingGeocoding } from '@modules/diseases/alert-reports/flow/hooks/geocoding';
import { Utils } from '@shared/utils/model';
import { Navigate } from 'react-router-dom';
import { useReportFlowContext } from '@modules/diseases/alert-reports/flow/context';
import { ReportFlowStepProps } from '@modules/diseases/alert-reports/flow/index';
import ReportFlowLayout from '@modules/diseases/alert-reports/flow/layout/ReportFlowLayout';

const ReportLocationStep: FC<ReportFlowStepProps> = ({ type }) => {
  const [state, dispatch, { loading, onSubmit }] = useReportFlowContext();

  const { location, form, selectedCategory, selectedSubCategory } = state;

  const [locationRef, geocodeLoading, handleGeocode] = useReportingGeocoding();

  const defaultCenter = location ? { lat: location.latitude, lng: location.longitude } : Maps.defaultOptions.center;
  const defaultZoom = location ? 16 : Maps.defaultOptions.zoom;

  const handleSubmit = (position: google.maps.LatLng) => {
    const location: Utils.GPSCoordinates = { latitude: position.lat(), longitude: position.lng() };

    dispatch({ type: 'LOCATION_SELECTED', location });

    onSubmit({ ...state, location });
  };

  if (!form || !selectedCategory || !selectedSubCategory) {
    return <Navigate to="../form" relative="path" replace />;
  }

  return (
    <ReportFlowLayout type={type} title="Localiser le signalement" back="../images" withClose>
      <Stack h="100%" spacing={0} sx={{ flex: '1 1 auto' }}>
        <ReportLocationInput loading={geocodeLoading} onSearch={handleGeocode} />

        <Box sx={{ display: 'grid', flex: '1 1 auto' }}>
          <GoogleMaps
            options={{ ...Maps.defaultOptions, center: defaultCenter, zoom: defaultZoom, zoomControl: false }}
            style={{ flexGrow: 1, minHeight: '100%' }}
          >
            {map => (
              <SelectReportLocation
                ref={locationRef}
                type={type}
                map={map}
                category={selectedCategory}
                subCategory={selectedSubCategory}
                loading={loading}
                hasPreviousLocation={location != null}
                onSubmit={handleSubmit}
              />
            )}
          </GoogleMaps>
        </Box>
      </Stack>
    </ReportFlowLayout>
  );
};

const reportLocationStepRoute = (props: ReportFlowStepProps) =>
  defineRoute({
    element: <ReportLocationStep {...props} />,
  });

export default reportLocationStepRoute;
