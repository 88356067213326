import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { ActionIcon, Group, Title } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { IconArrowLeft, IconPencil, IconTrash } from '@tabler/icons-react';
import DeleteModal from '@shared/components/modals/DeleteModal';
import { useDisclosure } from '@mantine/hooks';

interface AlertReportDetailTopProps {
  alertReport: AlertReport.Detail;
  deleteLoading: boolean;
  handleDelete: () => void;
}

const AlertReportDetailTop: FC<AlertReportDetailTopProps> = ({ alertReport, deleteLoading, handleDelete }) => {
  const navigate = useNavigate();

  const [deleteModalOpened, deleteModalTriggers] = useDisclosure();

  const handleGoBack = () => navigate('/diseases/alert-reports');

  return (
    <Group
      pos="sticky"
      top={0}
      spacing={5}
      bg="tertiary.8"
      h={63}
      pl={20}
      pr={15}
      noWrap
      style={{ flex: '0 0 auto', zIndex: 10000 }}
    >
      <ActionIcon variant="transparent" c="white" onClick={handleGoBack}>
        <IconArrowLeft size={22} />
      </ActionIcon>
      <Title
        fz={17}
        c="white"
        fw={600}
        sx={{ flex: '1 1 auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {alertReport.type.label}
      </Title>

      {alertReport.canEdit ? (
        <>
          <Group noWrap spacing={2}>
            <ActionIcon component={Link} to="edit" variant="transparent" c="white">
              <IconPencil size={22} />
            </ActionIcon>
            <ActionIcon variant="transparent" c="white" loading={deleteLoading} onClick={deleteModalTriggers.open}>
              <IconTrash size={22} />
            </ActionIcon>
          </Group>

          <DeleteModal onDelete={handleDelete} handleClose={deleteModalTriggers.close} opened={deleteModalOpened} />
        </>
      ) : null}
    </Group>
  );
};

export default AlertReportDetailTop;
