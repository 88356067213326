import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { defineAction, useAction } from '@core/router/action';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { AlertReportUtils } from '@modules/diseases/alert-reports/utils';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { getPageQuery } from '@shared/modules/range/utils';
import { useRange, VirtualizedList } from '@shared/modules/range';
import AlertReportRow from '@modules/diseases/alert-reports/list/components/AlertReportRow';
import AlertReportsFilters from '@modules/diseases/alert-reports/filters/AlertReportsFilters';
import { ActionIcon, Box, Button, Indicator } from '@mantine/core';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';
import { IconFilter, IconPlus } from '@tabler/icons-react';
import { isFilterEmpty } from '@shared/modules/filter';
import { Link } from 'react-router-dom';
import AlertReportListTopFilters from '@modules/diseases/alert-reports/list/components/AlertReportListTopFilters';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);

    return pipe(
      TE.Do,
      TE.bind('savedFilters', () => AlertReportsService.Filter.getFilters()),
      TE.let('filters', ({ savedFilters }) => AlertReportUtils.alertReportHistoryFilterParser(savedFilters)(queries)),
      TE.bind('range', ({ filters }) => AlertReportsService.getAlertReportsHistory(getPageQuery(queries), filters)),
      httpTaskToResponseTask,
    );
  },
});

const actions = {
  createFilter: defineAction({
    type: 'create',
    payload: AlertReport.Filter.Params,
    handler: ({ payload }) => AlertReportsService.Filter.createFilter(payload),
  }),
  deleteFilter: defineAction({
    type: 'deleteFilter',
    payload: AlertReport.Filter.Id,
    handler: ({ payload }) => AlertReportsService.Filter.deleteFilter(payload),
  }),
};

const AlertReportsList: FC = () => {
  const { savedFilters, filters } = useLoader<typeof loader>();

  const [, createFilter] = useAction(actions.createFilter);
  const [, deleteFilter] = useAction(actions.deleteFilter);

  const { range, handleLoadPage, handleFilter } = useRange<typeof loader, AlertReport.HistoryItem>(data => data.range);

  const pageProps: PageProps = {
    withNav: true,
    back: {
      to: '/diseases',
      title: 'Liste de mes signalements',
    },
  };

  const hasEmptyFilters = isFilterEmpty({ ...filters, status: null, year: null });

  return (
    <Page {...pageProps}>
      <AlertReportListTopFilters filters={filters} onFilter={handleFilter} />

      <Box mt={20} mb={50}>
        <VirtualizedList range={range} loadPage={handleLoadPage} gap={15}>
          {alertReport => <AlertReportRow alertReport={alertReport} />}
        </VirtualizedList>
      </Box>

      <Indicator
        pos="fixed"
        bottom={90}
        left="50%"
        color="tertiary.8"
        style={{ transform: 'translateX(-50%)' }}
        disabled={hasEmptyFilters}
      >
        <Button
          component={PreserveSearchLink}
          to={{ hash: 'filters' }}
          leftIcon={<IconFilter size={18} />}
          preventScrollReset
          radius={4}
          px={16}
          color="primary.5"
          tt="uppercase"
          styles={{
            leftIcon: {
              marginRight: 4,
              transform: 'rotateY(0.5turn)',
            },
          }}
        >
          Filtrer
        </Button>
      </Indicator>

      <ActionIcon
        component={Link}
        to="/diseases/alert-reports/new"
        pos="fixed"
        bottom={90}
        right={20}
        size={44}
        radius="50%"
        bg="tertiary.8"
        c="white"
      >
        <IconPlus />
      </ActionIcon>

      <AlertReportsFilters
        isHistory
        filters={filters}
        savedFilters={savedFilters}
        onCreateFilter={createFilter}
        onDeleteFilter={deleteFilter}
      />
    </Page>
  );
};

const alertReportsListRoute = defineRoute({
  element: <AlertReportsList />,
  loader,
  actions,
});

export default alertReportsListRoute;
