import { z } from 'zod';
import { NonEmptyString } from '@shared/schemas';
import { ImageMap } from '@shared/modules/images/model';
import { Utils } from '@shared/utils/model';
import { LocalDateTime } from '@shared/modules/dates';
import { StringifiableRecord } from 'query-string';
import { ordFromOrdering } from '@shared/utils/order';
import { Editorial } from '@shared/modules/editorial/model';
import { Previsions } from '@shared/modules/previsions/model';
import { Pest } from '@modules/diseases/pests/model';
import { ActiveSensor, Sensor as SensorModel } from '@modules/sensors/model';
import * as NEA from 'fp-ts/NonEmptyArray';
import { HubApi } from '@modules/iot/model';
import { Measure } from '@modules/measures/model';
import { Geo } from '@shared/modules/geo/model';
import { Profile } from '@modules/profile/model';

export namespace AlertReport {
  import Disease = Previsions.Disease;
  export const Id = z.coerce.number().brand('AlertReportId');
  export type Id = z.infer<typeof Id>;

  export const CategoryId = z.coerce.number().brand('AlertReportCategoryId');
  export type CategoryId = z.infer<typeof CategoryId>;

  export const SubCategoryId = z.coerce.number().brand('AlertReportSubCategoryId');
  export type SubCategoryId = z.infer<typeof SubCategoryId>;

  export const TypeId = z.coerce.number().brand('AlertReportTypeId');
  export type TypeId = z.infer<typeof TypeId>;

  export const Gravity = z.coerce.number().brand('AlertReportGravity');
  export type Gravity = z.infer<typeof Gravity>;

  export const Color = z.string().brand('AlertReportColor');
  export type Color = z.infer<typeof Color>;

  export const ImageId = z.string().brand('AlertReportImageId');
  export type ImageId = z.infer<typeof ImageId>;

  export interface Marker {
    id: Id;
    location: Utils.GPSCoordinates;
    category: AlertReport.Category;
    subCategory: AlertReport.SubCategory;
    type: AlertReport.Type;
  }

  export interface Category {
    id: CategoryId;
    label: NonEmptyString;
    color: Color;
    images: ImageMap;
    hasGrassType: boolean;
  }

  export interface SubCategory {
    id: SubCategoryId;
    categoryId: CategoryId;
    label: NonEmptyString;
    images: ImageMap;
    maxGravity: Gravity;
  }

  export interface Type {
    id: TypeId;
    subCategoryId: SubCategoryId;
    label: NonEmptyString;
    description: NonEmptyString | null;
    editorial: Editorial;
    isPrivateAuthorized: boolean;
    images: ImageMap;
  }

  export enum GrassType {
    START = 'START',
    FAIRWAY = 'FAIRWAY',
    ROUGH = 'ROUGH',
    GREEN = 'GREEN',
    AROUND_GREEN = 'AROUND_GREEN',
    OTHER = 'OTHER',
  }

  export const grassTypeLabels: Record<GrassType, string> = {
    [GrassType.START]: 'Départ',
    [GrassType.FAIRWAY]: 'Fairway',
    [GrassType.ROUGH]: 'Rough',
    [GrassType.GREEN]: 'Green',
    [GrassType.AROUND_GREEN]: 'Tour de green',
    [GrassType.OTHER]: 'Autre',
  };

  export enum Status {
    Active = 'active',
    Inactive = 'inactive',
    All = 'all',
  }

  export interface Image {
    id: ImageId;
    variants: ImageMap;
  }

  export enum Period {
    CurrentMonth = 'current-month',
    CurrentTrimester = 'current-trimester',
    CurrentSemester = 'current-semester',
    Personalized = 'personalized',
  }

  export const periodLabel: Record<Period, string> = {
    [Period.CurrentMonth]: 'Mois en cours',
    [Period.CurrentTrimester]: 'Trimestre en cours',
    [Period.CurrentSemester]: 'Semestre en cours',
    [Period.Personalized]: 'Personnaliser',
  };

  const periodOrdering: Record<Period, number> = {
    [Period.CurrentMonth]: 0,
    [Period.CurrentTrimester]: 1,
    [Period.CurrentSemester]: 2,
    [Period.Personalized]: 3,
  };

  export const periodOrd = ordFromOrdering(periodOrdering);

  export interface HistoryItem {
    id: Id;
    category: string;
    subCategory: string;
    type: string;
    status: Status;
    reportedAt: string;
    image: ImageMap | null;
  }

  export namespace Filter {
    export const Id = z.string().uuid().brand('AlertReportFilterId');
    export type Id = z.infer<typeof Id>;

    export interface Light {
      id: Filter.Id;
      name: string;
    }

    export interface Analysis extends StringifiableRecord {
      sensorId: SensorModel.Id | null;
      type: SensorModel.Type | null;
    }

    export const Schema = z.object({
      categoryId: AlertReport.CategoryId.nullish(),
      subCategoryId: AlertReport.SubCategoryId.nullish(),
      typeId: AlertReport.TypeId.nullish(),
      gravity: AlertReport.Gravity.nullish(),
      period: z.nativeEnum(AlertReport.Period).nullish(),
      personalizedStartDate: LocalDateTime.nullish(),
      personalizedEndDate: LocalDateTime.nullish(),
      myReports: z.boolean().nullish(),
      hasPictures: z.boolean().nullish(),
      privateOnly: z.boolean().nullish(),
      status: z.nativeEnum(AlertReport.Status),
    });

    export const Params = Schema.extend({
      name: NonEmptyString,
    });
    export type Params = z.infer<typeof Params>;

    export const Queries = Schema.extend({
      filterId: Filter.Id.nullish(),
    });
    export type Queries = StringifiableRecord & z.infer<typeof Queries>;

    export type Detail = Light & z.infer<typeof Schema>;

    export type History = Queries & { year: number | null };
  }

  export namespace Flow {
    export const UploadedImageId = z.string().uuid().brand('AlertReportUploadedImageId');
    export type UploadedImageId = z.infer<typeof UploadedImageId>;

    export interface UploadedImage {
      type: 'uploaded';
      id: UploadedImageId;
      isMain: boolean;
      file: File;
    }

    export interface ExistingImage {
      type: 'existing';
      id: ImageId;
      isMain: boolean;
      variants: ImageMap;
    }

    export type Image = UploadedImage | ExistingImage;

    export const Params = z.object({
      type: TypeId,
      comment: z.string().nullish(),
      internalComment: z.string().nullish(),
      grassType: z.nativeEnum(GrassType).nullish(),
      location: Utils.GPSCoordinates,
      severity: Gravity.nullish(),
      isPrivate: z.boolean(),
      mainImage: ImageId.or(UploadedImageId).nullish(),
      images: z.array(ImageId.or(UploadedImageId)),
    });
    export type Params = z.infer<typeof Params>;

    export const Body = Params.pick({
      isPrivate: true,
      grassType: true,
      severity: true,
      comment: true,
      internalComment: true,
    });
    export type Body = z.infer<typeof Body>;
  }

  export namespace Vote {
    export enum State {
      Like = 'LIKE',
      Dislike = 'DISLIKE',
      None = 'NONE',
    }
  }

  export interface Vote {
    likesCount: number;
    dislikesCount: number;
    mine: Vote.State | null;
  }

  export interface Detail {
    id: Id;
    companyId: Profile.Company.Id;
    department: NonEmptyString | null;
    category: Category;
    subCategory: SubCategory;
    type: Type;
    reportedAt: LocalDateTime;
    coordinates: Utils.GPSCoordinates;
    comment: NonEmptyString | null;
    internalComment: NonEmptyString | null;
    grassType: GrassType | null;
    companyReportTotal: number;
    images: NEA.NonEmptyArray<Image> | null;
    gravity: Gravity | null;
    vote: Vote;
    isPrivate: boolean;
    pest: Pest.Name | null;
    diseaseModels: NEA.NonEmptyArray<Disease.Model> | null;
    insee: Geo.City.INSEECode | null;
    city: NonEmptyString | null;
    postalCode: Utils.PostalCode | null;
    canEdit: boolean;
  }

  export interface Weather {
    reportDay: Previsions.Weather.DayForecast | null;
    previousDays: Array<Previsions.Weather.DayForecast>;
    location: Geo.City | null;
  }

  export interface Diseases {
    reportDay: Disease.Forecast;
    previousDays: Array<Disease.DayForecast>;
    location: Geo.City | null;
  }

  export interface Pests {
    reportWeek: Pest.Description;
    previousWeek: Pest.Description;
  }

  export type Sensor<Type extends SensorModel.Type = SensorModel.Type> = {
    id: SensorModel.Id;
    technicalId: HubApi.SensorId;
    type: Type;
    serial: SensorModel.Serial;
    name: NonEmptyString;
    zone: NonEmptyString | null;
    config: ActiveSensor.Config<Type> | null;
    measures: Measure.History<Type> | null;
    canAccessDetail: boolean;
  };
}
