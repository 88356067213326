import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { renderNullable, renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Box, Button, Drawer, Group, Spoiler, Stack, Text, ThemeIcon } from '@mantine/core';
import * as A from 'fp-ts/Array';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import Editorial from '@shared/modules/editorial/Editorial';

const SpoilerControl: FC<{
  type: 'more' | 'less';
}> = ({ type }) => {
  return (
    <Stack justify="center" align="center" mt={15} spacing={0}>
      <Text fz={12} fw={500} opacity={0.9}>
        Lire {type === 'more' ? 'plus' : 'moins'}
      </Text>

      <ThemeIcon size={18} variant="transparent" color="primary.5">
        {type === 'more' ? <IconChevronDown /> : <IconChevronUp />}
      </ThemeIcon>
    </Stack>
  );
};

interface AlertReportTypeDetailProps {
  type: AlertReport.Type;
}

const AlertReportTypeDetail: FC<AlertReportTypeDetailProps> = ({ type }) => {
  const [editorialDrawerOpened, editorialDrawerTriggers] = useDisclosure();

  return type.description || A.isNonEmpty(type.editorial ?? []) ? (
    <Spoiler
      mt={15}
      maxHeight={40}
      styles={{ control: { width: '100%' } }}
      showLabel={<SpoilerControl type="more" />}
      hideLabel={<SpoilerControl type="less" />}
    >
      {renderNullable(type.description, description => (
        <Text fz={14} c="tertiary.8" sx={{ whiteSpace: 'pre-wrap' }}>
          {description}
        </Text>
      ))}

      {renderOptional(NEA.fromArray(type.editorial ?? []), editorial => (
        <Group position="center" mt={15}>
          <Button variant="outline" tt="uppercase" onClick={editorialDrawerTriggers.open}>
            En savoir plus
          </Button>

          <Drawer
            size="100vw"
            opened={editorialDrawerOpened}
            onClose={editorialDrawerTriggers.close}
            transitionProps={{ transition: 'slide-up', timingFunction: 'ease' }}
            styles={theme => ({
              header: { background: theme.colors.tertiary[8], zIndex: 300 },
              // z-index to prevent dropdown options behind
              close: {
                border: 'none',
                width: '26px ',
                height: '26px ',
                background: 'transparent !important',
                svg: {
                  width: '100%',
                  height: '100%',
                  color: 'white',
                },
              },
              body: {
                background: theme.colors.tertiary[2],
              },
              content: {
                border: 'none !important',
              },
              title: {
                overflow: 'hidden',
              },
            })}
            withCloseButton
            title={
              <Text
                color="white"
                size={17}
                weight={600}
                mr={5}
                sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              >
                {type.label}
              </Text>
            }
            closeOnClickOutside
          >
            <Box py={15}>
              <Editorial editorial={editorial} />
            </Box>
          </Drawer>
        </Group>
      ))}
    </Spoiler>
  ) : null;
};

export default AlertReportTypeDetail;
