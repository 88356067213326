import React, { FC } from 'react';
import { useFetchTask } from '@core/http/hooks';
import { DashboardService } from '@modules/iot/dashboard/service';
import { renderHttpRemoteData } from '@shared/utils/render';
import { Box, Stack, Text } from '@mantine/core';
import AlertsPieChartCard from '@modules/alerts/components/AlertsPieChartCard';
import SimpleCard from '@shared/components/list/card/SimpleCard';
import { IconAlertTriangle, IconMap, IconSettings } from '@tabler/icons-react';

const SensorsDashboardContent: FC = () => {
  const [dashboard] = useFetchTask(DashboardService.getDashboard, false, null);

  return renderHttpRemoteData(dashboard, ({ sensors, alerts }) => (
    <>
      <Stack spacing={5} mb={25}>
        <Box>
          <Text color="tertiary.8" size={17} weight={600}>
            Sondes
          </Text>
          <Text color="tertiary.8" size={9} weight={500} lh={1.78} opacity={0.5} tt="uppercase">
            {sensors.total} sonde{sensors.total > 1 && 's'}
          </Text>
        </Box>
        <AlertsPieChartCard alerts={alerts} />
      </Stack>
      <Stack spacing={15}>
        <SimpleCard to="map" icon={IconMap} title="Carte des sondes" bg="tertiary.6">
          Localisez vos sondes
        </SimpleCard>
        <SimpleCard to="pending" icon={IconSettings} title="Sondes à configurer" bg="primary.5">
          Il vous reste{' '}
          <Text component="span" weight={600}>
            {sensors.pending.count}
          </Text>{' '}
          sondes à configurer
        </SimpleCard>
        <SimpleCard to="alerts" icon={IconAlertTriangle} title="Alertes" bg="primary.4">
          Configuration des alertes de sondes
        </SimpleCard>
      </Stack>
    </>
  ));
};

export default SensorsDashboardContent;
