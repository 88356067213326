import { Auth } from '@modules/auth/model';
import { OAuthService } from '@core/oauth/service';
import { OAuth } from '@core/oauth/model';
import { constVoid, pipe } from 'fp-ts/function';
import { httpService } from '@core/http';
import * as TE from 'fp-ts/TaskEither';

export namespace AuthService {
  export function exchangeToken(token: Auth.Token): OAuth.OAuthHttpTask {
    return pipe(
      httpService.post<OAuth.OAuthTokens, OAuth.OAuthError>('/app/authenticate/as/exchange', {
        access_token: token,
      }),
      TE.chainFirstTaskK(OAuthService.saveOAuthTokensInStorage),
      TE.map(constVoid),
    );
  }
}
