import React, { FC, PropsWithChildren } from 'react';
import { Group, MantineColor, Text } from '@mantine/core';
import { Previsions } from '@shared/modules/previsions/model';
import Disease = Previsions.Disease;

interface DashboardCaptionProps {
  color: MantineColor;
}

const Content: FC<PropsWithChildren<DashboardCaptionProps>> = ({ color, children }) => {
  return (
    <Text
      size={10}
      weight={600}
      sx={theme => ({
        ':after': { content: "''", width: 10, height: 10, background: theme.fn.themeColor(color), borderRadius: '50%' },
        display: 'flex',
        alignItems: 'center',
        columnGap: 4,
      })}
    >
      {children}
    </Text>
  );
};

const DiseaseCaption: FC = () => {
  return (
    <Group spacing={17}>
      <Content color={Disease.Level.nullableColor}>{Disease.Level.nullableText}</Content>
      <Content color={Disease.Level.color[Disease.Level.Low]}>{Disease.Level.text[Disease.Level.Low]}</Content>
      <Content color={Disease.Level.color[Disease.Level.Medium]}>{Disease.Level.text[Disease.Level.Medium]}</Content>
      <Content color={Disease.Level.color[Disease.Level.High]}>{Disease.Level.text[Disease.Level.High]}</Content>
    </Group>
  );
};

export default DiseaseCaption;
