import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import Supercluster from 'supercluster';
import { CustomPointFeature } from '@shared/modules/maps/hooks/supercluster';
import MapClustering from '@shared/modules/maps/components/MapClustering';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import AlertReportPin from '@modules/diseases/alert-reports/map/components/AlertReportPin';

interface AlertReportMapContentProps {
  map: google.maps.Map;
  markers: Array<AlertReport.Marker>;
  supercluster: Supercluster<CustomPointFeature<AlertReport.Marker>>;
  onSelectedMarkerChange: (marker: AlertReport.Marker | null) => void;
}

const AlertReportMapContent: FC<AlertReportMapContentProps> = ({
  map,
  markers,
  supercluster,
  onSelectedMarkerChange,
}) => {
  const handleMarkerClick = (marker: AlertReport.Marker) => () => onSelectedMarkerChange(marker);

  return (
    <MapClustering map={map} markers={markers} supercluster={supercluster}>
      {marker => (
        <GoogleMapsOverlay
          key={marker.id}
          position={{ lat: marker.location.latitude, lng: marker.location.longitude }}
          map={map}
        >
          <AlertReportPin
            category={marker.category}
            subCategory={marker.subCategory}
            onClick={handleMarkerClick(marker)}
          />
        </GoogleMapsOverlay>
      )}
    </MapClustering>
  );
};

export default AlertReportMapContent;
