import React, { FC, PropsWithChildren } from 'react';
import { Anchor, Button, Group, Modal, Text } from '@mantine/core';

interface FormPreventLeaveModalProps {
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
}

const FormPreventLeaveModal: FC<PropsWithChildren<FormPreventLeaveModalProps>> = ({ open, onClose, onLeave }) => {
  return (
    <Modal
      opened={open}
      onClose={onClose}
      centered
      size={350}
      withCloseButton={false}
      title={
        <Text size="lg" fw={700} c="dark.5">
          Ignorer les modifications ?
        </Text>
      }
    >
      <Text size="sm">Les informations saisies ne seront pas sauvegardées.</Text>

      <Group position="right" mt="xl">
        <Anchor component="button" size="sm" c="dark.3" onClick={onClose}>
          Annuler
        </Anchor>
        <Button onClick={onLeave} color="primary.5">
          Ignorer
        </Button>
      </Group>
    </Modal>
  );
};

export default FormPreventLeaveModal;
