import React, { FC } from 'react';
import { HttpTask } from '@core/http';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import FiltersDrawer from '@shared/modules/filter/components/FiltersDrawer';
import { AlertReportUtils } from '@modules/diseases/alert-reports/utils';
import { useProfile } from '@modules/profile/loader';
import AlertReportsFiltersContent from '@modules/diseases/alert-reports/filters/AlertReportsFiltersContent';

interface AlertReportsFiltersProps {
  isHistory?: boolean;
  filters: AlertReport.Filter.Queries;
  savedFilters: Array<AlertReport.Filter.Light>;
  onCreateFilter: (params: AlertReport.Filter.Params) => HttpTask<AlertReport.Filter.Detail>;
  onDeleteFilter: (id: AlertReport.Filter.Id) => HttpTask;
}

const AlertReportsFilters: FC<AlertReportsFiltersProps> = ({
  isHistory,
  filters,
  savedFilters,
  onCreateFilter,
  onDeleteFilter,
}) => {
  const profile = useProfile();

  const isAccessLimited = profile.subscription.isAccessLimited;

  const activeSavedFilter = (filter: AlertReport.Filter.Queries) =>
    pipe(
      savedFilters,
      A.findFirst(f => f.id === filter.filterId),
      O.toNullable,
    );

  const handleSavedFilterChange = (
    savedFilter: AlertReport.Filter.Light | null,
    filters: AlertReport.Filter.Queries,
  ): HttpTask<AlertReport.Filter.Queries> =>
    pipe(
      O.fromNullable(savedFilter),
      O.fold<AlertReport.Filter.Light, HttpTask<AlertReport.Filter.Queries>>(
        () =>
          TE.right({
            ...filters,
            filterId: null,
          }),
        savedFilter =>
          pipe(
            AlertReportsService.Filter.getFilterDetail(savedFilter.id),
            TE.map(filter => ({
              filterId: filter.id,
              ...filter,
            })),
          ),
      ),
    );

  const handleCreateSavedFilter = (
    name: string,
    filters: AlertReport.Filter.Queries,
  ): HttpTask<AlertReport.Filter.Queries> =>
    pipe(
      onCreateFilter({
        ...filters,
        name,
      }),
      TE.map(savedFilter => ({
        ...filters,
        filterId: savedFilter.id,
      })),
    );

  const handleDeleteSavedFilter = (savedFilter: AlertReport.Filter.Light) => onDeleteFilter(savedFilter.id);

  const handleResetFilter = (): AlertReport.Filter.Queries =>
    AlertReportUtils.alertReportsFilterParser(savedFilters)({});

  return (
    <FiltersDrawer
      filters={filters}
      savedFilters={savedFilters}
      canSaveFilter={!isAccessLimited}
      activeSavedFilter={activeSavedFilter}
      onSavedFilterChange={handleSavedFilterChange}
      onCreateSavedFilter={handleCreateSavedFilter}
      onDeleteSavedFilter={handleDeleteSavedFilter}
      onResetFilter={handleResetFilter}
    >
      {props => <AlertReportsFiltersContent {...props} isAccessLimited={isAccessLimited} isHistory={isHistory} />}
    </FiltersDrawer>
  );
};

export default AlertReportsFilters;
