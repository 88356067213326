import React, { FC } from 'react';
import { Box, Loader, TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { filterEmptyStringToNullable } from '@shared/utils/string';
import { IconSearch } from '@tabler/icons-react';

interface AddressBody {
  address: string;
}

interface ReportLocationInputProps {
  loading: boolean;
  onSearch: (address: string) => void;
}

const ReportLocationInput: FC<ReportLocationInputProps> = ({ loading, onSearch }) => {
  const { handleSubmit, register } = useForm<AddressBody>();

  const onSubmit = (body: AddressBody) => {
    const address = filterEmptyStringToNullable(body.address);

    // blur to close mobile keyboard
    if (document.activeElement instanceof HTMLInputElement) {
      document.activeElement.blur();
    }

    if (address) {
      onSearch(address);
    }
  };

  return (
    <Box pos="sticky" top={0} mt={-5} bg="tertiary.8" px={20} pb={10} sx={{ zIndex: 10000 }}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          type="search"
          placeholder="Saisir une adresse"
          sx={{ border: 'none' }}
          icon={<IconSearch />}
          rightSection={loading ? <Loader size={20} /> : undefined}
          styles={{
            input: {
              border: 'none',
              color: 'white',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
          }}
          {...register('address')}
        />
      </form>
    </Box>
  );
};

export default ReportLocationInput;
