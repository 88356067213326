import React, { FC } from 'react';
import { Skeleton, Stack, Text } from '@mantine/core';
import { renderHttpRemoteData } from '@shared/utils/render';
import MainDisease from '@shared/modules/previsions/components/disease/today/MainDisease';
import { Link, useLocation } from 'react-router-dom';
import { stringifyQueries } from '@shared/utils/queries';
import PestCard from '@modules/diseases/pests/components/PestCard';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { PrevisionsService } from '@shared/modules/previsions/service';
import { useFetchTask } from '@core/http/hooks';
import { PestService } from '@modules/diseases/pests/service';

function fetchData() {
  return pipe(
    PrevisionsService.getContext(null),
    TE.chain(context =>
      pipe(
        TE.of({ context }),
        TE.apS('pests', PestService.getPests(context)),
        TE.apS('disease', PrevisionsService.getDisease(context)),
      ),
    ),
  );
}

const DashboardContent: FC = () => {
  const location = useLocation();

  const [data] = useFetchTask(fetchData);

  return renderHttpRemoteData(
    data,
    ({ context, disease, pests }) => (
      <>
        <Stack>
          <MainDisease today={disease.today} location={context.location} />

          <Text
            ml="auto"
            component={Link}
            color="tertiary.8"
            weight={500}
            td="underline"
            size={12}
            to={{ pathname: '/previsions', search: stringifyQueries({ referrer: location.pathname }) }}
          >
            Voir toutes les prévisions
          </Text>
        </Stack>

        {pests.map(pest => (
          <PestCard key={pest.enum} pest={pest} />
        ))}
      </>
    ),
    () => <Skeleton h={216} radius={8} />,
  );
};

export default DashboardContent;
