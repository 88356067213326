import { AlertReport } from '@modules/diseases/alert-reports/model';
import { HttpRange, httpService, HttpTask } from '@core/http';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { createFormData } from '@shared/utils/formData';
import { AlertReportUtils } from '@modules/diseases/alert-reports/utils';

export namespace AlertReportsService {
  const URI = '/app/alert-reports';

  export function getMapAlertReports(filters: AlertReport.Filter.Queries): HttpTask<Array<AlertReport.Marker>> {
    return httpService.get(`${URI}/map`, { params: AlertReportUtils.statusServerFilterAdapter(filters) });
  }

  export function getAlertReportsHistory(
    page: number,
    filters: AlertReport.Filter.History,
  ): HttpRange<AlertReport.HistoryItem> {
    return httpService.getRange(`${URI}/history`, page, AlertReportUtils.statusServerFilterAdapter(filters));
  }

  export function getCategories(): HttpTask<Array<AlertReport.Category>> {
    return httpService.get(`${URI}/referential/categories`);
  }

  export function getSubCategories(id: AlertReport.CategoryId): HttpTask<Array<AlertReport.SubCategory>> {
    return httpService.get(`${URI}/referential/categories/${id}/subcategories`);
  }

  export function getTypes(id: AlertReport.SubCategoryId): HttpTask<Array<AlertReport.Type>> {
    return httpService.get(`${URI}/referential/subcategories/${id}/types`);
  }

  export function createAlert(params: AlertReport.Flow.Params): HttpTask {
    return httpService.post(URI, params);
  }

  export function uploadAlertImage(file: File): HttpTask<AlertReport.Flow.UploadedImageId> {
    return pipe(
      httpService.post<{ id: AlertReport.Flow.UploadedImageId }>(`${URI}/images`, createFormData({ body: file })),
      TE.map(({ id }) => id),
    );
  }

  export function deleteAlertImage(id: AlertReport.Flow.UploadedImageId): HttpTask {
    return httpService.delete(`${URI}/images`, { data: { imageId: id } });
  }

  export function getAlertDetail(id: AlertReport.Id): HttpTask<AlertReport.Detail> {
    return httpService.get(`${URI}/${id}`);
  }

  export function updateVote(id: AlertReport.Id, state: AlertReport.Vote.State): HttpTask {
    return httpService.post(`${URI}/${id}/vote`, { state });
  }

  export function updateAlert(id: AlertReport.Id, params: AlertReport.Flow.Params): HttpTask {
    return httpService.post(`${URI}/${id}`, params);
  }

  export function deleteAlert(id: AlertReport.Id): HttpTask {
    return httpService.delete(`${URI}/${id}`);
  }

  export namespace Filter {
    const URI = '/app/alert-reports/filters';

    export function getFilters(): HttpTask<Array<AlertReport.Filter.Light>> {
      return httpService.get(URI);
    }

    export function createFilter(params: AlertReport.Filter.Params): HttpTask<AlertReport.Filter.Detail> {
      return httpService.post(URI, AlertReportUtils.statusServerFilterAdapter(params));
    }

    export function getFilterDetail(id: AlertReport.Filter.Id): HttpTask<AlertReport.Filter.Detail> {
      return httpService.get(`${URI}/${id}`);
    }

    export function deleteFilter(id: AlertReport.Filter.Id): HttpTask {
      return httpService.delete(`${URI}/${id}`);
    }
  }

  export namespace Analysis {
    const URI = (id: AlertReport.Id) => `/app/alert-reports/${id}/analysis`;

    export function getWeather(id: AlertReport.Id): HttpTask<AlertReport.Weather> {
      return httpService.get(`${URI(id)}/weather`);
    }

    export function getDiseases(id: AlertReport.Id): HttpTask<AlertReport.Diseases> {
      return httpService.get(`${URI(id)}/diseases`);
    }

    export function getPests(id: AlertReport.Id): HttpTask<AlertReport.Pests> {
      return httpService.get(`${URI(id)}/pest`);
    }

    export function getSensors(id: AlertReport.Id): HttpTask<Array<AlertReport.Sensor>> {
      return httpService.get(`${URI(id)}/sensors`);
    }
  }
}
