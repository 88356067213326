import React, { FC, useReducer } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { defineAction, useAction } from '@core/router/action';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import {
  createReportFlowContext,
  reportFlowReducer,
  ReportFlowState,
  reportFlowStateToParams,
} from '@modules/diseases/alert-reports/flow/context';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { HttpError } from '@core/http';
import CreateReportPlaceholder from '@modules/diseases/alert-reports/create/components/CreateReportPlaceholder';
import { useProfile } from '@modules/profile/loader';

function reportFlowInitialState(categories: Array<AlertReport.Category>): ReportFlowState {
  return {
    categories,
    selectedCategory: null,
    subCategories: [],
    selectedSubCategory: null,
    types: [],
    selectedType: null,
    form: null,
    images: [],
    location: null,
  };
}

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(AlertReportsService.getCategories()),
});

const actions = {
  create: defineAction({
    type: 'create',
    payload: AlertReport.Flow.Params,
    handler: ({ payload }) => AlertReportsService.createAlert(payload),
  }),
};

const CreateReportContext: FC = () => {
  const navigate = useNavigate();

  const profile = useProfile();

  const categories = useLoader<typeof loader>();

  const [loading, createReport] = useAction(actions.create);

  const [state, dispatch] = useReducer(reportFlowReducer, categories, reportFlowInitialState);

  const onSubmit = (state: ReportFlowState) => {
    pipe(
      reportFlowStateToParams(state),
      TE.fromNullable(HttpError.notFound),
      TE.chain(createReport),
      TE.chainIOK(() => () => {
        navigate('../success', { replace: true, relative: 'path' });
      }),
    )();
  };

  if (!profile.rights.createAlertReport) {
    return <CreateReportPlaceholder />;
  }

  return <Outlet context={createReportFlowContext(state, dispatch, { loading, onSubmit })} />;
};

const createReportContextRoute = defineRoute({
  element: <CreateReportContext />,
  loader,
  actions,
});

export default createReportContextRoute;
