import { flow, pipe } from 'fp-ts/function';
import { Utils } from '@shared/utils/model';
import * as Ord from 'fp-ts/Ord';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import * as N from 'fp-ts/number';
import * as M from 'fp-ts/Monoid';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Measure } from '@modules/measures/model';
import { Charts } from '@shared/modules/charts/model';
import { LocalDateTime } from '@shared/modules/dates';
import { Sensor } from '@modules/sensors/model';

export namespace MeasureUtils {
  export const batteryLevel = (value: Utils.Percent) =>
    pipe(
      R.toEntries(Measure.batteryBreakpoint),
      A.sort(Ord.tuple(Ord.trivial, N.Ord)),
      A.reduceRight(Measure.BatteryLevel.Empty, ([level, breakpoint], acc) =>
        Ord.leq(Utils.percentOrd)(value, breakpoint) ? level : acc,
      ),
    );

  export function getMeasureValue<MeasureType extends Measure.Type, SensorType extends Sensor.Type>(
    measures: Measure.Last | Measure.Last.Impl<SensorType> | null,
    type: MeasureType,
  ): O.Option<Measure.Value<MeasureType>> {
    return pipe(
      A.flatten(Object.values(measures ?? {})),
      A.filter<Measure.RealTimeMeasure.Impl<Measure.Type>, Measure.RealTimeMeasure.Impl<MeasureType>>(
        (measure): measure is Measure.RealTimeMeasure.Impl<MeasureType> => measure.type === type,
      ),
      NEA.fromArray,
      O.map(
        flow(NEA.unappend, ([tail, head]) =>
          tail.reduce((prev, acc) => Ord.min(Measure.typeOrd[type])(acc.value, prev), head.value),
        ),
      ),
    );
  }

  export const mergeDateMonoid: M.Monoid<Record<LocalDateTime, Charts.Line.SensorDataValues>> = R.getUnionMonoid({
    concat: (x, y) => ({ ...x, ...y }),
  });
}
