import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import ReportFlowLayout from '@modules/diseases/alert-reports/flow/layout/ReportFlowLayout';
import { Button, Card, Stack, Text, ThemeIcon } from '@mantine/core';
import { ReactComponent as BullhornIcon } from '@assets/icons/bullhorn.svg';
import { Link } from 'react-router-dom';

const CreateReportSuccess: FC = () => {
  return (
    <ReportFlowLayout type="create" title="Signalement">
      <Stack p={40} sx={{ flex: '1 1 auto' }} justify="center">
        <Card py={35} px={10} bg="white" withBorder={false} radius={8}>
          <Stack align="center" ta="center">
            <ThemeIcon size={146} radius={100} bg="#f5f4f2" c="#C3BBB2">
              <BullhornIcon width={94} height={94} strokeWidth={1} />
            </ThemeIcon>

            <Text c="tertiary.8">
              Votre signalement a été envoyé avec succès. L’équipe Platform.garden vous remercie.
            </Text>

            <Button component={Link} to="/diseases/alert-reports" tt="uppercase">
              Visualiser les signalements
            </Button>
          </Stack>
        </Card>
      </Stack>
    </ReportFlowLayout>
  );
};

const createReportSuccessRoute = defineRoute({
  element: <CreateReportSuccess />,
});

export default createReportSuccessRoute;
