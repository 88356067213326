import React, { FC, useEffect, useState } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { Box, CloseButton, Text } from '@mantine/core';
import * as Styled from './ReportImagePreview.style';
import { useDoubleTap } from 'use-double-tap';

interface ReportImagePreviewProps {
  image: AlertReport.Flow.Image;
  onDelete: (image: AlertReport.Flow.Image) => void;
  onPromoteMain: (image: AlertReport.Flow.Image) => void;
}

const ReportImagePreview: FC<ReportImagePreviewProps> = ({ image, onDelete, onPromoteMain }) => {
  const [src] = useState(() => (image.type === 'uploaded' ? URL.createObjectURL(image.file) : image.variants.original));

  const bind = useDoubleTap(() => {
    onPromoteMain(image);
  });

  useEffect(() => {
    if (image.type === 'uploaded' && src) {
      return () => {
        URL.revokeObjectURL(src);
      };
    }
  }, [image.type, src]);

  const handleDelete = () => onDelete(image);

  return (
    <Styled.ReportImagePreviewContainer>
      <Styled.ReportImagePreviewContent {...bind}>
        <img src={src} alt={image.id} />
      </Styled.ReportImagePreviewContent>

      <CloseButton
        radius="100%"
        pos="absolute"
        top={5}
        right={5}
        size={16}
        bg="rgba(0, 0, 0, 0.5)"
        sx={{ zIndex: 2, color: 'white' }}
        onClick={handleDelete}
      />

      {image.isMain ? (
        <Box bg="rgba(0, 0, 0, 0.4)" pos="absolute" bottom={0} left={0} w="100%" p={3}>
          <Text c="#fff" fz={14} ta="center">
            Photo de couverture
          </Text>
        </Box>
      ) : null}
    </Styled.ReportImagePreviewContainer>
  );
};

export default ReportImagePreview;
