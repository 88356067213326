import React, { FC } from 'react';
import FiltersDrawer from '@shared/modules/filter/components/FiltersDrawer';
import { ActiveSensor } from '@modules/sensors/model';
import { SensorFilter } from '@modules/sensors/map/filters/model';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';
import { HttpTask } from '@core/http';
import { sequenceS } from 'fp-ts/Apply';
import SensorsFiltersContent from '@modules/sensors/map/filters/SensorsFiltersContent';
import { Zone } from '@modules/iot/zones/model';

interface SensorsFiltersProps {
  filters: ActiveSensor.FilterWithCity;
  savedFilters: Array<SensorFilter>;
  zones: Array<Zone>;
  onCreateFilter: (params: SensorFilter.CreateParams) => HttpTask<SensorFilter>;
  onDeleteFilter: (id: SensorFilter.Id) => HttpTask;
}

const SensorsFilters: FC<SensorsFiltersProps> = ({ filters, savedFilters, zones, onCreateFilter, onDeleteFilter }) => {
  const activeSavedFilter = (filters: ActiveSensor.FilterWithCity) =>
    pipe(
      savedFilters,
      A.findFirst(f => f.id === filters.filterId),
      O.toNullable,
    );

  const handleSavedFilterChange = (
    savedFilter: SensorFilter | null,
    filters: ActiveSensor.FilterWithCity,
  ): HttpTask<ActiveSensor.FilterWithCity> =>
    TE.right({
      ...filters,
      filterId: savedFilter?.id ?? null,
      ...(savedFilter
        ? {
            zoneId: savedFilter.zoneId,
            code: savedFilter.location?.code ?? null,
            postalCode: savedFilter.location?.postalCode ?? null,
            city: savedFilter.location?.city ?? null,
          }
        : {}),
    });

  const handleCreateSavedFilter = (
    name: string,
    filters: ActiveSensor.FilterWithCity,
  ): HttpTask<ActiveSensor.FilterWithCity> =>
    pipe(
      onCreateFilter({
        name,
        zoneId: filters.zoneId,
        location: pipe(
          sequenceS(O.Apply)({
            code: O.fromNullable(filters.code),
            postalCode: O.fromNullable(filters.postalCode),
            city: O.fromNullable(filters.city),
          }),
          O.toNullable,
        ),
      }),
      TE.map(savedFilter => ({
        ...filters,
        filterId: savedFilter.id,
      })),
    );

  const handleDeleteSavedFilter = (savedFilter: SensorFilter) => onDeleteFilter(savedFilter.id);

  const handleResetFilter = (filters: ActiveSensor.FilterWithCity): ActiveSensor.FilterWithCity => ({
    ...filters,
    filterId: null,
    zoneId: null,
    code: null,
    postalCode: null,
    city: null,
  });

  return (
    <FiltersDrawer
      filters={filters}
      savedFilters={savedFilters}
      canSaveFilter
      activeSavedFilter={activeSavedFilter}
      onSavedFilterChange={handleSavedFilterChange}
      onCreateSavedFilter={handleCreateSavedFilter}
      onDeleteSavedFilter={handleDeleteSavedFilter}
      onResetFilter={handleResetFilter}
    >
      {props => <SensorsFiltersContent zones={zones} {...props} />}
    </FiltersDrawer>
  );
};

export default SensorsFilters;
