import { Filter } from '@shared/modules/filter/model';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import { ParsedQuery } from 'query-string';

export function isFilterEmpty(filter: Filter): boolean {
  return pipe(
    filter,
    R.filter(value => value != null),
    R.isEmpty,
  );
}

export function getQueryValue<T extends string = string>(query: ParsedQuery, key: string): O.Option<T> {
  return pipe(
    O.fromNullable(query[key] as T | T[] | null),
    O.chain(value => (Array.isArray(value) ? A.head(value) : O.some(value))),
  );
}

function getQueryValueBoolean(query: ParsedQuery, key: string): O.Option<boolean> {
  return pipe(
    O.fromNullable(query[key] as string | null),
    O.chain(value => {
      switch (value) {
        case 'true':
          return O.some(true);
        case 'false':
          return O.some(false);
        default:
          return O.none;
      }
    }),
  );
}

function getArrayQueryValue<T extends string = string>(
  query: ParsedQuery,
  key: string,
): O.Option<NEA.NonEmptyArray<T>> {
  return pipe(
    O.fromNullable(query[key] as T | T[] | null),
    O.chain(value => (Array.isArray(value) ? NEA.fromArray(value) : O.some([value]))),
  );
}

export function getBooleanQuery(query: ParsedQuery, key: string): boolean | null {
  return O.toNullable(getQueryValueBoolean(query, key));
}

export function getStringQuery<T extends string>(query: ParsedQuery, key: string): T | null {
  return O.toNullable(getQueryValue(query, key));
}

export function getIntQuery<T extends number = number>(query: ParsedQuery, key: string): T | null {
  return pipe(
    getQueryValue(query, key),
    O.map(value => parseInt(value, 10) as T),
    O.filter(value => !isNaN(value)),
    O.toNullable,
  );
}

export function getSearchQuery(query: ParsedQuery): string | null {
  return getStringQuery(query, 'search');
}

export function getEnumQuery<E extends string>(
  query: ParsedQuery,
  enumeration: Record<string, E>,
  key: string,
): E | null {
  return pipe(
    getQueryValue(query, key),
    O.filterMap(value => (Object.values(enumeration).includes(value as E) ? O.some(value as E) : O.none)),
    O.toNullable,
  );
}

export function getEnumArrayQuery<E extends string>(
  query: ParsedQuery,
  enumeration: Record<string, E>,
  key: string,
): NEA.NonEmptyArray<E> | null {
  return pipe(
    getArrayQueryValue(query, key),
    O.chain(values =>
      pipe(
        values,
        A.filterMap(elt => (Object.values(enumeration).includes(elt as E) ? O.some(elt as E) : O.none)),
        NEA.fromArray,
      ),
    ),
    O.toNullable,
  );
}

export function getStringArrayQuery<T extends string = string>(
  query: ParsedQuery,
  key: string,
): NEA.NonEmptyArray<T> | null {
  return O.toNullable(getArrayQueryValue(query, key));
}

export function getIntArrayQuery<T extends number = number>(
  query: ParsedQuery,
  key: string,
): NEA.NonEmptyArray<T> | null {
  return pipe(
    getArrayQueryValue(query, key),
    O.chain(values =>
      pipe(
        values,
        A.map(value => parseInt(value, 10) as T),
        A.filter(value => !isNaN(value)),
        NEA.fromArray,
      ),
    ),
    O.toNullable,
  );
}
