import React, { FC, useState } from 'react';
import { defineRoute } from '@core/router';
import { Navigate, useNavigate } from 'react-router-dom';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { useSendTask } from '@core/http/hooks';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { Box, Stack, Title } from '@mantine/core';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import ReportSubCategoryCard from '@modules/diseases/alert-reports/flow/components/sub-categories/ReportSubCategoryCard';
import { useReportFlowContext } from '@modules/diseases/alert-reports/flow/context';
import { ReportFlowStepProps } from '@modules/diseases/alert-reports/flow/index';
import ReportFlowLayout from '@modules/diseases/alert-reports/flow/layout/ReportFlowLayout';
import ReportFlowBottom from '@modules/diseases/alert-reports/flow/layout/ReportFlowBottom';

const ReportSubCategoriesStep: FC<ReportFlowStepProps> = ({ type }) => {
  const navigate = useNavigate();

  const [{ selectedSubCategory, subCategories, selectedCategory }, dispatch] = useReportFlowContext();

  const [loadingSubCategory, setLoadingSubCategory] = useState<AlertReport.SubCategoryId | null>(null);

  const [, fetchTypes] = useSendTask(AlertReportsService.getTypes);

  const onNext = () => navigate('../types', { relative: 'path' });

  const handleSelectSubCategory = (selectedSubCategory: AlertReport.SubCategory) =>
    pipe(
      TE.fromIO(() => setLoadingSubCategory(selectedSubCategory.id)),
      TE.chain(() => fetchTypes(selectedSubCategory.id)),
      TE.chainIOK(types => () => {
        dispatch({ type: 'SUB_CATEGORY_SELECTED', selectedSubCategory, types });

        onNext();
      }),
      T.chainIOK(() => () => setLoadingSubCategory(null)),
    )();

  if (!selectedCategory) {
    return <Navigate to=".." relative="path" replace />;
  }

  return (
    <ReportFlowLayout type={type} title={selectedCategory.label} back=".." withClose>
      <Box p={20}>
        <Title c="primary.5">Préciser la nature de votre signalement</Title>

        <Stack mt={30} spacing={10}>
          {subCategories.map(subCategory => (
            <ReportSubCategoryCard
              key={subCategory.id}
              subCategory={subCategory}
              color={selectedCategory.color}
              selected={subCategory.id === selectedSubCategory?.id}
              loading={loadingSubCategory === subCategory.id}
              onSelect={handleSelectSubCategory}
            />
          ))}
        </Stack>
      </Box>

      {selectedSubCategory != null ? <ReportFlowBottom back=".." onNext={onNext} /> : null}
    </ReportFlowLayout>
  );
};

const reportSubCategoriesStepRoute = (props: ReportFlowStepProps) =>
  defineRoute({
    element: <ReportSubCategoriesStep {...props} />,
  });

export default reportSubCategoriesStepRoute;
