import React, { FC } from 'react';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import { pipe } from 'fp-ts/function';
import { Stack } from '@mantine/core';
import { ActiveSensor, Sensor } from '@modules/sensors/model';
import MeasureCard from '@modules/measures/last/MeasureCard';
import { LastMeasureUtils } from '@modules/measures/last/utils';
import filterMeasures = LastMeasureUtils.filterMeasures;
import { Measure } from '@modules/measures/model';
import Type = Measure.Type;
import MeasureValue = LastMeasureUtils.MeasureValue;
import { Sinafis } from '@modules/sensors/sinafis/model';

interface MeasureCardsProps {
  sensor: ActiveSensor;
}

const MeasureCards: FC<MeasureCardsProps> = ({ sensor }) => {
  if (sensor.measures === null) return null;

  switch (sensor.type) {
    case Sensor.Type.Sensoterra: {
      const humidityMeasures = filterMeasures(sensor.measures.ground, sensor.config.humidityThreshold, Type.Humidity);

      return (
        <MeasureCard
          title={{ icon: Sensor.typeIcon[sensor.type], label: 'Capteur sol' }}
          measures={A.compact([humidityMeasures])}
        />
      );
    }
    case Sensor.Type.Hortee: {
      const humidityMeasures = filterMeasures(sensor.measures.ground, sensor.config.humidityThreshold, Type.Humidity);

      const temperatureMeasures = filterMeasures(
        sensor.measures.ground,
        sensor.config.temperatureThreshold,
        Type.Temperature,
      );

      return (
        <MeasureCard
          title={{ icon: Sensor.typeIcon[sensor.type], label: 'Capteur sol' }}
          measures={A.compact<MeasureValue<Measure.Type.Humidity | Measure.Type.Temperature>>([
            humidityMeasures,
            temperatureMeasures,
          ])}
        />
      );
    }
    case Sensor.Type.Sinafis: {
      const sinafisMeasures = sensor.measures; // help typescript

      const cards = pipe(
        R.keys(sensor.measures),
        A.sort(Sinafis.Probe.identifierOrd),
        A.map(identifier => {
          const identifierConfig = Sinafis.Probe.identifierConfig[identifier];

          const humidityMeasures = filterMeasures(
            sinafisMeasures[identifier],
            sensor.config[identifierConfig]?.humidityThreshold,
            Type.Humidity,
          );

          const measures = (function () {
            switch (identifier) {
              case 'transmitter':
              case 'ground-1':
              case 'ground-2':
                const identiferConfig = Sinafis.Probe.identifierConfig[identifier];

                const temperatureMeasures = filterMeasures(
                  sinafisMeasures[identifier],
                  sensor.config[identiferConfig]?.temperatureThreshold,
                  Type.Temperature,
                );

                return A.compact<MeasureValue<Measure.Type.Humidity | Measure.Type.Temperature>>([
                  humidityMeasures,
                  temperatureMeasures,
                ]);

              case 'leaf':
                return A.compact([humidityMeasures]);
              default:
                return [];
            }
          })();

          return (
            <MeasureCard
              key={identifier}
              title={{ icon: Sensor.typeIcon[sensor.type], label: Sinafis.Probe.identifierLabel[identifier] }}
              measures={measures}
            />
          );
        }),
      );

      return <Stack>{cards}</Stack>;
    }
  }
};

export default MeasureCards;
