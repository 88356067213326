import React, { FC } from 'react';
import { Group, Text } from '@mantine/core';
import { useTheme } from '@emotion/react';
import { Pest } from '@modules/diseases/pests/model';

interface PestStageProps {
  name: Pest.Stage;
  level: Pest.Risk.Level;
}

const PestStage: FC<PestStageProps> = ({ name, level }) => {
  const {
    fn: { themeColor },
  } = useTheme();

  const getColor = (minLevel: Pest.Risk.Level) =>
    Pest.Risk.levelOrdering[level] >= Pest.Risk.levelOrdering[minLevel]
      ? themeColor(Pest.Risk.levelColor[level])
      : themeColor(Pest.Risk.levelColor[Pest.Risk.Level.None]);

  return (
    <Group spacing={10}>
      <Text fz={14} fw={400} lh={1.55} c="black">
        {name}
      </Text>
      <svg viewBox="0 0 40 10" width={40}>
        <circle cx={5} cy={5} r={5} fill={getColor(Pest.Risk.Level.Low)} />
        <circle cx={20} cy={5} r={5} fill={getColor(Pest.Risk.Level.Medium)} />
        <circle cx={35} cy={5} r={5} fill={getColor(Pest.Risk.Level.High)} />
      </svg>
    </Group>
  );
};

export default PestStage;
