import { AbsoluteUrl } from '@shared/schemas';

export enum PGImageVariant {
  Original = 'original',
  Bound_256x256 = 'bound_256x256',
  Bound_512x512 = 'bound_512x512',
  Fit_300x300 = 'fit_300x300',
  Cover_150x150 = 'cover_150x150',
}

export type ImageMap = {
  [P in PGImageVariant]?: AbsoluteUrl;
};
