import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';

import * as Styled from './ReportTypeCard.styles';
import { Text, ThemeIcon } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

interface ReportTypeCardProps {
  type: AlertReport.Type;
  selected: boolean;
  onSelect: (type: AlertReport.Type) => void;
}

const ReportTypeCard: FC<ReportTypeCardProps> = ({ type, selected, onSelect }) => {
  const handleClick = () => onSelect(type);

  return (
    <Styled.ReportTypeButton bg="white" pr={15} $selected={selected} onClick={handleClick}>
      <Styled.ReportTypeImageContainer $selected={selected}>
        <img src={type.images.bound_256x256} alt={type.label} />
      </Styled.ReportTypeImageContainer>

      <Text c="tertiary.8" fw={600} mx={15} sx={{ flex: '1 1 auto' }}>
        {type.label}
      </Text>

      <ThemeIcon size={18} radius={100} bg="#dfdfdf" c="#2c3e50">
        <IconChevronRight size={11} />
      </ThemeIcon>
    </Styled.ReportTypeButton>
  );
};

export default ReportTypeCard;
