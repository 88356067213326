import { z } from 'zod';
import { Utils } from '@shared/utils/model';
import { Sensoterra } from '@modules/sensors/sensoterra/model';
import { SensorsSchema } from '@modules/sensors/schema';
import { ThresholdSchema } from '@modules/iot/schema';

export namespace SensoterraSchema {
  import registerPendingSchema = SensorsSchema.registerPendingSchema;

  const configSchema = z.object({
    soilType: Sensoterra.Parameter.Key,
    humidityThreshold: ThresholdSchema.humidityScaleSchema,
    coordinates: Utils.GPSCoordinates,
  });

  export const registerSensoterraSchema = registerPendingSchema.extend({
    config: configSchema,
  });
}
