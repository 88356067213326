import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { SensorsService } from '@modules/sensors/service';
import { z } from 'zod';
import { HubApi } from '@modules/iot/model';
import { defineRoute, preventActionLeave } from '@core/router';
import { Stepper } from '@mantine/core';
import { Sensor } from '@modules/sensors/model';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import { ZonesService } from '@modules/iot/zones/service';
import DetailPage from '@modules/sensors/pending/DetailPage';
import { FieldPath } from 'react-hook-form';
import { Hortee } from '@modules/sensors/hortee/model';
import RegisterParams = Hortee.RegisterParams;
import { defineAction, useAction } from '@core/router/action';
import { PendingSensor } from '@modules/sensors/pending/model';
import typeRegisterSchema = PendingSensor.typeRegisterSchema;
import { HorteeUtils } from '@modules/sensors/hortee/utils';
import HorteeSecondStepForm from '@modules/sensors/hortee/components/HorteeSecondStepForm';
import ThirdStepForm from '@modules/sensors/pending/components/steps/ThirdStepForm';
import HorteeFirstStepForm from '@modules/sensors/hortee/components/HorteeFirstStepForm';

const params = z.object({ id: HubApi.SensorId });
const type = Sensor.Type.Hortee;

const actions = {
  register: defineAction({
    type: 'register',
    payload: typeRegisterSchema[type],
    handler: ({ payload }) => SensorsService.registerPending(type, payload),
    flashOptions: {
      success: () => 'Configuration réalisée',
    },
  }),
};

const loader = defineLoader({
  params,
  handler: ({ params }) =>
    httpTaskToResponseTask(
      sequenceS(TE.ApplyPar)({
        sensor: SensorsService.getPendingSensor<typeof type>(params.id),
        zones: ZonesService.getZones(),
      }),
    ),
});

const PendingDetailPage: FC = () => {
  const [registerLoading, register] = useAction(actions.register);
  const { sensor, zones } = useLoader<typeof loader>();

  const checks: Array<Array<FieldPath<RegisterParams>>> = [
    ['name', 'zoneId', 'comment', 'config.soilType'],
    ['config.humidityThreshold', 'config.temperatureThreshold'],
    ['config.coordinates'],
  ];

  return (
    <DetailPage
      sensor={sensor}
      loading={registerLoading}
      checks={checks}
      defaultValues={HorteeUtils.getPendingDefaultValues(sensor)}
      onRegister={register}
    >
      <Stepper.Step>
        <HorteeFirstStepForm zones={zones} />
      </Stepper.Step>
      <Stepper.Step>
        <HorteeSecondStepForm />
      </Stepper.Step>
      <Stepper.Step>
        <ThirdStepForm type={sensor.type} />
      </Stepper.Step>
    </DetailPage>
  );
};

const pendingHorteeDetail = defineRoute({
  element: <PendingDetailPage />,
  loader,
  actions,
  shouldRevalidate: preventActionLeave<typeof actions>('register'),
});

export default pendingHorteeDetail;
