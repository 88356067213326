import { Previsions } from '@shared/modules/previsions/model';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

export namespace PrevisionsUtils {
  export function getWeatherTypeInfos(
    values: Previsions.Weather.ForecastValues | null,
  ): O.Option<Previsions.Weather.TypeInfo> {
    return pipe(
      O.fromNullable(values?.[Previsions.Weather.DayForecast.Param.Weather]),
      O.map(swId => Number(swId).toString(16)),
      O.chain(swId =>
        pipe(
          Object.values(Previsions.Weather.Type),
          A.findFirst(type => swId === type),
        ),
      ),
      O.map(type => Previsions.Weather.typeInfos[type]),
    );
  }
}
