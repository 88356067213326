import React, { FC } from 'react';
import { useHashDisclosure } from '@core/router';
import { Button, Divider, Stack, Text, TextInput } from '@mantine/core';
import GeolocationDrawer from '@modules/sensors/sinafis/components/GeolocationDrawer';
import { Utils } from '@shared/utils/model';
import { Sensor } from '@modules/sensors/model';
import Type = Sensor.Type;

interface SinafisThirdStepFormProps {
  location: Utils.GPSCoordinates;
}

const SinafisThirdStepForm: FC<SinafisThirdStepFormProps> = ({ location }) => {
  const [openLocation, handlers] = useHashDisclosure('#location');

  return (
    <>
      <Stack spacing={25}>
        <Stack align="center" spacing={20}>
          <Text size={17} weight={600} lh={1.29} color="tertiary.8">
            Localisation de la sonde
          </Text>
          <Button variant="outline" color="primary" onClick={handlers.open}>
            <Text size={14} weight={600} tt="uppercase">
              Voir ma sonde
            </Text>
          </Button>
        </Stack>
        <Divider styles={{ label: { marginTop: '0 !important' } }} />
        <Stack spacing={15}>
          <TextInput value={location.latitude} label="Latitude" placeholder="Latitude" disabled required />
          <TextInput value={location.longitude} label="Longitude" placeholder="Longitude" disabled required />
        </Stack>
      </Stack>
      <GeolocationDrawer opened={openLocation} onClose={handlers.close} type={Type.Sinafis} location={location} />
    </>
  );
};

export default SinafisThirdStepForm;
