import React, { FC, PropsWithChildren } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Select, Stack, Text, Textarea, TextInput } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { Zone } from '@modules/iot/zones/model';
import { PendingSensor } from '@modules/sensors/pending/model';

interface FirstStepFormProps {
  zones: Array<Zone>;
}

const FirstStepForm: FC<PropsWithChildren<FirstStepFormProps>> = ({ children, zones }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useEnhancedFormContext<PendingSensor.RegisterParams>();

  return (
    <Stack spacing={15}>
      <Text size={17} lh={1.29} weight={600} color="tertiary.8">
        Configuration
      </Text>
      <TextInput
        {...register('name')}
        label="Nom de la sonde"
        placeholder="Nom de la sonde"
        error={!!errors.name}
        required
      />
      <Controller
        control={control}
        name="zoneId"
        render={({ field }) => (
          <Select
            {...field}
            data={zones.map(({ id, name }) => ({ value: id, label: name }))}
            label="Sélectionner un groupe"
            placeholder="Sélectionner"
            error={!!errors.zoneId}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="comment"
        render={({ field }) => (
          <Textarea
            {...field}
            value={field.value ?? ''}
            label="Commentaire"
            placeholder="Commentaire"
            error={!!errors.comment}
          />
        )}
      />
      {children}
    </Stack>
  );
};

export default FirstStepForm;
