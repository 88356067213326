import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { Emergency } from '@modules/emergency-numbers/model';
import Type = Emergency.Type;

export function filterMapEmergencyNumbers(numbers: Array<Emergency.Number>): Record<Type, Array<Emergency.Number>> {
  return pipe(
    numbers,
    A.reduce<Emergency.Number, Record<Type, Array<Emergency.Number>>>(
      { [Type.Main]: [], [Type.PoisonControlCenter]: [], [Type.GreenNumber]: [] },
      (numbers, number) => ({
        ...numbers,
        [number.type]: [...numbers[number.type], number],
      }),
    ),
  );
}
