import React, { FC } from 'react';
import { Emergency } from '@modules/emergency-numbers/model';
import { Anchor, AspectRatio } from '@mantine/core';

const EmergencyGreenNumberCard: FC<{ emergency: Emergency.Number }> = ({ emergency }) => {
  return (
    <AspectRatio ratio={294 / 48} py={5}>
      <Anchor href={`tel:${emergency.number}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="294" height="48" viewBox="0 0 294 48">
          <g transform="translate(-20 -677)">
            <g transform="translate(20 677)" stroke="#2b8a3e" strokeWidth={2} fill="none">
              <rect width="294" height="48" rx="24" stroke="none" />
              <rect x="1" y="1" width="292" height="46" rx="23" fill="none" />
            </g>
            <rect width="133" height="48" rx="24" transform="translate(20 677)" fill="#2b8a3e" />
            <text transform="translate(177 708)" fontWeight={500} fontSize={16} fill="#2b8a3e">
              <tspan x="0" y="0">
                {emergency.number}
              </tspan>
            </text>
            <text transform="translate(80 708)" fontWeight={500} fontSize={18} fill="#fff">
              <tspan x="0" y="0">
                N°Vert
              </tspan>
            </text>
            <circle cx="20" cy="20" r="20" transform="translate(25 681)" fill="#fff" />
            <g transform="translate(28 684)">
              <rect width="34" height="34" rx="8" fill="none" />
              <path d="M0 0h24v24H0z" fill="none" transform="translate(5 5)" />
              <path
                d="M5 4h4l2 5-2.5 1.5a11 11 0 0 0 5 5L15 13l5 2v4a2 2 0 0 1-2 2A16 16 0 0 1 3 6a2 2 0 0 1 2-2"
                fill="#2b8a3e"
                transform="translate(5 5)"
              />
            </g>
          </g>
        </svg>
      </Anchor>
    </AspectRatio>
  );
};

export default EmergencyGreenNumberCard;
