import React, { FC, useState } from 'react';
import { Card, Checkbox, Group, Stack, Text } from '@mantine/core';
import { parseISO } from 'date-fns';
import { formatDate } from '@shared/modules/dates';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as S from 'fp-ts/string';
import * as M from 'fp-ts/Monoid';
import * as A from 'fp-ts/Array';
import { Charts } from '@shared/modules/charts/model';
import { ActiveSensor, Sensor } from '@modules/sensors/model';
import { Measure } from '@modules/measures/model';

import identifierLabel = Sensor.Probe.identifierLabel;
import Identifier = Sensor.Probe.Identifier;
import Type = Measure.Type;
import { MeasureUtils } from '@modules/measures/utils';
import mergeDateMonoid = MeasureUtils.mergeDateMonoid;
import SimpleLineChart from '@shared/modules/charts/line/SimpleLineChart';

interface SensorMeasureProps {
  sensor: ActiveSensor;
  measures: Measure.History<Sensor.Type>;
}

const defaultSelection = Object.values(Identifier).flatMap(identifier =>
  Object.values(Type).map(type => `${identifier}.${type}` as const),
);

const SensorMeasure: FC<SensorMeasureProps> = ({ sensor, measures }) => {
  const [selection, setSelection] = useState(defaultSelection);

  const data = pipe(
    R.toEntries(measures),
    A.chain(([identifier, values]) =>
      pipe(
        values,
        A.filter(({ type }) => selection.some(key => key === `${identifier}.${type}`)),
        A.map(({ values, type }) =>
          values.reduce((acc, curr) => ({ ...acc, [curr.at]: { [`${identifier}.${type}`]: curr.value } }), {}),
        ),
      ),
    ),
    M.concatAll(mergeDateMonoid),
    R.collect(S.Ord)((at, values) => ({ date: parseISO(at), ...values })),
  );

  const dateFormatter = (date: Date) => formatDate(date, 'dd/MM/yyyy');

  const handleCheckboxChange = (selection: Array<`${Identifier}.${Type}`>) => setSelection(selection);

  return (
    <Card
      mt={15}
      p={15}
      radius={8}
      sx={theme => ({ '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}` } })}
    >
      <Stack>
        <Text color="rgba(46, 46, 46, 0.4)" size={12} weight={500}>
          Données de capteurs
        </Text>

        <SimpleLineChart data={data} dateFormatter={dateFormatter} />
        <Group align="start">
          {pipe(
            R.toEntries(measures),
            A.filter(([, values]) => A.isNonEmpty(values)),
            A.map(([identifier, values]) => (
              <Card
                py={10}
                px={14}
                key={identifier}
                withBorder={false}
                sx={theme => ({ border: `1px solid ${theme.colors.tertiary[2]}` })}
              >
                <Text color="dark.1" size={10} pb={10}>
                  {identifierLabel[identifier]}
                </Text>
                <Checkbox.Group value={selection} onChange={handleCheckboxChange}>
                  <Stack>
                    {values.map(({ type }) => (
                      <Checkbox
                        key={type}
                        value={`${identifier}.${type}`}
                        color="red"
                        styles={{
                          label: { paddingLeft: 8 },
                          input: {
                            borderColor: `${Charts.Line.sensorDataColor[`${identifier}.${type}`]} !important`,
                            ':checked': {
                              backgroundColor: Charts.Line.sensorDataColor[`${identifier}.${type}`],
                            },
                          },
                        }}
                        data-color={Charts.Line.sensorDataColor[`${identifier}.${type}`].split('.')[0]}
                        label={
                          <Text color="dark.5" size={12}>
                            {Measure.typeTitle[type]}
                          </Text>
                        }
                      />
                    ))}
                  </Stack>
                </Checkbox.Group>
              </Card>
            )),
          )}
        </Group>
      </Stack>
    </Card>
  );
};

export default SensorMeasure;
