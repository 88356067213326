import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { Box, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import ReportImageSelection from '@modules/diseases/alert-reports/flow/components/images/ReportImageSelection';
import { constVoid, pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';

import { AlertReport } from '@modules/diseases/alert-reports/model';
import { useSendTask } from '@core/http/hooks';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { Navigate, useNavigate } from 'react-router-dom';
import ReportImagesWarning from '@modules/diseases/alert-reports/flow/components/images/ReportImagesWarning';
import { useDisclosure } from '@mantine/hooks';
import { ReportFlowStepProps } from '@modules/diseases/alert-reports/flow/index';
import { useReportFlowContext } from '@modules/diseases/alert-reports/flow/context';
import ReportFlowLayout from '@modules/diseases/alert-reports/flow/layout/ReportFlowLayout';
import ReportFlowBottom from '@modules/diseases/alert-reports/flow/layout/ReportFlowBottom';

function setDefaultMainImage(images: Array<AlertReport.Flow.Image>) {
  const hasMainImage = pipe(
    images,
    A.some(image => image.isMain),
  );

  if (!hasMainImage) {
    return pipe(
      images,
      A.mapWithIndex((index, image) => (index === 0 ? { ...image, isMain: true } : image)),
    );
  }

  return images;
}

const ReportImagesStep: FC<ReportFlowStepProps> = ({ type }) => {
  const navigate = useNavigate();

  const [{ images, form }, dispatch] = useReportFlowContext();

  const [, deleteAlertImage] = useSendTask(AlertReportsService.deleteAlertImage);

  const [warningModalOpened, warningModalTriggers] = useDisclosure();

  const handleAddImages = (files: Array<AlertReport.Flow.Image>) => {
    const newImages = setDefaultMainImage([...images, ...files]);

    dispatch({ type: 'UPDATE_IMAGES', images: newImages });
  };

  const handleDeleteImage = (image: AlertReport.Flow.Image) => {
    const newImages = setDefaultMainImage(images.filter(img => img.id !== image.id));

    dispatch({ type: 'UPDATE_IMAGES', images: newImages });

    if (image.type === 'uploaded') {
      deleteAlertImage(image.id)();
    }
  };

  const handlePromoteMain = (image: AlertReport.Flow.Image) => {
    const newImages = pipe(
      images,
      A.map(img => ({ ...img, isMain: img.id === image.id })),
    );

    dispatch({ type: 'UPDATE_IMAGES', images: newImages });
  };

  const handleContinue = () => {
    if (A.isEmpty(images)) {
      warningModalTriggers.open();
    } else {
      navigate('../location', { relative: 'path' });
    }
  };

  if (!form) {
    return <Navigate to="../form" relative="path" replace />;
  }

  return (
    <ReportFlowLayout type={type} title="Ajouter des photos" back="../form" withClose>
      <Box p={20} bg="white">
        <Title c="primary.5">Ajouter des photos à votre signalement</Title>

        <Text mt={15}>
          Une fois vos photos ajoutées, double cliquez sur la photo que vous souhaitez mettre en couverture.
        </Text>
      </Box>

      <Box
        h={10}
        sx={{
          opacity: 0.1,
          backgroundImage: 'linear-gradient(to top, #f5f4f2, #363636)',
        }}
      />

      <Stack p={20} pt={10}>
        <SimpleGrid cols={2} spacing={20}>
          <ReportImageSelection
            primary
            image={null}
            onAddImages={handleAddImages}
            onDelete={constVoid}
            onPromoteMain={constVoid}
          />

          {images.map(image => (
            <ReportImageSelection
              key={image.id}
              image={image}
              onAddImages={handleAddImages}
              onDelete={handleDeleteImage}
              onPromoteMain={handlePromoteMain}
            />
          ))}

          {A.replicate(5 - images.length, null).map((_, i) => (
            <ReportImageSelection
              key={i}
              image={null}
              onAddImages={handleAddImages}
              onDelete={constVoid}
              onPromoteMain={constVoid}
            />
          ))}
        </SimpleGrid>
      </Stack>

      <ReportFlowBottom back="../form" onNext={handleContinue} />

      <ReportImagesWarning opened={warningModalOpened} onClose={warningModalTriggers.close} />
    </ReportFlowLayout>
  );
};

const reportImagesStepRoute = (props: ReportFlowStepProps) =>
  defineRoute({
    element: <ReportImagesStep {...props} />,
  });

export default reportImagesStepRoute;
