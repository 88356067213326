import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { z } from 'zod';
import { Auth } from '@modules/auth/model';
import { AuthService } from '@modules/auth/service';
import { defineRoute } from '@core/router';
import { getStringQuery } from '@shared/modules/filter';
import { parseQueriesFormUrl } from '@shared/utils/queries';

const params = z.object({ token: Auth.Token });

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(AuthService.exchangeToken(params.token)),
  redirect: ({ request }) => getStringQuery(parseQueriesFormUrl(request.url), 'referer') ?? '/',
});

const loginWithTokenRoute = defineRoute({
  loader,
});

export default loginWithTokenRoute;
