import { Route } from 'react-router-dom';
import React from 'react';
import { createRoute } from '@core/router';
import previsionsRoute from '@modules/previsions/ListPage';
import * as P from 'fp-ts/Predicate';
import { ProfilePredicate } from '@modules/profile/predicate';

const previsionsRoutes = (
  <Route path="previsions" {...createRoute({ restriction: P.not(ProfilePredicate.isAccessLimited) })}>
    <Route index {...createRoute(previsionsRoute)} />
  </Route>
);

export default previsionsRoutes;
