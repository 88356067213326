import React, { FC } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { defineRoute } from '@core/router';
import { Box, Stack, Title } from '@mantine/core';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import ReportTypeCard from '@modules/diseases/alert-reports/flow/components/types/ReportTypeCard';
import { useReportFlowContext } from '@modules/diseases/alert-reports/flow/context';
import { ReportFlowStepProps } from '@modules/diseases/alert-reports/flow/index';
import ReportFlowLayout from '@modules/diseases/alert-reports/flow/layout/ReportFlowLayout';
import ReportFlowBottom from '@modules/diseases/alert-reports/flow/layout/ReportFlowBottom';

const ReportTypesStep: FC<ReportFlowStepProps> = ({ type }) => {
  const navigate = useNavigate();

  const [{ selectedType, types, selectedSubCategory }, dispatch] = useReportFlowContext();

  const onNext = () => navigate('../form');

  const handleSelectType = (selectedType: AlertReport.Type) => {
    dispatch({ type: 'TYPE_SELECTED', selectedType });
    onNext();
  };

  if (!selectedSubCategory) {
    return <Navigate to="../sub-categories" relative="path" replace />;
  }

  return (
    <ReportFlowLayout withClose type={type} title={selectedSubCategory.label} back="../sub-categories">
      <Box p={20}>
        <Title c="primary.5">Choisissez parmi cette liste l'élément correspondant</Title>

        <Stack mt={30} spacing={10}>
          {types.map(type => (
            <ReportTypeCard
              key={type.id}
              type={type}
              selected={type.id === selectedType?.id}
              onSelect={handleSelectType}
            />
          ))}
        </Stack>
      </Box>

      {selectedType != null ? <ReportFlowBottom back="../sub-categories" onNext={onNext} /> : null}
    </ReportFlowLayout>
  );
};

const reportTypesStepRoute = (props: ReportFlowStepProps) =>
  defineRoute({
    element: <ReportTypesStep {...props} />,
  });

export default reportTypesStepRoute;
