import { Utils } from '@shared/utils/model';
import { Threshold } from '@modules/iot/model';
import { Measure } from '@modules/measures/model';
import { z } from 'zod';
import { HorteeSchema } from '@modules/sensors/hortee/schema';

export namespace Hortee {
  export enum SoilType {
    Slimy = 'SLIMY',
    LoamySand = 'LOAMY_SAND',
    Clayey = 'CLAYEY',
    Sandy = 'SANDY',
  }

  export const soilTypeLabel: Record<SoilType, string> = {
    [SoilType.Slimy]: 'Limoneux',
    [SoilType.LoamySand]: 'Sablo-Limoneux',
    [SoilType.Clayey]: 'Argileux',
    [SoilType.Sandy]: 'Sableux',
  };

  export namespace Probe {
    export enum Identifier {
      Ground = 'ground',
    }

    export const identifierProbeAlerts: Record<Identifier, Array<Measure.Type>> = {
      [Identifier.Ground]: [Measure.Type.Humidity, Measure.Type.Battery, Measure.Type.Temperature],
    };
  }

  export namespace Config {
    export interface Remote {
      coordinates: Utils.GPSCoordinates;
    }
  }

  export interface Config {
    soilType: SoilType;
    coordinates: Utils.GPSCoordinates;
    humidityThreshold: Threshold.HumidityScale;
    temperatureThreshold: Threshold.TemperatureScale;
  }

  export type RegisterParams = z.infer<typeof HorteeSchema.registerHorteeSchema>;
}
