import React, { useEffect, useState } from 'react';
import { SavedFilter } from '@shared/modules/filter/components/FiltersDrawer';
import { HttpTask } from '@core/http';
import { Filter } from '@shared/modules/filter';
import { ActionIcon, Button, Drawer, Group, Radio, Text } from '@mantine/core';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { useSendTask } from '@core/http/hooks';
import { IconTrash } from '@tabler/icons-react';
import DeleteModal from '@shared/components/modals/DeleteModal';
import { useDisclosure } from '@mantine/hooks';

interface SavedFiltersRowProps<SF extends SavedFilter> {
  savedFilter: SF;
  selectedFilter: SF | null;
  onDelete: (savedFilter: SF) => HttpTask;
  onChange: (filter: SF | null) => void;
}

function SavedFiltersRow<SF extends SavedFilter>({
  savedFilter,
  selectedFilter,
  onDelete,
  onChange,
}: SavedFiltersRowProps<SF>) {
  const [loading, deleteFilter] = useSendTask(onDelete, undefined, savedFilter);

  const [deleteModalOpened, deleteModalTriggers] = useDisclosure();

  const handleDelete = () => {
    if (savedFilter.id === selectedFilter?.id) {
      onChange(null);
    }

    deleteFilter()();
  };

  return (
    <Group
      position="apart"
      py={20}
      sx={theme => ({
        ':not(:first-child)': {
          borderTop: `1px solid ${theme.colors.gray[1]}`,
        },
      })}
    >
      <Radio
        value={savedFilter.id}
        label={
          <Text lh={1.43} size={14} color="white">
            {savedFilter.name}
          </Text>
        }
        styles={{
          label: { paddingLeft: 10 },
          radio: {
            backgroundColor: 'transparent',
            border: 'solid 1px rgba(255, 255, 255, 0.4)',
          },
        }}
      />
      <ActionIcon c="white" variant="transparent" onClick={deleteModalTriggers.open} loading={loading}>
        <IconTrash />
      </ActionIcon>

      <DeleteModal
        opened={deleteModalOpened}
        onDelete={handleDelete}
        loading={loading}
        handleClose={deleteModalTriggers.close}
      />
    </Group>
  );
}

interface SavedFiltersDrawerProps<F extends Filter, SF extends SavedFilter> {
  opened: boolean;
  savedFilters: Array<SF>;
  selectedFilter: SF | null;
  filters: F;
  onClose: () => void;
  onSelect: (savedFilter: SF | null, filters: F) => HttpTask<F>;
  onDelete: (savedFilter: SF) => HttpTask;

  onChange(filter: F): void;
}

function SavedFiltersDrawer<F extends Filter, SF extends SavedFilter>({
  opened,
  savedFilters,
  selectedFilter,
  filters,
  onClose,
  onSelect,
  onDelete,
  onChange,
}: SavedFiltersDrawerProps<F, SF>) {
  const [pendingSelectedFilter, setPendingSelectedFilter] = useState(selectedFilter);

  useEffect(() => {
    setPendingSelectedFilter(selectedFilter);
  }, [selectedFilter]);

  const [loading, selectFilter] = useSendTask(onSelect);

  const handleSelectedFilterChange = (id: string) => {
    const filter = pipe(
      savedFilters,
      A.findFirst(f => f.id === id),
      O.toNullable,
    );

    setPendingSelectedFilter(filter);
  };

  const handleValidate = () => {
    pipe(
      selectFilter(pendingSelectedFilter, filters),
      TE.chainIOK(filters => () => {
        onChange(filters);
        onClose();
      }),
    )();
  };

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      size="100%"
      transitionProps={{ transition: 'slide-up', timingFunction: 'ease' }}
      title={
        <Text color="white" weight={600} size={17}>
          Filtres enregistrés
        </Text>
      }
      styles={theme => ({
        header: { background: theme.colors.tertiary[8], padding: '20px' },
        content: {
          background: theme.colors.tertiary[8],
          display: 'flex',
          flexDirection: 'column',
          border: 'none !important',
        },
        body: { padding: 0, display: 'flex', flexDirection: 'column', flexGrow: 1 },
        close: {
          border: 'none',
          width: '26px ',
          height: '26px ',
          background: 'transparent !important',
          svg: {
            width: '100%',
            height: '100%',
            color: 'white',
          },
        },
      })}
    >
      <Radio.Group px={20} onChange={handleSelectedFilterChange} value={pendingSelectedFilter?.id ?? ''}>
        {savedFilters.map(filter => (
          <SavedFiltersRow
            key={filter.id}
            savedFilter={filter}
            selectedFilter={selectedFilter}
            onDelete={onDelete}
            onChange={setPendingSelectedFilter}
          />
        ))}
      </Radio.Group>
      <Button
        mt="auto"
        h={61}
        radius={0}
        color="primary.5"
        tt="uppercase"
        pos="sticky"
        bottom={0}
        loading={loading}
        onClick={handleValidate}
      >
        Valider
      </Button>
    </Drawer>
  );
}

export default SavedFiltersDrawer;
