import React, { FC } from 'react';
import { Emergency } from '@modules/emergency-numbers/model';
import { ActionIcon, Card, Group, Stack, Text } from '@mantine/core';
import { IconPhone } from '@tabler/icons-react';

const EmergencyNumberSimpleCard: FC<{ emergency: Emergency.Number }> = ({ emergency }) => {
  return (
    <Card bg="background.1" pr={20} pl={25} py={13} radius={40} withBorder={false}>
      <Group position="apart" noWrap>
        <Stack spacing={5}>
          <Text size={16} weight={600} lh={1}>
            {emergency.label}
          </Text>
          <Text lh={1}>{emergency.number}</Text>
        </Stack>
        <ActionIcon component="a" href={`tel:${emergency.number}`} variant="filled" radius="xl" size={41} color="red.4">
          <IconPhone size={22} />
        </ActionIcon>
      </Group>
    </Card>
  );
};

export default EmergencyNumberSimpleCard;
