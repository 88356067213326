import React, { createElement, FC } from 'react';
import { Box, Card, Group, Progress, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconGenderNeutrois } from '@tabler/icons-react';
import { LastMeasureUtils } from '@modules/measures/last/utils';
import levelColor = LastMeasureUtils.levelColor;
import measureIcon = LastMeasureUtils.measureIcon;
import { Measure } from '@modules/measures/model';
import NumericMeasure = LastMeasureUtils.NumericMeasure;
import MeasureValue = LastMeasureUtils.MeasureValue;

interface MeasureCardProps<Type extends NumericMeasure> {
  title: { label: string; icon: FC<React.ComponentProps<'svg'>> };
  measures: Array<MeasureValue<Type>>;
}

function MeasureCard<Type extends NumericMeasure>({ title, measures }: MeasureCardProps<Type>) {
  return (
    <Stack>
      <Card
        pt={15}
        pb={0}
        radius={8}
        sx={theme => ({ '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}` } })}
      >
        <Text color="rgba(46, 46, 46, 0.4)" pb={13} size={12} weight={500}>
          {title.label}
        </Text>

        {measures.map(({ measure: { value, type }, scale }) => (
          <Box key={type}>
            <Group spacing={8}>
              <ThemeIcon
                size={22}
                variant="light"
                color={levelColor[LastMeasureUtils.getCurrentScale(scale, value, type).level]}
              >
                {createElement(measureIcon[type], { size: 12 })}
              </ThemeIcon>
              <Text size={14} color="black" lh={1.43} weight={600}>
                {Measure.typeTitle[type]}
              </Text>
            </Group>
            <Box pos="relative" mt={40} mb={30}>
              <Progress
                h={4}
                sections={LastMeasureUtils.mapMeasureScaleToSections(scale, type).map(({ value, color }) => ({
                  value: (value * 100) / (LastMeasureUtils.MaxMeasure[type] - LastMeasureUtils.MinMeasure[type]),
                  color,
                }))}
              />
              <IconGenderNeutrois
                strokeWidth={1.4}
                size={20}
                style={{
                  position: 'absolute',
                  top: 2,
                  left: `${
                    ((value - LastMeasureUtils.MinMeasure[type]) /
                      (LastMeasureUtils.MaxMeasure[type] - LastMeasureUtils.MinMeasure[type])) *
                    100
                  }%`,
                  transform: 'rotate(0.5turn) translateX(50%) translateY(16px)',
                }}
              />
              <Text
                pos="absolute"
                top={0}
                color="dark.5"
                left={`${
                  ((value - LastMeasureUtils.MinMeasure[type]) /
                    (LastMeasureUtils.MaxMeasure[type] - LastMeasureUtils.MinMeasure[type])) *
                  100
                }%`}
                weight={700}
                size={14}
                style={{ transform: 'translateX(-50%) translateY(-150%)' }}
              >
                {LastMeasureUtils.formatter(value, type)}
              </Text>
              {scale.levels.map(({ until }, index) => (
                <Text
                  key={index}
                  color="tertiary.8"
                  size={9}
                  opacity={0.5}
                  pos="absolute"
                  bottom={-20}
                  left={`${
                    ((until - LastMeasureUtils.MinMeasure[type]) /
                      (LastMeasureUtils.MaxMeasure[type] - LastMeasureUtils.MinMeasure[type])) *
                    100
                  }%`}
                  style={{ transform: 'translateX(-50%)' }}
                >
                  {LastMeasureUtils.formatter(until, type)}
                </Text>
              ))}
            </Box>
          </Box>
        ))}
      </Card>
    </Stack>
  );
}

export default MeasureCard;
