import { Utils } from '@shared/utils/model';
import { NumberUtils } from '@shared/utils/number';
import { LastMeasureUtils } from '@modules/measures/last/utils';
import { Measure } from '@modules/measures/model';

export namespace ThresholdUtils {
  import Percent = Utils.Percent;
  import roundWithDigits = NumberUtils.roundWithDigits;
  import NumericMeasure = LastMeasureUtils.NumericMeasure;

  function percentFormatter(value: Percent): string {
    return `${roundWithDigits(value * 100, 0)}`;
  }

  export function numericFormatter<Type extends NumericMeasure>(
    value: Measure.Value<Type>,
    type: Type & Measure.Type,
  ): string {
    switch (type) {
      case Measure.Type.Battery:
        return percentFormatter(value as Measure.Value<typeof type>);
      case Measure.Type.Humidity:
        return percentFormatter(value as Measure.Value<typeof type>);
      case Measure.Type.Temperature:
        return `${value}`;
      default:
        return '';
    }
  }

  function percentParser(value: Percent): string {
    return `${value / 100}`;
  }

  export function numericParser<Type extends NumericMeasure>(
    value: Measure.Value<Type>,
    type: Type & Measure.Type,
  ): string {
    switch (type) {
      case Measure.Type.Battery:
        return percentParser(value as Measure.Value<typeof type>);
      case Measure.Type.Humidity:
        return percentParser(value as Measure.Value<typeof type>);
      case Measure.Type.Temperature:
        return `${value}`;
      default:
        return '';
    }
  }
}
