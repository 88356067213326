import React, { FC } from 'react';
import { ActionIcon, Drawer, Group, Stack, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import EmergencyNumberSimpleCard from '@modules/emergency-numbers/components/EmergencyNumberSimpleCard';
import { Emergency } from '@modules/emergency-numbers/model';

interface EmergencyPoisonControlDrawerProps {
  opened: boolean;
  onClose: () => void;
  poisonControlCenters: Array<Emergency.Number>;
}

//todo: better drawer management with layout routes

const EmergencyPoisonControlDrawer: FC<EmergencyPoisonControlDrawerProps> = ({
  opened,
  onClose,
  poisonControlCenters,
}) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title={
        <Group spacing={5}>
          <ActionIcon onClick={onClose} variant="transparent" c="white">
            <IconArrowLeft size={22} />
          </ActionIcon>
          <Text size={17} color="white" weight={600}>
            Centre antipoison
          </Text>
        </Group>
      }
      size="100%"
      position="right"
      withCloseButton={false}
      styles={theme => ({
        header: {
          width: '100%',
          background: theme.colors.tertiary[8],
          minHeight: 63,
          padding: '0 20px',
          marginBottom: 0,
          zIndex: 10,
        },
        content: { border: 'none !important', display: 'flex', flexDirection: 'column' },
        body: { background: theme.colors.tertiary[3], padding: 0, flexGrow: 1 },
      })}
    >
      <Stack spacing="lg" px="lg" pt={20} pb={90}>
        <Stack spacing={2}>
          <Text size="xs" lh={1}>
            Numéros d’urgences /
          </Text>
          <Text size={17} weight={600}>
            Liste des centres antipoison
          </Text>
        </Stack>
        <Stack spacing={10}>
          {poisonControlCenters.map(emergency => (
            <EmergencyNumberSimpleCard key={emergency.id} emergency={emergency} />
          ))}
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default EmergencyPoisonControlDrawer;
