import { Sensor } from '@modules/sensors/model';
import { DeepPartial } from 'react-hook-form';
import { Threshold } from '@modules/iot/model';
import { PendingSensor } from '@modules/sensors/pending/model';
import { Hortee } from '@modules/sensors/hortee/model';

export namespace HorteeUtils {
  import temperatureThreshold = Threshold.defaultTemperatureScale;
  import humidityThreshold = Threshold.defaultHumidityScale;
  type Type = Sensor.Type.Hortee;

  export function getPendingDefaultValues(sensor: PendingSensor<Type>): DeepPartial<Hortee.RegisterParams> {
    return {
      technicalId: sensor.technicalId,
      comment: null,
      config: {
        coordinates: {},
        humidityThreshold,
        temperatureThreshold,
      },
    };
  }
}
