import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { AspectRatio } from '@mantine/core';
import { renderNullable } from '@shared/utils/render';
import ReportImageDropZone from '@modules/diseases/alert-reports/flow/components/images/ReportImageDropZone';
import ReportImagePreview from '@modules/diseases/alert-reports/flow/components/images/ReportImagePreview';

interface ReportImageSelectionProps {
  image: AlertReport.Flow.Image | null;
  primary?: boolean;
  onAddImages: (images: Array<AlertReport.Flow.Image>) => void;
  onDelete: (image: AlertReport.Flow.Image) => void;
  onPromoteMain: (image: AlertReport.Flow.Image) => void;
}

const ReportImageSelection: FC<ReportImageSelectionProps> = ({
  image,
  primary,
  onAddImages,
  onDelete,
  onPromoteMain,
}) => {
  return (
    <AspectRatio ratio={4 / 3}>
      {renderNullable(
        image,
        image => (
          <ReportImagePreview image={image} onDelete={onDelete} onPromoteMain={onPromoteMain} />
        ),
        () => (
          <ReportImageDropZone primary={primary} onAddImages={onAddImages} />
        ),
      )}
    </AspectRatio>
  );
};

export default ReportImageSelection;
