import React, { FC, PropsWithChildren } from 'react';
import { constant, pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { Group, Text, Tooltip } from '@mantine/core';
import { renderNullable } from '@shared/utils/render';
import { Previsions } from '@shared/modules/previsions/model';
import Weather = Previsions.Weather;

interface WeatherForecastParamTooltipProps {
  values: Weather.ForecastValues;
}

const WeatherForecastParamTooltip: FC<PropsWithChildren<WeatherForecastParamTooltipProps>> = ({ values, children }) => {
  return (
    <Tooltip
      position="bottom-end"
      color="white"
      styles={{
        tooltip: {
          boxShadow: '0 4px 7px rgba(0, 0, 0, 0.15)',
        },
      }}
      label={pipe(
        Object.values(Weather.DayForecast.KnownParam),
        A.map(param => (
          <Group key={param} position="apart">
            <Text c="dark.1" fw={600} fz={12}>
              {Weather.DayForecast.paramLabel[param]}
            </Text>
            <Text c="dark.3" fz={14}>
              {renderNullable(
                values[param],
                value => `${value} ${Weather.DayForecast.paramUnit[param]}`,
                constant('--'),
              )}
            </Text>
          </Group>
        )),
      )}
      withinPortal
    >
      {children}
    </Tooltip>
  );
};

export default WeatherForecastParamTooltip;
