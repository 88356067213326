import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { SensorsService } from '@modules/sensors/service';
import { z } from 'zod';
import { HubApi } from '@modules/iot/model';
import { defineRoute, preventActionLeave } from '@core/router';
import { Stepper } from '@mantine/core';
import * as TE from 'fp-ts/TaskEither';
import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';
import { Sensor } from '@modules/sensors/model';
import SinafisFirstStepForm from '@modules/sensors/sinafis/components/steps/SinafisFirstStepForm';
import { pipe } from 'fp-ts/function';
import { Sinafis } from '@modules/sensors/sinafis/model';
import ExtendedMeasureProbeMapping = Sinafis.Config.ExtendedMeasureProbeMapping;
import SinafisSecondStepForm from '@modules/sensors/sinafis/components/steps/SinafisSecondStepForm';
import { sequenceS } from 'fp-ts/Apply';
import { ZonesService } from '@modules/iot/zones/service';
import { FieldPath } from 'react-hook-form';
import RegisterParams = Sinafis.RegisterParams;
import { defineAction, useAction } from '@core/router/action';
import { PendingSensor } from '@modules/sensors/pending/model';
import typeRegisterSchema = PendingSensor.typeRegisterSchema;
import { SinafisUtils } from '@modules/sensors/sinafis/utils';
import DetailPage from '@modules/sensors/pending/DetailPage';
import SinafisThirdStepForm from '@modules/sensors/sinafis/components/steps/SinafisThirdStepForm';
import { renderNullable } from '@shared/utils/render';

const params = z.object({ id: HubApi.SensorId });
const type = Sensor.Type.Sinafis;

const actions = {
  register: defineAction({
    type: 'register',
    payload: typeRegisterSchema[type],
    handler: ({ payload }) => SensorsService.registerPending(type, payload),
    flashOptions: {
      success: () => 'Configuration réalisée',
    },
  }),
};

const loader = defineLoader({
  params,
  handler: ({ params }) =>
    httpTaskToResponseTask(
      sequenceS(TE.ApplyPar)({
        sensor: SensorsService.getPendingSensor<typeof type>(params.id),
        zones: ZonesService.getZones(),
      }),
    ),
});

const PendingDetailPage: FC = () => {
  const { sensor, zones } = useLoader<typeof loader>();
  const [registerLoading, register] = useAction(actions.register);

  const filteredMeasureMappings: Record<keyof ExtendedMeasureProbeMapping, unknown> = pipe(
    { ...sensor.config.measureMappings, transmitter: -1 } satisfies ExtendedMeasureProbeMapping,
    R.filterMap(O.fromNullable),
  );

  const checks: Array<Array<FieldPath<RegisterParams>>> = [
    [
      'name',
      'zoneId',
      'comment',
      'config.transmitter.height',
      'config.ground1.depth',
      'config.ground2.depth',
      'config.leaf.height',
    ],
    ['config.transmitter', 'config.ground1', 'config.ground2', 'config.leaf'],
  ];

  const checksWithMapStep = !!sensor.location ? [...checks, []] : checks;

  return (
    <DetailPage
      sensor={sensor}
      onRegister={register}
      loading={registerLoading}
      checks={checksWithMapStep}
      defaultValues={SinafisUtils.getPendingDefaultValues(sensor, filteredMeasureMappings)}
    >
      <Stepper.Step>
        <SinafisFirstStepForm measureMappings={filteredMeasureMappings} zones={zones} />
      </Stepper.Step>
      <Stepper.Step>
        <SinafisSecondStepForm measureMappings={filteredMeasureMappings} />
      </Stepper.Step>
      {renderNullable(sensor.location, location => (
        <Stepper.Step>
          <SinafisThirdStepForm location={location} />
        </Stepper.Step>
      ))}
    </DetailPage>
  );
};

const pendingSinafisDetail = defineRoute({
  element: <PendingDetailPage />,
  loader,
  actions,
  shouldRevalidate: preventActionLeave<typeof actions>('register'),
});

export default pendingSinafisDetail;
