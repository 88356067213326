import React, { FC, useCallback } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { Box, Button, Group, Image, Text } from '@mantine/core';
import { useFetchTaskOption, useSendTask } from '@core/http/hooks';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { renderOptional } from '@shared/utils/render';
import DetailDrawer from '@modules/sensors/detail/DetailDrawer';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';
import { IconBuilding, IconThumbDownFilled, IconThumbUpFilled } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

interface AlertReportMapDetailProps {
  marker: AlertReport.Marker;
}

const AlertReportMapDetail: FC<AlertReportMapDetailProps> = ({ marker }) => {
  const [alert, refreshAlert] = useFetchTaskOption(AlertReportsService.getAlertDetail, marker.id);

  const sendVoteTask = useCallback(
    (vote: AlertReport.Vote.State) =>
      pipe(
        AlertReportsService.updateVote(marker.id, vote),
        TE.chain(() => refreshAlert),
      ),
    [marker.id, refreshAlert],
  );

  const [sendVoteLoading, sendVote] = useSendTask(sendVoteTask);

  const handleVote = (oldVote: AlertReport.Vote.State | null, newVote: AlertReport.Vote.State) => () => {
    const vote = oldVote === newVote ? AlertReport.Vote.State.None : newVote;

    sendVote(vote)();
  };

  const image = pipe(
    alert,
    O.chainNullableK(alert => alert.images),
    O.chain(A.head),
    O.chainNullableK(image => image.variants.cover_150x150),
    O.toNullable,
  );

  return (
    <DetailDrawer opened={O.isSome(alert)}>
      {renderOptional(alert, alert => (
        <Box h="100%" bg="white">
          <Group pos="absolute" right={15} top={-18} spacing={7}>
            <Button
              w={72}
              radius={15}
              variant="default"
              bg="#f5f4f2"
              c={alert.vote.mine === AlertReport.Vote.State.Like ? 'primary.5' : 'tertiary.8'}
              leftIcon={<IconThumbUpFilled />}
              sx={{ border: 'none' }}
              loading={sendVoteLoading}
              onClick={handleVote(alert.vote.mine, AlertReport.Vote.State.Like)}
            >
              {alert.vote.likesCount}
            </Button>
            <Button
              w={72}
              radius={15}
              variant="default"
              bg="#f5f4f2"
              c={alert.vote.mine === AlertReport.Vote.State.Dislike ? 'primary.5' : 'tertiary.8'}
              leftIcon={<IconThumbDownFilled />}
              sx={{ border: 'none' }}
              loading={sendVoteLoading}
              onClick={handleVote(alert.vote.mine, AlertReport.Vote.State.Dislike)}
            >
              {alert.vote.dislikesCount}
            </Button>
          </Group>

          <Box p={10} mt={5}>
            <Box component={Link} to={`/diseases/alert-reports/${marker.id}`} sx={{ textDecoration: 'none' }}>
              <Group noWrap>
                <Image width={85} height={85} radius={4} src={image} withPlaceholder sx={{ flex: '0 0 85px' }} />

                <Box c="tertiary.8" mt={5} sx={{ overflow: 'hidden' }}>
                  <Text fz={12} lh={1.3} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {alert.category.label} / {alert.subCategory.label} /
                  </Text>
                  <Text
                    fz={17}
                    lh={1.3}
                    fw={600}
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {alert.type.label}
                  </Text>
                  <Text fz={12}>{alert.reportedAt}</Text>

                  <Group spacing={5} mt={3}>
                    <IconBuilding size={10} />
                    <Text fz={9}>
                      {alert.companyReportTotal} alerte{alert.companyReportTotal > 1 ? 's' : ''} par cette entreprise
                    </Text>
                  </Group>
                </Box>
              </Group>
            </Box>
          </Box>
        </Box>
      ))}
    </DetailDrawer>
  );
};

export default AlertReportMapDetail;
