import React, { FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import Previsions from '@shared/modules/previsions/components/Previsions';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { ZonesService } from '@modules/iot/zones/service';
import { defineRoute } from '@core/router';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { getStringQuery } from '@shared/modules/filter';

const loader = defineLoader({
  handler: ({ request }) =>
    pipe(
      TE.of({ referrer: getStringQuery(parseQueriesFormUrl(request.url), 'referrer') }),
      TE.apS('zones', ZonesService.getZones()),
      httpTaskToResponseTask,
    ),
});

const ListPage: FC = () => {
  const { zones, referrer } = useLoader<typeof loader>();

  const pageProps: PageProps = {
    seoTitle: 'Prévisions',
    back: { title: 'Prévisions', to: referrer ?? '/' },
  };

  return (
    <Page {...pageProps} withNav px={0}>
      <Previsions zones={zones} />
    </Page>
  );
};

const previsionsRoute = defineRoute({
  element: <ListPage />,
  loader,
});

export default previsionsRoute;
