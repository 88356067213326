import React, { FC, ReactNode, useCallback } from 'react';
import { Box, Skeleton, Stack, Text } from '@mantine/core';
import { renderOptional } from '@shared/utils/render';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import MainWeather from '@shared/modules/previsions/components/weather/today/MainWeather';
import MainDisease from '@shared/modules/previsions/components/disease/today/MainDisease';
import { Link, useLocation } from 'react-router-dom';
import { Dashboard } from '@modules/iot/dashboard/model';
import AlertsPieChartCard from '@modules/alerts/components/AlertsPieChartCard';
import AlertsPieChartCardPlaceholder from '@modules/alerts/components/AlertsPieChartCardPlaceholder';
import { pipe } from 'fp-ts/function';
import { useFetchTaskOption } from '@core/http/hooks';
import { DashboardService } from '@modules/iot/dashboard/service';
import { PrevisionsService } from '@shared/modules/previsions/service';
import * as TE from 'fp-ts/TaskEither';
import { HttpError } from '@core/http';
import HomePest from '@modules/home/components/HomePest';
import { stringifyQueries } from '@shared/utils/queries';

const HomeContent: FC = () => {
  const location = useLocation();

  const [dashboard] = useFetchTaskOption(DashboardService.getDashboard, false, null);

  const [context] = useFetchTaskOption(PrevisionsService.getContext, null);

  const getWeather = useCallback(
    () =>
      pipe(
        context,
        TE.fromOption(() => HttpError.notFound),
        TE.chain(PrevisionsService.getWeather),
      ),
    [context],
  );

  const [weather] = useFetchTaskOption(getWeather);

  const getDisease = useCallback(
    () =>
      pipe(
        context,
        TE.fromOption(() => HttpError.notFound),
        TE.chain(PrevisionsService.getDisease),
      ),
    [context],
  );

  const [disease] = useFetchTaskOption(getDisease);

  const getPest = useCallback(
    () =>
      pipe(
        context,
        TE.fromOption(() => HttpError.notFound),
        TE.chain(PrevisionsService.getPest),
      ),
    [context],
  );

  const [pest] = useFetchTaskOption(getPest);

  return (
    <Stack spacing={25}>
      <Stack spacing={10}>
        {renderOptional(
          sequenceS(O.Apply)({ context, weather }),
          ({ context, weather }) => (
            <MainWeather values={weather.today} location={context.location} />
          ),
          () => (
            <Skeleton h={243} radius={8} />
          ),
        )}

        {renderOptional(
          sequenceS(O.Apply)({ context, disease }),
          ({ context, disease }) => (
            <MainDisease today={disease.today} location={context.location} />
          ),
          () => (
            <Skeleton h={190} radius={8} />
          ),
        )}

        <Text
          ml="auto"
          component={Link}
          color="tertiary.8"
          weight={500}
          td="underline"
          size={12}
          to={{ pathname: '/previsions', search: stringifyQueries({ referrer: location.pathname }) }}
        >
          Voir toutes les prévisions
        </Text>
      </Stack>

      {renderOptional(
        pest,
        pest => (
          <Stack spacing={5}>
            <Stack spacing={3} c="tertiary.8">
              <Text fz={17} fw={600} lh={1.29}>
                Vigilance bioagresseurs
              </Text>
              <Text fz={13} fw={500} lh={1.5} opacity={0.5}>
                Du {pest.dateRange.start} au {pest.dateRange.end}
              </Text>
            </Stack>
            <HomePest pest={pest} />
          </Stack>
        ),
        () => (
          <Skeleton h={130} />
        ),
      )}

      <Stack spacing={5}>
        <Box>
          <Text color="tertiary.8" size={17} weight={600}>
            Sondes
          </Text>
          <Text color="tertiary.8" size={13} weight={500} lh={1.5} opacity={0.5} tt="uppercase">
            {renderOptional<Dashboard, ReactNode>(
              dashboard,
              ({ sensors }) => `${sensors.total} sonde${sensors.total > 1 ? 's' : ''}`,
              () => (
                <>&nbsp;</>
              ),
            )}
          </Text>
        </Box>
        {renderOptional(
          dashboard,
          ({ alerts }) => (
            <AlertsPieChartCard alerts={alerts} />
          ),
          () => (
            <AlertsPieChartCardPlaceholder mah={135} />
          ),
        )}
      </Stack>
    </Stack>
  );
};

export default HomeContent;
