import { AlertReport } from '@modules/diseases/alert-reports/model';
import { useOutletContext } from 'react-router-dom';
import { Dispatch } from 'react';
import { Utils } from '@shared/utils/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';

export interface ReportFlowState {
  categories: Array<AlertReport.Category>;
  selectedCategory: AlertReport.Category | null;
  subCategories: Array<AlertReport.SubCategory>;
  selectedSubCategory: AlertReport.SubCategory | null;
  types: Array<AlertReport.Type>;
  selectedType: AlertReport.Type | null;
  form: AlertReport.Flow.Body | null;
  images: Array<AlertReport.Flow.Image>;
  location: Utils.GPSCoordinates | null;
}

export type ReportFlowAction =
  | {
      type: 'CATEGORY_SELECTED';
      selectedCategory: AlertReport.Category;
      subCategories: Array<AlertReport.SubCategory>;
    }
  | {
      type: 'SUB_CATEGORY_SELECTED';
      selectedSubCategory: AlertReport.SubCategory;
      types: Array<AlertReport.Type>;
    }
  | {
      type: 'TYPE_SELECTED';
      selectedType: AlertReport.Type;
    }
  | {
      type: 'FORM_SAVED';
      form: AlertReport.Flow.Body;
    }
  | { type: 'UPDATE_IMAGES'; images: Array<AlertReport.Flow.Image> }
  | { type: 'LOCATION_SELECTED'; location: Utils.GPSCoordinates };

export function reportFlowReducer(state: ReportFlowState, action: ReportFlowAction): ReportFlowState {
  switch (action.type) {
    case 'CATEGORY_SELECTED':
      return {
        ...state,
        selectedCategory: action.selectedCategory,
        subCategories: action.subCategories,
        ...(action.selectedCategory.id !== state.selectedCategory?.id
          ? {
              selectedSubCategory: null,
              types: [],
              selectedType: null,
            }
          : {}),
      };
    case 'SUB_CATEGORY_SELECTED':
      return {
        ...state,
        selectedSubCategory: action.selectedSubCategory,
        types: action.types,
        selectedType: action.selectedSubCategory.id !== state.selectedSubCategory?.id ? null : state.selectedType,
      };
    case 'TYPE_SELECTED':
      return {
        ...state,
        selectedType: action.selectedType,
      };
    case 'FORM_SAVED':
      return {
        ...state,
        form: action.form,
      };
    case 'UPDATE_IMAGES':
      return {
        ...state,
        images: action.images,
      };
    case 'LOCATION_SELECTED':
      return {
        ...state,
        location: action.location,
      };
    default:
      return state;
  }
}

export function reportFlowStateToParams({
  selectedCategory,
  selectedType,
  form,
  location,
  images,
}: ReportFlowState): AlertReport.Flow.Params | null {
  if (selectedCategory && selectedType && form && location) {
    const mainImage = pipe(
      images,
      A.findFirst(image => image.isMain),
      O.alt(() => A.head(images)),
      O.map(image => image.id),
      O.toNullable,
    );

    const otherImages = pipe(
      images,
      A.filterMap(image => (image.id !== mainImage ? O.some(image.id) : O.none)),
    );

    return {
      type: selectedType.id,
      comment: form.comment,
      internalComment: form.internalComment,
      location,
      grassType: selectedCategory.hasGrassType ? form.grassType : null,
      severity: form.severity,
      isPrivate: selectedType.isPrivateAuthorized ? form.isPrivate : false,
      mainImage,
      images: otherImages,
    };
  }

  return null;
}

export interface ReportFlowSubmitter {
  loading: boolean;
  onSubmit: (state: ReportFlowState) => void;
}

export type ReportFlowContext = [ReportFlowState, Dispatch<ReportFlowAction>, ReportFlowSubmitter];

export function useReportFlowContext() {
  return useOutletContext<ReportFlowContext>();
}

export function createReportFlowContext(
  state: ReportFlowState,
  dispatch: Dispatch<ReportFlowAction>,
  submitter: ReportFlowSubmitter,
): ReportFlowContext {
  return [state, dispatch, submitter];
}
