import { OAuth } from '@core/oauth/model';
import { HttpStatusCode } from '@core/http';

export namespace OAuthUtils {
  import OAuthHttpError = OAuth.OAuthHttpError;
  import OAuthErrorCode = OAuth.OAuthErrorCode;

  const defaultMessage = 'Une erreur technique est survenue.';

  export function getOAuthErrorMessage(error: OAuthHttpError): string {
    switch (error.status) {
      case HttpStatusCode.BAD_REQUEST:
        switch (error.data?.error) {
          case OAuthErrorCode.InvalidGrant:
          case OAuthErrorCode.UnsupportedGrantType:
            return 'Email ou mot de passe incorrect.';
          case OAuthErrorCode.AccessDenied:
            return 'Cet espace est réservé aux membres ayant souscrit à l’abonnement PRO';
          default:
            return defaultMessage;
        }
      default:
        return defaultMessage;
    }
  }
}
