import React, { FC } from 'react';
import { Box, Stack } from '@mantine/core';
import Page from '@layout/page/Page';
import { defineRoute } from '@core/router';
import { useProfile } from '@modules/profile/loader';
import HomeHeader from '@modules/home/components/HomeHeader';
import HomeLinks from '@modules/home/components/HomeLinks';
import HomeContentPlaceholder from '@modules/home/components/HomeContentPlaceholder';
import HomeContent from '@modules/home/components/HomeContent';

const HomePage: FC = () => {
  const { isAccessLimited } = useProfile().subscription;

  return (
    <Page withNav p={0} pb={25}>
      <Stack spacing={0} align="center">
        <HomeHeader />

        <Box w="100%" mx="auto" maw={430}>
          <Box px={20}>
            <HomeLinks />
            {!isAccessLimited && <HomeContent />}
          </Box>

          {isAccessLimited && <HomeContentPlaceholder />}
        </Box>
      </Stack>
    </Page>
  );
};

const homeRoute = defineRoute({
  element: <HomePage />,
});

export default homeRoute;
