import React, { forwardRef } from 'react';
import { Group, Select, SelectProps, Text, ThemeIcon } from '@mantine/core';
import { Threshold } from '@modules/iot/model';
import { IconAlertSmall } from '@tabler/icons-react';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  value: Threshold.Level;
  label: string;
}

const ThresholdItem = forwardRef<HTMLDivElement, ItemProps>(({ value, label, ...props }, ref) => {
  return (
    <div ref={ref} {...props}>
      <Group noWrap spacing={10}>
        <ThemeIcon radius="xl" color={Threshold.levelColor[value]} variant="light" sx={{ border: '1px solid #fff' }}>
          <IconAlertSmall />
        </ThemeIcon>

        <Text>{label}</Text>
      </Group>
    </div>
  );
});

interface ThresholdSelectProps extends Omit<SelectProps, 'value' | 'onChange' | 'data'> {
  value?: Threshold.Level | null;
  onChange?: (level: Threshold.Level) => void;
}

const ThresholdSelect = forwardRef<HTMLInputElement, ThresholdSelectProps>(({ ...props }, ref) => {
  return (
    <Select
      ref={ref}
      {...props}
      data={Object.values(Threshold.Level)
        .filter(level => level !== Threshold.Level.None)
        .map(level => ({
          value: level,
          label: `Niveau ${Threshold.levelLabel[level]}`,
        }))}
      itemComponent={ThresholdItem}
    />
  );
});

export default ThresholdSelect;
