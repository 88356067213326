import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import { Resource } from '@shared/modules/resources/model';
import { pipe, tuple } from 'fp-ts/function';
import config from '@root/config';

export namespace ResourceUtils {
  export function getResourceFromDetail(resource: Resource.Detail | null): O.Option<Resource> {
    return pipe(
      O.fromNullable(resource?.resourceId),
      O.alt(() => O.fromNullable(resource?.strategy.source)),
      O.map(id => ({
        id,
        url: pipe(
          Object.values(Resource.PGImageVariant),
          A.map(variant => tuple(variant, `${config.VITE_IMAGE_BASE_URL}/${config.VITE_ENVIRONMENT}/${id}-${variant}`)),
          R.fromEntries,
        ) as Record<Resource.PGImageVariant, string>,
      })),
    );
  }
}
