import React, { FC, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  ScrollRestoration,
  useNavigation,
} from 'react-router-dom';

import { createRoute } from '@core/router';
import Layout from '@layout/Layout';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';

import authRoutes from '@modules/auth/routes';
import profileLoaderRoute from '@modules/profile/loader';
import homeRoute from '@modules/home/HomePage';
import emergencyNumbersRoutes from '@modules/emergency-numbers/routes';
import sensorsRoutes from '@modules/sensors/routes';
import previsionsRoutes from '@modules/previsions/routes';
import diseasesRoutes from '@modules/diseases/routes';

const NavigationLoader: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationLoader />}>
      {authRoutes}

      <Route {...createRoute(profileLoaderRoute)}>
        <Route element={<Layout />}>
          <Route path="/" {...createRoute(homeRoute)} />
          {emergencyNumbersRoutes}
          {sensorsRoutes}
          {previsionsRoutes}
          {diseasesRoutes}

          <Route path="*" element={<ErrorPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Route>,
  ),
);

export default router;
