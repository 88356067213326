import { httpService, HttpTask } from '@core/http';
import { Previsions } from '@shared/modules/previsions/model';
import config from '@root/config';

export namespace PrevisionsService {
  const URI = `${config.VITE_API_PREFIX}/dashboard`;

  export function getContext(filter: Previsions.Filter | null): HttpTask<Previsions.Context> {
    return httpService.post(`${URI}/context`, { filter });
  }

  export function getWeather(context: Previsions.Context): HttpTask<Previsions.Weather> {
    return httpService.post(`${URI}/weather`, context);
  }

  export function getDisease(context: Previsions.Context): HttpTask<Previsions.Disease> {
    return httpService.post(`${URI}/disease`, context);
  }

  export function getPest(context: Previsions.Context): HttpTask<Previsions.Pest> {
    return httpService.post(`${URI}/pest`, context);
  }
}
