import React, { FC, MouseEventHandler, useEffect, useState } from 'react';
import { NavLink, useOutlet } from 'react-router-dom';
import { ActionIcon, Button, Group, Indicator, Modal, Text, ThemeIcon, Title, ActionIconProps } from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/utils';
import {
  IconArrowLeft,
  IconBattery2,
  IconCurrentLocation,
  IconDroplet,
  IconExclamationCircle,
  IconFilter,
  IconTemperature,
  IconX,
} from '@tabler/icons-react';
import Supercluster from 'supercluster';

import Page, { PageProps } from '@layout/page/Page';

import { defineRoute, usePreserveNavigate } from '@core/router';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { defineAction, useAction } from '@core/router/action';

import { parseQueriesFormUrl, stringifyQueries } from '@shared/utils/queries';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import { Maps } from '@shared/modules/maps/model';
import { isFilterEmpty } from '@shared/modules/filter';
import { renderNullable } from '@shared/utils/render';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import { CustomPointFeature, MAX_ZOOM } from '@shared/modules/maps/hooks/supercluster';
import MapClustering from '@shared/modules/maps/components/MapClustering';
import { useUserPosition } from '@shared/modules/maps/hooks/user-position';
import UserPosition from '@shared/modules/maps/components/UserPosition';

import { Measure } from '@modules/measures/model';
import { SensorsUtils } from '@modules/sensors/utils';
import { SensorsService } from '@modules/sensors/service';
import { SensorsMap } from '@modules/sensors/map/model';
import DetailDrawer from '@modules/sensors/detail/DetailDrawer';
import SensorPin from '@modules/sensors/map/components/SensorPin';
import { SensorFilterService } from '@modules/sensors/map/filters/service';
import { ZonesService } from '@modules/iot/zones/service';
import { SensorFilter } from '@modules/sensors/map/filters/model';
import SensorsFilters from '@modules/sensors/map/filters/SensorsFilters';
import { ProfilePredicate } from '@modules/profile/predicate';

import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as P from 'fp-ts/Predicate';

import Type = Measure.Type;

const loader = defineLoader({
  handler: ({ request }) => {
    const createCluster = (map: SensorsMap.WithLocation) => {
      const cluster = new Supercluster<CustomPointFeature<SensorsMap.Marker>>({ radius: 75, maxZoom: MAX_ZOOM });

      const points: Array<Supercluster.PointFeature<CustomPointFeature<SensorsMap.Marker>>> = map.markers.map(
        marker => ({
          type: 'Feature' as const,
          properties: { cluster: false, marker },
          geometry: {
            type: 'Point' as const,
            coordinates: [marker.location.longitude, marker.location.latitude],
          },
        }),
      );

      cluster.load(points);

      return cluster;
    };

    return pipe(
      TE.Do,
      TE.apS('savedFilters', SensorFilterService.listing()),
      TE.apS('zones', ZonesService.getZones()),
      TE.let('filters', ({ savedFilters }) =>
        SensorsUtils.sensorsFilterParser(savedFilters)(parseQueriesFormUrl(request.url)),
      ),
      TE.bind('map', ({ filters }) =>
        pipe(
          SensorsService.getMapSensors(filters),
          TE.map(map => ({
            ...map,
            markers: map.markers.filter((marker): marker is SensorsMap.Marker.WithLocation => !!marker.location),
          })),
        ),
      ),
      TE.let('supercluster', ({ map }) => createCluster(map)),
      httpTaskToResponseTask,
    );
  },
});

const actions = {
  createFilter: defineAction({
    type: 'create',
    payload: SensorFilter.CreateParams,
    handler: ({ payload }) => SensorFilterService.create(payload),
  }),
  deleteFilter: defineAction({
    type: 'deleteFilter',
    payload: SensorFilter.Id,
    handler: ({ payload }) => SensorFilterService.deleteFilter(payload),
  }),
};

const MapPage: FC = () => {
  const outlet = useOutlet();
  const navigate = usePreserveNavigate();

  const {
    map: { markers },
    supercluster,
    filters,
    savedFilters,
    zones,
  } = useLoader<typeof loader>();

  const [, createFilter] = useAction(actions.createFilter);
  const [, deleteFilter] = useAction(actions.deleteFilter);

  const showPlaceholder = !isFilterEmpty(filters) && markers.length === 0;

  const [placeholderOpen, setPlaceholderOpen] = useState(showPlaceholder);

  useEffect(() => {
    setPlaceholderOpen(!isFilterEmpty(filters) && markers.length === 0);
  }, [filters, markers.length]);

  const userPosition = useUserPosition();

  const pageProps: PageProps = {
    seoTitle: 'Carte des sondes',
  };

  const getActionFilterProps = (
    type: Measure.Type,
  ): PolymorphicComponentProps<typeof PreserveSearchLink, ActionIconProps> => ({
    size: 43,
    radius: '50%',
    bg: 'white',
    to: {
      search: stringifyQueries({
        ...filters,
        alertType: type === filters.alertType ? null : type,
      }),
    },
    sx: { border: !filters.alertType || filters.alertType === type ? '2px solid #7b6757' : '2px solid #e1ded9' },
    c: !filters.alertType || filters.alertType === type ? '#483729' : '#e1ded9',
  });

  const handleMouseDown = () => {
    if (outlet != null) navigate({ pathname: '..' }, { relative: 'path' });
  };

  const handlePreventDownDefault: MouseEventHandler = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Page {...pageProps} p={0} bg="tertiary.3" withNav>
      <Group
        pos="absolute"
        top={0}
        w="100%"
        spacing={5}
        bg="tertiary.8"
        h={63}
        px={20}
        style={{ flexGrow: 1, zIndex: 100 }}
      >
        <ActionIcon component={NavLink} to="/sensors" variant="transparent" c="white">
          <IconArrowLeft size={22} />
        </ActionIcon>
        <Title size={17} color="white" weight={600}>
          Carte des sondes
        </Title>
      </Group>

      <GoogleMaps
        options={{ ...Maps.defaultOptions, zoomControl: false }}
        onMouseDown={handleMouseDown}
        style={{ flexGrow: 1 }}
      >
        {map => (
          <>
            <Group w="100%" pos="absolute" top={20 + 64} position="center" spacing={15}>
              <ActionIcon component={PreserveSearchLink} {...getActionFilterProps(Type.Humidity)}>
                <IconDroplet />
              </ActionIcon>
              <ActionIcon component={PreserveSearchLink} {...getActionFilterProps(Type.Temperature)}>
                <IconTemperature />
              </ActionIcon>
              <ActionIcon component={PreserveSearchLink} {...getActionFilterProps(Type.Battery)}>
                <IconBattery2 />
              </ActionIcon>
            </Group>
            <MapClustering map={map} markers={markers} supercluster={supercluster}>
              {marker => (
                <GoogleMapsOverlay
                  key={marker.id}
                  position={{ lat: marker.location.latitude, lng: marker.location.longitude }}
                  map={map}
                >
                  <SensorPin
                    component={PreserveSearchLink}
                    to={marker.id}
                    display="block"
                    w="100%"
                    h="100%"
                    type={marker.type}
                    tip={marker.name}
                    level={marker.alertLevel ?? undefined}
                  />
                </GoogleMapsOverlay>
              )}
            </MapClustering>

            <Indicator
              pos="absolute"
              bottom={30}
              left="50%"
              color="tertiary.8"
              style={{ transform: 'translateX(-50%)' }}
              disabled={isFilterEmpty(filters)}
            >
              <Button
                onMouseDown={handlePreventDownDefault}
                component={PreserveSearchLink}
                to={{ hash: 'filters' }}
                leftIcon={<IconFilter size={18} />}
                radius={4}
                px={16}
                color="primary.5"
                tt="uppercase"
                styles={{
                  leftIcon: {
                    marginRight: 4,
                    transform: 'rotateY(0.5turn)',
                  },
                }}
              >
                Filtrer
              </Button>
            </Indicator>

            {renderNullable(userPosition, position => (
              <>
                <ActionIcon
                  pos="absolute"
                  bottom={30}
                  right={20}
                  onClick={() => map.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })}
                  onMouseDown={handlePreventDownDefault}
                  size={44}
                  radius="50%"
                  bg="white"
                  c="primary.5"
                >
                  <IconCurrentLocation />
                </ActionIcon>

                <UserPosition position={position} map={map} />
              </>
            ))}
          </>
        )}
      </GoogleMaps>
      {showPlaceholder ? (
        <Modal
          padding={8}
          size="auto"
          opened={placeholderOpen}
          onClose={() => setPlaceholderOpen(false)}
          withCloseButton={false}
          centered
        >
          <Group spacing={17} p={12}>
            <ThemeIcon size={22} color="primary.4" radius="100%">
              <IconExclamationCircle size={14} />
            </ThemeIcon>
            <Text size={14} color="dark.5" weight={600}>
              Aucun résultat
            </Text>
            <ActionIcon onClick={() => setPlaceholderOpen(false)}>
              <IconX />
            </ActionIcon>
          </Group>
        </Modal>
      ) : (
        <DetailDrawer opened={outlet != null}>{outlet}</DetailDrawer>
      )}

      <SensorsFilters
        filters={filters}
        savedFilters={savedFilters}
        zones={zones}
        onCreateFilter={createFilter}
        onDeleteFilter={deleteFilter}
      />
    </Page>
  );
};

const sensorsMap = defineRoute({
  restriction: P.not(ProfilePredicate.isAccessLimited),
  element: <MapPage />,
  loader,
  actions,
});

export default sensorsMap;
