import { httpService, HttpTask } from '@core/http';
import { SensorsMap } from '@modules/sensors/map/model';
import { ActiveSensor, Sensor } from '@modules/sensors/model';
import { Measure } from '@modules/measures/model';
import { HubApi } from '@modules/iot/model';
import z from 'zod';
import { PendingSensor } from '@modules/sensors/pending/model';

export namespace SensorsService {
  import typeRegisterSchema = PendingSensor.typeRegisterSchema;
  const URI = '/iot/sensors';
  export function getMapSensors(filters: ActiveSensor.Filter): HttpTask<SensorsMap> {
    return httpService.get(`${URI}/map`, { params: filters });
  }

  export function getActiveSensors(filters: ActiveSensor.Filter): HttpTask<ActiveSensor.List> {
    return httpService.get(URI, { params: filters });
  }

  export function getActiveSensor<Type extends Sensor.Type | ActiveSensor = ActiveSensor>(
    id: Sensor.Id,
  ): HttpTask<Type extends Sensor.Type ? ActiveSensor.Impl<Type> : ActiveSensor> {
    return httpService.get(`${URI}/${id}`);
  }

  export function getMeasures<Type extends Sensor.Type>(
    id: Sensor.Id,
    type: Type,
    filter: Measure.History.Filter,
  ): HttpTask<Measure.History<Type>> {
    return httpService.get(`${URI}/${type}/${id}/measures`, { params: filter });
  }

  export function getPendingSensors(): HttpTask<PendingSensor.List> {
    return httpService.get(`${URI}/pending`);
  }

  export function getPendingSensor<Type extends Sensor.Type = Sensor.Type>(
    id: HubApi.SensorId,
  ): HttpTask<PendingSensor<Type>> {
    return httpService.get(`${URI}/pending/${id}`);
  }

  export function registerPending<Type extends Sensor.Type>(
    type: Type,
    params: z.infer<(typeof typeRegisterSchema)[Type]>,
  ): HttpTask<PendingSensor.RegisterSensorOut> {
    return httpService.post(`${URI}/${type}`, params);
  }
}
