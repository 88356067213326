import React, { FC } from 'react';
import { Card, Group, Text } from '@mantine/core';
import AlertsPieChart from '@shared/modules/charts/half-pie/AlertsPieChart';
import { Dashboard } from '@modules/iot/dashboard/model';

interface AlertsPieChartCardProps {
  alerts: Dashboard.Alerts;
}

const AlertsPieChartCard: FC<AlertsPieChartCardProps> = ({ alerts }) => {
  const total = alerts.medium + alerts.high;

  const alertsData = [
    { value: 1 - alerts.level, bg: 'secondary', active: false },
    { value: total > 0 ? (alerts.medium * alerts.level) / total : 0, bg: 'primary.3', active: true },
    { value: total > 0 ? (alerts.high * alerts.level) / total : 0, bg: 'primary', active: true },
  ].filter(({ value }) => value !== 0);

  return (
    <Card shadow="0 0 12px 0 rgba(0, 0, 0, 0.1)" withBorder={false}>
      <Group pb={20}>
        <Card py={10} px={15} radius={10} withBorder={false} bg="#fbf6e6">
          <Text component={Group} spacing={10} size={12} color="primary.4">
            <Text component="span" size={20} weight="bold">
              {alerts.medium}
            </Text>{' '}
            En alerte
          </Text>
        </Card>
        <Card py={10} px={15} radius={10} withBorder={false} bg="#f7e2d4">
          <Text component={Group} spacing={10} size={12} color="primary.5">
            <Text component="span" size={20} weight="bold">
              {alerts.high}
            </Text>{' '}
            Critique(s)
          </Text>
        </Card>
      </Group>
      <AlertsPieChart h={135} value={alerts.level} data={alertsData} />
    </Card>
  );
};

export default AlertsPieChartCard;
