import { Navigate, Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import alertReportsMapRoute from '@modules/diseases/alert-reports/map/AlertReportsMap';
import createReportContextRoute from '@modules/diseases/alert-reports/create/CreateReportContext';
import reportCategoriesStepRoute from '@modules/diseases/alert-reports/flow/ReportCategoriesStep';
import reportSubCategoriesStepRoute from '@modules/diseases/alert-reports/flow/ReportSubCategoriesStep';
import reportTypesStepRoute from '@modules/diseases/alert-reports/flow/ReportTypesStep';
import reportFormStepRoute from '@modules/diseases/alert-reports/flow/ReportFormStep';
import reportImagesStepRoute from '@modules/diseases/alert-reports/flow/ReportImagesStep';
import reportLocationStepRoute from '@modules/diseases/alert-reports/flow/ReportLocationStep';
import createReportSuccessRoute from '@modules/diseases/alert-reports/create/CreateReportSuccess';
import { ReportFlowStepProps } from '@modules/diseases/alert-reports/flow';
import alertReportDetailRoute from '@modules/diseases/alert-reports/detail/AlertReportDetail';
import editAlertReportContextRoute from '@modules/diseases/alert-reports/detail/edit/EditReportContext';
import alertReportsListRoute from '@modules/diseases/alert-reports/list/AlertReportsList';
import alertReportAnalysisRoute from '@modules/diseases/alert-reports/analysis/AlertReportAnalysis';

const CREATE_PROPS: ReportFlowStepProps = { type: 'create' };
const EDIT_PROPS: ReportFlowStepProps = { type: 'edit' };

const alertReportsRoutes = (
  <Route path="alert-reports">
    <Route index element={<Navigate to="map" relative="path" replace />} />

    <Route path="map" {...createRoute(alertReportsMapRoute)} />
    <Route path="list" {...createRoute(alertReportsListRoute)} />

    <Route path="new" {...createRoute(createReportContextRoute)}>
      <Route index {...createRoute(reportCategoriesStepRoute, CREATE_PROPS)} />
      <Route path="sub-categories" {...createRoute(reportSubCategoriesStepRoute, CREATE_PROPS)} />
      <Route path="types" {...createRoute(reportTypesStepRoute, CREATE_PROPS)} />
      <Route path="form" {...createRoute(reportFormStepRoute, CREATE_PROPS)} />
      <Route path="images" {...createRoute(reportImagesStepRoute, CREATE_PROPS)} />
      <Route path="location" {...createRoute(reportLocationStepRoute, CREATE_PROPS)} />
    </Route>

    <Route path="new/success" {...createRoute(createReportSuccessRoute)} />

    <Route path=":id" {...createRoute(alertReportDetailRoute)} />
    <Route path=":id/analysis" {...createRoute(alertReportAnalysisRoute)} />

    <Route path=":id/edit" {...createRoute(editAlertReportContextRoute)}>
      <Route index {...createRoute(reportCategoriesStepRoute, EDIT_PROPS)} />
      <Route path="sub-categories" {...createRoute(reportSubCategoriesStepRoute, EDIT_PROPS)} />
      <Route path="types" {...createRoute(reportTypesStepRoute, EDIT_PROPS)} />
      <Route path="form" {...createRoute(reportFormStepRoute, EDIT_PROPS)} />
      <Route path="images" {...createRoute(reportImagesStepRoute, EDIT_PROPS)} />
      <Route path="location" {...createRoute(reportLocationStepRoute, EDIT_PROPS)} />
    </Route>
  </Route>
);

export default alertReportsRoutes;
