import React, { FC } from 'react';
import { Global } from '@mantine/core';

const GlobalStyles: FC = () => {
  return (
    <Global
      styles={theme => ({
        'html, body, #root': {
          width: '100%',
          height: '100%',
          background: theme.colors.tertiary[2],
        },
        body: {
          padding: `env(safe-area-inset-top, 20px)
            env(safe-area-inset-right, 20px)
            env(safe-area-inset-bottom, 20px)
            env(safe-area-inset-left, 20px)`,
        },
      })}
    />
  );
};

export { GlobalStyles };
