import React, { createElement, FC } from 'react';
import { ThemeIcon, Tooltip } from '@mantine/core';
import { Measure } from '@modules/measures/model';
import { MeasureUtils } from '@modules/measures/utils';
import batteryLevel = MeasureUtils.batteryLevel;
import BatteryLevel = Measure.BatteryLevel;
import batteryBreakpoint = Measure.batteryBreakpoint;
import batteryIcon = Measure.batteryIcon;
import batteryLabel = Measure.batteryLabel;

interface BatteryThemeIconProps {
  battery: Measure.Value<Measure.Type.Battery>;
}

const BatteryThemeIcon: FC<BatteryThemeIconProps> = ({ battery }) => {
  return (
    <Tooltip label={batteryLabel[batteryLevel(battery)]}>
      <ThemeIcon
        size={31}
        variant="transparent"
        c={battery < batteryBreakpoint[BatteryLevel.Empty] ? 'dark.1' : 'tertiary.8'}
      >
        {createElement(batteryIcon[batteryLevel(battery)], { strokeWidth: 1, size: 31 })}
      </ThemeIcon>
    </Tooltip>
  );
};

export default BatteryThemeIcon;
