import React, { FC, PropsWithChildren } from 'react';
import { useProfilePredicate } from '@modules/profile/hooks';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { HttpError } from '@core/http';
import { Profile } from '@modules/profile/model';
import * as P from 'fp-ts/Predicate';
interface AccessGuardProps {
  predicate: P.Predicate<Profile>;
}

const AccessGuard: FC<PropsWithChildren<AccessGuardProps>> = ({ predicate, children }) => {
  if (useProfilePredicate(predicate)) {
    return <>{children}</>;
  }

  return <ErrorPage error={HttpError.forbidden} />;
};

export default AccessGuard;
