import { z } from 'zod';
import { ActionFunctionArgs, redirect, resolvePath } from 'react-router-dom';
import { constNull, constTrue, pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import {
  Action,
  ActionFlashOptions,
  ActionHandler,
  ActionRedirectHandler,
  Actions,
  EmptyAction,
  FormDataAction,
  PayloadAction,
} from '@core/router/action/index';
import { parseParams } from '@core/router';
import { showFlashNotification } from '@shared/modules/notification/utils';
import { HttpResult } from '@core/http';
import { createPath } from 'history';

export function defineAction<
  PayloadSchema extends z.ZodType,
  ParamsSchema extends z.ZodType,
  Type extends string,
  R = unknown,
  E = unknown,
>(
  action: PayloadAction<PayloadSchema, ParamsSchema, R, E, Type>,
): PayloadAction<PayloadSchema, ParamsSchema, R, E, Type>;

export function defineAction<ParamsSchema extends z.ZodType, Type extends string, R = unknown, E = unknown>(
  action: FormDataAction<ParamsSchema, R, E, Type>,
): FormDataAction<ParamsSchema, R, E, Type>;

export function defineAction<ParamsSchema extends z.ZodType, Type extends string, R = unknown, E = unknown>(
  action: EmptyAction<ParamsSchema, R, E, Type>,
): EmptyAction<ParamsSchema, R, E, Type>;

export function defineAction(action: Action): Action {
  return action;
}

function findActionFromFormData(actions: Actions, formData: FormData) {
  const type = formData.get('_type');

  const action = Object.values(actions).find(action => action.type === type);

  if (!action) {
    throw new Error(`Cannot find action with type ${type}`);
  }

  return action;
}

/**
 * Return payload if action is of type Payload
 *
 * @param action
 * @param formData
 */
function parseActionPayload(action: PayloadAction, formData: FormData) {
  try {
    return action.payload.parse(JSON.parse(formData.get('payload') as any));
  } catch (e) {
    console.error('[action] failed to parse payload', e);

    throw new Response('[loader] failed to parse payload', { status: 422 });
  }
}

export function actionHandler<A extends Actions>(actions: A): (args: ActionFunctionArgs) => Promise<HttpResult> {
  return async args => {
    const request = args.request;

    const formData = await request.formData();

    const action = findActionFromFormData(actions, formData);

    const params = parseParams(args.params, action.params);

    /**
     * Generic handler for action
     *
     * @param args
     * @param handler
     * @param redirectFn
     * @param flashOptions
     */
    const runAction = <Args, R, E>(
      args: Args,
      handler: ActionHandler<Args, R, E>,
      redirectFn?: ActionRedirectHandler<Args, R, E>,
      { success = constNull, error = constTrue }: ActionFlashOptions<Args, R, E> = {},
    ): Promise<HttpResult<R, E>> => {
      const flashHandler = (result: HttpResult<R, E>) =>
        pipe(
          result,
          EI.fold(
            err => () => {
              const message = error({ ...args, error: err });

              if (message) {
                showFlashNotification('error', message === true ? 'Une erreur technique est survenue' : message);
              }
            },
            res => () => {
              const message = success({ ...args, result: res });

              if (message) {
                showFlashNotification('success', message);
              }
            },
          ),
        );

      const redirectHandler = (result: HttpResult<R, E>) => () => {
        const redirectUrl = redirectFn ? redirectFn({ ...args, result }) : null;

        if (redirectUrl) {
          throw redirect(createPath(resolvePath(redirectUrl)));
        }
      };

      return pipe(handler(args), T.chainFirstIOK(flashHandler), T.chainFirstIOK(redirectHandler))();
    };

    if ('payload' in action) {
      return runAction(
        { request, params, payload: parseActionPayload(action, formData) },
        action.handler,
        action.redirect,
        action.flashOptions,
      );
    }

    if ('formData' in action) {
      return runAction({ request, params, formData }, action.handler, action.redirect, action.flashOptions);
    }

    return runAction({ request, params }, action.handler, action.redirect, action.flashOptions);
  };
}
