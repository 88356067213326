import React, { FC, useReducer } from 'react';
import { defineAction, useAction } from '@core/router/action';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { z } from 'zod';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, LoaderReturnType, useLoader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as A from 'fp-ts/Array';
import {
  createReportFlowContext,
  reportFlowReducer,
  ReportFlowState,
  reportFlowStateToParams,
} from '@modules/diseases/alert-reports/flow/context';
import { Outlet, useNavigate } from 'react-router-dom';
import { HttpError } from '@core/http';

const params = z.object({
  id: AlertReport.Id,
});

const loader = defineLoader({
  params,
  handler: ({ params }) => {
    return pipe(
      TE.Do,
      TE.apS('alertReport', AlertReportsService.getAlertDetail(params.id)),
      TE.apS('categories', AlertReportsService.getCategories()),
      TE.bind('subCategories', ({ alertReport }) => AlertReportsService.getSubCategories(alertReport.category.id)),
      TE.bind('types', ({ alertReport }) => AlertReportsService.getTypes(alertReport.subCategory.id)),
      httpTaskToResponseTask,
    );
  },
});

function reportFlowInitialState({
  alertReport,
  categories,
  subCategories,
  types,
}: LoaderReturnType<typeof loader>): ReportFlowState {
  return {
    categories,
    selectedCategory: alertReport.category,
    subCategories,
    selectedSubCategory: alertReport.subCategory,
    types,
    selectedType: alertReport.type,
    form: {
      isPrivate: alertReport.isPrivate,
      grassType: alertReport.grassType,
      severity: alertReport.gravity,
      comment: alertReport.comment,
      internalComment: alertReport.internalComment,
    },
    images: pipe(
      alertReport.images ?? [],
      A.mapWithIndex((index, image) => ({
        type: 'existing',
        id: image.id,
        isMain: index === 0,
        variants: image.variants,
      })),
    ),
    location: alertReport.coordinates,
  };
}

const actions = {
  update: defineAction({
    type: 'update',
    params,
    payload: AlertReport.Flow.Params,
    handler: ({ params, payload }) => AlertReportsService.updateAlert(params.id, payload),
  }),
};

const EditReportContext: FC = () => {
  const navigate = useNavigate();

  const data = useLoader<typeof loader>();

  const [loading, updateReport] = useAction(actions.update);

  const [state, dispatch] = useReducer(reportFlowReducer, data, reportFlowInitialState);

  const onSubmit = (state: ReportFlowState) => {
    pipe(
      reportFlowStateToParams(state),
      TE.fromNullable(HttpError.notFound),
      TE.chain(updateReport),
      TE.chainIOK(() => () => {
        navigate(`/diseases/alert-reports/${data.alertReport.id}`, { replace: true });
      }),
    )();
  };

  return <Outlet context={createReportFlowContext(state, dispatch, { loading, onSubmit })} />;
};

const editAlertReportContextRoute = defineRoute({
  element: <EditReportContext />,
  loader,
  actions,
});

export default editAlertReportContextRoute;
