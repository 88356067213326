import React, { FC } from 'react';
import { FilterChildrenProps } from '@shared/modules/filter/components/FiltersDrawer';
import { ActiveSensor } from '@modules/sensors/model';
import { Zone } from '@modules/iot/zones/model';
import { Select } from '@mantine/core';
import SearchGeoCity from '@modules/sensors/map/components/SearchGeoCity';
import { Geo } from '@shared/modules/geo/model';
import * as O from 'fp-ts/Option';
import { sequenceS } from 'fp-ts/Apply';

interface SensorsFiltersContentProps extends FilterChildrenProps<ActiveSensor.FilterWithCity> {
  zones: Array<Zone>;
}

const SensorsFiltersContent: FC<SensorsFiltersContentProps> = ({ filter, zones, onChange }) => {
  const handleZoneChange = (zoneId: Zone.Id) => onChange({ ...filter, zoneId });

  const geoCityValue = O.toNullable(
    sequenceS(O.Apply)({
      code: O.fromNullable(filter.code),
      postalCode: O.fromNullable(filter.postalCode),
      city: O.fromNullable(filter.city),
    }),
  );

  const handleCityChange = (city: Geo.City | null) => {
    onChange({ ...filter, code: city?.code ?? null, postalCode: city?.postalCode ?? null, city: city?.city ?? null });
  };

  return (
    <>
      <Select
        value={filter.zoneId}
        onChange={handleZoneChange}
        data={zones.map(({ id, name }) => ({ value: id, label: name }))}
        label="Groupe"
        placeholder="Groupe"
        clearable
      />

      <SearchGeoCity
        mt={10}
        label="Ville"
        placeholder="Ville"
        onChange={handleCityChange}
        value={geoCityValue}
        clearable
      />
    </>
  );
};

export default SensorsFiltersContent;
