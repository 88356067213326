import React, { FC } from 'react';
import ThresholdScaleCard from '@shared/modules/threshold/ThresholdScaleCard';
import ThresholdScaleController from '@shared/modules/threshold/ThresholdScaleController';
import { Sensor } from '@modules/sensors/model';
import SecondStepForm from '@modules/sensors/pending/components/steps/SecondStepForm';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Measure } from '@modules/measures/model';
import { Sensoterra } from '@modules/sensors/sensoterra/model';

const SensoterraSecondStepForm: FC = () => {
  const { control } = useEnhancedFormContext<Sensoterra.RegisterParams>();

  return (
    <SecondStepForm>
      <ThresholdScaleCard title="Capteur Sol" icon={Sensor.typeIcon[Sensor.Type.Sensoterra]}>
        <ThresholdScaleController name="config.humidityThreshold" control={control} type={Measure.Type.Humidity} />
      </ThresholdScaleCard>
    </SecondStepForm>
  );
};

export default SensoterraSecondStepForm;
