import React, { FC, useState } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import * as TE from 'fp-ts/TaskEither';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { Maps } from '@shared/modules/maps/model';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import Supercluster from 'supercluster';
import { CustomPointFeature, MAX_ZOOM } from '@shared/modules/maps/hooks/supercluster';
import { pipe } from 'fp-ts/function';
import { renderNullable } from '@shared/utils/render';
import AlertReportMapDetail from '@modules/diseases/alert-reports/map/components/AlertReportMapDetail';
import { ActionIcon, Button, Indicator } from '@mantine/core';
import AlertReportMapContent from '@modules/diseases/alert-reports/map/components/AlertReportMapContent';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { AlertReportUtils } from '@modules/diseases/alert-reports/utils';
import { useUserPosition } from '@shared/modules/maps/hooks/user-position';
import { IconCurrentLocation, IconFilter, IconPlus } from '@tabler/icons-react';
import UserPosition from '@shared/modules/maps/components/UserPosition';
import { isFilterEmpty } from '@shared/modules/filter';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';
import { Link } from 'react-router-dom';
import { defineAction, useAction } from '@core/router/action';
import AlertReportsFilters from '@modules/diseases/alert-reports/filters/AlertReportsFilters';

const loader = defineLoader({
  handler: ({ request }) => {
    const createCluster = (markers: Array<AlertReport.Marker>) => {
      const cluster = new Supercluster<CustomPointFeature<AlertReport.Marker>>({ radius: 75, maxZoom: MAX_ZOOM });

      const points: Array<Supercluster.PointFeature<CustomPointFeature<AlertReport.Marker>>> = markers.map(marker => ({
        type: 'Feature' as const,
        properties: { cluster: false, marker },
        geometry: {
          type: 'Point' as const,
          coordinates: [marker.location.longitude, marker.location.latitude],
        },
      }));

      cluster.load(points);

      return cluster;
    };

    return pipe(
      TE.Do,
      TE.bind('savedFilters', () => AlertReportsService.Filter.getFilters()),
      TE.let('filters', ({ savedFilters }) =>
        AlertReportUtils.alertReportsFilterParser(savedFilters)(parseQueriesFormUrl(request.url)),
      ),
      TE.bind('markers', ({ filters }) => AlertReportsService.getMapAlertReports(filters)),
      TE.let('supercluster', ({ markers }) => createCluster(markers)),
      httpTaskToResponseTask,
    );
  },
});

const actions = {
  createFilter: defineAction({
    type: 'create',
    payload: AlertReport.Filter.Params,
    handler: ({ payload }) => AlertReportsService.Filter.createFilter(payload),
  }),
  deleteFilter: defineAction({
    type: 'deleteFilter',
    payload: AlertReport.Filter.Id,
    handler: ({ payload }) => AlertReportsService.Filter.deleteFilter(payload),
  }),
};

const AlertReportsMap: FC = () => {
  const { markers, supercluster, filters, savedFilters } = useLoader<typeof loader>();

  const [, createFilter] = useAction(actions.createFilter);
  const [, deleteFilter] = useAction(actions.deleteFilter);

  const [selectedMarker, setSelectedMarker] = useState<AlertReport.Marker | null>(null);

  const userPosition = useUserPosition();

  const pageProps: PageProps = {
    withNav: true,
    back: {
      to: '/diseases',
      title: 'Signalements',
    },
  };

  const hasEmptyFilters = isFilterEmpty({ ...filters, status: null });

  return (
    <Page {...pageProps} p={0} bg="tertiary.3">
      <GoogleMaps options={{ ...Maps.defaultOptions, zoomControl: false }} style={{ flexGrow: 1 }}>
        {map => (
          <>
            <AlertReportMapContent
              map={map}
              markers={markers}
              supercluster={supercluster}
              onSelectedMarkerChange={setSelectedMarker}
            />

            <Indicator
              pos="absolute"
              bottom={30}
              left="50%"
              color="tertiary.8"
              style={{ transform: 'translateX(-50%)' }}
              disabled={hasEmptyFilters}
            >
              <Button
                component={PreserveSearchLink}
                to={{ hash: 'filters' }}
                leftIcon={<IconFilter size={18} />}
                radius={4}
                px={16}
                color="primary.5"
                tt="uppercase"
                styles={{
                  leftIcon: {
                    marginRight: 4,
                    transform: 'rotateY(0.5turn)',
                  },
                }}
              >
                Filtrer
              </Button>
            </Indicator>

            <ActionIcon
              component={Link}
              to="/diseases/alert-reports/new"
              pos="absolute"
              bottom={30}
              right={20}
              size={44}
              radius="50%"
              bg="tertiary.8"
              c="white"
            >
              <IconPlus />
            </ActionIcon>

            {renderNullable(userPosition, position => (
              <>
                <ActionIcon
                  pos="absolute"
                  bottom={90}
                  right={20}
                  onClick={() => map.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })}
                  size={44}
                  radius="50%"
                  bg="white"
                  c="primary.5"
                >
                  <IconCurrentLocation />
                </ActionIcon>

                <UserPosition position={position} map={map} />
              </>
            ))}
          </>
        )}
      </GoogleMaps>

      {renderNullable(selectedMarker, marker => (
        <AlertReportMapDetail marker={marker} />
      ))}

      <AlertReportsFilters
        filters={filters}
        savedFilters={savedFilters}
        onCreateFilter={createFilter}
        onDeleteFilter={deleteFilter}
      />
    </Page>
  );
};

const alertReportsMapRoute = defineRoute({
  element: <AlertReportsMap />,
  loader,
  actions,
});

export default alertReportsMapRoute;
