import React, { FC, PropsWithChildren } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { AlertTrigger } from '@modules/alert-triggers/model';
import { Select, Stack, Switch, Text, TextInput } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { Measure } from '@modules/measures/model';
import * as A from 'fp-ts/Array';
import UpdateParams = AlertTrigger.UpdateParams;
import ThresholdSelect from '@modules/alert-triggers/components/ThresholdSelect';

interface AlertFormProps {
  types: Array<Measure.Type>;
}

const AlertForm: FC<PropsWithChildren<AlertFormProps>> = ({ children, types }) => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useEnhancedFormContext<UpdateParams>();

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={15}>
        {children}
        <TextInput
          {...register('name')}
          error={!!errors.name}
          label="Nom de l'alerte"
          placeholder="Nom de l'alerte"
          required
        />

        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Select
              {...field}
              label="Type d'alerte"
              placeholder="Type d'alerte"
              disabled={A.isEmpty(types)}
              data={types.map(type => ({ value: type, label: Measure.typeTitle[type] }))}
              error={!!errors.type}
              required
            />
          )}
        />
        <Controller
          control={control}
          name="thresholdLevel"
          render={({ field }) => (
            <ThresholdSelect
              {...field}
              label="Niveau d'alerte"
              placeholder="Niveau d'alerte"
              error={!!errors.thresholdLevel}
              required
            />
          )}
        />
        <Controller
          control={control}
          name="notification.byEmail"
          render={({ field }) => (
            <Switch
              {...field}
              value={undefined}
              checked={field.value}
              label={<Text color="tertiary.8">Notification par e-mail</Text>}
            />
          )}
        />
      </Stack>
    </form>
  );
};

export default AlertForm;
