import axios, { AxiosHeaders } from 'axios';

import { v4 as uuid } from 'uuid';
import { stringifyQueries } from '@shared/utils/queries';
import { TRANSACTION_ID_HEADER_KEY } from '@core/http/model';
import config from '@root/config';
import { flow, pipe } from 'fp-ts/function';
import { OAuthService } from '@core/oauth/service';
import * as TO from 'fp-ts/TaskOption';
import * as T from 'fp-ts/Task';

export const defaultAxiosInstance = axios.create({
  paramsSerializer: { serialize: stringifyQueries },
  withCredentials: true,
  baseURL: import.meta.env.PROD ? config.VITE_API_URL : '/api',
});

defaultAxiosInstance.interceptors.request.use(config => {
  const setAuthorizationHeader = (headers: AxiosHeaders) =>
    pipe(
      OAuthService.getOAuthTokensFromStorage(),
      TO.fold(
        () => T.of(headers),
        ({ access_token }) => T.fromIO(() => headers.setAuthorization(`Bearer ${access_token}`)),
      ),
    );

  const setTransactionId = (headers: AxiosHeaders) => T.fromIO(() => headers.set(TRANSACTION_ID_HEADER_KEY, uuid()));

  const headers = flow(setAuthorizationHeader, T.chain(setTransactionId))(config.headers);

  return pipe(
    headers,
    T.map(headers => ({
      ...config,
      headers,
    })),
  )();
});
