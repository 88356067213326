import { MutableRefObject, useRef, useState } from 'react';
import { SelectReportLocationHandle } from '@modules/diseases/alert-reports/flow/components/location/SelectReportLocation';

export function useReportingGeocoding(): [
  MutableRefObject<SelectReportLocationHandle | null>,
  boolean,
  (address: string) => void,
] {
  const ref = useRef<SelectReportLocationHandle | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const handleGeocode = (address: string) => {
    const geocoder = new google.maps.Geocoder();

    setLoading(true);

    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        ref.current?.setPosition(results[0].geometry.location);
      }

      setLoading(false);
    });
  };

  return [ref, loading, handleGeocode];
}
