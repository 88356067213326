import React, { FC, useCallback } from 'react';
import { renderHttpRemoteData } from '@shared/utils/render';
import * as O from 'fp-ts/Option';
import { Stack, Text } from '@mantine/core';
import { constNull, flow } from 'fp-ts/function';

import { AlertReport } from '@modules/diseases/alert-reports/model';
import { useFetchTask } from '@core/http/hooks';
import * as NEA from 'fp-ts/NonEmptyArray';
import SensorsDataListPlaceholder from '@modules/diseases/alert-reports/analysis/components/sensors/SensorsDataListPlaceholder';
import SensorsDataList from '@modules/diseases/alert-reports/analysis/components/sensors/SensorsDataList';
import SensorsDataChart from '@modules/diseases/alert-reports/analysis/components/sensors/SensorsDataChart';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { StackProps } from '@mantine/core/lib/Stack/Stack';

interface AlertReportAnalysisSensorsProps {
  alertReport: AlertReport.Detail;
  filter: AlertReport.Filter.Analysis;
}

const AlertReportAnalysisSensors: FC<AlertReportAnalysisSensorsProps & StackProps> = ({
  alertReport,
  filter,
  ...rest
}) => {
  const getSensors = useCallback(() => AlertReportsService.Analysis.getSensors(alertReport.id), [alertReport]);

  const [sensors] = useFetchTask(getSensors);

  return (
    <>
      <Stack spacing={18} {...rest}>
        <Text color="dark.5" weight={700} size={18} lh={1.45}>
          Données de sondes une semaine avant le signalement
        </Text>
        {renderHttpRemoteData(
          sensors,
          flow(
            NEA.fromArray,
            O.match(
              () => <SensorsDataListPlaceholder />,
              sensors => <SensorsDataList sensors={sensors} />,
            ),
          ),
        )}
      </Stack>

      {renderHttpRemoteData(
        sensors,
        flow(
          NEA.fromArray,
          O.match(constNull, sensors => (
            <Stack spacing={18} {...rest}>
              <Text color="dark.5" weight={700} size={18} lh={1.45}>
                Représentation des données de sondes
              </Text>
              <SensorsDataChart reportedAt={alertReport.reportedAt} sensors={sensors} filter={filter} />
            </Stack>
          )),
        ),
      )}
    </>
  );
};

export default AlertReportAnalysisSensors;
