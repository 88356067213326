import React, { FC } from 'react';
import { Dropzone } from '@mantine/dropzone';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { pipe } from 'fp-ts/function';
import imageCompression from 'browser-image-compression';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as A from 'fp-ts/Array';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { useSendTask } from '@core/http/hooks';
import { Stack, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

function uploadImagesTask(files: Array<File>) {
  const uploadFile = (file: File) => {
    console.log(file);

    const compressFile = () =>
      imageCompression(file, {
        fileType: 'image/jpeg',
        maxWidthOrHeight: 800,
        initialQuality: 0.75,
      });

    const sendFile = (file: File) =>
      pipe(
        AlertReportsService.uploadAlertImage(file),
        TE.map<AlertReport.Flow.UploadedImageId, AlertReport.Flow.UploadedImage>(id => ({
          type: 'uploaded',
          id,
          file,
          isMain: false,
        })),
      );

    return pipe(compressFile, T.chain(sendFile));
  };

  return pipe(files, A.traverse(TE.ApplicativePar)(uploadFile));
}

interface ReportImageDropZoneProps {
  primary?: boolean;
  onAddImages: (images: Array<AlertReport.Flow.Image>) => void;
}

const ReportImageDropZone: FC<ReportImageDropZoneProps> = ({ primary, onAddImages }) => {
  const [loading, uploadFiles] = useSendTask(uploadImagesTask);

  const handleUpload = (files: Array<File>) => {
    pipe(
      uploadFiles(files),
      TE.chainIOK(images => () => onAddImages(images)),
    )();
  };

  return (
    <Dropzone
      loading={loading}
      multiple={true}
      onDrop={handleUpload}
      onReject={files => console.log('rejected files', files)}
      accept={['image/*']}
      bg={primary ? 'rgba(225, 82, 61, 0.04)' : 'transparent'}
      sx={{
        borderColor: primary ? 'rgba(225, 82, 61, 0.4)' : 'rgba(155, 155, 155, 0.5)',
        color: primary ? '#e1523d' : '#9b9b9b',
      }}
    >
      <Stack align="center" justify="center" spacing={10}>
        <IconPlus size={22} />

        <Text ta="center" fz={9} fw={500}>
          {' '}
          Ajouter une photo
        </Text>
      </Stack>
    </Dropzone>
  );
};

export default ReportImageDropZone;
