import React, { FC, ReactNode, useEffect } from 'react';
import { Card, Stack, Text } from '@mantine/core';
import { renderOptional } from '@shared/utils/render';
import * as A from 'fp-ts/Array';
import * as IOO from 'fp-ts/IOOption';
import { Previsions } from '@shared/modules/previsions/model';
import PestStage from '@modules/diseases/pests/components/PestStage';
import { useLocation } from 'react-router-dom';
import { filterEmptyStringToOption } from '@shared/utils/string';
import { pipe } from 'fp-ts/function';
import { useScrollIntoView } from '@mantine/hooks';
import { HomeUtils } from '@modules/home/utils';

interface DashboardPestProps {
  pest: Previsions.Pest;
}

const HomePest: FC<DashboardPestProps> = ({ pest }) => {
  const location = useLocation();

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>();

  useEffect(() => {
    pipe(
      filterEmptyStringToOption(location.hash),
      IOO.fromOption,
      IOO.chainIOK(() => scrollIntoView),
    )();
  }, [location.hash, scrollIntoView]);

  const risks = HomeUtils.Pest.mergeRisks(pest.risks);

  return (
    <Card
      mah={237}
      withBorder={false}
      radius={10}
      shadow="0 0 12px 0 rgba(0, 0, 0, 0.1)"
      px={20}
      py={15}
      style={{
        overflow: 'auto',
      }}
    >
      {renderOptional<Array<HomeUtils.Pest.MergedRisk>, ReactNode>(
        risks,
        A.mapWithIndex((index, risk) => (
          <Stack
            key={index}
            ref={location.hash === `#${risk.enum}` ? targetRef : null}
            spacing={5}
            py={15}
            sx={{ '&:not(:last-child)': { borderBottom: `1px solid #DADADA` } }}
          >
            <Text c="black" fw={700} fz={16} lh={1.55}>
              {risk.name}
            </Text>
            <Stack spacing={5}>
              {risk.stages.map(({ stage, level }) => (
                <PestStage key={stage} name={stage} level={level} />
              ))}
            </Stack>
          </Stack>
        )),
        () => (
          <Text lh={1.5} c="gray.7">
            Aucun risque de prévu pour cette semaine
          </Text>
        ),
      )}
    </Card>
  );
};

export default HomePest;
