import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import React from 'react';
import sensorsDashboardRoute from '@modules/sensors/DashboardPage';
import sensorsMap from '@modules/sensors/map/MapPage';
import mapDetailRoute from '@modules/sensors/detail/DetailPage';
import alertsRoutes from '@modules/alert-triggers/routes';
import pendingRoutes from '@modules/sensors/pending/routes';

const sensorsRoutes = (
  <Route path="sensors">
    <Route index {...createRoute(sensorsDashboardRoute)} />
    <Route path="map" {...createRoute(sensorsMap)}>
      <Route path=":id" {...createRoute(mapDetailRoute)} />
    </Route>
    {pendingRoutes}
    {alertsRoutes}
  </Route>
);

export default sensorsRoutes;
