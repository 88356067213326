import React, { FC } from 'react';
import { Card, Checkbox, Group, Stack, Text } from '@mantine/core';

import { Charts } from '@shared/modules/charts/model';
import { Measure } from '@modules/measures/model';
import { Sensor } from '@modules/sensors/model';

import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';

import identifierLabel = Sensor.Probe.identifierLabel;
import Identifier = Sensor.Probe.Identifier;
import Type = Measure.Type;

type SelectionFormat = `${Identifier}.${Type}`;

interface SensorMeasureSelectionProps {
  measures: Measure.History<Sensor.Type>;
  selection: Array<SelectionFormat>;
  setSelection(selection: Array<SelectionFormat>): void;
}

const SensorMeasureSelection: FC<SensorMeasureSelectionProps> = ({ measures, selection, setSelection }) => {
  const handleCheckboxChange = (selection: Array<SelectionFormat>) => setSelection(selection);

  return (
    <Group align="start">
      {pipe(
        R.toEntries(measures),
        A.filter(([, values]) => A.isNonEmpty(values)),
        A.map(([identifier, values]) => (
          <Card
            key={identifier}
            py={10}
            px={14}
            withBorder={false}
            sx={theme => ({ border: `1px solid ${theme.colors.tertiary[2]}` })}
          >
            <Text color="dark.1" size={10} pb={10}>
              {identifierLabel[identifier]}
            </Text>
            <Checkbox.Group value={selection} onChange={handleCheckboxChange}>
              <Stack>
                {values.map(({ type }) => (
                  <Checkbox
                    key={type}
                    value={`${identifier}.${type}`}
                    color="red"
                    styles={{
                      label: { paddingLeft: 8 },
                      input: {
                        borderColor: `${Charts.Line.sensorDataColor[`${identifier}.${type}`]} !important`,
                        ':checked': {
                          backgroundColor: Charts.Line.sensorDataColor[`${identifier}.${type}`],
                        },
                      },
                    }}
                    data-color={Charts.Line.sensorDataColor[`${identifier}.${type}`].split('.')[0]}
                    label={
                      <Text color="dark.5" size={12}>
                        {Measure.typeTitle[type]}
                      </Text>
                    }
                  />
                ))}
              </Stack>
            </Checkbox.Group>
          </Card>
        )),
      )}
    </Group>
  );
};

export default SensorMeasureSelection;
