import React, { FC, useMemo } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import AlertReportDetailImages from '@modules/diseases/alert-reports/detail/components/AlertReportDetailImages';
import AlertReportDetailHeader from '@modules/diseases/alert-reports/detail/components/AlertReportDetailHeader';
import { Stack } from '@mantine/core';
import AlertReportAnalysisCommentsCards from '@modules/diseases/alert-reports/analysis/components/AlertReportAnalysisCommentsCards';
import AlertReportAnalysisWeather from '@modules/diseases/alert-reports/analysis/components/AlertReportAnalysisWeather';
import AlertReportAnalysisDisease from '@modules/diseases/alert-reports/analysis/components/AlertReportAnalysisDisease';
import AlertReportAnalysisSensors from '@modules/diseases/alert-reports/analysis/components/AlertReportAnalysisSensors';
import { useLocation } from 'react-router-dom';
import { parseQueries } from '@shared/utils/queries';
import { AlertReportUtils } from '../../utils';

interface AlertReportAnalysisContentProps {
  alertReport: AlertReport.Detail;
}

const AlertReportAnalysisContent: FC<AlertReportAnalysisContentProps> = ({ alertReport }) => {
  const location = useLocation();

  const filter = useMemo(
    () => AlertReportUtils.alertReportFilterParser(parseQueries(location.search)),
    [location.search],
  );

  return (
    <>
      <AlertReportDetailImages alertReport={alertReport} />
      <AlertReportDetailHeader alertReport={alertReport} />

      <Stack spacing={20} pt={8}>
        <AlertReportAnalysisCommentsCards alertReport={alertReport} px={20} />
        <AlertReportAnalysisWeather alertReport={alertReport} />
        <AlertReportAnalysisDisease alertReport={alertReport} />
        <AlertReportAnalysisSensors alertReport={alertReport} filter={filter} px={20} />
      </Stack>
    </>
  );
};

export default AlertReportAnalysisContent;
