import styled from '@emotion/styled';

export const NavListing = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0;
  margin: 0;

  li {
    width: 65px;
    list-style: none;
  }

  .mantine-NavLink-label {
    color: ${({ theme }) => theme.white};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ theme }) => theme.white};
    opacity: 0.4;

    &.active {
      opacity: 1;
    }

    :hover {
      background: inherit;
    }

    > span {
      margin: 0;
    }
  }
`;

export const NavPlatformReturn = styled.li`
  width: 55px !important;
  margin-bottom: auto;
  transform: translateY(-10px);

  > a {
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    box-sizing: border-box;
    background: ${({ theme }) => theme.colors.primary[5]} !important;

    img {
      margin-top: 2px;
    }
  }
`;
