import { HubApi } from '@modules/iot/model';
import { Utils } from '@shared/utils/model';
import { Measure } from '@modules/measures/model';
import { Sensoterra } from '@modules/sensors/sensoterra/model';
import { Hortee } from '@modules/sensors/hortee/model';
import { Sinafis } from '@modules/sensors/sinafis/model';
import z from 'zod';
import { Sensor } from '@modules/sensors/model';
import { SensorsSchema } from '@modules/sensors/schema';
import { HorteeSchema } from '@modules/sensors/hortee/schema';
import { SinafisSchema } from '@modules/sensors/sinafis/schema';
import { SensoterraSchema } from '@modules/sensors/sensoterra/schema';

export namespace PendingSensor {
  import registerPendingSchema = SensorsSchema.registerPendingSchema;
  import registerHorteeSchema = HorteeSchema.registerHorteeSchema;
  import registerSinafisSchema = SinafisSchema.registerSinafisSchema;
  import registerSensoterraSchema = SensoterraSchema.registerSensoterraSchema;

  export type RemoteConfig<Type extends Sensor.Type> = {
    [Sensor.Type.Sensoterra]: Sensoterra.Config.Remote;
    [Sensor.Type.Hortee]: Hortee.Config.Remote;
    [Sensor.Type.Sinafis]: Sinafis.Config.Remote;
  }[Type];

  export namespace List {
    export interface Item {
      technicalId: HubApi.SensorId;
      type: Sensor.Type;
      serial: Sensor.Serial;
    }
  }

  export type List = Array<List.Item>;

  export type RegisterParams = z.infer<typeof registerPendingSchema>;

  export const typeRegisterSchema = {
    [Sensor.Type.Sensoterra]: registerSensoterraSchema,
    [Sensor.Type.Sinafis]: registerSinafisSchema,
    [Sensor.Type.Hortee]: registerHorteeSchema,
  } satisfies Record<Sensor.Type, typeof registerPendingSchema>;

  export interface RegisterSensorOut {
    id: Sensor.Id;
  }
}

export interface PendingSensor<Type extends Sensor.Type = Sensor.Type> {
  technicalId: HubApi.SensorId;
  type: Type;
  serial: Sensor.Serial;
  location: Utils.GPSCoordinates | null;
  config: PendingSensor.RemoteConfig<Type>;
  measures: Measure.Last.Impl<Type> | null;
}
