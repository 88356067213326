import React, { FC } from 'react';
import { Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DateFormat, formatDate } from '@shared/modules/dates';
import { useTheme } from '@emotion/react';
import * as A from 'fp-ts/Array';
import * as S from 'fp-ts/string';
import { NumberUtils } from '@shared/utils/number';
import formatPercent = NumberUtils.formatPercent;
import { pipe } from 'fp-ts/function';
import { Charts } from '@shared/modules/charts/model';
import SensorData = Charts.Line.SensorData;
import CustomTooltip from '@shared/modules/charts/line/CustomTooltip';
import { Sensor } from '@modules/sensors/model';
import Identifier = Sensor.Probe.Identifier;
import { Measure } from '@modules/measures/model';
import { LastMeasureUtils } from '@modules/measures/last/utils';
import identifierLabel = Sensor.Probe.identifierLabel;

const typeYAxis: Record<Measure.History.Type, 'left' | 'right' | 'hidden'> = {
  battery: 'right',
  humidity: 'right',
  temperature: 'left',
  nutrition: 'hidden',
};

interface SimpleLineChartProps {
  data: Array<SensorData>;
  dateFormatter(date: Date): string;
}

const defaultMargin = { bottom: 0, left: 0, right: 0, top: 20 };
const percentageTicks = A.makeBy(11, i => i * 0.1);
const temperatureTicks = A.makeBy(11, i => i * 5 - 10);

const SimpleLineChart: FC<SimpleLineChartProps> = ({ data, dateFormatter }) => {
  const theme = useTheme();

  const keys = pipe(
    data,
    A.chain(({ date, ...keys }) => Object.keys(keys)),
    A.uniq(S.Eq),
    A.map(s => s.split('.') as [Identifier, Measure.History.Type]),
  );

  return (
    <ResponsiveContainer height={244}>
      <LineChart data={data} margin={defaultMargin}>
        <XAxis
          fontSize={8}
          color={theme.colors.dark[0]}
          dataKey="date"
          type="category"
          tickLine={false}
          tickFormatter={dateFormatter}
        />
        <YAxis
          yAxisId="left"
          fontSize={8}
          color={theme.colors.dark[0]}
          width={30}
          tickLine={false}
          axisLine={false}
          interval="preserveStartEnd"
          ticks={temperatureTicks}
          tickFormatter={value => `${value}°C`}
        >
          <Label value="Température en C°" fontSize={8} offset={15} position="top" dx={25} />
        </YAxis>
        <YAxis
          yAxisId="right"
          fontSize={8}
          color={theme.colors.dark[0]}
          width={30}
          tickLine={false}
          axisLine={false}
          interval="preserveStartEnd"
          ticks={percentageTicks}
          orientation="right"
          tickFormatter={formatPercent}
        >
          <Label value="En %" fontSize={8} offset={15} position="top" />
        </YAxis>
        <YAxis yAxisId="hidden" hide />
        <Tooltip
          formatter={(value, name, item, index, payload) => {
            const [, type] = (payload[index].dataKey ?? '').toString().split('.') as [Identifier, Measure.History.Type];
            return LastMeasureUtils.formatter(value as Measure.Value, type);
          }}
          labelFormatter={date => formatDate(date, DateFormat.LocalDateTime)}
          separator=" "
          content={CustomTooltip}
        />
        {keys.map(([identifier, type]) => (
          <Line
            key={`${identifier}.${type}`}
            dataKey={`${identifier}.${type}`}
            yAxisId={typeYAxis[type]}
            name={`${identifierLabel[identifier]} - ${Measure.typeTitle[type]}`}
            stroke={Charts.Line.sensorDataColor[`${identifier}.${type}`]}
            activeDot={{ fill: Charts.Line.sensorDataColor[`${identifier}.${type}`], r: 4 }}
            dot={false}
            connectNulls
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SimpleLineChart;
