import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import mapBackground from '@assets/images/alert-report-map.png';

export const DiseasesDashboardMapLink = styled(Link)`
  border-radius: 10px;
  background: url(${mapBackground}) no-repeat center center;
  background-size: cover;
  text-decoration: none;
`;
