import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { AspectRatio, Box, Card, Text } from '@mantine/core';
import AlertReportTypeDetail from '@modules/diseases/alert-reports/detail/components/AlertReportTypeDetail';

interface ReportFormHeaderProps {
  category: AlertReport.Category;
  subCategory: AlertReport.SubCategory;
  type: AlertReport.Type;
}

const ReportFormHeader: FC<ReportFormHeaderProps> = ({ type, subCategory, category }) => {
  return (
    <>
      <Box bg="white">
        <AspectRatio ratio={375 / 179}>
          <img src={type.images.bound_512x512} alt={type.label} />
        </AspectRatio>

        <Box p={20}>
          <Text size={17} c="tertiary.8" fw={600}>
            Nature du signalement
          </Text>

          <Card
            mt={20}
            radius={10}
            bg="rgba(232, 147, 63, 0.15)"
            fz={12}
            c="tertiary.8"
            ta="center"
            sx={{ borderColor: 'rgba(232, 147, 63, 0.4) !important' }}
          >
            {category.label} / {subCategory.label} / {type.label}
          </Card>

          <AlertReportTypeDetail type={type} />
        </Box>
      </Box>

      <Box
        h={10}
        sx={{
          opacity: 0.1,
          backgroundImage: 'linear-gradient(to top, #f5f4f2, #363636)',
        }}
      />
    </>
  );
};

export default ReportFormHeader;
