import React from 'react';
import { Button, Modal, Stack, Text, TextInput, UnstyledButton } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { NonEmptyString } from '@shared/schemas';
import { HttpTask } from '@core/http';
import { useSendTask } from '@core/http/hooks';

const Params = z.object({ name: NonEmptyString });
type Params = z.infer<typeof Params>;

interface CreateFilterModalProps<F> {
  opened: boolean;
  filter: F;
  onClose(): void;
  onCreate(name: string, filter: F): HttpTask<F>;
  onFiltersChange(filter: F): void;
}

function CreateFilterModal<F>({ opened, filter, onClose, onCreate, onFiltersChange }: CreateFilterModalProps<F>) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Params>({
    resolver: zodResolver(Params),
  });

  const [loading, create] = useSendTask(onCreate);

  const onSubmit = ({ name }: Params) =>
    pipe(
      create(name, filter),
      TE.chainIOK(filter => () => {
        onFiltersChange(filter);
        onClose();
        reset();
      }),
    )();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      styles={{
        overlay: { zIndex: 201 },
        close: { svg: { minWidth: 26, height: 26, strokeWidth: 1, color: 'black' } },
        body: { padding: '0 23px 35px' },
        header: { padding: '35px 23px 15px' },
      }}
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('name')}
          error={!!errors.name}
          label="Enregistrer le filtre"
          placeholder="Nom du filtre"
          required
        />
        <Stack spacing={15} align="center" pt={30}>
          <Button tt="uppercase" color="primary.5" type="submit" loading={loading}>
            Enregistrer
          </Button>
          <UnstyledButton onClick={onClose}>
            <Text td="underline" size={12} lh={1.33} weight={500} color="tertiary.8">
              Annuler
            </Text>
          </UnstyledButton>
        </Stack>
      </form>
    </Modal>
  );
}

export default CreateFilterModal;
