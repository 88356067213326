import React, { FC } from 'react';
import { defineRoute, preventActionLeave } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import Page from '@layout/page/Page';
import AlertReportDetailTop from '@modules/diseases/alert-reports/detail/components/AlertReportDetailTop';
import AlertReportDetailImages from '@modules/diseases/alert-reports/detail/components/AlertReportDetailImages';
import AlertReportDetailHeader from '@modules/diseases/alert-reports/detail/components/AlertReportDetailHeader';
import AlertReportDetailContent from '@modules/diseases/alert-reports/detail/components/AlertReportDetailContent';
import { defineAction, useAction } from '@core/router/action';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { useNavigate } from 'react-router-dom';

const params = z.object({
  id: AlertReport.Id,
});

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(AlertReportsService.getAlertDetail(params.id)),
});

const actions = {
  delete: defineAction({
    type: 'delete',
    params,
    handler: ({ params }) => AlertReportsService.deleteAlert(params.id),
  }),
};

const AlertReportDetail: FC = () => {
  const alertReport = useLoader<typeof loader>();

  const navigate = useNavigate();

  const [deleteLoading, deleteAlert] = useAction(actions.delete);

  const handleDelete = () =>
    pipe(
      deleteAlert(),
      TE.chainIOK(() => () => navigate('/diseases/alert-reports')),
    )();

  return (
    <Page p={0} withNav seoTitle={alertReport.type.label}>
      <AlertReportDetailTop alertReport={alertReport} deleteLoading={deleteLoading} handleDelete={handleDelete} />
      <AlertReportDetailImages alertReport={alertReport} />
      <AlertReportDetailHeader alertReport={alertReport} />

      <AlertReportDetailContent alertReport={alertReport} />
    </Page>
  );
};

const alertReportDetailRoute = defineRoute({
  element: <AlertReportDetail />,
  loader,
  actions,
  shouldRevalidate: preventActionLeave<typeof actions>('delete'),
});

export default alertReportDetailRoute;
