import { pipe, tuple } from 'fp-ts/function';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as R from 'fp-ts/Record';
import * as Ord from 'fp-ts/Ord';
import { Previsions } from '@shared/modules/previsions/model';
import * as A from 'fp-ts/Array';
import * as N from 'fp-ts/number';
import * as S from 'fp-ts/string';
import { Pest as PestModel } from '@modules/diseases/pests/model';

export namespace HomeUtils {
  export namespace Pest {
    import levelOrd = PestModel.Risk.levelOrd;
    interface MergedStage {
      stage: PestModel.Stage;
      level: PestModel.Risk.Level;
    }

    export interface MergedRisk {
      name: string;
      enum: PestModel.Name;
      stages: NEA.NonEmptyArray<MergedStage>;
    }

    const riskOrd: Ord.Ord<MergedRisk> = Ord.fromCompare((first, second) => {
      const ord = Ord.tuple(Ord.reverse(A.getOrd(N.Ord)), S.Ord);
      const levels = pipe(Object.values(PestModel.Risk.Level), A.sort(Ord.reverse(levelOrd))); // compare high level first
      const getLevels = (
        risk: MergedRisk, // get numbers of each level
      ) => levels.map(level => risk.stages.filter(stage => stage.level === level).length);

      const getTuple = (risk: MergedRisk) => tuple(getLevels(risk), risk.name);

      return ord.compare(getTuple(first), getTuple(second)); // compare number of each level from high to low
    });

    const stageOrd: Ord.Ord<MergedStage> = Ord.fromCompare((first, second) => {
      const ord = Ord.tuple(Ord.reverse(levelOrd), S.Ord);
      const getTuple = ({ level, stage }: MergedStage) => tuple(level, stage);
      return ord.compare(getTuple(first), getTuple(second));
    });

    export function mergeRisks(risks: Array<Previsions.Pest.Risk>) {
      return pipe(
        risks,
        NEA.groupBy(risk => risk.enum),
        R.reduce(Ord.trivial)<NEA.NonEmptyArray<Previsions.Pest.Risk>, Array<MergedRisk>>([], (acc, risks) => {
          const { enum: riskEnum, name } = NEA.last(risks); // enum and name should be the same on all elements

          const stages = pipe(
            risks,
            NEA.map(({ stage, level }) => ({ stage, level })),
            NEA.sort(stageOrd),
          );
          return pipe(acc, A.append({ enum: riskEnum, name, stages }));
        }),
        A.sort(riskOrd),
        NEA.fromArray,
      );
    }
  }
}
