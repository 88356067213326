import { FunctionComponent, SVGProps } from 'react';
import { MantineColor } from '@mantine/core';

import { ReactComponent as IconCloudy } from '@assets/icons/weather/cloudy.svg';
import { ReactComponent as IconCloudyGusts } from '@assets/icons/weather/cloudy-gusts.svg';
import { ReactComponent as IconShowers } from '@assets/icons/weather/showers.svg';
import { ReactComponent as IconRainMix } from '@assets/icons/weather/rain-mix.svg';
import { ReactComponent as IconRain } from '@assets/icons/weather/rain.svg';
import { ReactComponent as IconSleet } from '@assets/icons/weather/sleet.svg';
import { ReactComponent as IconSnow } from '@assets/icons/weather/snow.svg';
import { ReactComponent as IconDayCloudy } from '@assets/icons/weather/day-cloudy.svg';
import { ReactComponent as IconDayCloudyGusts } from '@assets/icons/weather/day-cloudy-gusts.svg';
import { ReactComponent as IconDayRain } from '@assets/icons/weather/day-rain.svg';
import { ReactComponent as IconDayWindy } from '@assets/icons/weather/day-windy.svg';
import { ReactComponent as IconHot } from '@assets/icons/weather/hot.svg';
import { ReactComponent as IconDaySunny } from '@assets/icons/weather/day-sunny.svg';
import { Geo } from '@shared/modules/geo/model';
import { Zone } from '@modules/iot/zones/model';
import { LocalDate } from '@shared/modules/dates';
import { Utils } from '@shared/utils/model';
import { Pest as PestModel } from '@modules/diseases/pests/model';
import * as Eq from 'fp-ts/Eq';

export namespace Previsions {
  import GPSCoordinates = Utils.GPSCoordinates;
  export namespace Weather {
    export enum Type {
      Cloudy = 'f013',
      CloudyGusts = 'f011',
      Showers = 'f01a',
      RainMix = 'f017',
      Rain = 'f019',
      Sleet = 'f0b5',
      Snow = 'f01b',
      DayCloudy = 'f002',
      DayCloudyDusts = 'f000',
      DayRain = 'f008',
      DayWindy = 'f085',
      Hot = 'f072',
      DaySunny = 'f00d',
    }

    export interface TypeInfo {
      label: string;
      icon: FunctionComponent<SVGProps<SVGSVGElement>>;
      color: MantineColor;
    }

    export const typeInfos: Record<Type, TypeInfo> = {
      [Type.Cloudy]: {
        label: 'Nuageux',
        icon: IconCloudy,
        color: 'blue.3',
      },
      [Type.CloudyGusts]: {
        label: 'Nuageux et venteux',
        icon: IconCloudyGusts,
        color: 'blue.3',
      },
      [Type.Showers]: {
        label: 'Couvert, pluie occationnelle',
        icon: IconShowers,
        color: 'blue.3',
      },
      [Type.RainMix]: {
        label: 'Pluvieux',
        icon: IconRainMix,
        color: 'blue.3',
      },
      [Type.Rain]: {
        label: 'Orageux',
        icon: IconRain,
        color: 'blue.3',
      },
      [Type.Sleet]: {
        label: 'Bruine',
        icon: IconSleet,
        color: 'blue.3',
      },
      [Type.Snow]: {
        label: 'Neige',
        icon: IconSnow,
        color: 'blue.3',
      },
      [Type.DayCloudy]: {
        label: 'Partiellement nuageux',
        icon: IconDayCloudy,
        color: 'yellow.5',
      },
      [Type.DayCloudyDusts]: {
        label: 'Venteux avec quelques nuages',
        icon: IconDayCloudyGusts,
        color: 'blue.3',
      },
      [Type.DayRain]: {
        label: 'Partiellement nuageux avec quelques averses',
        icon: IconDayRain,
        color: 'blue.3',
      },
      [Type.DayWindy]: {
        label: 'Venteux / vent fort',
        icon: IconDayWindy,
        color: 'yellow.5',
      },
      [Type.Hot]: {
        label: 'Vague de chaleur',
        icon: IconHot,
        color: 'yellow.5',
      },
      [Type.DaySunny]: {
        label: 'Ensoleillé',
        icon: IconDaySunny,
        color: 'yellow.5',
      },
    };
    export namespace DayForecast {
      export enum Param {
        MinTemp = 'T2M_MIN',
        MaxTemp = 'T2M_MAX',
        AverageTemp = 'T2M_MEAN',
        MinHumidity = 'RH2M_MIN',
        MaxHumidity = 'RH2M_MAX',
        AverageHumidity = 'RH2M_MEAN',
        MinWind = 'WS2M_MIN',
        MaxWind = 'WS2M_MAX',
        AverageWind = 'WS2M_MEAN',
        Weather = 'SW_ID',
        ETP = 'ETP',
        Precipitation = 'PRECIP_SUM',
        MinDewPoint = 'DEWT2M_MIN',
        MaxDewPoint = 'DEWT2M_MAX',
        AverageDewPoint = 'DEWT2M_MEAN',
      }

      export const KnownParam = {
        MinTemp: Param.MinTemp,
        MaxTemp: Param.MaxTemp,
        AverageTemp: Param.AverageTemp,
        MinHumidity: Param.MinHumidity,
        MaxHumidity: Param.MaxHumidity,
        MinWind: Param.MinWind,
        MaxWind: Param.MaxWind,
        AverageWind: Param.AverageWind,
        ETP: Param.ETP,
        Precipitation: Param.Precipitation,
        AverageDewPoint: Param.AverageDewPoint,
      } satisfies Partial<{
        [key in keyof typeof Param]: (typeof Param)[key];
      }>;

      export type KnownParam = (typeof KnownParam)[keyof typeof KnownParam];

      export const paramLabel: Record<Param, string> = {
        [Param.MinTemp]: 'T2M min',
        [Param.MaxTemp]: 'T2M max',
        [Param.AverageTemp]: 'T2M mean',
        [Param.MinHumidity]: 'RH2M min',
        [Param.MaxHumidity]: 'RH2M max',
        [Param.AverageHumidity]: 'RH2M mean',
        [Param.MinWind]: 'WS2M min',
        [Param.MaxWind]: 'WS2M max',
        [Param.AverageWind]: 'WS2M mean',
        [Param.Weather]: 'Weather',
        [Param.ETP]: 'ETP',
        [Param.Precipitation]: 'PRECIP sum',
        [Param.MinDewPoint]: 'PR min',
        [Param.MaxDewPoint]: 'PR max',
        [Param.AverageDewPoint]: 'PR',
      };

      export const paramDescription: Record<Param, string> = {
        [Param.MinTemp]: 'Température min à 2 mètres (en Degré Celsius)',
        [Param.MaxTemp]: 'Température max à 2 mètres (en Degré Celsius)',
        [Param.AverageTemp]: 'Température moy à 2 mètres (en Degré Celsius)',
        [Param.MinHumidity]: 'Humidité relative min à 2 mètres (centième de pourcentage)',
        [Param.MaxHumidity]: 'Humidité relative max à 2 mètres (centième de pourcentage)',
        [Param.AverageHumidity]: 'Humidité relative moy à 2 mètres (centième de pourcentage)',
        [Param.MinWind]: 'Vitesse du vent min à 2 mètres (en m/s)',
        [Param.MaxWind]: 'Vitesse du vent max à 2 mètres (en m/s)',
        [Param.AverageWind]: 'Vitesse du vent moy à 2 mètres (en m/s)',
        [Param.Weather]: 'Météo',
        [Param.ETP]: 'Evapotranspiration (en Mm)',
        [Param.Precipitation]: 'Cumul des précipitations pour la journée (en Mm)',
        [Param.MinDewPoint]: 'Point de rosé min (en Degré Celsius)',
        [Param.MaxDewPoint]: 'Point de rosé max (en Degré Celsius)',
        [Param.AverageDewPoint]: 'Point de rosé (en Degré Celsius)',
      };

      export const paramOrdering: Record<Param, number> = {
        [Param.Weather]: -1,
        [Param.MinTemp]: 1,
        [Param.MaxTemp]: 2,
        [Param.AverageTemp]: 3,
        [Param.MinHumidity]: 4,
        [Param.MaxHumidity]: 5,
        [Param.AverageHumidity]: 6,
        [Param.MinWind]: 7,
        [Param.MaxWind]: 8,
        [Param.AverageWind]: 9,
        [Param.ETP]: 10,
        [Param.Precipitation]: 11,
        [Param.MinDewPoint]: 12,
        [Param.MaxDewPoint]: 13,
        [Param.AverageDewPoint]: 14,
      };

      export const paramUnit: Record<Param, string> = {
        [Param.MinTemp]: '°C',
        [Param.MaxTemp]: '°C',
        [Param.AverageTemp]: '°C',
        [Param.MinHumidity]: '',
        [Param.MaxHumidity]: '',
        [Param.AverageHumidity]: '',
        [Param.MinWind]: 'm/s',
        [Param.MaxWind]: 'm/s',
        [Param.AverageWind]: 'm/s',
        [Param.Weather]: '',
        [Param.ETP]: 'mm',
        [Param.Precipitation]: 'mm',
        [Param.MinDewPoint]: '°C',
        [Param.MaxDewPoint]: '°C',
        [Param.AverageDewPoint]: '°C',
      };
    }

    export type ForecastValues = {
      [Param in DayForecast.Param]?: number;
    };

    export interface DayForecast {
      date: LocalDate;
      values: ForecastValues;
    }
  }

  export interface Weather {
    today: Weather.ForecastValues;
    forecast: Array<Weather.DayForecast>;
  }

  export interface LocationFilter {
    type: 'location';
    location: Geo.City;
  }

  export interface ZoneFilter {
    type: 'zone';
    zoneId: Zone.Id;
  }

  export type Filter = LocationFilter | ZoneFilter;

  export namespace Disease {
    export enum Level {
      Low = 'low',
      Medium = 'medium',
      High = 'high',
    }

    export namespace Level {
      export const color: Record<Level, MantineColor> = {
        [Level.Low]: 'yellow.5',
        [Level.Medium]: 'primary.4',
        [Level.High]: 'primary',
      };

      export const nullableColor: MantineColor = 'green';

      export const text: Record<Level, string> = {
        [Level.Low]: 'Moyen',
        [Level.Medium]: 'Fort',
        [Level.High]: 'Très important',
      };

      export const nullableText = 'Faible';
    }

    export enum Model {
      DollarSpot = 'dollar-spot',
      ColdFusarium = 'cold-fusarium',
      LWD = 'lwd',
    }

    export const modelLabel: Record<Model, string> = {
      [Model.ColdFusarium]: 'Fusariose froide',
      [Model.DollarSpot]: 'Dollar Spot',
      [Model.LWD]: 'Humidité Foliaire',
    };

    export const modelEq: Eq.Eq<Model> = Eq.eqStrict;

    export namespace Forecast {
      export interface Value {
        score: number;
        level: Level | null;
      }
    }

    export type Forecast = {
      [Model in Disease.Model]?: Forecast.Value;
    };

    export interface DayForecast {
      date: LocalDate;
      values: Forecast;
    }
  }

  export interface Disease {
    today: Disease.Forecast;
    forecast: Array<Disease.DayForecast>;
  }

  export interface Context {
    filter: Previsions.Filter;
    coordinates: GPSCoordinates;
    location: Geo.City;
  }

  export namespace Pest {
    export interface Risk {
      name: string;
      enum: PestModel.Name;
      stage: PestModel.Stage;
      level: PestModel.Risk.Level;
    }
  }

  export interface Pest {
    dateRange: PestModel.Description.DateRange;
    risks: Array<Pest.Risk>;
  }
}

export interface Previsions {
  filter: Previsions.Filter;
  weather: Previsions.Weather;
  disease: Previsions.Disease;
}
