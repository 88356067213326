import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import DiseasesDashboardLinks from '@modules/diseases/components/dashboard/links/DiseasesDashboardLinks';
import { Box, Stack } from '@mantine/core';
import { useProfile } from '@modules/profile/loader';
import DashboardContent from '@modules/diseases/components/dashboard/DashboardContent';
import DashboardPlaceholder from '@modules/diseases/components/dashboard/DashboardPlaceholder';

const DiseasesDashboardPage: FC = () => {
  const { isAccessLimited } = useProfile().subscription;

  const pageProps: PageProps = {
    seoTitle: 'Epidémio-surveillance',
    back: {
      to: '/',
      title: 'Epidémio-surveillance',
    },
    withNav: true,
  };

  return (
    <Page {...pageProps} px={0}>
      <Box maw={430} mx="auto">
        <Stack spacing={25} px={20}>
          <DiseasesDashboardLinks />
          {!isAccessLimited && <DashboardContent />}
        </Stack>

        {isAccessLimited && <DashboardPlaceholder />}
      </Box>
    </Page>
  );
};

const diseasesDashboardRoute = defineRoute({
  element: <DiseasesDashboardPage />,
});

export default diseasesDashboardRoute;
