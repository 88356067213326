import React, { FC } from 'react';
import { Box, Group, ScrollArea, Skeleton, Stack } from '@mantine/core';
import * as A from 'fp-ts/Array';

const WeatherSkeleton: FC = () => {
  return (
    <Stack mt={20} spacing={20}>
      <Box px={20}>
        <Skeleton radius={8} h={241} style={{ gridColumn: 'span 3' }} />
      </Box>
      <ScrollArea>
        <Group spacing={10} px={20} noWrap>
          {A.makeBy(6, index => (
            <Skeleton key={index} radius={8} h={246} w={128} />
          ))}
        </Group>
      </ScrollArea>
    </Stack>
  );
};

export default WeatherSkeleton;
