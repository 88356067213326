import React, { FC, PropsWithChildren } from 'react';
import { Link, To } from 'react-router-dom';
import { ReportFlowType } from '@modules/diseases/alert-reports/flow';
import Page from '@layout/page/Page';
import { ActionIcon, Button, Group, Modal, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { renderNullable } from '@shared/utils/render';
import { IconArrowLeft, IconX } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useIdParam } from '@core/router';
import { ReactComponent as BullhornIcon } from '@assets/icons/bullhorn.svg';

interface ReportFlowLayoutProps {
  title: string;
  type: ReportFlowType;
  back?: To;
  withClose?: boolean;
  withNav?: boolean;
}

const ReportFlowLayout: FC<PropsWithChildren<ReportFlowLayoutProps>> = ({
  title,
  type,
  back,
  withClose,
  withNav,
  children,
}) => {
  const id = useIdParam();

  const [preventLeaveOpened, preventLeaveTriggers] = useDisclosure();

  return (
    <Page p={0} seoTitle={type === 'create' ? 'Nouveau signalement' : 'Modifier le signalement'} withNav={withNav}>
      <Group
        pos="sticky"
        top={0}
        spacing={5}
        bg="tertiary.8"
        h={63}
        px={20}
        style={{ flex: '0 0 auto', zIndex: 10000 }}
        noWrap
      >
        {renderNullable(back, back => (
          <ActionIcon component={Link} to={back} replace relative="path" variant="transparent" c="white">
            <IconArrowLeft size={22} />
          </ActionIcon>
        ))}

        <Title
          size={17}
          color="white"
          weight={600}
          sx={{ flex: '1 1 auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {title}
        </Title>

        {withClose ? (
          <ActionIcon variant="transparent" c="white" onClick={preventLeaveTriggers.open}>
            <IconX size={22} />
          </ActionIcon>
        ) : null}
      </Group>

      {children}

      <Modal opened={preventLeaveOpened} onClose={preventLeaveTriggers.close} centered withCloseButton={false}>
        <Stack p={10} align="center" justify="center" ta="center">
          <ThemeIcon size={146} radius={100} bg="#f5f4f2" c="#C3BBB2">
            <BullhornIcon width={94} height={94} strokeWidth={1} />
          </ThemeIcon>

          <Text c="tertiary.8">
            {type === 'create'
              ? 'Vous souhaitez annuler votre signalement, êtes-vous sûr ?'
              : 'Vous souhaitez annuler la modification de votre signalement, êtes-vous sûr ?'}
          </Text>

          <Button onClick={preventLeaveTriggers.close} mt={10} tt="uppercase">
            {type === 'create' ? 'Je poursuis le signalement' : 'Je poursuis la modification'}
          </Button>

          <Button
            component={Link}
            to={type === 'create' ? '/diseases' : `/diseases/alert-reports/${id}`}
            relative="path"
            tt="uppercase"
            variant="outline"
          >
            {type === 'create' ? "J'annule mon signalement" : "J'annule la modification"}
          </Button>
        </Stack>
      </Modal>
    </Page>
  );
};

export default ReportFlowLayout;
