import React, { forwardRef, useDeferredValue, useEffect, useState } from 'react';
import { constTrue, constVoid, flow, pipe } from 'fp-ts/function';
import { Loader, Select, SelectProps } from '@mantine/core';
import { Geo } from '@shared/modules/geo/model';
import { Utils } from '@shared/utils/model';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as A from 'fp-ts/Array';
import * as TO from 'fp-ts/TaskOption';
import { GeoService } from '@shared/modules/geo/service';
import { filterEmptyStringToOption } from '@shared/utils/string';

interface SearchGeoCityProps extends Partial<Omit<SelectProps, 'value' | 'onChange'>> {
  value?: Geo.City | null;
  onChange?: (city: Geo.City | null) => void;
}

type CityDataValue = `${Geo.City.INSEECode}-${Utils.PostalCode}`;

function geoCityToCityDataValue(geoCity?: Geo.City | null): CityDataValue | null {
  return geoCity ? `${geoCity.code}-${geoCity.postalCode}` : null;
}

function getCityToCityDataLabel(geoCity?: Geo.City | null): string | null {
  return geoCity ? `${geoCity.postalCode}, ${geoCity.city}` : null;
}

const SearchGeoCity = forwardRef<HTMLInputElement, SearchGeoCityProps>(
  ({ value, onChange = constVoid, ...props }, ref) => {
    const [search, setSearch] = useDebouncedState<string | null>(getCityToCityDataLabel(value), 400);
    const [l, { open: setLoading, close: unsetLoading }] = useDisclosure(false);
    const loading = useDeferredValue(l);
    const [cities, setCities] = useState<Array<Geo.City>>(() =>
      pipe(
        O.fromNullable(value),
        O.fold(
          () => [],
          city => [city],
        ),
      ),
    );

    useEffect(() => {
      pipe(
        filterEmptyStringToOption(search),
        TO.fromOption,
        TO.chainFirstIOK(() => setLoading),
        TO.chainTaskK(
          flow(
            GeoService.getCities,
            TE.chainIOK(cities => () => setCities(cities)),
          ),
        ),
        T.chainFirstIOK(() => unsetLoading),
      )();
    }, [search, setLoading, unsetLoading]);

    const citiesData = pipe(
      cities,
      A.map(city => ({
        value: geoCityToCityDataValue(city) ?? '',
        label: getCityToCityDataLabel(city) ?? '',
      })),
    );

    const handleCityChange = (value: CityDataValue | null) => {
      const city = pipe(
        O.fromNullable(value),
        O.map(value => value.split('-') as [Geo.City.INSEECode, Utils.PostalCode]),
        O.chain(([code, postalCode]) =>
          pipe(
            cities,
            A.findFirst(city => city.code === code && city.postalCode === postalCode),
          ),
        ),
        O.toNullable,
      );
      onChange(city);
    };

    return (
      <Select
        ref={ref}
        placeholder="Sélectionner une ville"
        value={geoCityToCityDataValue(value)}
        data={citiesData}
        onSearchChange={setSearch}
        onChange={handleCityChange}
        filter={constTrue} // prevent double filter
        nothingFound={search ? 'Aucun résultat trouvé' : 'Aucune recherche renseignée'}
        rightSection={loading ? <Loader pr="xs" size={26} /> : null}
        searchable
        label="Ville"
        styles={{
          rightSection: { '&&': { pointerEvents: 'auto' } },
        }}
        {...props}
      />
    );
  },
);

export default SearchGeoCity;
