import { z } from 'zod';
import { Utils } from '@shared/utils/model';
import { SensorsSchema } from '@modules/sensors/schema';
import { ThresholdSchema } from '@modules/iot/schema';
import { Hortee } from '@modules/sensors/hortee/model';

export namespace HorteeSchema {
  import registerPendingSchema = SensorsSchema.registerPendingSchema;

  const configSchema = z.object({
    soilType: z.nativeEnum(Hortee.SoilType),
    coordinates: Utils.GPSCoordinates,
    humidityThreshold: ThresholdSchema.humidityScaleSchema,
    temperatureThreshold: ThresholdSchema.temperatureScaleSchema,
  });

  export const registerHorteeSchema = registerPendingSchema.extend({
    config: configSchema,
  });
}
