import React, { FC } from 'react';
import ThresholdSlider from '@shared/modules/threshold/ThresholdSlider';
import { Threshold } from '@modules/iot/model';
import Level = Threshold.Level;
import { Box, Group, Text, ThemeIcon } from '@mantine/core';
import { IconDroplet } from '@tabler/icons-react';
import { FieldErrors } from 'react-hook-form/dist/types';
import { Measure } from '@modules/measures/model';
import Type = Measure.Type;
import { LastMeasureUtils } from '@modules/measures/last/utils';
import MinMeasure = LastMeasureUtils.MinMeasure;
import MaxMeasure = LastMeasureUtils.MaxMeasure;

const type = Type.Humidity;
const last = Level.None;

type ThresholdType = Threshold.Scale<Measure.Value<typeof type>, Threshold.Level>;

interface HumidityScaleEditProps {
  onChange(value: ThresholdType): void;
  values: ThresholdType;
  errors?: FieldErrors<Threshold.Scale<Measure.Value<Measure.History.Type>, Threshold.Level>>;
}

const HumidityScaleEdit: FC<HumidityScaleEditProps> = ({ onChange, values, errors }) => {
  const handleChange = ([fst, snd]: [Measure.Value<typeof type>, Measure.Value<typeof type>]) => {
    onChange({
      levels: [
        { until: fst, level: Level.Critical },
        { until: snd, level: Level.Alert },
      ],
      last,
    });
  };

  const [firstValue, sndValue] = values.levels.map(({ until }) => until);

  return (
    <Box>
      <Group spacing={8} pb={18}>
        <ThemeIcon size={22} bg="tertiary.3" c="tertiary.5">
          <IconDroplet size={12} />
        </ThemeIcon>
        <Text color="black" weight={600} size={14}>
          Humidité
        </Text>
      </Group>
      <ThresholdSlider
        type={type}
        min={MinMeasure[type]}
        max={MaxMeasure[type]}
        lastLevel={last}
        onChange={handleChange}
        values={[firstValue, sndValue]}
        errors={[errors?.levels?.[0], errors?.levels?.[1]]}
      />
    </Box>
  );
};

export default HumidityScaleEdit;
