import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { Loader, Text, ThemeIcon } from '@mantine/core';
import * as Styled from './ReportSubCategoryCard.styles';
import { IconChevronRight } from '@tabler/icons-react';

interface ReportSubCategoryCardProps {
  subCategory: AlertReport.SubCategory;
  color: string;
  loading: boolean;
  selected: boolean;
  onSelect: (subCategory: AlertReport.SubCategory) => void;
}

const ReportSubCategoryCard: FC<ReportSubCategoryCardProps> = ({ subCategory, color, loading, selected, onSelect }) => {
  const handleClick = () => onSelect(subCategory);

  return (
    <Styled.ReportSubCategoryButton bg="white" pr={15} disabled={loading} $selected={selected} onClick={handleClick}>
      <Styled.ReportSubCategoryImageContainer bg={color} ta="center" $selected={selected}>
        <img src={subCategory.images.fit_300x300} alt={subCategory.label} />
      </Styled.ReportSubCategoryImageContainer>

      <Text c="tertiary.8" fw={600} mx={15} sx={{ flex: '1 1 auto' }}>
        {subCategory.label}
      </Text>

      {loading ? (
        <Loader size={18} />
      ) : (
        <ThemeIcon size={18} radius={100} bg="#dfdfdf" c="#2c3e50">
          <IconChevronRight size={11} />
        </ThemeIcon>
      )}
    </Styled.ReportSubCategoryButton>
  );
};

export default ReportSubCategoryCard;
