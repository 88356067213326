import React, { FC, useEffect, useMemo, useState } from 'react';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import { Box } from '@mantine/core';

interface UserPositionProps {
  position: GeolocationPosition;
  map: google.maps.Map;
}

const UserPosition: FC<UserPositionProps> = ({ position, map }) => {
  const [bounds, setBounds] = useState(map.getBounds()?.toJSON());

  useEffect(() => {
    const listener = map.addListener('bounds_changed', () => setBounds(map.getBounds()?.toJSON()));

    return () => listener.remove();
  }, [map]);

  const size = useMemo(() => {
    if (bounds) {
      const meterWidth = google.maps.geometry.spherical.computeDistanceBetween(
        { lat: bounds.north, lng: bounds.west },
        { lat: bounds.north, lng: bounds.east },
      );

      const pixelWidth = map.getDiv().clientWidth; // offsetWidth ?
      const meterAccuracy = position.coords.accuracy;

      return (meterAccuracy * pixelWidth) / meterWidth; // pixel accuracy
    }

    return 0;
  }, [bounds, map, position]);

  return (
    <GoogleMapsOverlay position={{ lat: position.coords.latitude, lng: position.coords.longitude }} map={map}>
      <Box pos="relative">
        <Box
          w={14}
          h={14}
          bg="primary.5"
          pos="absolute"
          style={{
            zIndex: 10,
            borderRadius: '100%',
            border: '2px solid white',
            transform: 'translate(-50%, -50%)',
          }}
        />
        <Box
          w={size}
          h={size}
          pos="absolute"
          bg="primary.5"
          opacity={0.4}
          sx={{
            zIndex: 5,
            borderRadius: '100%',
            transform: 'translate(-50%, -50%)',
            transitionProperty: 'width, height',
            transitionDuration: '400ms',
            transitionTimingFunction: 'ease-in-out',
          }}
        />
      </Box>
    </GoogleMapsOverlay>
  );
};

export default UserPosition;
