import { HttpRange, httpService, HttpTask } from '@core/http';
import { AlertTrigger } from '@modules/alert-triggers/model';

export namespace AlertTriggersService {
  const URI = '/iot/alert-triggers';

  export function getAlertTriggers(page: number): HttpRange<AlertTrigger> {
    return httpService.getRange(URI, page, {});
  }

  export function toggleState(id: AlertTrigger.Id, state: AlertTrigger.State): HttpTask {
    return httpService.post(`${URI}/${id}/state`, { state });
  }

  export function createAlertTrigger(params: AlertTrigger.CreateParams): HttpTask {
    return httpService.post(URI, params);
  }

  export function getAlertTrigger(id: AlertTrigger.Id): HttpTask<AlertTrigger> {
    return httpService.get(`${URI}/${id}`);
  }

  export function updateAlertTrigger(id: AlertTrigger.Id, params: AlertTrigger.UpdateParams): HttpTask {
    return httpService.post(`${URI}/${id}`, params);
  }

  export function deleteAlertTrigger(id: AlertTrigger.Id): HttpTask {
    return httpService.delete(`${URI}/${id}`);
  }
}
