import { useEffect, useState } from 'react';

export function useUserPosition() {
  const [userPosition, setUserPosition] = useState<GeolocationPosition | null>(null);

  useEffect(() => {
    if ('geolocation' in navigator) {
      const id = navigator.geolocation.watchPosition(setUserPosition);

      return () => {
        navigator.geolocation.clearWatch(id);
      };
    }
  }, []);

  return userPosition;
}
