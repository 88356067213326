import { httpService, HttpTask } from '@core/http';
import { Emergency } from '@modules/emergency-numbers/model';

export namespace EmergencyService {
  const URI = '/app/emergency-numbers';

  export function getEmergencyNumbers(): HttpTask<Array<Emergency.Number>> {
    return httpService.get(URI);
  }
}
