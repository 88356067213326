import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { ActionIcon, Box, Button, Stack, Text } from '@mantine/core';
import { IconCurrentLocation } from '@tabler/icons-react';
import { renderNullable } from '@shared/utils/render';
import { useUserPosition } from '@shared/modules/maps/hooks/user-position';
import AlertReportPin from '@modules/diseases/alert-reports/map/components/AlertReportPin';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { ReportFlowType } from '@modules/diseases/alert-reports/flow';

export interface SelectReportLocationHandle {
  setPosition: (position: google.maps.LatLng | google.maps.LatLngLiteral) => void;
}

interface SelectReportLocationProps {
  type: ReportFlowType;
  map: google.maps.Map;
  category: AlertReport.Category;
  subCategory: AlertReport.SubCategory;
  loading: boolean;
  hasPreviousLocation: boolean;
  onSubmit: (location: google.maps.LatLng) => void;
}

const SelectReportLocation = forwardRef<SelectReportLocationHandle, SelectReportLocationProps>(
  ({ type, map, category, subCategory, loading, hasPreviousLocation, onSubmit }, ref) => {
    const userPositionSet = useRef(false);
    const userPosition = useUserPosition();

    const handleSetPosition = useCallback(
      (position: google.maps.LatLng | google.maps.LatLngLiteral) => {
        map.setCenter(position);
        map.setZoom(16);
      },
      [map],
    );

    useImperativeHandle(ref, () => ({
      setPosition: handleSetPosition,
    }));

    useEffect(() => {
      if (userPosition && !userPositionSet.current && !hasPreviousLocation) {
        handleSetPosition({ lat: userPosition.coords.latitude, lng: userPosition.coords.longitude });
        userPositionSet.current = true;
      }
    }, [handleSetPosition, hasPreviousLocation, userPosition]);

    const handleSubmit = () => {
      const center = map.getCenter();

      if (center) {
        onSubmit(center);
      }
    };

    return (
      <>
        <Box pos="absolute" left="50%" top="50%">
          <AlertReportPin category={category} subCategory={subCategory} size={43} />
        </Box>

        <Stack px={28} spacing={20} pos="absolute" bottom={15} w="100%" align="center">
          <Button color="primary.5" tt="uppercase" loading={loading} onClick={handleSubmit}>
            {type === 'create' ? 'Envoyer le signalement' : 'Modifier le signalement'}
          </Button>

          {renderNullable(userPosition, position => (
            <ActionIcon
              pos="absolute"
              bottom={90}
              right={20}
              onClick={() => handleSetPosition({ lat: position.coords.latitude, lng: position.coords.longitude })}
              size={44}
              radius="50%"
              bg="white"
              c="primary.5"
            >
              <IconCurrentLocation />
            </ActionIcon>
          ))}

          <Box bg="tertiary.8" px={54} py={15} style={{ borderRadius: 3 }}>
            <Text color="white" ta="center" size={16} lh={1.33}>
              Déplacez la carte pour choisir votre localisation
            </Text>
          </Box>
        </Stack>
      </>
    );
  },
);

export default SelectReportLocation;
