import { createPolymorphicComponent, UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import styled from '@emotion/styled';
import { styledOptions } from '@styles/utils';

interface SelectedProps {
  $selected: boolean;
}
export const ReportTypeButton = createPolymorphicComponent<'button', UnstyledButtonProps & SelectedProps>(styled(
  UnstyledButton,
  styledOptions,
)<SelectedProps>`
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  overflow: hidden;
`);

export const ReportTypeImageContainer = styled.div<SelectedProps>`
  position: relative;
  width: 100px;
  min-height: 80px;
  flex: 0 0 100px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ${props =>
    props.$selected &&
    `
    border: 4px solid white;
    border-right: none;
  `};
`;
