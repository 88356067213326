import { FilterQueryParser, getEnumQuery, getSearchQuery, getStringQuery } from '@shared/modules/filter';
import { ActiveSensor, Sensor } from '@modules/sensors/model';
import { Zone } from '@modules/iot/zones/model';
import { Threshold } from '@modules/iot/model';
import { Measure } from '@modules/measures/model';
import { Sinafis } from '@modules/sensors/sinafis/model';
import { SensorFilter } from '@modules/sensors/map/filters/model';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';

export namespace SensorsUtils {
  export const sensorsFilterParser =
    (savedFilters: Array<SensorFilter>): FilterQueryParser<ActiveSensor.FilterWithCity> =>
    query => ({
      search: getSearchQuery(query),
      zoneId: getStringQuery<Zone.Id>(query, 'zoneId'),
      sensorType: getEnumQuery(query, Sensor.Type, 'sensorType'),
      sinafisProbeIdentifier: getEnumQuery(query, Sinafis.Probe.Identifier, 'sinafisProbeIdentifier'),
      alertLevel: getEnumQuery(query, Threshold.Level, 'alertLevel'),
      alertType: getEnumQuery(query, Measure.Type, 'alertType'),
      code: getStringQuery(query, 'code'),
      postalCode: getStringQuery(query, 'postalCode'),
      filterId: pipe(
        O.fromNullable(getStringQuery<SensorFilter.Id>(query, 'filterId')),
        O.filter(filterId =>
          pipe(
            savedFilters,
            A.some(savedFilter => savedFilter.id === filterId),
          ),
        ),
        O.toNullable,
      ),
      city: getStringQuery(query, 'city'),
    });
}
