export namespace Maps {
  export const defaultOptions = {
    center: { lat: 46.9839695, lng: 1.7773997 },
    zoom: 6.6,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    clickableIcons: false,
    draggableCursor: 'grab',
    draggingCursor: 'grabbing',
    zoomControlOptions: {
      position: 5.0,
    },
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  } satisfies google.maps.MapOptions;
}
