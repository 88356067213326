import { RangeCursor, VirtualizedListProps } from '@shared/modules/range';
import React, { CSSProperties, FC, useEffect, useLayoutEffect } from 'react';
import { renderOptional } from '@shared/utils/render';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { Box, Stack, Text } from '@mantine/core';
import { useThrottledCallback } from 'use-debounce';

interface LoadingRowProps {
  index: number;
  loadRow: (index: number) => void;
}

const LoadingRow: FC<LoadingRowProps> = ({ index, loadRow }) => {
  useEffect(() => {
    loadRow(index);
  });

  return null;
};

export function VirtualizedList<T>({
  header,
  range,
  children,
  rowHeight = 55,
  loadPage,
  gap = 10,
}: VirtualizedListProps<T>) {
  const virtualizer = useWindowVirtualizer({
    count: range.total,
    estimateSize: () => rowHeight,
    overscan: 10,
  });

  useLayoutEffect(() => {
    if (range.total > 0) {
      virtualizer.scrollToIndex(0);
    }
  }, [range.total, virtualizer]);

  const handleLoadRow = (index: number) => {
    if (!range.has(index)) {
      loadPage(Math.floor(index / RangeCursor.DEFAULT_SIZE) + 1);
    }
  };

  // Throttle to wait state refresh
  const throttleLoadRow = useThrottledCallback(handleLoadRow, 400);

  return (
    <Stack>
      {header}

      {range.total === 0 ? (
        <Text py="lg" size="sm" color="gray.8" fs="italic" ta="center">
          Aucun élément à afficher
        </Text>
      ) : (
        <div style={{ position: 'relative', height: virtualizer.getTotalSize(), width: '100%' }}>
          {virtualizer.getVirtualItems().map(row => {
            const style: CSSProperties = {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              minHeight: 55,
              transform: `translateY(${row.start}px)`,
            };

            return (
              <Box key={row.key} ref={virtualizer.measureElement} data-index={row.index} style={style}>
                <Box pb={gap}>
                  {renderOptional(
                    range.get(row.index),
                    item => (
                      <>{children(item, row.index)}</>
                    ),
                    () => (
                      <LoadingRow key={row.key} index={row.index} loadRow={throttleLoadRow} />
                    ),
                  )}
                </Box>
              </Box>
            );
          })}
        </div>
      )}
    </Stack>
  );
}
