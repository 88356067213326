import { ImageMap } from '@shared/modules/images/model';
import { LocalDate } from '@shared/modules/dates';
import { z } from 'zod';
import { NonEmptyString } from '@shared/schemas';
import { MantineColor } from '@mantine/core';
import { ordFromOrdering } from '@shared/utils/order';

export namespace Pest {
  export enum Name {
    ThaumetopoeaPityocampa = 'thaumetopoea-pityocampa',
    ThaumetopoeaProcessionea = 'thaumetopoea-processionea',
    RhynchophorusFerrugineus = 'rhynchophorus-ferrugineus',
    PaysandisiaArchon = 'paysandisia-archon',
    CydalimaPerspectalis = 'cydalima-perspectalis',
    MelolonthaMelolontha = 'melolontha-melolontha',
    CamerariaOhridella = 'cameraria-ohridella',
    CorythuchaCiliata = 'corythucha-ciliata',
  }

  export const Stage = z.string().brand('PestStage');
  export type Stage = z.infer<typeof Stage>;

  export namespace Risk {
    export enum Level {
      None = 'none',
      Low = 'low',
      Medium = 'medium',
      High = 'high',
    }

    export const levelOrdering: Record<Level, number> = {
      [Level.None]: 0,
      [Level.Low]: 1,
      [Level.Medium]: 2,
      [Level.High]: 3,
    };

    export const levelOrd = ordFromOrdering(levelOrdering);

    export const levelColor: Record<Level, MantineColor> = {
      [Level.None]: 'gray.3',
      [Level.Low]: 'primary.3',
      [Level.Medium]: 'primary.4',
      [Level.High]: 'primary.5',
    };
  }

  export namespace Description {
    export interface DateRange {
      start: LocalDate;
      end: LocalDate;
    }

    export interface Stage {
      name: Pest.Stage;
      level: Risk.Level;
    }
  }

  export interface Description {
    enum: Pest.Name;
    name: String;
    dateRange: Description.DateRange;
    image: ImageMap | null;
    advice: NonEmptyString | null;
    stages: Array<Description.Stage>;
    hidden: boolean;
    notifEnabled: boolean;
  }
}
