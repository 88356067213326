import React, { FC, useCallback, useState } from 'react';

import { Previsions as PrevisionsType } from '../model';
import { Box, Group, ScrollArea, Select, Skeleton, Stack, Text, Title } from '@mantine/core';
import WeatherPrevisions from '@shared/modules/previsions/components/weather/WeatherPrevisions';
import { useFetchTaskOption } from '@core/http/hooks';
import { Zone } from '@modules/iot/zones/model';
import SearchGeoCity from '@shared/modules/geo/SearchGeoCity';
import { PrevisionsService } from '@shared/modules/previsions/service';
import { Geo } from '@shared/modules/geo/model';
import DiseasePrevisions from '@shared/modules/previsions/components/disease/DiseasePrevisions';
import { renderOptional } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { HttpError } from '@core/http';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';

interface PrevisionsProps {
  zones: Array<Zone>;
}

const Previsions: FC<PrevisionsProps> = ({ zones }) => {
  const [filter, setFilter] = useState<PrevisionsType.Filter | null>(null);

  const [context] = useFetchTaskOption(PrevisionsService.getContext, filter);

  const handleZoneChange = (zoneId: Zone.Id) => setFilter({ type: 'zone', zoneId });

  const handleLocationChange = (location: Geo.City) => setFilter({ type: 'location', location });

  const getWeather = useCallback(
    () =>
      pipe(
        context,
        TE.fromOption(() => HttpError.notFound),
        TE.chain(PrevisionsService.getWeather),
      ),
    [context],
  );

  const [weather] = useFetchTaskOption(getWeather);

  const getDisease = useCallback(
    () =>
      pipe(
        context,
        TE.fromOption(() => HttpError.notFound),
        TE.chain(PrevisionsService.getDisease),
      ),
    [context],
  );

  const [disease] = useFetchTaskOption(getDisease);

  return (
    <>
      <Stack px={20}>
        <Title order={2} size={17} lh={1.29} weight={600}>
          Prévisions météo
        </Title>

        {renderOptional(
          context,
          ({ filter }) => (
            <Group spacing={10} noWrap>
              <Select
                sx={{ flexGrow: 1 }}
                value={filter.type === 'zone' ? filter.zoneId : null}
                placeholder="Sélectionner un groupe"
                data={zones.map(zone => ({ value: zone.id, label: zone.name }))}
                onChange={handleZoneChange}
                nothingFound="Aucun groupe disponible"
              />
              <Text size={10} c="dark.1" fw={600}>
                ou
              </Text>
              <SearchGeoCity
                sx={{ flexGrow: 1 }}
                label={undefined}
                value={filter.type === 'location' ? filter.location : null}
                onChange={handleLocationChange}
              />
            </Group>
          ),
          () => (
            <Skeleton h={42} />
          ),
        )}
      </Stack>

      {renderOptional(
        sequenceS(O.Apply)({ weather, context }),
        ({ weather, context }) => (
          <WeatherPrevisions main={weather.today} days={weather.forecast} location={context.location} />
        ),
        () => (
          <Stack spacing={20} mt={20}>
            <Box px={20}>
              <Skeleton radius={8} h={243} />
            </Box>
            <ScrollArea>
              <Group spacing={10} px={20} noWrap>
                {A.makeBy(4, index => (
                  <Skeleton key={index} radius={8} w={130} h={246} />
                ))}
              </Group>
            </ScrollArea>
          </Stack>
        ),
      )}

      <Group position="apart" pt={28} px={20}>
        <Text color="#2e2e2e" weight={600} size={17} lh={1.29}>
          Épidémiosurveillance
        </Text>
      </Group>

      {renderOptional(
        sequenceS(O.Apply)({ disease, context }),
        ({ disease, context }) => (
          <DiseasePrevisions
            main={disease.today}
            days={disease.forecast}
            location={context.location}
            formatStr="EEEE"
          />
        ),
        () => (
          <Stack spacing={8} pt={8}>
            <Box px={20}>
              <Skeleton radius={8} h={190} />
            </Box>
            <ScrollArea>
              <Group spacing={10} px={20} mt={5} noWrap>
                {A.makeBy(4, index => (
                  <Skeleton key={index} radius={8} w={130} h={266} />
                ))}
              </Group>
            </ScrollArea>
          </Stack>
        ),
      )}
    </>
  );
};

export default Previsions;
