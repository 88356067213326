import { Box, BoxProps, createPolymorphicComponent, UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import styled from '@emotion/styled';
import { styledOptions } from '@styles/utils';

interface SelectedProps {
  $selected: boolean;
}

export const ReportSubCategoryButton = createPolymorphicComponent<'button', UnstyledButtonProps & SelectedProps>(styled(
  UnstyledButton,
  styledOptions,
)<SelectedProps>`
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  overflow: hidden;
`);

export const ReportSubCategoryImageContainer = createPolymorphicComponent<'div', BoxProps & SelectedProps>(styled(
  Box,
  styledOptions,
)<SelectedProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100%;
  min-height: 80px;
  flex: 0 0 100px;

  img {
    width: 55px;
    height: 55px;
  }

  ${props =>
    props.$selected &&
    `
    border: 4px solid white;
    border-right: none;
  `};
`);
