import React, { FC, MouseEventHandler } from 'react';
import { Box, Button, Drawer, Stack, Text } from '@mantine/core';
import { Maps } from '@shared/modules/maps/model';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import SensorPin from '@modules/sensors/map/components/SensorPin';
import { Sensor } from '@modules/sensors/model';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

interface GeolocationDrawerProps {
  opened: boolean;
  onClose: () => void;
  type: Sensor.Type;
  center?: google.maps.LatLngLiteral;
  onValidate(position: google.maps.LatLngLiteral): void;
}

const GeolocationDrawer: FC<GeolocationDrawerProps> = ({ opened, onClose, onValidate, type, center }) => {
  const defaultCenter = center ?? Maps.defaultOptions.center;
  const handleValidate =
    (map: google.maps.Map): MouseEventHandler =>
    () => {
      const center = pipe(
        O.fromNullable(map.getCenter()),
        O.fold(
          () => defaultCenter,
          center => ({ lat: center.lat(), lng: center.lng() }),
        ),
      );
      onClose();
      onValidate(center);
    };

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      transitionProps={{ transition: 'slide-up', timingFunction: 'ease' }}
      title={
        <Text color="white" weight={600} size={17}>
          Positionner ma sonde
        </Text>
      }
      styles={theme => ({
        header: { background: theme.colors.tertiary[8], padding: '17px 11px 14px 20px' },
        body: {
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        },
        close: {
          border: 'none',
          width: '26px ',
          height: '26px ',
          background: 'transparent !important',
          svg: {
            width: '100%',
            height: '100%',
            color: 'white',
          },
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          border: 'none !important',
        },
      })}
      lockScroll={false}
      closeOnClickOutside
    >
      <GoogleMaps options={{ ...Maps.defaultOptions, center: defaultCenter }} style={{ flexGrow: 1 }}>
        {map => (
          <>
            <Box pos="absolute" left="50%" top="50%">
              <SensorPin type={type} />
            </Box>
            <Stack px={28} spacing={20} pos="absolute" bottom={15} w="100%" align="center">
              <Button color="primary.5" tt="uppercase" onClick={handleValidate(map)}>
                Valider la localisation
              </Button>
              <Box bg="tertiary.8" px={54} py={20} style={{ borderRadius: 3 }}>
                <Text color="white" ta="center" size={16} lh={1.33}>
                  Déplacez la carte pour choisir votre localisation
                </Text>
              </Box>
            </Stack>
          </>
        )}
      </GoogleMaps>
    </Drawer>
  );
};

export default GeolocationDrawer;
