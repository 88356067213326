import React, { ChangeEventHandler, FC } from 'react';
import { FilterChildrenProps } from '@shared/modules/filter/components/FiltersDrawer';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { useFetchTaskArray } from '@core/http/hooks';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { Box, Checkbox, Chip, Divider, Group, Radio, Select, Text } from '@mantine/core';
import { constNull, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import * as Ord from 'fp-ts/Ord';
import * as NEA from 'fp-ts/NonEmptyArray';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';

const EMPTY_VAlUE = 'empty';

function subCategoriesTask(categoryId: AlertReport.CategoryId | null | undefined) {
  return pipe(
    O.fromNullable(categoryId),
    O.fold(
      () => TE.right([]),
      categoryId => AlertReportsService.getSubCategories(categoryId),
    ),
  );
}

function typesTask(subCategoryId: AlertReport.SubCategoryId | null | undefined) {
  return pipe(
    O.fromNullable(subCategoryId),
    O.fold(
      () => TE.right([]),
      subCategoryId => AlertReportsService.getTypes(subCategoryId),
    ),
  );
}

interface AlertReportsFiltersContentProps extends FilterChildrenProps<AlertReport.Filter.Queries> {
  isAccessLimited: boolean;
  isHistory?: boolean;
}

const AlertReportsFiltersContent: FC<AlertReportsFiltersContentProps> = ({
  filter,
  onChange,
  isAccessLimited,
  isHistory,
}) => {
  const [categories] = useFetchTaskArray(AlertReportsService.getCategories);
  const [subCategories] = useFetchTaskArray(subCategoriesTask, filter.categoryId);
  const [types] = useFetchTaskArray(typesTask, filter.subCategoryId);

  const handleCategoryChange = (category: string | null) => {
    onChange({
      ...filter,
      categoryId: AlertReport.CategoryId.nullable().catch(constNull).parse(category),
      subCategoryId: null,
      typeId: null,
      gravity: null,
    });
  };

  const handleSubCategoryChange = (subCategory: string | null) => {
    onChange({
      ...filter,
      subCategoryId: AlertReport.SubCategoryId.nullable().catch(constNull).parse(subCategory),
      typeId: null,
      gravity: null,
    });
  };

  const handleTypeChange = (type: string | null) => {
    onChange({
      ...filter,
      typeId: AlertReport.TypeId.nullable().catch(constNull).parse(type),
    });
  };

  const severityData = pipe(
    O.fromNullable(filter.subCategoryId),
    O.chain(id =>
      pipe(
        subCategories,
        A.findFirst(subCategory => subCategory.id === id),
      ),
    ),
    O.fold(
      () => [],
      subCategory => NEA.range(1, subCategory.maxGravity).map(severity => `${severity}`),
    ),
  );

  const handleSeverityChange = (severity: string | null) => {
    onChange({
      ...filter,
      gravity: AlertReport.Gravity.nullable().catch(constNull).parse(severity),
    });
  };

  const handleDatesChange = ([start, end]: DatesRangeValue) => {
    onChange({
      ...filter,
      personalizedStartDate: formatDate(start, DateFormat.LocalDateTime),
      personalizedEndDate: formatDate(end, DateFormat.LocalDateTime),
    });
  };

  const handleCheckBoxChange =
    (field: 'myReports' | 'hasPictures' | 'privateOnly'): ChangeEventHandler<HTMLInputElement> =>
    e => {
      onChange({
        ...filter,
        [field]: e.target.checked || null,
      });
    };

  const handleStatusChange = (status: AlertReport.Status) => {
    onChange({
      ...filter,
      status,
    });
  };

  const handlePeriodChange = (period: AlertReport.Period) => {
    onChange({
      ...filter,
      period: Object.values(AlertReport.Period).includes(period) ? period : null,
      personalizedStartDate: null,
      personalizedEndDate: null,
    });
  };

  return (
    <Box mt={20}>
      <Select
        label="Catégorie"
        placeholder="Catégorie"
        value={filter.categoryId?.toString() ?? null}
        data={categories.map(category => ({ value: category.id.toString(), label: category.label }))}
        nothingFound="Aucun élément"
        clearable
        onChange={handleCategoryChange}
      />

      {!isAccessLimited ? (
        <>
          <Select
            mt={15}
            label="Sous catégorie"
            placeholder="Sous catégorie"
            value={filter.subCategoryId?.toString() ?? null}
            data={subCategories.map(subCategory => ({ value: subCategory.id.toString(), label: subCategory.label }))}
            nothingFound="Aucun élément"
            clearable
            onChange={handleSubCategoryChange}
          />

          <Select
            mt={15}
            label="Type de signalement"
            placeholder="Type de signalement"
            value={filter.typeId?.toString() ?? null}
            data={types.map(type => ({ value: type.id.toString(), label: type.label }))}
            nothingFound="Aucun élément"
            clearable
            onChange={handleTypeChange}
          />

          <Select
            mt={15}
            label="Niveau de gravité"
            placeholder="Niveau de gravité"
            value={filter.gravity?.toString() ?? null}
            data={severityData}
            nothingFound="Aucun élément"
            clearable
            onChange={handleSeverityChange}
          />
        </>
      ) : null}

      {!isHistory ? (
        <>
          <Divider my={20} />

          <Text mb={8} fw={600}>
            Période
          </Text>

          <Chip.Group value={filter.period ?? EMPTY_VAlUE} onChange={handlePeriodChange}>
            <Group spacing={10}>
              <Chip radius={4} value={EMPTY_VAlUE}>
                Aucun
              </Chip>
              {pipe(
                R.toEntries(AlertReport.periodLabel),
                A.filter(([period]) => (!isAccessLimited ? true : period !== AlertReport.Period.Personalized)),
                A.sort(Ord.tuple(AlertReport.periodOrd, Ord.trivial)),
                A.map(([period, label]) => (
                  <Chip key={period} radius={4} value={period}>
                    {label}
                  </Chip>
                )),
              )}
            </Group>
          </Chip.Group>

          {!isAccessLimited && filter.period === AlertReport.Period.Personalized ? (
            <DatePickerInput
              type="range"
              placeholder="Sélectionner"
              allowSingleDateInRange
              mt={10}
              onChange={handleDatesChange}
              value={[
                parseDate(filter.personalizedStartDate, DateFormat.LocalDateTime, null),
                parseDate(filter.personalizedEndDate, DateFormat.LocalDateTime, null),
              ]}
            />
          ) : null}
        </>
      ) : null}

      <Divider my={20} />

      <Group>
        {!isHistory ? (
          <Checkbox
            checked={filter.myReports ?? false}
            label="Mes signalements"
            onChange={handleCheckBoxChange('myReports')}
          />
        ) : null}
        <Checkbox
          checked={filter.hasPictures ?? false}
          label="Avec photos"
          onChange={handleCheckBoxChange('hasPictures')}
        />
        {!isAccessLimited ? (
          <Checkbox
            checked={filter.privateOnly ?? false}
            label="Uniquement privé"
            onChange={handleCheckBoxChange('privateOnly')}
          />
        ) : null}
      </Group>

      <Divider my={20} />

      <Radio.Group value={filter.status ?? AlertReport.Status.All} onChange={handleStatusChange} label="Statut">
        <Group>
          <Radio value={AlertReport.Status.All} label="Tous" />
          <Radio value={AlertReport.Status.Active} label="Actif" />
          <Radio value={AlertReport.Status.Inactive} label="Inactif" />
        </Group>
      </Radio.Group>
    </Box>
  );
};

export default AlertReportsFiltersContent;
