import z from 'zod';

export namespace Emergency {
  export const NumberId = z.string().uuid().brand('EmergencyNumberId');
  export type NumberId = z.infer<typeof NumberId>;

  export enum Type {
    Main = 'main',
    PoisonControlCenter = 'poison-control-center',
    GreenNumber = 'green-number',
  }

  export interface Number {
    id: NumberId;
    type: Type;
    label: string;
    number: string;
  }
}
