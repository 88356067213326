import z from 'zod';

export enum DateFormat {
  LocalDate = 'dd/MM/yyyy',
  LocalDateTime = 'dd/MM/yyyy HH:mm',
  LocalTime = 'HH:mm',
}

export const LocalDate = z.string().brand<'LocalDate'>();
export type LocalDate = z.infer<typeof LocalDate>;

export const LocalDateTime = z.string().brand<'LocalDateTime'>();
export type LocalDateTime = z.infer<typeof LocalDateTime>;

export const LocalTime = z.string().brand<'LocalTime'>();
export type LocalTime = z.infer<typeof LocalTime>;

export type DateTypes<Format extends DateFormat> = {
  [DateFormat.LocalDate]: LocalDate;
  [DateFormat.LocalDateTime]: LocalDateTime;
  [DateFormat.LocalTime]: LocalTime;
}[Format];
