import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { Box, Card, Group, Text } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
import AlertReportTypeDetail from '@modules/diseases/alert-reports/detail/components/AlertReportTypeDetail';

interface AlertReportDetailHeaderProps {
  alertReport: AlertReport.Detail;
}

const AlertReportDetailHeader: FC<AlertReportDetailHeaderProps> = ({ alertReport }) => {
  return (
    <>
      <Box bg="white">
        <Box px={20} py={15}>
          <Text size={17} c="tertiary.8" fw={600}>
            Nature du signalement
          </Text>

          <Card
            mt={10}
            radius={10}
            bg="rgba(232, 147, 63, 0.15)"
            fz={12}
            c="tertiary.8"
            ta="center"
            sx={{ borderColor: 'rgba(232, 147, 63, 0.4) !important' }}
          >
            <Text>
              {alertReport.category.label} / {alertReport.subCategory.label} / {alertReport.type.label}
            </Text>
            <Text>Signalé le {alertReport.reportedAt}</Text>
          </Card>

          {alertReport.isPrivate ? (
            <Group mt={15} position="center" spacing={8} fz={14} c="tertiary.7">
              <IconLock size={18} />
              <Text>Signalement privé</Text>
            </Group>
          ) : null}

          <AlertReportTypeDetail type={alertReport.type} />
        </Box>
      </Box>

      <Box
        h={10}
        sx={{
          opacity: 0.1,
          backgroundImage: 'linear-gradient(to top, #f5f4f2, #363636)',
        }}
      />
    </>
  );
};

export default AlertReportDetailHeader;
