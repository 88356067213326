import { defineLoader, httpTaskToResponseTask, useParentLoader } from '@core/router/loader';
import { defineRoute } from '@core/router';
import { ProfileService } from '@modules/profile/service';

const LOADER_ID = 'profile-loader';

const profileLoader = defineLoader({
  id: LOADER_ID,
  handler: () => httpTaskToResponseTask(ProfileService.getProfile()),
});

const profileLoaderRoute = defineRoute({
  loader: profileLoader,
});

export function useProfile() {
  return useParentLoader<typeof profileLoaderRoute.loader>(LOADER_ID);
}

export default profileLoaderRoute;
