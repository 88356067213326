import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Filter } from '@shared/modules/filter';
import { Range, RangeCursor, RangeResult } from './model';
import { useFetcher, useLocation, useNavigate } from 'react-router-dom';
import { stringifyQueries } from '@shared/utils/queries';
import { LoaderReturnType, useLoader } from '@core/router/loader';
import PQueue from 'p-queue';

/**
 * Hooks permettant d'utilisation un range à partir d'un loader
 * Il prend un mapper en paramètres pour mapper les données du loader en un RangeResult. La plupart du temps on pourra utiliser `identity` de fp-ts
 *
 * @param mapper - selecteur des données du range.
 */
export function useRange<
  Loader,
  R,
  F extends Filter = {},
  Mapper extends (data: LoaderReturnType<Loader>) => RangeResult<R, F> = (
    data: LoaderReturnType<Loader>,
  ) => RangeResult<R, F>,
>(mapper: Mapper) {
  const initialData = useLoader<Loader>();

  const mapperRef = useRef(mapper);

  mapperRef.current = mapper;

  const initialRange = useMemo(() => mapperRef.current(initialData), [initialData]);

  const location = useLocation();
  const navigate = useNavigate();

  const fetcher = useFetcher();
  const fetcherRef = useRef(fetcher);
  fetcherRef.current = fetcher;

  const [range, setRange] = useState<Range<R, F>>(() => Range.fromRangeResult(initialRange));

  const rangeRef = useRef(range);
  rangeRef.current = range;

  const queue = useMemo(() => new PQueue({ concurrency: 1 }), []);
  const queuePriority = useRef(0);

  /**
   * Synchronisation avec les données du loader et écrase les anciennes données
   * Les données du loader changent quand les queries changes
   */
  useEffect(() => {
    setRange(Range.fromRangeResult(initialRange));
  }, [initialRange]);

  /**
   * Chargement d'une nouvelle page
   *
   * On utilise alors le fetcher avec en paramètres les nouvelles queries. La query page est alors cachée à l'utilisateur
   */
  const handleLoadPage = useCallback(
    (page: number, force: boolean = false) => {
      const task = () => {
        const range = rangeRef.current;

        if (force || !range.has(RangeCursor.fromPage(page).startIndex)) {
          fetcherRef.current.submit(new URLSearchParams(stringifyQueries({ ...range.filter, page })));

          return new Promise(resolve => {
            let interval: number | NodeJS.Timer;

            let hasRun = false;

            /**
             * Check toutes les 5 ms si l'état du fetcher change
             */
            const waitForResult = () => {
              if (!hasRun) {
                hasRun = fetcherRef.current.state !== 'idle';
              } else if (fetcherRef.current.state === 'idle') {
                setRange(old => old.merge(Range.fromRangeResult(mapperRef.current(fetcherRef.current.data))));

                rangeRef.current = rangeRef.current.merge(
                  Range.fromRangeResult(mapperRef.current(fetcherRef.current.data)),
                );

                resolve(true);

                clearInterval(interval);
              }
            };

            interval = setInterval(waitForResult, 5);

            waitForResult();
          });
        }

        return new Promise(resolve => resolve(false));
      };

      const priority = queuePriority.current + 1;
      queuePriority.current = priority;

      queue.add(task, { priority });
    },
    [queue],
  );

  /**
   * Rafraichi la page concerné par l'index
   */
  const handleRefreshIndex = useCallback(
    (index: number) => {
      handleLoadPage(RangeCursor.fromIndex(index).toPage(), true);
    },
    [handleLoadPage],
  );

  /**
   * Modifie les queries de l'utilisateur pour relancer le fetcher
   */
  const handleFilter = useCallback(
    (filter: F) => navigate({ ...location, search: stringifyQueries(filter) }, { replace: true }),
    [location, navigate],
  );

  return {
    range,
    handleLoadPage,
    handleFilter,
    handleRefreshIndex,
    setRange,
  };
}
