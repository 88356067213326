import React, { createElement, FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { SensorsService } from '@modules/sensors/service';
import { renderOptional } from '@shared/utils/render';
import { Box, Card, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Link } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import { Sensor } from '@modules/sensors/model';
import { defineRoute } from '@core/router';
import { ReactComponent as SensorPlaceholderIcon } from '@assets/icons/sensor-placeholder.svg';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(SensorsService.getPendingSensors()),
});

const ListPage: FC = () => {
  const sensors = useLoader<typeof loader>();
  const pageProps: PageProps = {
    seoTitle: 'Sondes à configurer',
    back: {
      title: 'Sondes à configurer',
      to: '/sensors',
    },
  };

  return (
    <Page {...pageProps} withNav>
      {renderOptional(
        NEA.fromArray(sensors),
        sensors => (
          <Stack spacing={10}>
            {sensors.map(({ type, serial, technicalId }) => (
              <Card
                key={technicalId}
                component={Link}
                to={`${type}/${technicalId}`}
                shadow="0 0 12px 0 rgba(0, 0, 0, 0.1)"
                radius={8}
                px={15}
                py={10}
                withBorder={false}
              >
                <Group position="apart" noWrap>
                  <Group spacing={15} noWrap>
                    {createElement(Sensor.typeIcon[type], { width: 30, height: 30 })}
                    <Box pl={10} sx={theme => ({ borderLeft: `1px solid ${theme.colors.tertiary[3]}` })}>
                      <Text color="primary.5" weight={600} lh={1.43} size={14}>
                        Sonde {Sensor.typeTitle[type]}
                      </Text>
                      <Text color="rgba(46, 46, 46, 0.4)" weight={500} size={12} lh={1.33}>
                        {serial}
                      </Text>
                    </Box>
                  </Group>
                  <ThemeIcon c="primary.5" bg="transparent">
                    <IconChevronRight size={26} />
                  </ThemeIcon>
                </Group>
              </Card>
            ))}
          </Stack>
        ),
        () => (
          <Stack m="auto" spacing={18} align="center">
            <ThemeIcon size={110} c="#c3bbb3" bg="transparent">
              <SensorPlaceholderIcon />
            </ThemeIcon>
            <Text color="tertiary.8" size={16} ta="center">
              Vous n'avez aucune sonde à configurer
            </Text>
          </Stack>
        ),
      )}
    </Page>
  );
};

const pendingList = defineRoute({
  element: <ListPage />,
  loader,
});

export default pendingList;
