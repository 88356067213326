import React, { FC } from 'react';
import { IconCalendar } from '@tabler/icons-react';
import { Group, GroupProps, Text } from '@mantine/core';
import { Pest } from '@modules/diseases/pests/model';

interface PestDateRangeProps {
  dateRange: Pest.Description.DateRange;
}

const PestDateRange: FC<PestDateRangeProps & GroupProps> = ({ dateRange, ...rest }) => {
  return (
    <Group spacing={10} c="gray.6" noWrap {...rest}>
      <IconCalendar strokeWidth={1} style={{ flexShrink: 0 }} />
      <Text fw={400} size={14} lh={1}>
        Du {dateRange.start} au {dateRange.end}
      </Text>
    </Group>
  );
};

export default PestDateRange;
