import { httpService, HttpTask } from '@core/http';
import { Zone } from '@modules/iot/zones/model';

export namespace ZonesService {
  const URI = '/iot/zones';

  export function getZones(): HttpTask<Array<Zone>> {
    return httpService.get(URI);
  }
}
