import React, { FC, useMemo } from 'react';
import { Previsions } from '@shared/modules/previsions/model';

import * as Styled from './DayWeather.styles';
import { Box, Group, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { PrevisionsUtils } from '@shared/modules/previsions/utils';
import { renderOptional } from '@shared/utils/render';
import WeatherTemperature from '@shared/modules/previsions/components/weather/temperature/WeatherTemperature';
import { NumberUtils } from '@shared/utils/number';
import WeatherForecastParamTooltip from '../WeatherForecastParamTooltip';
import { IconInfoCircle } from '@tabler/icons-react';

interface DayWeatherProps {
  day: Previsions.Weather.DayForecast;
}

const DayWeather: FC<DayWeatherProps> = ({ day }) => {
  const typeInfo = useMemo(() => PrevisionsUtils.getWeatherTypeInfos(day.values), [day.values]);

  return (
    <Styled.DayWeatherContainer>
      <Group position="apart" spacing={5}>
        <Text size={12} lh={1.33} tt="uppercase" c="rgba(46, 46, 46, 0.4)" fw={500}>
          {day.date}
        </Text>
        <WeatherForecastParamTooltip values={day.values}>
          <ThemeIcon c="dark.1" variant="white" w="fit-content" miw="fit-content" h="fit-content" mih="fit-content">
            <IconInfoCircle size={16} />
          </ThemeIcon>
        </WeatherForecastParamTooltip>
      </Group>

      <Group spacing={0} noWrap position="center" pt={10}>
        <Text size={31} fw={600} c="#2e2e2e" ta="center" mb={5}>
          {NumberUtils.formatTemperature(day.values[Previsions.Weather.DayForecast.Param.AverageTemp])}
        </Text>

        {renderOptional(typeInfo, ({ label, icon: Icon, color }) => (
          <Tooltip label={label} position="bottom" offset={-10}>
            <Box c={color} ta="center">
              <Icon width={37} height={37} />
            </Box>
          </Tooltip>
        ))}
      </Group>

      <WeatherTemperature values={day.values} />

      <Styled.DayWeatherStats>
        <Text>PR</Text>
        <Text>{NumberUtils.formatTemperature(day.values[Previsions.Weather.DayForecast.Param.AverageDewPoint])}</Text>

        <Text>Précip.</Text>
        <Text>
          {NumberUtils.formatNumber(day.values[Previsions.Weather.DayForecast.Param.Precipitation], {
            style: 'unit',
            unit: 'millimeter',
            maximumFractionDigits: 1,
          })}
        </Text>

        <Text>ETP</Text>
        <Text>
          {NumberUtils.formatNumber(day.values[Previsions.Weather.DayForecast.Param.ETP], {
            style: 'unit',
            unit: 'millimeter',
            maximumFractionDigits: 1,
          })}
        </Text>
      </Styled.DayWeatherStats>
    </Styled.DayWeatherContainer>
  );
};

export default DayWeather;
