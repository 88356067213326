import React, { FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { AlertTriggersService } from '@modules/alert-triggers/service';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { useRange, VirtualizedList } from '@shared/modules/range';
import { AlertTrigger } from '@modules/alert-triggers/model';
import { identity, pipe } from 'fp-ts/function';
import AlertRow from '@modules/alert-triggers/components/AlertRow';
import { getPageQuery } from '@shared/modules/range/utils';
import { defineAction } from '@core/router/action';
import { AlertTriggersSchema } from '@modules/alert-triggers/schema';
import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

const loader = defineLoader({
  handler: ({ request }) =>
    pipe(parseQueriesFormUrl(request.url), getPageQuery, AlertTriggersService.getAlertTriggers, httpTaskToResponseTask),
});

export const actions = {
  toggleState: defineAction({
    type: 'toggleState',
    payload: AlertTriggersSchema.toggleStateParams,
    handler: ({ payload }) => AlertTriggersService.toggleState(payload.id, payload.state),
  }),
};

const ListPage: FC = () => {
  const { range, handleLoadPage } = useRange<typeof loader, AlertTrigger>(identity);

  const pageProps: PageProps = {
    seoTitle: 'Alertes',
    back: {
      to: '/sensors',
      title: 'Alertes',
    },
    bottomBar: (
      <Button
        component={Link}
        to="new"
        color="primary.5"
        leftIcon={<IconPlus size={17} />}
        tt="uppercase"
        styles={{ leftIcon: { marginRight: 8 } }}
      >
        Créer
      </Button>
    ),
  };

  return (
    <Page {...pageProps} withNav>
      <VirtualizedList range={range} loadPage={handleLoadPage}>
        {alert => <AlertRow alert={alert} />}
      </VirtualizedList>
    </Page>
  );
};

const alertsList = defineRoute({
  element: <ListPage />,
  loader,
  actions,
});

export default alertsList;
