import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import loginWithTokenRoute from '@modules/auth/LoginWithTokenPage';
import loginDevRoute from '@modules/auth/LoginDev';

export const authRoutes = (
  <Route path="login">
    <Route index {...createRoute(loginDevRoute)} />
    <Route path="token/:token" {...createRoute(loginWithTokenRoute)} />
  </Route>
);

export default authRoutes;
