import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { z } from 'zod';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import Page from '@layout/page/Page';
import AlertReportAnalysisTop from '@modules/diseases/alert-reports/analysis/components/AlertReportAnalysisTop';
import { useProfile } from '@modules/profile/loader';
import AlertReportAnalysisPlaceholder from '@modules/diseases/alert-reports/analysis/components/AlertReportAnalysisPlaceholder';
import AlertReportAnalysisContent from '@modules/diseases/alert-reports/analysis/components/AlertReportAnalysisContent';

const params = z.object({
  id: AlertReport.Id,
});

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(AlertReportsService.getAlertDetail(params.id)),
});

const AlertReportAnalysis: FC = () => {
  const alertReport = useLoader<typeof loader>();

  const { isAccessLimited } = useProfile().subscription;

  return (
    <Page pt={0} px={0} withNav seoTitle={`Analyse ${alertReport.type.label}`}>
      <AlertReportAnalysisTop alertReport={alertReport} />

      {isAccessLimited ? (
        <AlertReportAnalysisPlaceholder alertReport={alertReport} />
      ) : (
        <AlertReportAnalysisContent alertReport={alertReport} />
      )}
    </Page>
  );
};

const alertReportAnalysisRoute = defineRoute({
  element: <AlertReportAnalysis />,
  loader,
});

export default alertReportAnalysisRoute;
