import React, { createElement, FC, PropsWithChildren } from 'react';
import { NavLink, To } from 'react-router-dom';
import { Card, CardProps, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { Icon } from '@tabler/icons-react';

interface SimpleCardProps {
  to: To;
  icon: Icon;
  title: string;
  description?: string;
}

const SimpleCard: FC<PropsWithChildren<SimpleCardProps & Partial<CardProps>>> = ({
  to,
  icon,
  title,
  description,
  c = 'white',
  children,
  ...rest
}) => {
  return (
    <Card component={NavLink} to={to} px={15} pt={10} pb={15} withBorder={false} radius={10} c={c} {...rest}>
      <Stack spacing={3}>
        <Group spacing={5}>
          <ThemeIcon size={34} bg="transparent" c={c}>
            {createElement(icon, { strokeWidth: 1.8 })}
          </ThemeIcon>
          <Text tt="uppercase" fw={600}>
            {title}
          </Text>
        </Group>
        <Text size="sm" lh={1.21}>
          {children ?? <>&nbsp;</>}
        </Text>
      </Stack>
    </Card>
  );
};

export default SimpleCard;
