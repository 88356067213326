import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import React from 'react';
import pendingList from '@modules/sensors/pending/ListPage';
import { Sensor } from '@modules/sensors/model';
import pendingSinafisDetail from '@modules/sensors/sinafis/PendingDetailPage';
import pendingSensoterraDetail from '@modules/sensors/sensoterra/PendingDetailPage';
import pendingSuccessDetail from '@modules/sensors/pending/SuccessPage';
import pendingHorteeDetail from '@modules/sensors/hortee/PendingDetailPage';
import * as P from 'fp-ts/Predicate';
import { ProfilePredicate } from '@modules/profile/predicate';

const pendingRoutes = (
  <Route path="pending" {...createRoute({ restriction: P.not(ProfilePredicate.isAccessLimited) })}>
    <Route index {...createRoute(pendingList)} />
    <Route path={`${Sensor.Type.Sensoterra}/:id`} {...createRoute(pendingSensoterraDetail)} />
    <Route path={`${Sensor.Type.Sinafis}/:id`} {...createRoute(pendingSinafisDetail)} />
    <Route path={`${Sensor.Type.Hortee}/:id`} {...createRoute(pendingHorteeDetail)} />
    <Route path="success/:id" {...createRoute(pendingSuccessDetail)} />
  </Route>
);

export default pendingRoutes;
