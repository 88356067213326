import React, { FC, useCallback } from 'react';
import { HttpTask } from '@core/http';
import * as O from 'fp-ts/Option';
import * as E from 'fp-ts/Either';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { pipe } from 'fp-ts/function';
import * as TO from 'fp-ts/TaskOption';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import * as TE from 'fp-ts/TaskEither';
import { useFetchTask } from '@core/http/hooks';
import { renderHttpRemoteData, renderOptional } from '@shared/utils/render';
import DiseasesDataPlaceholder from '@modules/diseases/alert-reports/analysis/components/diseases/DiseasesDataPlaceholder';
import { Box, Group, Stack, Text } from '@mantine/core';
import DiseaseCaption from '@shared/modules/previsions/components/disease/DiseaseCaption';
import DiseasePrevisions from '@shared/modules/previsions/components/disease/DiseasePrevisions';
import PestCard from '@modules/diseases/pests/components/PestCard';
import DiseaseSkeleton from '@shared/modules/previsions/components/disease/DiseaseSkeleton';
import * as NEA from 'fp-ts/NonEmptyArray';
import { DateFormat } from '@shared/modules/dates';
import DiseaseChartCard from '@modules/diseases/alert-reports/analysis/components/AlertReportAnalysisChartCard';

interface AlertReportAnalysisDiseaseProps {
  alertReport: AlertReport.Detail;
}

const AlertReportAnalysisDisease: FC<AlertReportAnalysisDiseaseProps> = ({ alertReport }) => {
  const getDiseases = useCallback(
    (): HttpTask<O.Option<E.Either<AlertReport.Diseases, AlertReport.Pests>>> =>
      pipe(
        TO.fromNullable(alertReport.diseaseModels),
        TO.chainTaskK(() =>
          pipe(
            AlertReportsService.Analysis.getDiseases(alertReport.id),
            TE.map(E.left<AlertReport.Diseases, AlertReport.Pests>),
          ),
        ),
        TO.altW(() =>
          pipe(
            TO.fromNullable(alertReport.pest),
            TO.chainTaskK(() =>
              pipe(
                AlertReportsService.Analysis.getPests(alertReport.id),
                TE.map(E.right<AlertReport.Diseases, AlertReport.Pests>),
              ),
            ),
          ),
        ),
        TO.match(() => E.right(O.none), E.map(O.some)),
      ),
    [alertReport],
  );

  const [diseases] = useFetchTask(getDiseases);

  return renderHttpRemoteData(
    diseases,
    diseases =>
      pipe(
        diseases,
        O.match(
          () => (
            <Stack spacing={8} px={20}>
              <Text color="dark.5" weight={700} size={18} lh={1.45}>
                Données épidémiosurveillance une semaine avant le signalement
              </Text>
              <DiseasesDataPlaceholder>
                Nous ne disposons pas de modèle de prévision ou de vigilance pour ce bioagresseur
              </DiseasesDataPlaceholder>
            </Stack>
          ),
          E.match(
            ({ reportDay, previousDays, location }) => (
              <Stack spacing={8}>
                <Group position="apart" px={20}>
                  <Text color="dark.5" weight={700} size={18} lh={1.45}>
                    Données épidémiosurveillance une semaine avant le signalement
                  </Text>

                  <DiseaseCaption />
                </Group>
                {renderOptional(
                  NEA.fromArray(previousDays),
                  previousDays => (
                    <Stack spacing={20}>
                      <DiseasePrevisions
                        location={location}
                        main={reportDay}
                        days={previousDays}
                        date={alertReport.reportedAt}
                        formatStr={DateFormat.LocalDate}
                        withoutIcon
                      />
                      <Box px={20}>
                        <DiseaseChartCard days={previousDays} />
                      </Box>
                    </Stack>
                  ),
                  () => (
                    <DiseasesDataPlaceholder>
                      Aucune donnée existante une semaine avant le signalement
                    </DiseasesDataPlaceholder>
                  ),
                )}
              </Stack>
            ),
            ({ reportWeek, previousWeek }) => (
              <Stack spacing={14} px={20}>
                <Text color="dark.5" weight={700} size={18} lh={1.45}>
                  Suivi des risques par bioagresseur
                </Text>
                <Stack spacing={20} c="primary" fz={18} fw={700} lh={1.45}>
                  <Stack spacing={10}>
                    <Text>Semaine du signalement</Text>
                    <PestCard pest={reportWeek} />
                  </Stack>
                  <Stack spacing={10}>
                    <Text>Semaine précédent le signalement</Text>
                    <PestCard pest={previousWeek} />
                  </Stack>
                </Stack>
              </Stack>
            ),
          ),
        ),
      ),
    () => (
      <Box px={20}>
        <DiseaseSkeleton />
      </Box>
    ),
  );
};

export default AlertReportAnalysisDisease;
