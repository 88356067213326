import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import config from '@root/config';
import { Box, BoxProps } from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/utils';

export type GoogleMapsChildren = (map: google.maps.Map) => ReactNode;

export interface GoogleMapsProps extends Omit<PolymorphicComponentProps<'div', BoxProps>, 'children'> {
  options?: google.maps.MapOptions;
  className?: string;
  children: GoogleMapsChildren;
}

const GoogleMapsContent: FC<GoogleMapsProps> = ({ options, className, children, ...rest }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const optionsRef = useRef(options);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    if (ref.current) {
      setMap(new google.maps.Map(ref.current, optionsRef.current));
    }
  }, []);

  return (
    <Box ref={ref} className={className} {...rest} style={{ height: '100%', width: '100%', ...rest.style }}>
      {map ? children(map) : null}
    </Box>
  );
};

const GoogleMaps: FC<GoogleMapsProps> = ({ children, ...props }) => {
  return (
    <Wrapper apiKey={config.VITE_GOOGLE_MAPS_KEY} language="fr" region="fr-FR" libraries={['geometry']}>
      <GoogleMapsContent {...props}>{children}</GoogleMapsContent>
    </Wrapper>
  );
};

export default GoogleMaps;
