import React, { FC, PropsWithChildren } from 'react';
import { Stack, Text } from '@mantine/core';

const SecondStepForm: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack>
      <Text size={14} weight={700}>
        Seuils d'alertes
      </Text>
      <Stack>{children}</Stack>
    </Stack>
  );
};

export default SecondStepForm;
