import { Sensor } from '@modules/sensors/model';
import { DeepPartial } from 'react-hook-form';
import { Threshold } from '@modules/iot/model';
import { Sensoterra } from '@modules/sensors/sensoterra/model';
import { PendingSensor } from '@modules/sensors/pending/model';

export namespace SensoterraUtils {
  import humidityThreshold = Threshold.defaultHumidityScale;
  type Type = Sensor.Type.Sensoterra;

  export function getPendingDefaultValues(sensor: PendingSensor<Type>): DeepPartial<Sensoterra.RegisterParams> {
    return {
      technicalId: sensor.technicalId,
      comment: null,
      config: {
        coordinates: {},
        humidityThreshold,
      },
    };
  }
}
