import React, { FC } from 'react';
import ReportFlowLayout from '@modules/diseases/alert-reports/flow/layout/ReportFlowLayout';
import { Button, Card, Stack, Text, ThemeIcon } from '@mantine/core';
import { Link } from 'react-router-dom';
import { ReactComponent as BullhornIcon } from '@assets/icons/bullhorn.svg';
import config from '@root/config';

const CreateReportPlaceholder: FC = () => {
  return (
    <ReportFlowLayout type="create" title="Nouveau signalement" back="/diseases/alert-reports" withNav>
      <Stack p={40} sx={{ flex: '1 1 auto' }} justify="center">
        <Card py={35} px={10} bg="white" withBorder={false} radius={8}>
          <Stack align="center" ta="center">
            <ThemeIcon size={146} radius={100} bg="#f5f4f2" c="#C3BBB2">
              <BullhornIcon width={94} height={94} strokeWidth={1} />
            </ThemeIcon>

            <Text c="tertiary.8">
              Votre compte ne permet pas d'envoyer un signalement. Veuillez nous contacter pour plus d'informations.
            </Text>

            <Button component="a" href={config.VITE_CONTACT_LINK} tt="uppercase">
              Nous contacter
            </Button>
            <Button component={Link} to="/diseases/alert-reports" relative="path" tt="uppercase" variant="outline">
              Revenir aux signalements
            </Button>
          </Stack>
        </Card>
      </Stack>
    </ReportFlowLayout>
  );
};

export default CreateReportPlaceholder;
