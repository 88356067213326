import { MantineProvider, MantineThemeOverride, rem } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { IconCalendar, IconCheck } from '@tabler/icons-react';
import { rgba } from 'polished';
import 'dayjs/locale/fr';

const theme: MantineThemeOverride = {
  fontFamily: 'Barlow, sans-serif',
  headings: {
    sizes: {
      h1: {
        fontSize: rem(20),
      },
    },
  },
  primaryColor: 'primary',
  primaryShade: 5,
  focusRing: 'never',
  colors: {
    red: ['#FFF5F5', '#FFE3E3', '#FFC9C9', '#FFA8A8', '#FF8787', '#FF6B6B', '#FA5252', '#E1523D', '#E03131', '#C92A2A'],
    blue: [
      '#E2DED8',
      '#D0EBFF',
      '#A5D8FF',
      '#74C0FC',
      '#4DABF7',
      '#7B6756',
      '#228BE6',
      '#1C7ED6',
      '#1971C2',
      '#483729',
    ],
    tertiary: [
      '#F5F4F2',
      '#F5F4F2',
      '#F5F4F2',
      '#E2DED8',
      '#A39382',
      '#7B6756',
      '#483729',
      '#363636',
      '#2E2E2E',
      '#2E2E2E',
    ],
    primary: [
      '#FCE1D2',
      '#FCE1D2',
      '#F7B690',
      '#EAC448',
      '#E8933F',
      '#E1523D',
      '#E1523D',
      '#E1523D',
      '#E1523D',
      '#E1523D',
    ],
    // 0 - 4 -> S100
    // 5 - 9 -> S500
    secondary: [
      '#DDEEDB',
      '#DDEEDB',
      '#DDEEDB',
      '#DDEEDB',
      '#DDEEDB',
      '#43B02A',
      '#43B02A',
      '#43B02A',
      '#43B02A',
      '#43B02A',
    ],
    complementary: ['#FFFFFF', '#FCF6E4', '#FDF4EC', '#FAE9D9', '#FDEEEC'],
  },
  components: {
    Tooltip: {
      defaultProps: {
        events: { touch: true, hover: true, focus: false },
      },
    },
    Select: {
      defaultProps: theme => ({
        size: 'md',
        radius: 8,
      }),
      styles: theme => ({
        input: {
          background: 'rgba(226, 222, 216, 0.6)',
          border: '1px solid rgba(226, 222, 216, 0.6)',
          '::placeholder': {
            color: theme.colors.tertiary[8],
            opacity: 0.4,
            fontSize: 16,
          },
        },
      }),
    },
    TextInput: {
      defaultProps: {
        size: 'md',
        radius: 8,
      },
      styles: {
        input: { backgroundColor: 'rgba(226, 222, 216, 0.6)' },
      },
    },
    PasswordInput: {
      defaultProps: {
        size: 'md',
        radius: 8,
      },
      styles: {
        input: { backgroundColor: 'rgba(226, 222, 216, 0.6)' },
      },
    },
    Input: {
      defaultProps: {
        size: 'md',
        radius: 8,
      },
      styles: {
        input: { backgroundColor: 'rgba(226, 222, 216, 0.6)' },
      },
    },
    Textarea: {
      defaultProps: {
        size: 'md',
        radius: 8,
      },
      styles: {
        input: { backgroundColor: 'rgba(226, 222, 216, 0.6)' },
      },
    },
    NumberInput: {
      defaultProps: {
        size: 'md',
        radius: 8,
      },
      styles: {
        input: { backgroundColor: 'rgba(226, 222, 216, 0.6)' },
      },
    },
    Text: {
      defaultProps: {
        color: 'inherit',
      },
    },
    InputWrapper: {
      styles: {
        label: {
          fontWeight: 600,
          paddingBottom: 5,
        },
      },
    },
    Paper: {
      defaultProps: {
        withBorder: true,
        radius: 16,
      },
    },
    NavLink: {
      defaultProps: {
        // typed by @shared/Link
        component: NavLink,
      },
    },
    Radio: {
      defaultProps: {
        color: 'primary.5',
      },
    },
    Modal: {
      styles: {
        root: {
          position: 'relative',
          zIndex: 20000,
        },
      },
      defaultProps: theme => ({
        overlayProps: {
          color: theme.colors.gray[2],
          blur: 2,
        },
      }),
    },
    Drawer: {
      styles: {
        root: {
          position: 'relative',
          zIndex: 20000,
        },
      },
    },
    Stepper: {
      defaultProps: theme => ({
        color: '#ce5b46',
        completedIcon: <IconCheck strokeWidth={3} />,
        allowNextStepsSelect: false,
      }),
      styles: theme => ({
        content: {
          paddingTop: 23,
        },
        separator: {
          margin: 0,
          height: 3,
          backgroundColor: rgba('#e1ded9', 0.45),
        },
        stepIcon: {
          color: theme.colors.tertiary[8],
          borderWidth: 2,
          borderColor: rgba(theme.colors.tertiary[3], 0.45),
          backgroundColor: rgba(theme.colors.tertiary[3], 0.45),
          '&[data-progress]': {
            backgroundColor: theme.colors.primary[1],
          },
        },
        stepCompletedIcon: {},
      }),
    },
    DatePickerInput: {
      defaultProps: {
        locale: 'fr',
        valueFormat: 'DD/MM/YYYY',
        icon: <IconCalendar />,
      },
    },
  },
};
const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
      <Notifications position="top-right" zIndex={10000000} />
      {children}
    </MantineProvider>
  );
};

export default MantineTheme;
