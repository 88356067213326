import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import React from 'react';
import emergencyNumbersListRoute from '@modules/emergency-numbers/ListPage';

const emergencyNumbersRoutes = (
  <Route path="emergency-numbers">
    <Route index {...createRoute(emergencyNumbersListRoute)} />
  </Route>
);

export default emergencyNumbersRoutes;
