import styled from '@emotion/styled';
import { createPolymorphicComponent, UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import { styledOptions } from '@styles/utils';

interface SelectedProps {
  $selected: boolean;
}

export const ReportCategoryButton = createPolymorphicComponent<'button', UnstyledButtonProps & SelectedProps>(styled(
  UnstyledButton,
  styledOptions,
)<SelectedProps>`
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;

  ${props =>
    props.$selected &&
    `
    border: 4px solid white;
  `};

  img {
    width: 50%;
    height: 50%;
  }
`);
