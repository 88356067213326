import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { Anchor, AspectRatio, Box, Card, Group, Image, Text, ThemeIcon } from '@mantine/core';
import AlertReportInactiveBadge from '@modules/diseases/alert-reports/components/AlertReportInactiveBadge';
import { IconChevronRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useProfile } from '@modules/profile/loader';

interface AlertReportRowProps {
  alertReport: AlertReport.HistoryItem;
}

const AlertReportRow: FC<AlertReportRowProps> = ({ alertReport }) => {
  const { isAccessLimited } = useProfile().subscription;

  return (
    <Card p={20} pr={10} radius={10} withBorder={false} shadow="0 10px 20px 0 rgba(0, 0, 0, 0.1)">
      <Group noWrap spacing={0} w="100%" align="stretch">
        <Box w={77} sx={{ flex: '0 0 77px' }}>
          <AspectRatio ratio={1}>
            <Image
              src={alertReport.image?.cover_150x150}
              withPlaceholder
              radius={10}
              opacity={alertReport.status === AlertReport.Status.Inactive ? 0.4 : 1}
            />
          </AspectRatio>
        </Box>

        <Box w="100%" pl={15} sx={{ flex: '1 1 auto' }}>
          {alertReport.status === AlertReport.Status.Inactive ? <AlertReportInactiveBadge /> : null}

          <Text fz={12} c="dark.3" mb={2}>
            {alertReport.reportedAt}
          </Text>

          <Text fw={600} c="tertiary.8" lh={1.2}>
            {alertReport.type}
          </Text>

          {!isAccessLimited && (
            <Box>
              <Anchor
                component={Link}
                to={`../${alertReport.id}/analysis`}
                relative="path"
                td="underline"
                fz={12}
                fw={500}
              >
                Consulter l'analyse
              </Anchor>
            </Box>
          )}
        </Box>

        <Group sx={{ flex: '0 0 40px' }} align="center">
          <Box component={Link} to={`../${alertReport.id}`} relative="path" w={40} h={40} p={10}>
            <ThemeIcon radius={100} size={20} color="blue.0">
              <IconChevronRight size={14} />
            </ThemeIcon>
          </Box>
        </Group>
      </Group>
    </Card>
  );
};

export default AlertReportRow;
