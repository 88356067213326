import React, { FC } from 'react';
import { ActionIcon, Badge, Box, Card, Group, Stack, Text, Tooltip } from '@mantine/core';
import { IconDroplet, IconEye, IconTemperature } from '@tabler/icons-react';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Link } from 'react-router-dom';
import { renderNullable } from '@shared/utils/render';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import SensorsDataListMeasure from '@modules/diseases/alert-reports/analysis/components/sensors/SensorsDataListMeasure';
import { Measure } from '@modules/measures/model';

interface SensorsDataListProps {
  sensors: NEA.NonEmptyArray<AlertReport.Sensor>;
}

const SensorsDataList: FC<SensorsDataListProps> = ({ sensors }) => {
  return (
    <Card radius={10} shadow="0px 4px 10px 0px rgba(0, 0, 0, 0.10)" withBorder={false} px={20} py={0}>
      {sensors.map(({ id, technicalId, type, serial, canAccessDetail, zone, measures }) => (
        <Stack
          key={technicalId}
          py={20}
          sx={({ colors }) => ({ '&:not(:last-child)': { borderBottom: `1px solid ${colors.gray[1]}` } })}
        >
          <Group position="apart">
            <Box>
              {renderNullable(zone, zone => (
                <Badge h="auto" radius={13} px={17} py={3} bg="complementary.3" c="primary" lh={1.3} fz={12} fw={600}>
                  {zone}
                </Badge>
              ))}

              <Text fz={14} lh={1.55} c="dark.5" fw={600}>
                {serial}
              </Text>
            </Box>

            {canAccessDetail && (
              <ActionIcon
                component={Link}
                to={`/sensors/map/${id}`}
                size={36}
                bg="tertiary.2"
                radius={4}
                style={{ justifySelf: 'right' }}
              >
                <IconEye strokeWidth={1.2} />
              </ActionIcon>
            )}
          </Group>
          <Group style={{ justifyContent: 'space-around' }}>
            <Group
              spacing={10}
              sx={({ colors }) => ({ border: `1px solid ${colors.gray[3]}`, borderRadius: 8 })}
              p={10}
              pl={8}
            >
              <Tooltip label="Humidité">
                <IconDroplet strokeWidth={1.5} />
              </Tooltip>
              <SensorsDataListMeasure measures={measures} type={Measure.Type.Humidity} />
            </Group>

            <Group
              spacing={10}
              p={10}
              pl={6}
              sx={({ colors }) => ({ border: `1px solid ${colors.gray[3]}`, borderRadius: 8 })}
            >
              <Tooltip label="Température">
                <IconTemperature spacing={10} strokeWidth={1.5} />
              </Tooltip>
              <SensorsDataListMeasure measures={measures} type={Measure.Type.Temperature} />
            </Group>
          </Group>
        </Stack>
      ))}
    </Card>
  );
};

export default SensorsDataList;
