import React, { FC, useMemo } from 'react';
import { Previsions } from '@shared/modules/previsions/model';

import * as Styled from './TodayWeather.styles';
import { Box, Group, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { PrevisionsUtils } from '@shared/modules/previsions/utils';
import { renderNullable, renderOptional } from '@shared/utils/render';
import WeatherTemperature from '@shared/modules/previsions/components/weather/temperature/WeatherTemperature';
import { NumberUtils } from '@shared/utils/number';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import WeatherForecastParamTooltip from '@shared/modules/previsions/components/weather/WeatherForecastParamTooltip';
import { formatDate, LocalDate, parseDate } from '@shared/modules/dates';
import { Geo } from '@shared/modules/geo/model';

interface MainWeatherProps {
  values: Previsions.Weather.ForecastValues | null;
  location: Geo.City | null;
  date?: LocalDate;
}

const MainWeather: FC<MainWeatherProps> = ({ values, location, date }) => {
  const typeInfo = useMemo(() => PrevisionsUtils.getWeatherTypeInfos(values), [values]);

  return (
    <Styled.TodayWeatherContainer>
      <Stack>
        <Group position="apart" noWrap>
          <Box>
            {renderNullable(location, ({ city, postalCode }) => (
              <Text c="white" size={20} fw={600} lh="normal">
                {city}, {postalCode}
              </Text>
            ))}

            <Text c="white" size={14} lh={1.43} mt={5}>
              {pipe(
                parseDate(date),
                O.map(date => formatDate(date, 'dd LLLL yyyy')),
                O.getOrElse(() => "Aujourd'hui"),
              )}
            </Text>
          </Box>

          {renderOptional(typeInfo, ({ label, icon: Icon }) => (
            <Tooltip label={label} position="bottom">
              <ThemeIcon size={53} bg="transparent">
                <Icon color="white" />
              </ThemeIcon>
            </Tooltip>
          ))}
        </Group>

        <Box sx={{ flex: '1 1 auto' }} />

        <Group position="apart">
          <Box>
            {renderNullable(values, values => (
              <Text
                size={65}
                c="white"
                weight={600}
                lh={1}
                sx={{
                  ':after': {
                    content: "'°C'",
                    verticalAlign: 'top',
                    fontSize: 16,
                    fontWeight: 'normal',
                  },
                }}
              >
                {NumberUtils.formatNumber(values[Previsions.Weather.DayForecast.Param.AverageTemp])}
              </Text>
            ))}
            {renderNullable(values, values => (
              <Box w={100} mt={11}>
                <WeatherTemperature values={values} />
              </Box>
            ))}
          </Box>

          <Stack justify="flex-end">
            <Styled.TodayWeatherInfos>
              <Text>
                Point de rosée
                {renderNullable(values, values => (
                  <Text component="span">
                    {NumberUtils.formatTemperature(values[Previsions.Weather.DayForecast.Param.AverageDewPoint])}
                  </Text>
                ))}
              </Text>

              <Text>
                Précipitation
                {renderNullable(values, values => (
                  <Text component="span">
                    {NumberUtils.formatNumber(values[Previsions.Weather.DayForecast.Param.Precipitation], {
                      style: 'unit',
                      unit: 'millimeter',
                      maximumFractionDigits: 1,
                    })}
                  </Text>
                ))}
              </Text>

              <Text>
                ETP cumulée
                {renderNullable(values, values => (
                  <Text component="span">
                    {NumberUtils.formatNumber(values[Previsions.Weather.DayForecast.Param.ETP], {
                      style: 'unit',
                      unit: 'millimeter',
                      maximumFractionDigits: 1,
                    })}
                  </Text>
                ))}
              </Text>
              {renderNullable(values, values => (
                <WeatherForecastParamTooltip values={values}>
                  <Text td="underline" pt={3} style={{ cursor: 'default' }}>
                    Voir tout
                  </Text>
                </WeatherForecastParamTooltip>
              ))}
            </Styled.TodayWeatherInfos>
          </Stack>
        </Group>
      </Stack>
    </Styled.TodayWeatherContainer>
  );
};

export default MainWeather;
