import React, { FC } from 'react';
import { Previsions } from '@shared/modules/previsions/model';
import { Card, Group, Text } from '@mantine/core';
import { IconArrowNarrowDown, IconArrowNarrowUp } from '@tabler/icons-react';
import { NumberUtils } from '@shared/utils/number';

interface WeatherTemperatureProps {
  values: Previsions.Weather.ForecastValues;
}

const WeatherTemperature: FC<WeatherTemperatureProps> = ({ values }) => {
  return (
    <Card bg="#f5f4f2" radius={10} withBorder={false} p={8}>
      <Group spacing={4} c="tertiary.8" fz={12} position="center" noWrap>
        <Group spacing={0} noWrap>
          <IconArrowNarrowDown size={16} />

          <Text size={17} fw={600}>
            {NumberUtils.formatTemperature(values[Previsions.Weather.DayForecast.Param.MinTemp])}
          </Text>
        </Group>

        <Group spacing={0} noWrap>
          <IconArrowNarrowUp size={16} />

          <Text size={17} fw={600}>
            {NumberUtils.formatTemperature(values[Previsions.Weather.DayForecast.Param.MaxTemp])}
          </Text>
        </Group>
      </Group>
    </Card>
  );
};

export default WeatherTemperature;
