import React, { FC } from 'react';
import { Button, Divider, NumberInput, Stack, Text } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Sensoterra } from '@modules/sensors/sensoterra/model';
import { useHashDisclosure } from '@core/router';
import GeolocationDrawer from '@modules/sensors/pending/components/GeolocationDrawer';
import { Sensor } from '@modules/sensors/model';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import { Hortee } from '@modules/sensors/hortee/model';

const coordinatesPrecision = 14;

interface ThirdStepFormProps {
  type: Sensor.Type;
}

type RegisterParams = Sensoterra.RegisterParams | Hortee.RegisterParams;

const ThirdStepForm: FC<ThirdStepFormProps> = ({ type }) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useEnhancedFormContext<RegisterParams>();

  const [openLocation, handlers] = useHashDisclosure('#location');

  const handleValidate = (location: google.maps.LatLngLiteral) => {
    setValue('config.coordinates.latitude', location.lat);
    setValue('config.coordinates.longitude', location.lng);
  };

  const center = O.toUndefined(
    sequenceS(O.Apply)({
      lat: O.fromNullable(watch('config.coordinates.latitude')),
      lng: O.fromNullable(watch('config.coordinates.longitude')),
    }),
  );

  return (
    <>
      <Stack spacing={25}>
        <Stack align="center" spacing={20}>
          <Text size={17} weight={600} lh={1.29} color="tertiary.8">
            Localisation de la sonde
          </Text>
          <Button variant="outline" color="primary" onClick={handlers.open}>
            <Text size={14} weight={600} tt="uppercase">
              {center ? 'Modifier la position' : ' Géolocaliser ma sonde'}
            </Text>
          </Button>
        </Stack>
        <Divider
          label={
            <Text tt="uppercase" weight={600} size={16} opacity={0.35}>
              Ou
            </Text>
          }
          labelPosition="center"
          styles={{ label: { marginTop: '0 !important' } }}
        />
        <Stack spacing={15}>
          <Controller
            control={control}
            name="config.coordinates.latitude"
            render={({ field }) => (
              <NumberInput
                {...field}
                error={!!errors.config?.coordinates?.latitude}
                label="Latitude"
                placeholder="Latitude"
                precision={coordinatesPrecision}
                required
              />
            )}
          />
          <Controller
            control={control}
            name="config.coordinates.longitude"
            render={({ field }) => (
              <NumberInput
                {...field}
                error={!!errors.config?.coordinates?.longitude}
                label="Longitude"
                placeholder="Longitude"
                precision={coordinatesPrecision}
                required
              />
            )}
          />
        </Stack>
      </Stack>
      <GeolocationDrawer
        type={type}
        center={center}
        opened={openLocation}
        onClose={handlers.close}
        onValidate={handleValidate}
      />
    </>
  );
};

export default ThirdStepForm;
