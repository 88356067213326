import React, { FC } from 'react';
import * as Styled from './Nav.styles';
import { Navbar } from '@mantine/core';
import { IconHome2, IconPhone, IconSeeding, IconSpeakerphone } from '@tabler/icons-react';
import { SharedLink } from '@styles/shared/Link';
import { ReactComponent as IconPlatform } from '@assets/logos/logo.svg';
import config from '@root/config';

const Nav: FC = () => {
  return (
    <Navbar
      pos="fixed"
      bottom={0}
      left={0}
      w="100%"
      bg="tertiary.8"
      height={64}
      sx={{ borderRadius: '16px 16px 0 0', zIndex: 10000 }}
      withBorder={false}
    >
      <Styled.NavListing>
        <li>
          <SharedLink label="Portail&nbsp;Pro" icon={<IconHome2 />} to="/" />
        </li>
        <li>
          <SharedLink label="Parc" icon={<IconSeeding />} to="/sensors" />
        </li>
        <Styled.NavPlatformReturn>
          <SharedLink
            icon={<IconPlatform width={30} height={30} />}
            to={config.VITE_PLATFORM_URL}
            styles={{ body: { maxHeight: 0 }, icon: { paddingBottom: 4 } }}
          />
        </Styled.NavPlatformReturn>
        <li>
          <SharedLink label="Epidémio" icon={<IconSpeakerphone />} to="/diseases" />
        </li>
        <li>
          <SharedLink label="Urgence" icon={<IconPhone />} to="/emergency-numbers" />
        </li>
      </Styled.NavListing>
    </Navbar>
  );
};

export default Nav;
