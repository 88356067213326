import styled from '@emotion/styled';
import { Box, BoxProps, createPolymorphicComponent } from '@mantine/core';

export const PageContainer = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`);

export const PageContent = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
`);

export const PageBottom = styled.div<{ withNav: boolean }>`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  z-index: 10000;

  ${({ withNav }) =>
    withNav &&
    `
    padding-bottom: 64px;
  `}
`;

export const PageBottomBar = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  width: 100%;
  padding: 20px 20px 30px;
  background-image: linear-gradient(to bottom, transparent, ${({ theme }) => theme.white} 90%);
`;

export const PageBottomSafeArea = styled.div<{ withNav: boolean }>`
  height: env(safe-area-inset-bottom, 20px);

  ${({ withNav, theme }) =>
    withNav &&
    `
    background-color: ${theme.colors.tertiary[8]}
  `}
`;
