import React, { FC } from 'react';
import { Box, Center, Image, Text } from '@mantine/core';
import headerBackground from '@assets/images/home-header.webp';
import platformLogo from '@assets/logos/logo-title.svg';

const HomeHeader: FC = () => {
  return (
    <Center w="100%" pos="relative">
      <Image src={headerBackground} height={215} />
      <Box pos="absolute">
        <Image src={platformLogo} width={148} />

        <Text size={12} lh={1.67} tt="uppercase" ta="center" color="white">
          Portail pro
        </Text>
      </Box>
    </Center>
  );
};

export default HomeHeader;
