import React, { ChangeEventHandler, FC, MouseEventHandler } from 'react';
import { AlertTrigger } from '@modules/alert-triggers/model';
import { Badge, Card, Group, Switch, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Threshold } from '@modules/iot/model';
import { useAction } from '@core/router/action';
import { actions } from '@modules/alert-triggers/ListPage';
import State = AlertTrigger.State;

interface AlertRowProps {
  alert: AlertTrigger;
}

const AlertRow: FC<AlertRowProps> = ({ alert }) => {
  const [loading, toggleState] = useAction(actions.toggleState);

  const handlePreventClickDefault: MouseEventHandler = e => e.stopPropagation();

  const handleSwitchChange: ChangeEventHandler = () => {
    toggleState({ id: alert.id, state: AlertTrigger.stateContrary[alert.state] })();
  };

  return (
    <Card component={Link} to={alert.id} px={10} py={15} radius={6} withBorder={false}>
      <Group position="apart" noWrap>
        <Text color="tertiary.8" size={16} weight="normal">
          {alert.name}
        </Text>

        <Switch
          wrapperProps={{ onClick: handlePreventClickDefault }}
          onChange={handleSwitchChange}
          label={
            <Text size={14} lh={1.43} color="tertiary.8">
              {AlertTrigger.stateLabel[alert.state]}
            </Text>
          }
          checked={alert.state === State.Enabled}
          labelPosition="left"
          disabled={loading}
        />
      </Group>
      <Badge mt={5} radius={2} color={Threshold.levelColor[alert.thresholdLevel]} tt="none" px={8}>
        {Threshold.levelLabel[alert.thresholdLevel]}
      </Badge>
    </Card>
  );
};

export default AlertRow;
