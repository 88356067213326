import React, { FC } from 'react';
import { Card, Group, Text } from '@mantine/core';
import { IconEyeOff } from '@tabler/icons-react';

const AlertReportInactiveBadge: FC = () => {
  return (
    <Card
      px={8}
      py={2}
      bg="rgba(232, 147, 63, 0.15)"
      c="primary.4"
      radius={5}
      sx={{ display: 'inline-block', borderColor: 'rgba(232, 147, 63, 0.4) !important' }}
    >
      <Group noWrap spacing={3}>
        <IconEyeOff size={17} />
        <Text fz={12} c="rgba(0, 0, 0, 0.5)">
          Inactif
        </Text>
      </Group>
    </Card>
  );
};

export default AlertReportInactiveBadge;
