import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';

import * as Styled from './ReportCategoryCard.styles';
import { AspectRatio, LoadingOverlay, Stack, Text } from '@mantine/core';

interface ReportCategoryCardProps {
  category: AlertReport.Category;
  selected: boolean;
  loading: boolean;
  onSelect: (category: AlertReport.Category) => void;
}

const ReportCategoryCard: FC<ReportCategoryCardProps> = ({ category, loading, selected, onSelect }) => {
  const handleClick = () => onSelect(category);

  return (
    <AspectRatio ratio={1}>
      <Styled.ReportCategoryButton
        pos="relative"
        p={15}
        bg={category.color}
        disabled={loading}
        $selected={selected}
        onClick={handleClick}
      >
        <LoadingOverlay visible={loading} />

        <Stack ta="center" align="center" justify="center" spacing={12}>
          <img src={category.images.fit_300x300} alt={category.label} />

          <Text
            c="white"
            tt="uppercase"
            fz={15}
            fw={600}
            lh={1.2}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              overflow: 'hidden',
            }}
          >
            {category.label}
          </Text>
        </Stack>
      </Styled.ReportCategoryButton>
    </AspectRatio>
  );
};

export default ReportCategoryCard;
