import { Sensor } from '@modules/sensors/model';
import { DeepPartial } from 'react-hook-form';
import { Threshold } from '@modules/iot/model';
import { Sinafis } from '@modules/sensors/sinafis/model';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import { PendingSensor } from '@modules/sensors/pending/model';

export namespace SinafisUtils {
  import ExtendedMeasureProbeMapping = Sinafis.Config.ExtendedMeasureProbeMapping;
  import humidityThreshold = Threshold.defaultHumidityScale;
  import temperatureThreshold = Threshold.defaultTemperatureScale;
  type Type = Sensor.Type.Sinafis;

  export function getPendingDefaultValues(
    sensor: PendingSensor<Type>,
    measureMappings: ExtendedMeasureProbeMapping,
  ): DeepPartial<Sinafis.RegisterParams> {
    const config = pipe(
      measureMappings,
      R.mapWithIndex(key => {
        switch (key) {
          case 'leaf':
            return { humidityThreshold };
          default:
            return { humidityThreshold, temperatureThreshold };
        }
      }),
    );

    return {
      comment: '',
      name: '',
      technicalId: sensor.technicalId,
      config: {
        ...config,
        transmitter: { humidityThreshold, temperatureThreshold },
      },
    };
  }
}
