import React, { ReactNode } from 'react';
import { CustomPointFeature, MarkerWithLocation, useMapCluster } from '@shared/modules/maps/hooks/supercluster';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import Supercluster from 'supercluster';
import Pin from '@shared/modules/maps/components/Pin';
import { Text } from '@mantine/core';

interface MarkersProps<Marker> {
  map: google.maps.Map;
  markers: Array<MarkerWithLocation<Marker>>;
  supercluster: Supercluster<CustomPointFeature<Marker>>;
  children: (marker: MarkerWithLocation<Marker>) => ReactNode;
}

function MapClustering<Marker>({ map, markers, supercluster, children }: MarkersProps<Marker>) {
  const clusters = useMapCluster(map, markers, supercluster);

  const handleClusterClick = (id: number, latitude: number, longitude: number) => () => {
    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(id), 20);
    map.setZoom(expansionZoom);
    map.panTo({ lat: latitude, lng: longitude });
  };

  return (
    <>
      {clusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const position = { lat: latitude, lng: longitude };

        if (cluster.properties.cluster) {
          const { properties } = cluster;
          return (
            <GoogleMapsOverlay key={`supercluster-${properties.cluster_id}`} position={position} map={map}>
              <Pin
                onClick={handleClusterClick(properties.cluster_id, latitude, longitude)}
                size={50}
                color="tertiary.5"
              >
                <Text size={18} weight={700}>
                  {properties.point_count}
                </Text>
              </Pin>
            </GoogleMapsOverlay>
          );
        } else {
          return children(cluster.properties.marker);
        }
      })}
    </>
  );
}

export default MapClustering;
