import React, { FC } from 'react';
import { Button, Modal, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { IconBulb } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

interface ReportImagesWarningProps {
  opened: boolean;
  onClose: () => void;
}

const ReportImagesWarning: FC<ReportImagesWarningProps> = ({ opened, onClose }) => {
  return (
    <Modal opened={opened} onClose={onClose} centered withCloseButton={false}>
      <Stack p={10} align="center" justify="center" ta="center">
        <ThemeIcon size={146} radius={100} bg="#f5f4f2" c="#C3BBB2">
          <IconBulb size={94} strokeWidth={1} />
        </ThemeIcon>

        <Title c="tertiary.8">Mettre en valeur votre signalement</Title>

        <Text c="tertiary.8">
          Plus il y a de photos, plus le signalement sera visible. Ajoutez au moins 3 photos pour voir en un coup d’oeil
          le bio agresseur.
        </Text>

        <Button onClick={onClose} mt={10} tt="uppercase">
          Ajouter des photos
        </Button>
        <Button component={Link} to="../location" relative="path" tt="uppercase" variant="outline">
          Passer cette étape
        </Button>
      </Stack>
    </Modal>
  );
};

export default ReportImagesWarning;
