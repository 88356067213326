import React, { FC } from 'react';
import { defineRoute, usePreserveNavigate } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { Sensor } from '@modules/sensors/model';
import { SensorsService } from '@modules/sensors/service';
import { ActionIcon, Badge, Box, Group, Image, Stack, Text } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { renderNullable, renderOptional } from '@shared/utils/render';
import SignalThemeIcon from '@modules/measures/SignalThemeIcon';
import BatteryThemeIcon from '@modules/measures/BatteryThemeIcon';
import { Measure } from '@modules/measures/model';
import { MeasureUtils } from '@modules/measures/utils';
import { useLocation } from 'react-router-dom';
import MeasureCards from '@modules/measures/last/MeasureCards';
import * as D from 'fp-ts/Date';
import * as TE from 'fp-ts/TaskEither';
import { subWeeks } from 'date-fns';
import { pipe } from 'fp-ts/function';
import { DateFormat, formatDate } from '@shared/modules/dates';
import { Utils } from '@shared/utils/model';
import SensorMeasure from '@modules/measures/SensorMeasure';
import getMeasureValue = MeasureUtils.getMeasureValue;

const params = z.object({
  id: Sensor.Id,
});

export const loader = defineLoader({
  params,
  handler: ({ params }) => {
    const today = D.create();

    return pipe(
      SensorsService.getActiveSensor(params.id),
      TE.bindTo('sensor'),
      TE.bind('measures', ({ sensor }) =>
        SensorsService.getMeasures(sensor.id, sensor.type, {
          startDate: formatDate(subWeeks(today, 1), DateFormat.LocalDateTime),
          endDate: formatDate(today, DateFormat.LocalDateTime),
          unit: Utils.ChronoUnit.Hours,
        }),
      ),
      httpTaskToResponseTask,
    );
  },
});

const DetailPage: FC = () => {
  const navigate = usePreserveNavigate();
  const location = useLocation();
  const { sensor, measures } = useLoader<typeof loader>();

  const signal = getMeasureValue(sensor.measures, Measure.Type.Signal);
  const battery = getMeasureValue(sensor.measures, Measure.Type.Battery);

  const isExpand = location.hash === '#expand';
  const toggleExpand = () => navigate({ hash: isExpand ? undefined : 'expand' });

  return (
    <Stack px={20} py={15} spacing={5}>
      <Group position="apart" noWrap>
        <Group spacing={8} noWrap>
          <Text size={20} color="primary.5" weight={600} mr={5}>
            Sonde {sensor.name}
          </Text>
          {renderOptional(signal, signal => (
            <SignalThemeIcon signal={signal} />
          ))}
          {renderOptional(battery, battery => (
            <BatteryThemeIcon battery={battery} />
          ))}
        </Group>
        <ActionIcon size={32} radius="100%" bg="#d0d0d0" c="black" onClick={toggleExpand}>
          {isExpand ? <IconChevronDown /> : <IconChevronUp />}
        </ActionIcon>
      </Group>
      {renderNullable(sensor.zone, ({ name }) => (
        <Badge w="fit-content" bg="primary.0" c="tertiary.8" tt="none" radius={2} fw={500}>
          {name}
        </Badge>
      ))}
      <Group position="apart">
        <Text size={12} color="tertiary.8">
          Identifiant sonde : {sensor.serial}
        </Text>
        <Image src={Sensor.typeLogo[sensor.type]} width={70} />
      </Group>
      <Box>
        <MeasureCards sensor={sensor} />
        <SensorMeasure sensor={sensor} measures={measures} />
      </Box>
    </Stack>
  );
};

const mapDetailRoute = defineRoute({
  element: <DetailPage />,
  loader,
});

export default mapDetailRoute;
