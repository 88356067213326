import React, { FC, useState } from 'react';
import { Button, Checkbox, Drawer, Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { useLoader } from '@core/router/loader';
import { loader } from '@modules/alert-triggers/CreatePage';
import { useEnhancedFormContext } from '@shared/modules/form';
import { AlertTrigger } from '@modules/alert-triggers/model';
import CreateParams = AlertTrigger.CreateParams;
import { Sensor } from '@modules/sensors/model';

interface AlertSensorsDrawerProps {
  opened: boolean;
  onClose: () => void;
}

const AlertSensorsDrawer: FC<AlertSensorsDrawerProps> = ({ opened, onClose }) => {
  const { setValue, getValues, resetField } = useEnhancedFormContext<CreateParams>();
  const [sensorIds, setSensorIds] = useState<Array<Sensor.Id>>(getValues('sensors'));
  const { zones, sensors } = useLoader<typeof loader>();

  const handleChange: (values: Array<Sensor.Id>) => void = setSensorIds;

  const handleSelectAll = () => setSensorIds(sensors.map(({ id }) => id));

  const handleValidate = () => {
    resetField('type');
    setValue('sensors', sensorIds);
    onClose();
  };

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      size="100%"
      transitionProps={{ transition: 'slide-up', timingFunction: 'ease' }}
      title={
        <Text color="tertiary.4" weight={600} size={17}>
          Sélectionner des sondes
        </Text>
      }
      styles={theme => ({
        header: { background: theme.colors.tertiary[8] },
        content: {
          background: theme.colors.tertiary[8],
          display: 'flex',
          flexDirection: 'column',
          border: 'none !important',
        },
        body: { padding: 0, display: 'flex', flexDirection: 'column', flexGrow: 1 },
        close: {
          border: 'none',
          width: '26px ',
          height: '26px ',
          background: 'transparent !important',
          svg: {
            width: '100%',
            height: '100%',
            color: 'white',
          },
        },
      })}
    >
      <Group position="right" p={20}>
        <UnstyledButton onClick={handleSelectAll}>
          <Text color="white" lh={1.33} size={12} td="underline" weight={500}>
            Sélectionner tout
          </Text>
        </UnstyledButton>
      </Group>
      <Checkbox.Group
        value={sensorIds}
        display="flex"
        style={{ flexDirection: 'column', rowGap: 20 }}
        onChange={handleChange}
      >
        {zones.map(({ id, name }) => (
          <Stack key={id} spacing={20}>
            <Text bg="rgba(255,255,255,0.1)" color="white" size={16} weight={600} px={18} py={4}>
              {name}
            </Text>
            <Stack spacing={17}>
              {sensors
                .filter(({ zone }) => zone?.id === id)
                .map(({ id, name }) => (
                  <Checkbox
                    key={id}
                    value={id}
                    labelPosition="left"
                    label={
                      <Text color="white" size={14} lh={1.43}>
                        {name}
                      </Text>
                    }
                    px={18}
                    styles={{
                      body: {
                        justifyContent: 'space-between',
                      },
                    }}
                  />
                ))}
            </Stack>
          </Stack>
        ))}
      </Checkbox.Group>
      <Button
        mt="auto"
        h={61}
        color="primary.5"
        tt="uppercase"
        onClick={handleValidate}
        pos="sticky"
        bottom={0}
        radius={0}
      >
        Valider
      </Button>
    </Drawer>
  );
};

export default AlertSensorsDrawer;
