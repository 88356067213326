import React, { FC } from 'react';
import background from '@assets/images/disease-background.jpg';
import { Badge, Box, Card, Group, Spoiler, Stack, Text, ThemeIcon } from '@mantine/core';
import { renderNullable } from '@shared/utils/render';
import * as R from 'fp-ts/Record';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { Previsions } from '@shared/modules/previsions/model';
import { ReactComponent as BullhornIcon } from '@assets/icons/bullhorn.svg';
import Disease = Previsions.Disease;
import * as O from 'fp-ts/Option';
import { DateFormat, formatDate, LocalDateTime, parseDate } from '@shared/modules/dates';
import { Geo } from '@shared/modules/geo/model';

interface MainDiseaseProps {
  today: Disease.Forecast;
  location: Geo.City | null;
  date?: LocalDateTime;
  withoutIcon?: boolean;
}

const MainDisease: FC<MainDiseaseProps> = ({ today: forecast, location, date, withoutIcon }) => {
  const today: Record<Disease.Model, Disease.Forecast.Value> = R.filterMap(O.fromNullable)(forecast);

  return (
    <Card
      radius={8}
      px={15}
      pt={20}
      pb={10}
      withBorder={false}
      sx={{
        minHeight: '100%',
        gridColumn: 'span 2',
        background: `url(${background}) no-repeat bottom center`,
        backgroundSize: 'cover',
      }}
    >
      <Group position="apart" noWrap>
        <Box>
          {renderNullable(location, location => (
            <Text color="white" size={20} weight={600} lh="normal">
              {location.city}, {location.postalCode}
            </Text>
          ))}

          <Text color="white" size={14} mt={5}>
            {pipe(
              parseDate(date, DateFormat.LocalDateTime),
              O.map(date => formatDate(date, 'dd LLLL yyyy')),
              O.getOrElse(() => "Aujourd'hui"),
            )}
          </Text>
        </Box>

        {!withoutIcon && (
          <ThemeIcon size={60} bg="transparent">
            <BullhornIcon />
          </ThemeIcon>
        )}
      </Group>

      {renderNullable(today, today => (
        <Card p={15} radius={8} bg="rgba(255, 255, 255, 0.3)" withBorder={false} w="100%" mt={25}>
          <Spoiler
            maxHeight={110}
            showLabel={
              <Badge color="tertiary.3" mt={12}>
                <Text weight={400} color="tertiary.5" size={12} lh={1}>
                  +{R.keys(today).length} indice(s)
                </Text>
              </Badge>
            }
            hideLabel={
              <Badge color="tertiary.3" mt={12}>
                <Text weight={400} color="tertiary.5" size={12} lh={1}>
                  Voir moins
                </Text>
              </Badge>
            }
          >
            <Stack spacing={8}>
              {pipe(
                R.toEntries(today),
                A.map(([model, entry]) => (
                  <Group key={model} spacing={10}>
                    <ThemeIcon
                      color={entry.level ? Disease.Level.color[entry.level] : Disease.Level.nullableColor}
                      size={14}
                      radius="50%"
                      sx={{ border: '1px solid white' }}
                    >
                      &nbsp;
                    </ThemeIcon>
                    <Text color="white" weight={500} size={12}>
                      {Disease.modelLabel[model]}
                    </Text>
                  </Group>
                )),
              )}
            </Stack>
          </Spoiler>
        </Card>
      ))}
    </Card>
  );
};

export default MainDisease;
