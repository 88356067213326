import React, { FC } from 'react';
import { flow, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Stack, Text } from '@mantine/core';
import { StackProps } from '@mantine/core/lib/Stack/Stack';
import { Sensor } from '@modules/sensors/model';
import { Measure } from '@modules/measures/model';
import { LastMeasureUtils } from '@modules/measures/last/utils';
import formatter = LastMeasureUtils.formatter;

interface SensorsDataListMeasureProps {
  measures: Measure.History<Sensor.Type> | null;
  type: Measure.Type;
  probes?: Array<Sensor.Probe.Identifier>;
}

const SensorsDataListMeasure: FC<SensorsDataListMeasureProps & StackProps> = ({
  measures,
  type,
  probes = Object.values(Sensor.Probe.Identifier),
  ...rest
}) => {
  const measure = pipe(
    O.fromNullable(measures),
    O.flatMap(
      flow(
        R.toEntries,
        A.flatMap(([probe, measures]) =>
          pipe(
            measures,
            A.filterMap(measure =>
              pipe(
                measure.type === type ? O.some({ type }) : O.none,
                O.apS('value', A.head(measure.values.map<Measure.Value>(({ value }) => value))), // assert correct measure type
                O.apS('probe', probes?.includes(probe) ? O.some(probe) : O.none),
              ),
            ),
          ),
        ),
        NEA.fromArray,
      ),
    ),
  );

  return (
    <Stack spacing={2} fz={14} fw={400} lh={1.55} {...rest}>
      {pipe(
        measure,
        O.matchW(
          () => <Text>N/A</Text>,
          NEA.map(({ probe, type, value }) => (
            <Text key={probe}>
              {Sensor.Probe.identifierLabel[probe]} : {formatter(value, type)}
            </Text>
          )),
        ),
      )}
    </Stack>
  );
};

export default SensorsDataListMeasure;
