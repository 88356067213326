import React, { FC } from 'react';
import { Link, To } from 'react-router-dom';
import { Anchor, Button, Group } from '@mantine/core';
import { renderNullable } from '@shared/utils/render';

interface ReportFlowBottomProps {
  back?: To;
  bottom?: number;
  onNext: () => void;
}

const ReportFlowBottom: FC<ReportFlowBottomProps> = ({ back, bottom = 0, onNext }) => {
  return (
    <Group pos="sticky" position="apart" mt="auto" bottom={bottom} px={20} py={15} bg="tertiary.2" sx={{ zIndex: 2 }}>
      {renderNullable(
        back,
        back => (
          <Anchor component={Link} to={back} relative="path" replace td="underline" fz={12} fw={500} c="tertiary.8">
            Précédent
          </Anchor>
        ),
        () => (
          <div />
        ),
      )}

      <Button tt="uppercase" onClick={onNext}>
        Suivant
      </Button>
    </Group>
  );
};

export default ReportFlowBottom;
