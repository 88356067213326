import React, { FC } from 'react';
import { Image } from '@mantine/core';

import placeholder from '@assets/images/home-placeholder.png';

const HomeContentPlaceholder: FC = () => {
  return <Image src={placeholder} alt="placeholder" />;
};

export default HomeContentPlaceholder;
