import { httpService, HttpTask } from '@core/http';
import { Threshold } from '@modules/iot/model';
import { Dashboard } from '@modules/iot/dashboard/model';

export namespace DashboardService {
  import Level = Threshold.Level;
  const URI = '/iot/dashboard';
  export function getDashboard(includeMap: boolean, alertLevel: Level | null): HttpTask<Dashboard> {
    return httpService.get(URI, { params: { includeMap, alertLevel } });
  }
}
