import React, { FC } from 'react';
import { Select } from '@mantine/core';
import { Controller } from 'react-hook-form';
import FirstStepForm from '@modules/sensors/pending/components/steps/FirstStepForm';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Hortee } from '@modules/sensors/hortee/model';
import { Zone } from '@modules/iot/zones/model';
import { getDropdownOptionsFromEnumLabels } from '@shared/utils/enum';

interface HorteeFirstStepFormProps {
  zones: Array<Zone>;
}

const HorteeFirstStepForm: FC<HorteeFirstStepFormProps> = ({ zones }) => {
  const {
    control,
    formState: { errors },
  } = useEnhancedFormContext<Hortee.RegisterParams>();

  return (
    <FirstStepForm zones={zones}>
      <Controller
        control={control}
        name="config.soilType"
        render={({ field }) => (
          <Select
            {...field}
            label="Type de sol"
            placeholder="Sélectionner"
            error={!!errors.config?.soilType}
            data={getDropdownOptionsFromEnumLabels(Hortee.soilTypeLabel)}
            required
          />
        )}
      />
    </FirstStepForm>
  );
};

export default HorteeFirstStepForm;
