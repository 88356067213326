import React, { FC, useCallback } from 'react';
import { ActionIcon, Box, Group, Text } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import { renderHttpRemoteData } from '@shared/utils/render';
import WeatherPrevisions from '@shared/modules/previsions/components/weather/WeatherPrevisions';
import WeatherParamDescriptionDrawer from '@shared/modules/previsions/components/weather/WeatherParamDescriptionDrawer';
import { useHashDisclosure } from '@core/router';
import { useFetchTask } from '@core/http/hooks';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import WeatherSkeleton from '@modules/diseases/alert-reports/analysis/components/weather/WeatherSkeleton';

interface AlertReportAnalysisWeatherProps {
  alertReport: AlertReport.Detail;
}

const AlertReportAnalysisWeather: FC<AlertReportAnalysisWeatherProps> = ({ alertReport }) => {
  const [helpWeatherOpen, { open: openHelpWeather, close: closeHelpWeather }] = useHashDisclosure('#help-weather');

  const getWeather = useCallback(() => AlertReportsService.Analysis.getWeather(alertReport.id), [alertReport]);

  const [weather] = useFetchTask(getWeather);

  return (
    <>
      <Box>
        <Group position="apart" px={20} style={{ rowGap: 2 }}>
          <Text size={18} weight={700}>
            Données météo une semaine avant le signalement
          </Text>
          <Group spacing={4} noWrap>
            <Text
              component="label"
              fz={10}
              fw={600}
              lh={1.55}
              c="primary.4"
              htmlFor="help-weather"
              style={{ cursor: 'pointer' }}
            >
              Légende des données météo
            </Text>
            <ActionIcon id="help-weather" onClick={openHelpWeather} size={20} c="primary.4">
              <IconHelp strokeWidth={1.5} />
            </ActionIcon>
          </Group>
        </Group>
        {renderHttpRemoteData(
          weather,
          weather => (
            <WeatherPrevisions
              location={weather.location}
              main={weather.reportDay?.values ?? null}
              days={weather.previousDays}
              date={weather.reportDay?.date}
            />
          ),
          () => (
            <WeatherSkeleton />
          ),
        )}
      </Box>
      <WeatherParamDescriptionDrawer opened={helpWeatherOpen} onClose={closeHelpWeather} />
    </>
  );
};

export default AlertReportAnalysisWeather;
