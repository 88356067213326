import { useEffect, useState } from 'react';

export function useWindowInnerHeight() {
  const [vh, setVh] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => setVh(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return vh;
}
