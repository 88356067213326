import React, { FC } from 'react';
import { Box, Button, Card, Group, Stack, Text } from '@mantine/core';
import SimpleCard from '@shared/components/list/card/SimpleCard';
import { IconSeeding, IconSpeakerphone } from '@tabler/icons-react';
import { useProfile } from '@modules/profile/loader';
import config from '@root/config';

const HomeLinks: FC = () => {
  const { isAccessLimited } = useProfile().subscription;

  return (
    <Box style={{ transform: 'translateY(-20px)' }}>
      <Card withBorder={false} px={17} py={20} bg="tertiary.2" radius={15}>
        <Text size={15} lh="normal" weight={500} ta="center" color="tertiary.8">
          Cet espace est réservé aux membres ayant souscrit à l’abonnement PRO. Il vous permet de manager votre équipe,
          de piloter votre parc de sonde et de gérer les risques épidémiologiques.
        </Text>

        {isAccessLimited ? (
          <Group mt={15} position="center">
            <Button component="a" href={config.VITE_CONTACT_LINK} variant="outline" tt="uppercase">
              Nous contacter
            </Button>
          </Group>
        ) : null}
      </Card>

      <Stack spacing={10} sx={{ flexDirection: isAccessLimited ? 'column-reverse' : undefined }}>
        <SimpleCard
          to="/sensors"
          icon={IconSeeding}
          title="Parc de sondes"
          bg={isAccessLimited ? '#e5e2dd' : 'primary.5'}
          c={isAccessLimited ? 'rgba(0, 0, 0, 0.25)' : 'white'}
        >
          Gérez votre parc de sondes
        </SimpleCard>
        <SimpleCard to="/diseases" icon={IconSpeakerphone} title="EPIDÉMIOSURVEILLANCE" bg="primary.4">
          Prévention et déclaration des bio-agresseurs.
        </SimpleCard>
      </Stack>
    </Box>
  );
};

export default HomeLinks;
