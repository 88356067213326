import React, { FC } from 'react';
import router from 'routes';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyles } from '@styles/global';
import MantineTheme from '@styles/mantine';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import { NavigationProgress } from '@mantine/nprogress';
import { RouterProvider } from 'react-router-dom';
import ApiDownIndicator from '@core/http/components/ApiDownIndicator';
import { Center, Loader } from '@mantine/core';

const App: FC = () => {
  return (
    <SentryProvider>
      <MantineTheme>
        <GlobalStyles />

        <ApiDownIndicator />

        <NavigationProgress color="primary" size={2} />

        <HelmetProvider>
          <RouterProvider
            router={router}
            fallbackElement={
              <Center h="100vh">
                <Loader size="xl" color="primary.5" variant="bars" />
              </Center>
            }
          />
        </HelmetProvider>
      </MantineTheme>
    </SentryProvider>
  );
};

export default App;
