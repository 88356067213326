import { ParsedQuery } from 'query-string';
import { NavigateOptions, To, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as S from 'fp-ts/string';

import { parseQueries } from '@shared/utils/queries';
import { useCallback } from 'react';

export function useIdParam<I extends string>(key: string = 'id'): I {
  return useParams<{ [key: string]: I }>()[key] as I;
}

export function useTokenParam<I extends string>(): I {
  return useParams<{ token: I }>().token as I;
}

export function useQuery(name: string): string | null {
  return new URLSearchParams(useLocation().search).get(name);
}

export function useQueries(): ParsedQuery {
  return parseQueries(useLocation().search);
}

export function useHashDisclosure(hash: string): readonly [
  boolean,
  {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  },
] {
  const location = useLocation();
  const navigate = useNavigate();

  const isOpen = location.hash === hash;

  const open = () => navigate({ ...location, hash });

  const close = () => navigate({ ...location, hash: undefined }, { replace: true });

  const toggle = () => (isOpen ? close() : open());

  return [isOpen, { open, close, toggle }];
}

export function usePreserveNavigate() {
  const location = useLocation();
  const navigate = useNavigate();

  const mergeToLocation = useCallback(
    (receivedTo: To): To => ({
      ...location,
      ...(S.isString(receivedTo) ? { pathname: receivedTo } : receivedTo),
    }),
    [location],
  );

  return (to: To, options?: NavigateOptions) => navigate(mergeToLocation(to), options);
}
