import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { Navigate, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Card, Chip, Group, SegmentedControl, Switch, Text, TextInput } from '@mantine/core';
import * as NEA from 'fp-ts/NonEmptyArray';
import ReportFormHeader from '@modules/diseases/alert-reports/flow/components/form/ReportFormHeader';
import { useReportFlowContext } from '@modules/diseases/alert-reports/flow/context';
import { ReportFlowStepProps } from '@modules/diseases/alert-reports/flow/index';
import ReportFlowLayout from '@modules/diseases/alert-reports/flow/layout/ReportFlowLayout';
import ReportFlowBottom from '@modules/diseases/alert-reports/flow/layout/ReportFlowBottom';

const ReportFormStep: FC<ReportFlowStepProps> = ({ type }) => {
  const navigate = useNavigate();

  const [{ selectedType, selectedSubCategory, selectedCategory, form }, dispatch] = useReportFlowContext();

  const severities = NEA.range(1, selectedSubCategory?.maxGravity ?? 1);

  const getDefaultValues = (): AlertReport.Flow.Body => {
    const isPrivate = !!selectedType?.isPrivateAuthorized ? form?.isPrivate ?? false : false;

    const grassType = !!selectedCategory?.hasGrassType ? form?.grassType ?? AlertReport.GrassType.START : null;
    // On regarde le severity est toujours possible en fonction de la sous-catégorie
    const severity = form?.severity && severities.includes(parseInt(`${form.severity}`)) ? form.severity : null;

    return {
      ...form,
      isPrivate,
      grassType,
      severity,
    };
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AlertReport.Flow.Body>({
    defaultValues: getDefaultValues(),
    resolver: zodResolver(AlertReport.Flow.Body),
  });

  const onSubmit = (form: AlertReport.Flow.Body) => {
    dispatch({ type: 'FORM_SAVED', form });
    navigate('../images');
  };

  const onNext = () => handleSubmit(onSubmit)();

  if (!selectedType || !selectedSubCategory || !selectedCategory) {
    return <Navigate to="../types" relative="path" replace />;
  }

  return (
    <ReportFlowLayout type={type} title={selectedType.label} back="../types" withClose>
      <ReportFormHeader category={selectedCategory} subCategory={selectedSubCategory} type={selectedType} />

      <Box p={20} pt={10}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {selectedType.isPrivateAuthorized ? (
            <>
              <Text c="tertiary.8" fz={17} fw={600}>
                Visibilité
              </Text>

              <Card bg="white" withBorder={false} radius={5} mt={10} mb={25} p={15}>
                <Controller
                  control={control}
                  name="isPrivate"
                  render={({ field }) => (
                    <Switch
                      ref={field.ref}
                      label="Signalement privé"
                      checked={field.value}
                      error={!!errors.isPrivate}
                      onChange={e => field.onChange(e.target.checked)}
                    />
                  )}
                />

                <Text mt={10} fz={12} opacity={0.5}>
                  Vous pouvez rendre ce signalement privé. Seuls les membres de votre entreprise pourront voir ce
                  signalement, il ne sera pas visible sur la carte des signalements.
                </Text>
              </Card>
            </>
          ) : null}

          {selectedCategory.hasGrassType ? (
            <>
              <Text c="tertiary.8" fz={17} fw={600}>
                Type de pelouse
              </Text>

              <Card bg={!!errors.grassType ? 'red.2' : 'white'} withBorder={false} radius={5} mt={10} p={15}>
                <Controller
                  control={control}
                  name="grassType"
                  render={({ field }) => (
                    <Chip.Group multiple={false} value={field.value ?? ''} onChange={field.onChange}>
                      <Group spacing={8}>
                        {Object.values(AlertReport.GrassType).map(grassType => (
                          <Chip key={grassType} value={grassType} radius={5}>
                            {AlertReport.grassTypeLabels[grassType]}
                          </Chip>
                        ))}
                      </Group>
                    </Chip.Group>
                  )}
                />
              </Card>
            </>
          ) : null}

          <Text mt={20} c="tertiary.8" fz={17} fw={600}>
            Gravité
          </Text>

          <Card bg="white" withBorder={false} radius={5} mt={10} p={15}>
            <Text fz={15} fw={500} c="tertiary.8">
              Indiquez le niveau de gravité : du plus faible (1) au plus fort ({selectedSubCategory.maxGravity})
            </Text>

            <Controller
              control={control}
              name="severity"
              render={({ field }) => (
                <SegmentedControl
                  mt={7}
                  w="100%"
                  color="primary"
                  data={severities.map(severity => `${severity}`)}
                  value={`${field.value ?? ''}`}
                  onChange={field.onChange}
                />
              )}
            />
          </Card>

          <Text mt={25} c="tertiary.8" fz={17} fw={600}>
            Laisser un commentaire
          </Text>

          <Card bg="white" withBorder={false} radius={5} mt={10} p={15}>
            <TextInput label="Pour tous" placeholder="Commentaire" {...register('comment')} />
          </Card>

          <Card bg="white" withBorder={false} radius={5} mt={10} p={15}>
            <TextInput label="Pour mes collaborateurs" placeholder="Commentaire" {...register('internalComment')} />
          </Card>
        </form>
      </Box>

      <ReportFlowBottom back="../types" onNext={onNext} />
    </ReportFlowLayout>
  );
};

const reportFormStepRoute = (props: ReportFlowStepProps) =>
  defineRoute({
    element: <ReportFormStep {...props} />,
  });

export default reportFormStepRoute;
