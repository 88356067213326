import React, { FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { defineRoute } from '@core/router';
import { useProfile } from '@modules/profile/loader';
import SensorsDashboardContent from '@modules/sensors/dashboard/components/SensorsDashboardContent';
import SensorsDashboardPlaceholder from '@modules/sensors/dashboard/components/SensorsDashboardPlaceholder';

const DashboardPage: FC = () => {
  const { isAccessLimited } = useProfile().subscription;

  const pageProps: PageProps = {
    seoTitle: 'Parc de sondes',
    back: {
      to: '/',
      title: 'Parc de sondes',
    },
  };

  return (
    <Page {...pageProps} withNav>
      {isAccessLimited ? <SensorsDashboardPlaceholder /> : <SensorsDashboardContent />}
    </Page>
  );
};

const sensorsDashboardRoute = defineRoute({
  element: <DashboardPage />,
});

export default sensorsDashboardRoute;
