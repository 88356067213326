import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';

/**
 * Highly inspired by
 * https://betterprogramming.pub/building-a-custom-google-maps-marker-react-component-like-airbnb-in-next-js-52fb37ccfabb
 *
 * @param container
 * @param position
 */
function createOverlay(container: HTMLElement, position: google.maps.LatLng | google.maps.LatLngLiteral) {
  class Overlay extends google.maps.OverlayView {
    constructor(private container: HTMLElement, private position: google.maps.LatLng | google.maps.LatLngLiteral) {
      super();
    }

    onAdd(): void {
      const pane = this.getPanes()?.floatPane;

      pane?.appendChild(this.container);
    }

    draw(): void {
      const projection = this.getProjection();
      const point = projection.fromLatLngToDivPixel(this.position);

      if (point === null) {
        return;
      }

      this.container.style.transform = `translate(${point.x}px, ${point.y}px)`;
    }

    onRemove(): void {
      if (this.container.parentNode !== null) {
        this.container.parentNode.removeChild(this.container);
      }
    }
  }

  return new Overlay(container, position);
}

interface GoogleMapsOverlayProps {
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  map: google.maps.Map;
  children: Required<NonNullable<ReactNode>>;
}

const GoogleMapsOverlay: FC<GoogleMapsOverlayProps> = ({ position, map, children }) => {
  const initPosition = useRef(position);

  const container = useMemo(() => {
    const div = document.createElement('div');

    div.style.position = 'absolute';

    return div;
  }, []);

  const overlay = useMemo(() => createOverlay(container, initPosition.current), [container]);

  useEffect(() => {
    overlay.setMap(map);

    return () => overlay.setMap(null);
  }, [map, overlay]);

  useEffect(() => {
    overlay.set('position', position);
  }, [position, overlay]);

  return createPortal(children, container);
};

export default GoogleMapsOverlay;
