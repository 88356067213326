import { httpService, HttpTask } from '@core/http';
import { Geo } from '@shared/modules/geo/model';

export namespace GeoService {
  const URI = '/app/geo';

  export function getCities(search: string): HttpTask<Array<Geo.City>> {
    return httpService.get(`${URI}/cities`, { params: { search } });
  }
}
