import z from 'zod';
import { Sensor } from '@modules/sensors/model';
import { Measure } from '@modules/measures/model';
import { Threshold } from '@modules/iot/model';
import { AlertTriggersSchema } from '@modules/alert-triggers/schema';
import { flow } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import * as Ord from 'fp-ts/Ord';
import { Hortee } from '@modules/sensors/hortee/model';
import { Sinafis } from '@modules/sensors/sinafis/model';
import { Sensoterra } from '@modules/sensors/sensoterra/model';

export namespace AlertTrigger {
  export const Id = z.string().uuid().brand('AlertTriggerId');
  export type Id = z.infer<typeof Id>;

  export enum State {
    Enabled = 'enabled',
    Disabled = 'disabled',
  }

  export const stateLabel: Record<State, string> = {
    [State.Enabled]: 'Activée',
    [State.Disabled]: 'Désactivée',
  };

  export const stateContrary: Record<State, State> = {
    [State.Enabled]: State.Disabled,
    [State.Disabled]: State.Enabled,
  };

  export interface Notification {
    byPush: boolean;
    byEmail: boolean;
  }

  export type CreateParams = z.infer<typeof AlertTriggersSchema.createParams>;
  export type UpdateParams = z.infer<typeof AlertTriggersSchema.updateParams>;

  const reduceMeasureTypeAlerts = flow(
    R.reduce(Ord.trivial)<Array<Measure.Type>, Array<Measure.Type>>([], (acc, curr) =>
      A.union(Measure.typeEq)(acc, curr),
    ),
  );

  export const alertMeasureTypeBySensorType: Record<Sensor.Type, Array<Measure.Type>> = {
    [Sensor.Type.Hortee]: reduceMeasureTypeAlerts(Hortee.Probe.identifierProbeAlerts),
    [Sensor.Type.Sinafis]: reduceMeasureTypeAlerts(Sinafis.Probe.identifierProbeAlerts),
    [Sensor.Type.Sensoterra]: reduceMeasureTypeAlerts(Sensoterra.Probe.identifierProbeAlerts),
  };
}

export interface AlertTrigger {
  id: AlertTrigger.Id;
  name: string;
  sensor: Sensor;
  type: Measure.Type;
  thresholdLevel: Threshold.Level;
  state: AlertTrigger.State;
  notification: AlertTrigger.Notification;
}
