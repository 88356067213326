import React, { FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { defineRoute, preventActionLeave, useHashDisclosure } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { AlertTriggersSchema } from '@modules/alert-triggers/schema';
import { AlertTriggersService } from '@modules/alert-triggers/service';
import { ActionIcon, Box, Button, Group, Text, Title } from '@mantine/core';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import AlertForm from '@modules/alert-triggers/components/AlertForm';
import { AlertTrigger } from '@modules/alert-triggers/model';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import UpdateParams = AlertTrigger.UpdateParams;
import alertMeasureTypeBySensorType = AlertTrigger.alertMeasureTypeBySensorType;
import { IconTrash } from '@tabler/icons-react';
import DeleteModal from '@shared/components/modals/DeleteModal';
import { constant, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { useNavigate } from 'react-router-dom';

const params = z.object({ id: AlertTrigger.Id });

export const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(AlertTriggersService.getAlertTrigger(params.id)),
});

export const actions = {
  update: defineAction({
    type: 'update',
    params,
    payload: AlertTriggersSchema.updateParams,
    handler: ({ payload, params }) => AlertTriggersService.updateAlertTrigger(params.id, payload),
    flashOptions: {
      success: () => 'Modification réalisée',
    },
    redirect: constant('/sensors/alerts'),
  }),
  deleteTrigger: defineAction({
    type: 'deleteTrigger',
    params,
    handler: ({ params }) => AlertTriggersService.deleteAlertTrigger(params.id),
    flashOptions: {
      success: () => 'Suppression réalisée',
    },
  }),
};
const UpdatePage: FC = () => {
  const [loadingUpdate, update] = useAction(actions.update);
  const [loadingDelete, deleteTrigger] = useAction(actions.deleteTrigger);
  const alertTrigger = useLoader<typeof loader>();
  const [opened, handlers] = useHashDisclosure('#delete');
  const navigate = useNavigate();

  const pageProps: PageProps = {
    seoTitle: alertTrigger.name,
    back: {
      title: (
        <Group position="apart" noWrap style={{ flexGrow: 1 }}>
          <Title size={17} color="white" weight={600}>
            {alertTrigger.name}
          </Title>
          <ActionIcon c="white" variant="transparent" onClick={handlers.open}>
            <IconTrash />
          </ActionIcon>
        </Group>
      ),
      to: '..',
    },
  };

  const handleDelete = () =>
    pipe(
      deleteTrigger(),
      TE.chainIOK(() => () => navigate({ pathname: '/sensors/alerts' }, { replace: true })),
    )();

  const { form, formRef, handleFormSubmit } = useEnhancedForm<UpdateParams>({
    resolver: zodResolver(AlertTriggersSchema.updateParams),
    defaultValues: {
      notification: alertTrigger.notification,
      type: alertTrigger.type,
      name: alertTrigger.name,
      thresholdLevel: alertTrigger.thresholdLevel,
    },
  });

  return (
    <>
      <Page {...pageProps} p={0}>
        <Box p="20px 20px 25px">
          <EnhancedForm ref={formRef} form={form} onSubmit={update} preventLeave>
            <AlertForm types={alertMeasureTypeBySensorType[alertTrigger.sensor.type]}>
              <Box>
                <Text weight={600} size={17} lh={1.29} color="tertiary.8" pb={10}>
                  Sonde
                </Text>
                <Box
                  px={10}
                  py={12}
                  bg="white"
                  sx={theme => ({
                    borderRadius: 10,
                    borderColor: theme.colors.gray[4],
                    color: theme.colors.tertiary[8],
                    borderWidth: 1,
                    borderStyle: 'solid',
                    transition: 'border-color 100ms ease',
                  })}
                >
                  <Text size={12}>{alertTrigger.sensor.name}</Text>
                </Box>
              </Box>
            </AlertForm>
          </EnhancedForm>
        </Box>
        <Button
          mt="auto"
          h={61}
          color="primary.5"
          tt="uppercase"
          onClick={handleFormSubmit}
          loading={loadingUpdate}
          radius={0}
        >
          Valider
        </Button>
      </Page>
      <DeleteModal opened={opened} handleClose={handlers.close} onDelete={handleDelete} loading={loadingDelete} />
    </>
  );
};

const updateAlert = defineRoute({
  element: <UpdatePage />,
  actions,
  loader,
  shouldRevalidate: preventActionLeave<typeof actions>('deleteTrigger'),
});

export default updateAlert;
