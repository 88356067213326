import React, { FC, useMemo } from 'react';
import { Box, Card, Divider, Group, ScrollArea, Stack, Text, ThemeIcon } from '@mantine/core';
import { isToday } from 'date-fns';

import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import * as M from 'fp-ts/Monoid';

import { Previsions } from '@shared/modules/previsions/model';
import { formatDate, LocalDateTime, parseDate } from '@shared/modules/dates';
import MainDisease from '@shared/modules/previsions/components/disease/today/MainDisease';

import Disease = Previsions.Disease;
import { Geo } from '@shared/modules/geo/model';

interface DiseasePrevisionsProps {
  main: Disease.Forecast;
  days: Array<Disease.DayForecast>;
  location: Geo.City | null;
  formatStr: string;
  date?: LocalDateTime;
  withoutIcon?: boolean;
}

interface DateForecast extends Disease.Forecast.Value {
  date: Date;
}

const mergeForecastMonoid: M.Monoid<Record<Disease.Model, Array<DateForecast>>> = R.getUnionMonoid({
  concat: (x, y) => [...x, ...y],
});

const DiseasePrevisions: FC<DiseasePrevisionsProps> = ({ location, main, days, formatStr, date, withoutIcon }) => {
  const forthcoming = useMemo(
    () =>
      pipe(
        days,
        A.filterMap(({ date, values }) =>
          pipe(
            parseDate(date),
            O.map(date =>
              pipe(
                values,
                R.filterMap(O.fromNullable),
                R.map(value => [{ ...value, date }]),
              ),
            ),
          ),
        ),
        M.concatAll(mergeForecastMonoid),
        R.toEntries,
      ),
    [days],
  );

  return (
    <Stack spacing={8} pt={8}>
      <Box px={20}>
        <MainDisease today={main} location={location} date={date} withoutIcon={withoutIcon} />
      </Box>

      <ScrollArea>
        <Group spacing={10} px={20} mt={5} noWrap align="strech">
          {forthcoming.map(([model, forecast]) => (
            <Card key={model} miw={130} p={15} radius={10} shadow="0 0 12px 0 rgba(0, 0, 0, 0.1)" withBorder={false}>
              <Text color="#2e2e2e" weight={500} size={12}>
                {Disease.modelLabel[model]}
              </Text>
              <Stack mt={10} spacing={10}>
                {forecast.map(({ date, level }, index) => (
                  <Box key={index}>
                    <Group position="apart" noWrap>
                      <Text tt="capitalize" color="dark.1" size={12} fw={500} lh={1.33}>
                        {isToday(date) ? "Aujourd'hui" : formatDate(date, formatStr)}
                      </Text>
                      <ThemeIcon
                        color={level ? Disease.Level.color[level] : Disease.Level.nullableColor}
                        size={14}
                        radius="50%"
                      >
                        &nbsp;
                      </ThemeIcon>
                    </Group>

                    {index < forecast.length - 1 ? <Divider color="tertiary.2" mt={5} /> : null}
                  </Box>
                ))}
              </Stack>
            </Card>
          ))}
        </Group>
      </ScrollArea>
    </Stack>
  );
};

export default DiseasePrevisions;
