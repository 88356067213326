import React, { createElement } from 'react';
import { Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { Sensor } from '@modules/sensors/model';
import BatteryThemeIcon from '@modules/measures/BatteryThemeIcon';
import SignalThemeIcon from '@modules/measures/SignalThemeIcon';
import { MeasureUtils } from '@modules/measures/utils';
import { Measure } from '@modules/measures/model';
import { PendingSensor } from '@modules/sensors/pending/model';
import getMeasureValue = MeasureUtils.getMeasureValue;
import { renderOptional } from '@shared/utils/render';

interface PendingHeaderProps<Type extends Sensor.Type> {
  sensor: PendingSensor<Type>;
}

function PendingHeader<Type extends Sensor.Type>({ sensor }: PendingHeaderProps<Type>) {
  const signal = getMeasureValue(sensor.measures, Measure.Type.Signal);
  const battery = getMeasureValue(sensor.measures, Measure.Type.Battery);

  return (
    <Group align="start" position="apart" noWrap>
      <Group spacing={5} align="start" noWrap>
        <ThemeIcon size={34} c="black" bg="transparent">
          {createElement(Sensor.typeIcon[sensor.type])}
        </ThemeIcon>
        <Stack spacing={2}>
          <Text size={20} weight={600} color="primary.5">
            Sonde {Sensor.typeTitle[sensor.type]}
          </Text>
          <Text size={12} weight={500} lh={1.33} color="rgba(46, 46, 46, 0.4)">
            Numéro de capteur : {sensor.serial}
          </Text>
        </Stack>
      </Group>
      <Group spacing={8} noWrap>
        {renderOptional(signal, signal => (
          <SignalThemeIcon signal={signal} />
        ))}
        {renderOptional(battery, battery => (
          <BatteryThemeIcon battery={battery} />
        ))}
      </Group>
    </Group>
  );
}

export default PendingHeader;
