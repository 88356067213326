import React, { FC, useCallback, useMemo, useState } from 'react';
import { renderHttpRemoteData } from '@shared/utils/render';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import { Group, Select, Stack } from '@mantine/core';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import SensorLineChart from '@shared/modules/charts/line/SensorLineChart';
import SensorMeasureSelection from '@modules/measures/SensorMeasureSelection';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { Sensor } from '@modules/sensors/model';
import * as IOO from 'fp-ts/IOOption';
import { stringifyQueries } from '@shared/utils/queries';
import { Measure } from '@modules/measures/model';
import * as R from 'fp-ts/Record';
import * as M from 'fp-ts/Monoid';
import { MeasureUtils } from '@modules/measures/utils';
import * as S from 'fp-ts/string';

import { parseISO, subDays } from 'date-fns';
import { usePreserveNavigate } from '@core/router';
import { useFetchTask } from '@core/http/hooks';
import { DateFormat, formatDate, LocalDateTime, parseDate } from '@shared/modules/dates';
import * as TE from 'fp-ts/TaskEither';
import { HttpError } from '@core/http';
import { SensorsService } from '@modules/sensors/service';
import { Utils } from '@shared/utils/model';

interface SensorsDataChartProps {
  sensors: Array<AlertReport.Sensor<Sensor.Type>>;
  reportedAt: LocalDateTime;
  filter: AlertReport.Filter.Analysis;
}

const defaultSelection = Object.values(Sensor.Probe.Identifier).flatMap(identifier =>
  Object.values(Measure.Type).map(type => `${identifier}.${type}` as const),
);
const SensorsDataChart: FC<SensorsDataChartProps> = ({ sensors, reportedAt, filter }) => {
  const [selection, setSelection] = useState(defaultSelection);

  const navigate = usePreserveNavigate();
  const handleSensorChange = (sensors: Array<AlertReport.Sensor>) => (id: Sensor.Id) =>
    pipe(
      sensors,
      A.findFirst(sensor => sensor.id === id),
      IOO.fromOption,
      IOO.chainIOK(
        ({ id, type }) =>
          () =>
            navigate({ search: stringifyQueries({ sensorId: id, type }) }, { preventScrollReset: true }),
      ),
    )();

  const sensor = useMemo(
    () =>
      pipe(
        sequenceS(O.Apply)({ id: O.fromNullable(filter.sensorId), type: O.fromNullable(filter.type) }),
        O.altW(() => A.head(sensors)),
      ),
    [filter, sensors],
  );

  const getData = (measures: Measure.History<Sensor.Type>) =>
    pipe(
      R.toEntries(measures),
      A.chain(([identifier, values]) =>
        pipe(
          values,
          A.filter(({ type }) => selection.some(key => key === `${identifier}.${type}`)),
          A.map(({ values, type }) =>
            values.reduce((acc, curr) => ({ ...acc, [curr.at]: { [`${identifier}.${type}`]: curr.value } }), {}),
          ),
        ),
      ),
      M.concatAll(MeasureUtils.mergeDateMonoid),
      R.collect(S.Ord)((at, values) => ({ date: parseISO(at), ...values })),
    );

  const historyFilter: O.Option<Measure.History.Filter> = useMemo(
    () =>
      pipe(
        parseDate(reportedAt, DateFormat.LocalDateTime),
        O.map(endDate => ({
          startDate: formatDate(subDays(endDate, 8), DateFormat.LocalDateTime),
          endDate: reportedAt,
          unit: Utils.ChronoUnit.Days,
        })),
      ),
    [reportedAt],
  );

  const getMeasures = useCallback(
    () =>
      pipe(
        sensor,
        O.bind('filter', () => historyFilter),
        TE.fromOption(() => HttpError.notFound),
        TE.chain(sensor => SensorsService.getMeasures(sensor.id, sensor.type, sensor.filter)),
      ),
    [sensor, historyFilter],
  );

  const [measures] = useFetchTask(getMeasures);

  const dateFormatter = (date: Date) => formatDate(date, 'dd/MM');

  return renderHttpRemoteData(measures, measures => (
    <Stack>
      <Group>
        <Select
          label="Sélectionner une sonde"
          placeholder="Sélectionner une sonde"
          data={sensors.map(({ id, serial }) => ({ value: id, label: serial }))}
          onChange={handleSensorChange(sensors)}
          value={O.toNullable(sensor)?.id}
        />
      </Group>
      <SensorLineChart data={getData(measures)} dateFormatter={dateFormatter} />
      <SensorMeasureSelection measures={measures} selection={selection} setSelection={setSelection} />
    </Stack>
  ));
};

export default SensorsDataChart;
