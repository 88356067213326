import React, { FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { defineRoute, useHashDisclosure } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { AlertTriggersSchema } from '@modules/alert-triggers/schema';
import { AlertTriggersService } from '@modules/alert-triggers/service';
import { Box, Button, Group, Text, UnstyledButton } from '@mantine/core';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import AlertForm from '@modules/alert-triggers/components/AlertForm';
import { AlertTrigger } from '@modules/alert-triggers/model';
import CreateParams = AlertTrigger.CreateParams;
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { SensorsService } from '@modules/sensors/service';
import { ActiveSensor, Sensor } from '@modules/sensors/model';
import { Measure } from '@modules/measures/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconChevronDown } from '@tabler/icons-react';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import AlertSensorsDrawer from '@modules/alert-triggers/components/AlertSensorsDrawer';
import alertMeasureTypeBySensorType = AlertTrigger.alertMeasureTypeBySensorType;
import { useNavigate } from 'react-router-dom';

export const loader = defineLoader({
  handler: () => httpTaskToResponseTask(SensorsService.getActiveSensors(ActiveSensor.defaultFilter)),
});

export const actions = {
  create: defineAction({
    type: 'create',
    payload: AlertTriggersSchema.createParams,
    handler: ({ payload }) => AlertTriggersService.createAlertTrigger(payload),
    flashOptions: {
      success: () => 'Création réalisée',
    },
  }),
};
const CreatePage: FC = () => {
  const navigate = useNavigate();
  const { sensors } = useLoader<typeof loader>();
  const [sensorsOpened, sensorsHandlers] = useHashDisclosure('#sensors');
  const [loading, create] = useAction(actions.create);
  const pageProps: PageProps = {
    seoTitle: 'Créer mon alerte',
    back: {
      title: 'Créer mon alerte',
      to: '..',
    },
  };

  const { form, formRef, handleFormSubmit } = useEnhancedForm<CreateParams>({
    resolver: zodResolver(AlertTriggersSchema.createParams),
    defaultValues: {
      notification: { byEmail: false, byPush: true },
      sensors: [],
      type: '' as Measure.Type, // for resetField
    },
  });

  const errors = form.formState.errors;

  const sensorIds = form.watch('sensors');
  const sensorsNames = pipe(
    sensors,
    A.filterMap(({ id, name }) => (sensorIds.includes(id) ? O.some(name) : O.none)),
  );

  const types = pipe(
    sensors,
    A.filterMap(({ id, type }) => (sensorIds.includes(id) ? O.some(type) : O.none)),
    A.uniq(Sensor.typeEq),
    A.reduce<Sensor.Type, Array<Measure.Type>>([], (acc, type) =>
      A.union(Measure.typeEq)(acc, alertMeasureTypeBySensorType[type]),
    ),
  );

  const handleEmptySensors = () => form.setValue('sensors', []);

  const handleCreate = (params: CreateParams) =>
    pipe(
      create(params),
      TE.chainIOK(() => () => navigate(`/sensors/alerts`, { replace: true })),
    );

  return (
    <Page {...pageProps} p={0}>
      <Box p="20px 20px 25px">
        <EnhancedForm ref={formRef} form={form} onSubmit={handleCreate} preventLeave>
          <AlertForm types={types}>
            <Box>
              <Group position="apart" pb={10}>
                <Text weight={600} size={17} lh={1.29} color="tertiary.8">
                  Sondes
                </Text>
                <Button
                  p={0}
                  h="auto"
                  bg="transparent !important"
                  c="primary.5"
                  onClick={sensorsHandlers.open}
                  rightIcon={<IconChevronDown size={16} />}
                  styles={{ rightIcon: { marginLeft: 6 } }}
                >
                  <Text size={14}>Choisir</Text>
                </Button>
              </Group>
              <Box
                pl={10}
                bg="white"
                sx={theme => ({
                  borderRadius: 10,
                  borderColor: !!errors.sensors ? theme.colors.red[6] : theme.colors.gray[4],
                  color: !!errors.sensors ? theme.colors.red[6] : theme.colors.tertiary[8],
                  borderWidth: 1,
                  borderStyle: 'solid',
                  transition: 'border-color 100ms ease',
                })}
              >
                <Group position="apart" noWrap>
                  <Box py={12} pr={17}>
                    {renderOptional(
                      NEA.fromArray(sensorsNames),
                      names => (
                        <Text size={12}>{names.join(', ')}</Text>
                      ),
                      () => (
                        <Text size={12} opacity={0.5}>
                          Aucune sonde
                        </Text>
                      ),
                    )}
                  </Box>
                  {sensorIds.length > 0 && (
                    <UnstyledButton
                      pl={20}
                      pr={17}
                      py={4}
                      onClick={handleEmptySensors}
                      sx={theme => ({ borderLeft: `1px solid ${theme.colors.tertiary[3]}` })}
                    >
                      <Text size={12} h="100%" td="underline" color="tertiary.8" weight={500}>
                        Retirer
                      </Text>
                    </UnstyledButton>
                  )}
                </Group>
              </Box>
            </Box>
          </AlertForm>
          <AlertSensorsDrawer opened={sensorsOpened} onClose={sensorsHandlers.close} />
        </EnhancedForm>
      </Box>
      <Button mt="auto" h={61} color="primary.5" tt="uppercase" onClick={handleFormSubmit} loading={loading} radius={0}>
        Valider
      </Button>
    </Page>
  );
};

const createAlert = defineRoute({
  element: <CreatePage />,
  actions,
  loader,
});

export default createAlert;
