import React from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import Pin, { PinProps } from '@shared/modules/maps/components/Pin';

interface AlertReportPinProps {
  category: AlertReport.Category;
  subCategory: AlertReport.SubCategory;
  size?: number;
}

function AlertReportPin<C = 'div'>({ category, subCategory, size = 43, ...props }: AlertReportPinProps & PinProps<C>) {
  const imageSize = Math.round((size * 22) / 43);

  return (
    <Pin color={category.color} size={size} {...props}>
      <img width={imageSize} height={imageSize} src={subCategory.images.fit_300x300} alt={subCategory.label} />
    </Pin>
  );
}

export default AlertReportPin;
