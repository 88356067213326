import React, { FC } from 'react';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { ActionIcon, Group, Title } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

interface AlertReportAnalysisTopProps {
  alertReport: AlertReport.Detail;
}

const AlertReportAnalysisTop: FC<AlertReportAnalysisTopProps> = ({ alertReport }) => {
  const navigate = useNavigate();

  const handleGoBack = () => navigate(`/diseases/alert-reports/${alertReport.id}`);

  return (
    <Group
      pos="sticky"
      top={0}
      spacing={5}
      bg="tertiary.8"
      h={63}
      pl={20}
      pr={15}
      noWrap
      style={{ flex: '0 0 auto', zIndex: 10000 }}
    >
      <ActionIcon variant="transparent" c="white" onClick={handleGoBack}>
        <IconArrowLeft size={22} />
      </ActionIcon>
      <Title
        fz={17}
        c="white"
        fw={600}
        sx={{ flex: '1 1 auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        Analyse {alertReport.type.label}
      </Title>
    </Group>
  );
};

export default AlertReportAnalysisTop;
