import styled from '@emotion/styled';

export const ReportImagePreviewContainer = styled.div`
  border-radius: 5px;
`;

export const ReportImagePreviewContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
