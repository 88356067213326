import React, { FC } from 'react';
import FirstStepForm from '@modules/sensors/pending/components/steps/FirstStepForm';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Select, TextInput } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { Sensoterra } from '@modules/sensors/sensoterra/model';
import Parameter = Sensoterra.Parameter;
import { Zone } from '@modules/iot/zones/model';

interface SensoterraFirstStepFormProps {
  parameters: Array<Sensoterra.Parameter>;
  depth: Sensoterra.Parameter.Key;
  zones: Array<Zone>;
}

const SensoterraFirstStepForm: FC<SensoterraFirstStepFormProps> = ({ parameters, depth, zones }) => {
  const {
    control,
    formState: { errors },
  } = useEnhancedFormContext<Sensoterra.RegisterParams>();

  return (
    <FirstStepForm zones={zones}>
      <TextInput
        label="Profondeur Capteur Sol (cm)"
        placeholder="Saisir"
        value={parameters.find(({ key }) => key === depth)?.name}
        required
        disabled
      />
      <Controller
        control={control}
        name="config.soilType"
        render={({ field }) => (
          <Select
            {...field}
            label="Type de sol"
            placeholder="Sélectionner"
            error={!!errors.config?.soilType}
            data={parameters
              .filter(({ type }) => type === Parameter.Type.Soil)
              .map(({ name, key }) => ({ value: key, label: name }))}
            required
          />
        )}
      />
    </FirstStepForm>
  );
};

export default SensoterraFirstStepForm;
