import { FilterQueryParser, getBooleanQuery, getEnumQuery, getIntQuery, getStringQuery } from '@shared/modules/filter';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { LocalDateTime, parseDate } from '@shared/modules/dates';
import * as R from 'fp-ts/Record';
import { Previsions } from '@shared/modules/previsions/model';
import { Charts } from '@shared/modules/charts/model';
import { Sensor } from '@modules/sensors/model';

export namespace AlertReportUtils {
  import Disease = Previsions.Disease;
  export const alertReportsFilterParser =
    (savedFilters: Array<AlertReport.Filter.Light>): FilterQueryParser<AlertReport.Filter.Queries> =>
    query => ({
      categoryId: getIntQuery<AlertReport.CategoryId>(query, 'categoryId'),
      subCategoryId: getIntQuery<AlertReport.SubCategoryId>(query, 'subCategoryId'),
      typeId: getIntQuery<AlertReport.TypeId>(query, 'typeId'),
      gravity: getIntQuery<AlertReport.Gravity>(query, 'gravity'),
      period: getEnumQuery(query, AlertReport.Period, 'period'),
      personalizedStartDate: getStringQuery<LocalDateTime>(query, 'personalizedStartDate'),
      personalizedEndDate: getStringQuery<LocalDateTime>(query, 'personalizedEndDate'),
      myReports: getBooleanQuery(query, 'myReports'),
      hasPictures: getBooleanQuery(query, 'hasPictures'),
      privateOnly: getBooleanQuery(query, 'privateOnly'),
      status: getEnumQuery(query, AlertReport.Status, 'status') ?? AlertReport.Status.Active,
      filterId: pipe(
        O.fromNullable(getStringQuery<AlertReport.Filter.Id>(query, 'filterId')),
        O.filter(filterId =>
          pipe(
            savedFilters,
            A.some(savedFilter => savedFilter.id === filterId),
          ),
        ),
        O.toNullable,
      ),
    });

  export const alertReportFilterParser: FilterQueryParser<AlertReport.Filter.Analysis> = query => ({
    sensorId: getStringQuery<Sensor.Id>(query, 'sensorId'),
    type: getStringQuery<Sensor.Type>(query, 'type'),
  });

  export const alertReportHistoryFilterParser =
    (savedFilters: Array<AlertReport.Filter.Light>): FilterQueryParser<AlertReport.Filter.History> =>
    query => ({
      ...alertReportsFilterParser(savedFilters)(query),
      year: getIntQuery(query, 'year'),
      status: getEnumQuery(query, AlertReport.Status, 'status') ?? AlertReport.Status.All,
    });

  interface WithStatusFilter {
    status: AlertReport.Status;
  }

  export function statusServerFilterAdapter<F extends WithStatusFilter>(filter: F) {
    const status = filter.status === AlertReport.Status.All ? null : filter.status;

    return {
      ...filter,
      status,
    };
  }

  export function getForecastData(days: Array<Disease.DayForecast>, selection: Array<Disease.Model>) {
    return pipe(
      days,
      A.filterMap(({ date, values }) =>
        pipe(
          values,
          R.filterMap(O.fromNullable),
          R.filterMapWithIndex((type: Disease.Model, { score }) => (selection.includes(type) ? O.some(score) : O.none)),
          O.some<Omit<Charts.Line.Disease.Data, 'date'>>,
          O.bind('date', () => parseDate(date)),
        ),
      ),
    );
  }
}
