import styled from '@emotion/styled';

export const DayWeatherContainer = styled.div`
  padding: 15px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: white;
`;

export const DayWeatherStats = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 10px;
  padding-top: 10px;
  align-items: center;
  line-height: 1.33;

  > div {
    &:nth-of-type(odd) {
      font-size: 12px;
      font-weight: 500;
      color: rgba(46, 46, 46, 0.4);
    }

    &:nth-of-type(even) {
      font-weight: 500;
      font-size: 14px;
      color: #2e2e2e;
      text-align: right;
    }
  }
`;
