import z from 'zod';
import { Zone } from '@modules/iot/zones/model';
import { Sensor } from '@modules/sensors/model';
import { HubApi, Threshold } from '@modules/iot/model';
import { Measure } from '@modules/measures/model';
import { Sinafis } from '@modules/sensors/sinafis/model';
import { NonEmptyString } from '@shared/schemas';
import { Geo } from '@shared/modules/geo/model';
import { SensorFilter } from '@modules/sensors/map/filters/model';

export namespace SensorsSchema {
  import INSEECode = Geo.City.INSEECode;
  export const filtersSchema = z.object({
    search: z.string().nullable(),
    zoneId: Zone.Id.nullable(),
    sensorType: z.nativeEnum(Sensor.Type).nullable(),
    sinafisProbeIdentifier: z.nativeEnum(Sinafis.Probe.Identifier).nullable(),
    alertLevel: z.nativeEnum(Threshold.Level).nullable(),
    alertType: z.nativeEnum(Measure.Type).nullable(),
    code: INSEECode.nullable(),
    postalCode: z.coerce.string().brand<'PostalCode'>().nullable(),
    filterId: SensorFilter.Id.nullable(),
  });

  export const filtersWithCitySchema = filtersSchema.extend({
    city: z.string().nullable(),
  });

  export const registerPendingSchema = z.object({
    technicalId: HubApi.SensorId,
    name: NonEmptyString,
    zoneId: Zone.Id,
    comment: z.string().nullable(),
  });
}
