import React, { FC, useState } from 'react';
import { defineRoute, useIdParam } from '@core/router';
import { Box, SimpleGrid, Text, Title } from '@mantine/core';
import { AlertReportsService } from '@modules/diseases/alert-reports/service';
import { useSendTask } from '@core/http/hooks';
import { AlertReport } from '@modules/diseases/alert-reports/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { useNavigate } from 'react-router-dom';
import ReportCategoryCard from '@modules/diseases/alert-reports/flow/components/categories/ReportCategoryCard';
import { useReportFlowContext } from '@modules/diseases/alert-reports/flow/context';
import { ReportFlowStepProps } from '@modules/diseases/alert-reports/flow/index';
import ReportFlowLayout from '@modules/diseases/alert-reports/flow/layout/ReportFlowLayout';
import ReportFlowBottom from '@modules/diseases/alert-reports/flow/layout/ReportFlowBottom';

const ReportCategoriesStep: FC<ReportFlowStepProps> = ({ type }) => {
  const id = useIdParam();

  const navigate = useNavigate();

  const [{ selectedCategory, categories }, dispatch] = useReportFlowContext();

  const [loadingCategory, setLoadingCategory] = useState<AlertReport.CategoryId | null>(null);

  const [, fetchSubCategories] = useSendTask(AlertReportsService.getSubCategories);

  const onNext = () => navigate('sub-categories', { relative: 'path' });

  const handleSelectCategory = (selectedCategory: AlertReport.Category) =>
    pipe(
      TE.fromIO(() => setLoadingCategory(selectedCategory.id)),
      TE.chain(() => fetchSubCategories(selectedCategory.id)),
      TE.chainIOK(subCategories => () => {
        dispatch({ type: 'CATEGORY_SELECTED', subCategories, selectedCategory });

        onNext();
      }),
      T.chainIOK(() => () => setLoadingCategory(null)),
    )();

  return (
    <ReportFlowLayout
      type={type}
      title={type === 'create' ? 'Nouveau signalement' : 'Modifier le signalement'}
      back={type === 'create' ? '/diseases' : `/diseases/alert-reports/${id}`}
      withClose
      withNav
    >
      {type === 'create' ? (
        <>
          <Box p={20}>
            <Title c="primary.5">Envoyer un signalement</Title>

            <Text mt={15}>
              Vos retours terrains comptent ! Grâce à eux, nous pouvons prévenir la communauté et donner des conseils
              adaptés.
            </Text>
          </Box>

          <Box
            h={10}
            sx={{
              opacity: 0.1,
              backgroundImage: 'linear-gradient(to top, #f5f4f2, #363636)',
            }}
          />
        </>
      ) : null}

      <SimpleGrid cols={2} py={20} px={25} spacing={15}>
        {categories.map(category => (
          <ReportCategoryCard
            key={category.id}
            category={category}
            selected={category.id === selectedCategory?.id}
            loading={loadingCategory === category.id}
            onSelect={handleSelectCategory}
          />
        ))}
      </SimpleGrid>

      {selectedCategory != null ? <ReportFlowBottom bottom={64} onNext={onNext} /> : null}
    </ReportFlowLayout>
  );
};

const reportCategoriesStepRoute = (props: ReportFlowStepProps) =>
  defineRoute({
    element: <ReportCategoriesStep {...props} />,
  });

export default reportCategoriesStepRoute;
