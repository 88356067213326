import { httpService, HttpTask } from '@core/http';
import { Pest } from '@modules/diseases/pests/model';
import { Previsions } from '@shared/modules/previsions/model';

export namespace PestService {
  const URI = '/pest';
  export function getPests(context: Previsions.Context): HttpTask<Array<Pest.Description>> {
    return httpService.post(`${URI}/list`, context);
  }
}
