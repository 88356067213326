import React, { FC } from 'react';
import SecondStepForm from '@modules/sensors/pending/components/steps/SecondStepForm';
import { Sinafis } from '@modules/sensors/sinafis/model';
import ExtendedMeasureProbeMapping = Sinafis.Config.ExtendedMeasureProbeMapping;
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import { Sensor } from '@modules/sensors/model';
import { Measure } from '@modules/measures/model';
import { useEnhancedFormContext } from '@shared/modules/form';
import ThresholdScaleCard from '@shared/modules/threshold/ThresholdScaleCard';
import ThresholdScaleController from '@shared/modules/threshold/ThresholdScaleController';

const thresholdIdentifierLabel: Record<Sinafis.Config.Identifier, string> = {
  transmitter: 'Transmetteur',
  ground1: 'Capteur Sol 1',
  ground2: 'Capteur Sol 2',
  leaf: 'Capteur Foliaire',
};

interface SinafisSecondStepFormProps {
  measureMappings: ExtendedMeasureProbeMapping;
}

const SinafisSecondStepForm: FC<SinafisSecondStepFormProps> = ({ measureMappings }) => {
  const { control } = useEnhancedFormContext<Sinafis.RegisterParams>();

  const thresholdScales = pipe(
    R.keys(measureMappings),
    A.sort(Sinafis.Config.configOrd),
    A.map(key => (
      <ThresholdScaleCard key={key} title={thresholdIdentifierLabel[key]} icon={Sensor.typeIcon[Sensor.Type.Sinafis]}>
        <ThresholdScaleController
          name={`config.${key}.humidityThreshold`}
          control={control}
          type={Measure.Type.Humidity}
        />
        {key !== 'leaf' && (
          <ThresholdScaleController
            name={`config.${key}.temperatureThreshold`}
            control={control}
            type={Measure.Type.Temperature}
          />
        )}
      </ThresholdScaleCard>
    )),
  );

  return <SecondStepForm>{thresholdScales}</SecondStepForm>;
};

export default SinafisSecondStepForm;
