import styled from '@emotion/styled';

import background from '@assets/images/weather-background.jpg';

export const TodayWeatherContainer = styled.div`
  grid-column: span 3;
  padding: 20px 15px;
  border-radius: 8px;
  background: url(${background}) no-repeat bottom center;
  background-size: cover;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
`;

export const TodayWeatherInfos = styled.div`
  padding: 12px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  color: ${props => props.theme.white};
  font-size: 12px;
  font-weight: 500;

  span {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 600;
  }
`;
