import z from 'zod';

import { Resource } from '@shared/modules/resources/model';
import { TitleOrder } from '@mantine/core';

export namespace Editorial {
  export namespace Block {
    export const Id = z.string().uuid().brand('EditorialBlockId');
    export type Id = z.infer<typeof Id>;

    export enum Template {
      Title = 'title',
      Text = 'text',
      Youtube = 'youtube',
      Link = 'link',
      Address = 'address',
      Contact = 'contact',
      Image = 'image',
      Gallery = 'gallery',
      Pdf = 'pdf',
    }

    export interface Common<T extends Template = Template> {
      id: Id;
      order: number;
      template: T;
    }

    export namespace Title {
      export enum Level {
        H1 = 'h1',
        H2 = 'h2',
        H3 = 'h3',
      }

      export const levelOrder: Record<Level, TitleOrder> = {
        [Level.H1]: 1,
        [Level.H2]: 2,
        [Level.H3]: 3,
      };
    }

    export interface Title extends Common<Template.Title> {
      level: Title.Level;
      content: string;
    }

    export interface Text extends Common<Template.Text> {
      columns: number;
      content: string;
    }

    export interface Youtube extends Common<Template.Youtube> {
      youtubeId: string;
      thumbnail: string | null;
    }

    export interface Link extends Common<Template.Link> {
      content: string;
      url: string;
    }

    export namespace Address {
      export enum MapActionType {
        AddressActionType = 'address',
        DestinationActionType = 'destination',
      }
    }

    export interface Address extends Common<Template.Address> {
      lng: number | null;
      lat: number | null;
      address: string | null;
      type: Address.MapActionType | null;
      text: string | null;
      showMap: boolean;
      zoomLevel: number | null;
      content: string | null;
    }

    export namespace Contact {
      export enum Type {
        Mail = 'mail_contact',
        Phone = 'phone_contact',
      }
    }

    export interface Contact extends Common<Template.Contact> {
      type: Contact.Type;
      text: string;
      target: string;
      content: string | null;
    }

    export interface Image extends Common<Template.Image> {
      mode: 'crop';
      image: Resource.Detail | null;
      caption: string | null;
    }

    export namespace Gallery {
      export const ImageId = z.string().uuid().brand('EditorialGalleryImageId');
      export type ImageId = z.infer<typeof Id>;

      export interface Image {
        id: ImageId;
        order: number;
        resource: Resource.Detail;
        title: string | null;
      }
    }

    export interface Gallery extends Common<Template.Gallery> {
      images: Array<Gallery.Image>;
    }

    export interface Pdf extends Common<Template.Pdf> {
      pdf: Resource.Detail | null;
      caption: string | null;
    }
  }

  export type Block<
    T extends Block.Template = Block.Template,
    R extends {
      [T in Block.Template]: Block.Common<T>;
    } = {
      [Block.Template.Title]: Block.Title;
      [Block.Template.Text]: Block.Text;
      [Block.Template.Youtube]: Block.Youtube;
      [Block.Template.Link]: Block.Link;
      [Block.Template.Address]: Block.Address;
      [Block.Template.Contact]: Block.Contact;
      [Block.Template.Image]: Block.Image;
      [Block.Template.Gallery]: Block.Gallery;
      [Block.Template.Pdf]: Block.Pdf;
    },
  > = R[T];
}

export type Editorial = Array<Editorial.Block>;
