import { Route } from 'react-router-dom';
import alertReportsRoutes from '@modules/diseases/alert-reports/routes';
import { createRoute } from '@core/router';
import diseasesDashboardRoute from '@modules/diseases/DiseasesDashboardPage';

const diseasesRoutes = (
  <Route path="diseases">
    <Route index {...createRoute(diseasesDashboardRoute)} />

    {alertReportsRoutes}
  </Route>
);

export default diseasesRoutes;
