import React, { FC, PropsWithChildren } from 'react';
import { Card, Stack, Text } from '@mantine/core';

interface ThresholdScaleCardProps {
  title: string;
  icon: FC<React.ComponentProps<'svg'> & { title?: string }>;
}

const ThresholdScaleCard: FC<PropsWithChildren<ThresholdScaleCardProps>> = ({ children, title, icon }) => {
  return (
    <Card
      px={14}
      py={10}
      radius={8}
      withBorder={false}
      sx={theme => ({ border: `1px solid ${theme.colors.tertiary[2]}` })}
    >
      <Text color="rgba(46, 46, 46, 0.4)" size={12} weight={600} pb={15}>
        {title}
      </Text>
      <Stack spacing={30}>{children}</Stack>
    </Card>
  );
};

export default ThresholdScaleCard;
