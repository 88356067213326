import React, { FC } from 'react';
import { AspectRatio, Box, Card, Group, SimpleGrid, Skeleton } from '@mantine/core';

interface AlertsPieChartCardPlaceholderProps {
  mah: number;
}
const AlertsPieChartCardPlaceholder: FC<AlertsPieChartCardPlaceholderProps> = ({ mah }) => {
  return (
    <Card shadow="0 0 12px 0 rgba(0, 0, 0, 0.1)" withBorder={false}>
      <Group pb={20} noWrap>
        <Skeleton radius={10} h={51} w={100} />
        <Skeleton radius={10} h={51} w={100} />
      </Group>

      <SimpleGrid>
        <Box mah={mah} h="100%" pos="relative" style={{ overflow: 'hidden' }}>
          <Box h="200%" pos="relative">
            <AspectRatio ratio={4 / 3} style={{ top: '50%', transform: 'translateY(-50%)' }}>
              <Skeleton radius="100%" />
            </AspectRatio>
          </Box>
        </Box>
      </SimpleGrid>
    </Card>
  );
};

export default AlertsPieChartCardPlaceholder;
