import React, { FC } from 'react';
import { AspectRatio, Card, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import SimpleCard from '@shared/components/list/card/SimpleCard';
import { IconChartBar, IconCirclePlus, IconMap2 } from '@tabler/icons-react';

import * as Styled from './DiseasesDashboardLinks.styles';

const DiseasesDashboardLinks: FC = () => {
  return (
    <Stack spacing={15}>
      <AspectRatio ratio={335 / 176}>
        <Styled.DiseasesDashboardMapLink to="alert-reports/map">
          <Stack h="100%" w="100%" px={15} py={12} justify="end" spacing={5}>
            <Group spacing={5}>
              <ThemeIcon size={34} bg="transparent" c="white">
                <IconMap2 strokeWidth={1.8} />
              </ThemeIcon>
              <Text tt="uppercase" color="white" fw={600}>
                Carte Signalements
              </Text>
            </Group>

            <Card bg="rgba(255, 255, 255, 0.25)" c="white" withBorder={false} radius={8} px={10} py={7} fz={14}>
              Visualiser les signalements de bio-agresseurs
            </Card>
          </Stack>
        </Styled.DiseasesDashboardMapLink>
      </AspectRatio>

      <SimpleCard to="alert-reports/new" icon={IconCirclePlus} title="Envoyer un signalement" bg="primary.4">
        Déclarer tout type de bio-agresseurs.
      </SimpleCard>

      <SimpleCard to="alert-reports/list" icon={IconChartBar} title="Liste de mes signalements" bg="primary.5">
        Liste des signalements de ma société
      </SimpleCard>
    </Stack>
  );
};

export default DiseasesDashboardLinks;
